import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import * as S from './styles'

const SaveButton = ({
  submitLabel,
  onSave,
  isSaving,
  canSave,
  ...buttonProps
}) => (
  <S.SaveButton
    onClick={onSave}
    disabled={!canSave}
    color={'primary'}
    {...buttonProps}
  >
    {isSaving ? <Spinner /> : submitLabel}
  </S.SaveButton>
)

SaveButton.propTypes = {
  submitLabel: PropTypes.string,
  isSaving: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  canSave: PropTypes.bool,
}

SaveButton.defaultProps = {
  submitLabel: 'SAVE',
  isSaving: false,
  canSave: true,
}

export default SaveButton
