import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-align: left;
  gap: 12px;
`

export const Item = styled.div`
  padding: 10px 20px;
  transition: 0.2 all ease;
  ${(props) =>
    props.disabled &&
    `
    opacity: .3;
    pointer-events: none;
  `}

  :hover {
    background: var(--white-90);
    cursor: pointer;
  }
`
