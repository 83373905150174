export default function Bell({ ...svgProps }) {
  return (
    <svg
      width="100pt"
      height="100pt"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path d="m78.309 63.438v-19.855c0.007812-6.0078-1.8984-11.859-5.4414-16.711-3.5469-4.8516-8.5469-8.4453-14.273-10.258 0.94141-2.7734 0.48828-5.8281-1.2148-8.207s-4.4531-3.793-7.3789-3.793-5.6758 1.4141-7.3789 3.793-2.1562 5.4336-1.2148 8.207c-5.7188 1.8281-10.707 5.4258-14.25 10.273-3.5469 4.8438-5.457 10.691-5.4648 16.695v19.855c-5.4922 0.70312-9.6094 5.375-9.6172 10.914 0.007812 6.0703 4.9258 10.992 10.996 11h14.008c0.050781 5.5117 4.5312 9.9531 10.043 9.9609h5.7578c5.5156 0.007812 10-4.4375 10.043-9.9492h14.008c6.0703-0.007812 10.988-4.9297 10.996-11 0-5.543-4.1172-10.223-9.6172-10.926zm-28.309-54.062c1.4961-0.015625 2.8945 0.74219 3.7031 2 0.80469 1.2617 0.91016 2.8477 0.26953 4.2031-2.6328-0.41797-5.3125-0.41797-7.9492 0-0.26953-0.57031-0.41016-1.1875-0.41406-1.8164 0.007813-2.4219 1.9688-4.3828 4.3906-4.3867zm6.6719 79.672c-1.0078 1-2.3711 1.5625-3.793 1.5625h-5.7578c-2.918-0.007813-5.2969-2.3477-5.3477-5.2656h16.453c-0.023437 1.3906-0.57812 2.7188-1.5547 3.707zm24.703-10.227h0.003906c-1.1758 1.1875-2.7812 1.8516-4.4492 1.8438h-53.859c-3.4805-0.007812-6.3047-2.8281-6.3086-6.3125 0.007812-3.4844 2.8281-6.3047 6.3086-6.3125h0.96484c1.293 0 2.3438-1.0508 2.3438-2.3438v-22.113c0-8.4375 4.5-16.238 11.809-20.457s16.316-4.2188 23.625 0 11.809 12.02 11.809 20.457v22.121c0 1.293 1.0508 2.3438 2.3438 2.3438h0.96484c3.4844 0.003906 6.3047 2.8281 6.3086 6.3125 0.007813 1.6797-0.66406 3.2891-1.8594 4.4688z" />
    </svg>
  )
}

Bell.propTypes = {}

Bell.defaultProps = {}
