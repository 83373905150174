import styled from 'styled-components'
import ChevronUp from '../../assets/components/icon_chevron_up'
import Column from '../Column'
import Label from '../Label'
import Row from '../Row'

export const CustomRow = styled(Row)`
  gap: 20px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`

export const CustomLabel = styled(Label)`
  cursor: pointer;
`

export const CustomChevronUp = styled(ChevronUp)`
  transform: ${(props) => (props.$show ? 'rotate(180deg)' : 'rotate(0deg)')};
  cursor: pointer;
  user-select: none;
  transition: 0.2s all ease;
  will-change: transform;
`

export const CustomColumn = styled(Column)`
  max-height: ${(props) => (props.$show ? `${props.$maxHeight ?? 1000}px` : 0)};
  overflow: hidden;
  transition: max-height 0.2s linear;
`
