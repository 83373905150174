import styled from 'styled-components'
import FloatingButton from '../FloatingButton'

export const RedButton = styled(FloatingButton)`
  width: 90px;
  font-size: 14px;
  color: var(--red);
  margin: 4px;
  font-family: var(--semibold-font);
  background-color: var(--red-78);
  border-color: var(--red-78);

  :hover {
    background-color: var(--red-78);
    border-color: var(--red-78);
  }
`
