import TextareaAutosize from 'react-autosize-textarea'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import Column from '../Column'
import Input from '../Input'
import Row from '../Row'

export const LineInput = styled(Input)`
  padding: 0;
  border: 0;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--white-30);
  background: #00000000;

  &:focus {
    outline: none;
    border-bottom: 1px solid var(--aqua-59);
  }

  &[type='checkbox'] {
    appearance: none;
    display: grid;
    place-content: center;
    width: 18px;
    height: 18px;
    margin: 0;
    font: inherit;
    color: currentColor;
    border: 0.15em solid var(--aqua-49);
    border-radius: 3px;
    transition: background-color 120ms ease-in-out;
  }

  &[type='checkbox']:checked {
    background-color: var(--aqua-49);
  }

  &[type='checkbox']:disabled {
    background-color: var(--white-70);
    border: 0.15em solid var(--white-70);
  }

  &[type='checkbox']:before {
    background-color: var(--white);
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    transform-origin: center center;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &[type='checkbox']:checked:before {
    transform: scale(1);
  }

  &[type='radio'] {
    width: 18px;
    height: 18px;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--aqua-49);
    border-radius: 50%;
    display: grid;
    place-content: center;
  }

  &[type='radio']:before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background: var(--aqua-49);
  }

  &[type='radio']:checked:before {
    transform: scale(1);
  }

  &[type='date'] {
    appearence: none;
    background: none;

    ${isMobile &&
    `
      display: block;
      color: var(--black);
      background: var(--white-95);
    `}

    ${(props) =>
      !props.disabled &&
      `
      cursor: pointer;
    `}

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  &[type='file'] {
    border-bottom: transparent;
  }

  ${(props) =>
    props.disabled &&
    `
    border-bottom-color: transparent;
  `}
`

export const RadioGroup = styled(Column)``

export const Radio = styled(Row)`
  align-items: center;
`

export const Textarea = styled(TextareaAutosize)`
  border: none;
  border-bottom: var(--border);
  border-radius: 0px;
  color: var(--black);
  resize: none;
  width: 100%;
  padding: 0 0 0;
  font-size: 16px;
  position: relative;

  ::placeholder {
    opacity: 0.5;
    text-transform: capitalize;
    font-family: var(--light-font);
  }

  ${(props) =>
    props.disabled &&
    `
    background: none;
    border-bottom: none;
    opacity: 1;
    color: var(--black);
  `}
`
