import { toolkitApi } from '.'
import { getToken } from '../getters'
import { serverDelete, serverGet, serverPost, serverPut } from './server'

const companyApi = toolkitApi.injectEndpoints({
  endpoints: (builder) => ({
    create: builder.mutation({
      query: (body) => ({
        url: '/company',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export async function createCompanyOnServer(company) {
  return await serverPost('/company', { body: company })
}

export async function removeCompanyOnServer(companyId) {
  return await serverDelete(`/company/${companyId}`)
}

export async function updateCompanyOnServer(companyId, company) {
  return await serverPut(`/company/${companyId}`, { body: company })
}

export async function getCompanyFromServer(companyId) {
  return await serverGet(`/company/${companyId}`)
}

export async function getCompanyPictureUrlFromServer(companyId, isGuest) {
  return await serverGet(
    !isGuest
      ? `/company/${companyId}/picture/url`
      : `/guest/company/picture/url`
  )
}

export async function uploadCompanyPictureToServer(
  companyId,
  formData,
  token = getToken()
) {
  return await fetch(
    `${process.env.REACT_APP_SERVER_LOCATION}/company/${companyId}/picture`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  )
}

export const { useCreateMutation: useCreateCompanyMutation } = companyApi
