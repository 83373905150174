import { createSelector } from '@reduxjs/toolkit'
import { chain, isEmpty, map, prop, unnest } from 'ramda'
import { OWNER_FIELD } from './constants'
import {
  appGetCompanyById,
  appGetProjectById,
  appGetSelectedCompanyCustomFields,
  appGetSelectedCompanyStates,
} from './reducers/app/utils'
import { getFilteredEntities } from './utils'
import { filterNotNil, findId } from './utils/ramdaExtensions'

const DEFAULT_SHOW_DETAILS = true
const DEFAULT_SORT_BY = {
  field: 'child_updated_at',
  order: 'desc',
}

export const getApp = (state) => state.app
export const getPoller = (state) => state.poller

export const selectApp = createSelector([getApp], (app) => {
  const {
    root,
    companyFilters,
    companyShowDetails,
    companyOpenSidebarMenu,
    companySortBy,
    companySelectedProjectIdList,
    companySelectedFilteredTags,
    selectedCompanyId,
  } = app

  const { user, workflows } = root ?? {}

  const selectedCompany = appGetCompanyById(app, selectedCompanyId)

  const selectedProjectIdList =
    companySelectedProjectIdList?.[selectedCompanyId] ?? []

  const selectedProjectList =
    filterNotNil(
      selectedProjectIdList.map((projectId) =>
        appGetProjectById(app, projectId)
      )
    ) ?? []

  const selectedCompanyProjects = selectedCompany?.projects ?? []

  const shownProjectList = !isEmpty(selectedProjectList)
    ? selectedProjectList
    : selectedCompany?.projects ?? []

  const taskList = chain(prop('tasks'))(shownProjectList)

  const tagList = unnest(map(prop('tags'))(selectedCompany?.workflows ?? []))

  const members = selectedCompany?.members ?? []

  const outsiders = selectedCompany?.outsiders ?? []
  const outsiderGroups = selectedCompany?.outsider_groups ?? []

  const filter = companyFilters?.[selectedCompanyId] ?? {}

  const filteredTasks = getFilteredEntities({ entityList: taskList, filter })
  const filteredTasksByOwner = getFilteredEntities({
    entityList: taskList,
    filter: { [OWNER_FIELD]: filter[OWNER_FIELD] },
  })

  const stateList = appGetSelectedCompanyStates(app)
  const customFieldList = appGetSelectedCompanyCustomFields(app)

  const allSelectedCompanyTasks = unnest(
    map(prop('tasks'))(selectedCompanyProjects)
  )

  const activeSidebarMenu = companyOpenSidebarMenu?.[selectedCompanyId] ?? null

  const allProjects = chain(prop('projects'))(user?.companies ?? [])
  const allTasks = chain(prop('tasks'))(allProjects)

  const companyList = user?.companies ?? []

  const showDetails =
    companyShowDetails?.[selectedCompanyId] ?? DEFAULT_SHOW_DETAILS
  const sortBy = companySortBy?.[selectedCompanyId] ?? DEFAULT_SORT_BY

  const selectedFilterTags =
    companySelectedFilteredTags?.[selectedCompanyId] ?? []

  const userMember = findId(user?.id)(selectedCompany?.members ?? [])

  const userMembership = userMember?.company_member

  const isUserOwner = userMembership?.role === 'owner'

  const isUserAdmin = isUserOwner || userMembership?.role === 'admin'

  const isUserCollaborator = isUserAdmin || userMembership?.role === 'member'

  return {
    ...app,
    filter,
    filteredTasks,
    filteredTasksByOwner,
    stateList,
    companyList,
    user,
    workflows,
    members,
    outsiders,
    outsiderGroups,
    customFieldList,
    selectedCompany,
    activeSidebarMenu,
    showDetails,
    sortBy,
    selectedFilterTags,
    allSelectedCompanyTasks,
    selectedCompanyProjects,
    selectedProjectList,
    shownProjectList,
    allProjects,
    allTasks,
    isUserOwner,
    isUserAdmin,
    isUserCollaborator,
    taskList,
    tagList,
  }
})
export const selectPoller = createSelector([getPoller], (poller) => poller)
