import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import * as S from './styles'

const TitleContent = ({ title, controls, children }) => (
  <>
    <S.Header>
      <S.Title>{title}</S.Title>
      {!isEmpty(controls) && <S.Controls>{controls}</S.Controls>}
    </S.Header>
    {children}
  </>
)

TitleContent.propTypes = {
  title: PropTypes.string.isRequired,
  controls: PropTypes.node,
  children: PropTypes.node.isRequired,
}

TitleContent.defaultProps = {
  controls: null,
}

export default TitleContent
