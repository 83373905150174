import PeopleStatusList from '../PeopleStatusList'
import ProjectList from '../ProjectList'
import TagFilterList from '../TagFilterList'

export function getDrawerContent(activeMenu) {
  if (activeMenu === 'project') {
    return <ProjectList />
  }

  if (activeMenu === 'people' || activeMenu === 'status') {
    return <PeopleStatusList type={activeMenu} />
  }

  if (activeMenu === 'tags') {
    return <TagFilterList />
  }

  return null
}
