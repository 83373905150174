import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { find, propEq } from 'ramda'
import { useState } from 'react'
import { EditFormModal } from '..'

const EditWorkflowModal = ({
  workflow,
  workflows,
  $isNew,
  onClose,
  onSave,
}) => {
  const [workflowToEdit, setWorkflowToEdit] = useState(workflow)

  function handleClose() {
    onClose(workflowToEdit)
  }

  function handleSave() {
    onSave(workflowToEdit)
  }

  return (
    <EditFormModal
      title={`WORKFLOW`}
      $isNew={$isNew}
      fields={[
        {
          title: 'NAME',
          type: 'text',
          value: workflowToEdit.name,
          placeholder: 'Type Workflow Name here',
          onChange: (name) => {
            setWorkflowToEdit({
              ...workflowToEdit,
              name,
            })
          },
          errors: (() => {
            if (isEmpty(workflowToEdit.name)) {
              return ['Cannot have empty workflow title.']
            }

            const sameTitleWorkflow = find(propEq('name', workflowToEdit.name))(
              workflows
            )

            const isDuplicatedWorkflowName =
              sameTitleWorkflow &&
              ($isNew || workflow.name !== sameTitleWorkflow.name)

            if (isDuplicatedWorkflowName) {
              return ['Cannot have duplicated workflow name.']
            }
            return []
          })(),
        },
        {
          title: 'SHOW PRIORITY',
          type: 'checkbox',
          value: !workflowToEdit.task_template.hide_priority,
          onChange: (show_priority) => {
            setWorkflowToEdit({
              ...workflowToEdit,
              task_template: {
                ...workflowToEdit.task_template,
                hide_priority: !show_priority,
              },
            })
          },
        },
        {
          title: 'SHOW DUE DATE',
          type: 'checkbox',
          value: !workflowToEdit.task_template.hide_deadline,
          onChange: (show_deadline) => {
            setWorkflowToEdit({
              ...workflowToEdit,
              task_template: {
                ...workflowToEdit.task_template,
                hide_deadline: !show_deadline,
              },
            })
          },
        },
      ]}
      onClose={handleClose}
      onCancel={handleClose}
      onSave={handleSave}
    />
  )
}

EditWorkflowModal.propTypes = {
  workflow: PropTypes.object.isRequired,
  workflows: PropTypes.array.isRequired,
  $isNew: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

EditWorkflowModal.defaultProps = {}

export default EditWorkflowModal
