import { Button } from '..'
import * as S from './styles'

const GrayButton = ({ ...buttonProps }) => <S.GrayButton {...buttonProps} />

GrayButton.propTypes = {
  ...Button.propTypes,
}

GrayButton.defaultProps = {}

export default GrayButton
