import { useAuth0 } from '@auth0/auth0-react'
import { setToken } from '../../actions'
import { track } from '../../utils/mixpanel'

const useLogout = () => {
  const { logout } = useAuth0()

  function handleLogout() {
    setToken(null)

    track('Logout')

    logout({ returnTo: window.location.origin })
  }

  return { handleLogout }
}

export default useLogout
