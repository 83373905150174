import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { getHslaFromHex } from '../../utils'

export const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  border-radius: var(--border-radius);
  padding: 15px;
  height: 75px;
  border: 2px solid transparent;
  gap: 10px;
  background: ${(props) => (props.$active ? 'var(--white)' : 'transparent')};
  width: 100%;
  transition: 0.2s all ease;

  :hover {
    cursor: pointer;

    ${(props) =>
      !props.selected &&
      `
      border: 2px solid var(--white-50);
    `}
  }

  ${(props) =>
    props.selected &&
    `
    cursor: default;
    background: var(--white);
    box-shadow: var(--box-shadow);
    border: none;
  `}

  ${(props) =>
    props.color &&
    `
    border-left: 8px solid ${getHslaFromHex({
      hex: props.color,
      opacity: 0.5,
    })}};
  `}
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 44px;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Title = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 18px;
  user-select: none;

  ${isMobile &&
  `
    max-width: 115px;

  `}

  ${(props) =>
    props.$isPeopleList &&
    isMobile &&
    `
        max-width: 180px;
    `}
`

export const Icon = styled.div`
  width: 35px;
  height: 35px;
`

export const LastUpdate = styled.span`
  font-size: 13px;
  height: fit-content;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${isMobile &&
  `
    width: fit-content;
  `}
`

export const TaskCount = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: var(--semibold-font);
  font-size: 12px;
  text-transform: uppercase;
`
