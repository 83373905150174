import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  lastUpdateDate: new Date(null).toISOString(),
}

const pollerSlice = createSlice({
  name: 'poller',
  initialState,
  reducers: {
    setLastUpdateDate(state, { payload: lastUpdateDate }) {
      return assoc('lastUpdateDate', lastUpdateDate, state)
    },
  },
})

export const { setLastUpdateDate } = pollerSlice.actions

export default pollerSlice.reducer
