export function getColor(color) {
  if (color === 'secondary') {
    return `
      background: var(--white-95);
      color: var(--white-30);
    `
  }

  if (color === 'highlight') {
    return `
      background: var(--black);
      color: var(--white);
    `
  }

  return `
    background: var(--white);
    color: var(--aqua-59);
  `
}
