import { toolkitApi } from '.'
import { serverPost, serverPut } from './server'

const authApi = toolkitApi.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (body) => ({
        url: '/signup',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useSignupMutation } = authApi

export async function createUserProfileOnServer(profile) {
  return await serverPost('/signup', { body: profile })
}

export async function updateUserProfileOnServer(profile) {
  return await serverPut('/user/profile', { body: profile })
}
