import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { setGuestToken, setIsGuest, setSkip, setToken } from '../../actions'
import { selectApp } from '../../selectors'

const useAppAuth = () => {
  const { root } = useSelector(selectApp)

  const {
    isLoading: isAuthLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0()

  const { hash } = window.location

  const hashParams = new URLSearchParams(`?${hash.slice(1)}`)

  const hashToken = hashParams.get('token')

  useEffect(() => {
    if (hashToken) {
      setIsGuest(true)
      setGuestToken(hashToken)
      setSkip(false)
    }
  }, [hashToken])

  useEffect(() => {
    ;(async () => {
      if (hashToken) {
        return
      }

      if (root) {
        return
      }

      if (isAuthLoading) {
        return
      }

      try {
        const auth0Token = await getAccessTokenSilently()

        setToken(auth0Token)

        setSkip(false)
      } catch (err) {
        loginWithRedirect()
      }
    })()
  }, [
    hashToken,
    root,
    isAuthLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  ])
}

export default useAppAuth
