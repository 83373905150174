import styled from 'styled-components'
import { EditForm } from '..'
import { fadeIn } from '../../styles/animations'

export const Container = styled.div`
  display: grid;
  place-items: center;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  gap: 20px;
  height: calc(100vh - var(--header-height));
  width: 200px;

  svg {
    transform: none !important;
  }
`

export const Text = styled.p`
  opacity: 0;
  animation: ${fadeIn} 0.6s ease forwards;
  animation-delay: 0.3s;
`

export const StyledForm = styled(EditForm)`
  border-radius: 6px;
  width: 250px;
  animation-delay: 0.6s;
`

export const Logo = styled.img`
  width: 100%;
`
