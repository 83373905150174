import PropTypes from 'prop-types'

export default function Projects({ fill }) {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5078 15.9332C19.5078 16.4047 19.3182 16.8568 18.9806 17.1902C18.643 17.5236 18.1852 17.7109 17.7078 17.7109H3.30781C2.83042 17.7109 2.37259 17.5236 2.03502 17.1902C1.69745 16.8568 1.50781 16.4047 1.50781 15.9332V3.48872C1.50781 3.01722 1.69745 2.56503 2.03502 2.23164C2.37259 1.89824 2.83042 1.71094 3.30781 1.71094H7.54207C7.70822 1.71094 7.86353 1.79348 7.95649 1.9312L9.45913 4.15734C9.5521 4.29506 9.7074 4.3776 9.87356 4.3776H17.7078C18.1852 4.3776 18.643 4.56491 18.9806 4.8983C19.3182 5.2317 19.5078 5.68389 19.5078 6.15538V15.9332Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Projects.propTypes = {
  fill: PropTypes.string,
}

Projects.defaultProps = {
  fill: '#808080',
}
