import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 60px;
  align-items: center;
  gap: 8px;
`

export const Name = styled.span`
  min-width: calc(100% - 70px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: unset;
`
