import PropTypes from 'prop-types'
import { Dropdown, Label } from '..'
import { findValueEq, keyMap } from '../../utils/ramdaExtensions'
import * as S from './styles'

const SelectWorkflow = ({
  workflow,
  workflows,
  $isEditMode,
  required,
  disabled,
  onWorkflowChange,
  onEditWorkflowClick,
}) => {
  const options = workflows?.map(keyMap({ value: 'id', label: 'name' }))

  const selectedWorkflowId = workflow?.id

  const selectedWorkflowOption = findValueEq(selectedWorkflowId, options ?? [])

  const handleWorkflowChange = ({ value }) => {
    onWorkflowChange(value)
  }

  const handleWorkflowClick = () => {
    if ($isEditMode) {
      onEditWorkflowClick()
    }
  }

  return (
    <S.WorkflowContainer
      disabled={!$isEditMode}
      $isEditMode={$isEditMode}
      onClick={handleWorkflowClick}
    >
      <Label required={required}>WORKFLOW</Label>
      <Dropdown
        options={options}
        disabled={disabled}
        value={selectedWorkflowOption}
        onChange={handleWorkflowChange}
        borderless
      />
    </S.WorkflowContainer>
  )
}

SelectWorkflow.propTypes = {
  workflow: PropTypes.any.isRequired,
  workflows: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  $isEditMode: PropTypes.bool.isRequired,
  onWorkflowChange: PropTypes.func.isRequired,
  onEditWorkflowClick: PropTypes.func.isRequired,
}

export default SelectWorkflow
