import { useLocation, useNavigate } from 'react-router-dom'

export default function useNavigatePreserve(
  keepHash = true,
  keepSearch = false
) {
  const navigate = useNavigate()
  const location = useLocation()

  function navigateWithHash(path) {
    return navigate(
      `${path}${keepSearch ? location.search : ''}${
        keepHash ? location.hash : ''
      }`
    )
  }

  return navigateWithHash
}
