import { curry, sort } from 'ramda'
import { isBefore } from '../date'

const FAR_AWAY_DATE = new Date('3000-01-01').toISOString()

const sortByDate = curry((keyName, array) =>
  sort((a, b) =>
    isBefore(a[keyName] ?? FAR_AWAY_DATE, b[keyName] ?? FAR_AWAY_DATE) ? 1 : -1
  )(array)
)

export default sortByDate
