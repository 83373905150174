import { bindActionCreators } from '@reduxjs/toolkit'
import * as appActions from './reducers/app'
import * as pollerActions from './reducers/poller'
import store from './store'

export const {
  setIsGuest,
  setGuestToken,
  setRoot,
  setIsLoading,
  setData,
  setIsSuccess,
  setError,
  setSelectedTask,
  setAttachmentsToUpload,
  setToken,
  setSkip,
  setWorkflows,
  setPreview,
  setLastSeenDate,
  setShowUpsertTaskModal,
  setShowCreateProjectModal,
  setHasTaskToEdit,
  setShowDetails,
  setSortBy,
  setFilter,
  setNewTaskInit,
  setSelectedProjectIdList,
  setSelectedCompanyId,
  setShowInvites,
  setInvalidToken,
  setProjectIdToEdit,
  setProjectIdToPeople,
  setProjectToDelete,
  setProjectSelected,
  setActiveSidebarMenu,
  createWorkflow,
  createCompany,
  createProject,
  createTask,
  patchRoot,
  patchUser,
  patchCompany,
  patchProject,
  patchTask,
  deleteProject,
  deleteTask,
  deleteTasks,
  upsertTask,
  setShowMobileSidebar,
  setSelectedFilterTags,
  setMemberToEdit,
  setCompanyPictureLoading,
} = bindActionCreators(appActions, store.dispatch)

export const { setLastUpdateDate } = bindActionCreators(
  pollerActions,
  store.dispatch
)
