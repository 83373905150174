import { SubMenu } from '@szhsin/react-menu'
import styled from 'styled-components'
import { ICONS } from '../../assets'
import Settings from '../../assets/components/icon_settings'
import WhiteButton from '../WhiteButton'

export const MENU_STYLE = {
  transform: 'translate(-10px, 10px)',
  background: 'var(--white)',
  borderRadius: '9px',
  zIndex: '99999',
  border: `1px solid var(--white-62)`,
  padding: 'none',
  paddingLeft: '0',
  width: 150,
  boxShadow: 'var(--box-shadow)',
}

export const StyledButton = styled(WhiteButton)`
  height: 44px;
  min-width: 44px;
  padding: 0;

  ${(props) =>
    props.$small &&
    `
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-color: var(--white-80) !important;
    border-width: 1px;
  `}
`

export const SettingsIcon = styled(Settings)`
  width: 21px;
  height: 21px;
`

export const MenuButtonContainer = styled.div`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`

export const StyledSubmenu = styled(SubMenu)`
  list-style: none;
  border-radius: 6px;
  background: url(${ICONS.arrowHeadLeft}) no-repeat center left 7px;
  background-size: 5px;

  > div {
    padding: 10px 20px;
  }

  > ul {
    background: var(--white);
    list-style: none;
    padding: 0;
    border: 1px solid var(--white-62);
    transform: translate(-5px, 5px);
    border-radius: var(--border-radius);
    max-height: 400px;
    overflow: scroll;

    li {
      max-width: 210px;

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`
