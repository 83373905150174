import { getToken } from '../getters'
import { serverDelete, serverGet } from './server'

export async function readAttachmentUrlFromServer(
  companyId,
  projectId,
  taskId,
  attachmentId,
  fileId,
  isGuest
) {
  return await serverGet(
    !isGuest
      ? `/company/${companyId}/project/${projectId}/task/${taskId}/attachment/${attachmentId}/file/${fileId}/url`
      : `/guest/task/${taskId}/attachment/${attachmentId}/file/${fileId}/url`
  )
}

export async function deleteAttachmentFileFromServer(
  companyId,
  projectId,
  taskId,
  attachmentId,
  fileId
) {
  return await serverDelete(
    `/company/${companyId}/project/${projectId}/task/${taskId}/attachment/${attachmentId}/file/${fileId}`
  )
}

export async function uploadAttachmentFilesToServer(
  companyId,
  projectId,
  taskId,
  formData,
  isGuest,
  token = getToken()
) {
  return await fetch(
    !isGuest
      ? `${process.env.REACT_APP_SERVER_LOCATION}/company/${companyId}/project/${projectId}/task/${taskId}/attachment`
      : `${process.env.REACT_APP_SERVER_LOCATION}/guest/task/${taskId}/attachment`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  )
}
