import { Button } from '..'
import * as S from './styles'

const GreenButton = ({ ...buttonProps }) => <S.GreenButton {...buttonProps} />

GreenButton.propTypes = {
  ...Button.propTypes,
}

GreenButton.defaultProps = {}

export default GreenButton
