import { curry, map } from 'ramda'

const mapPatch = curry((idField, id, data, list) =>
  map(
    (entry) =>
      entry[idField] === id
        ? {
            ...entry,
            ...data,
          }
        : entry,
    list
  )
)

export default mapPatch
