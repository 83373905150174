import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CompanyUserSettingsPropType } from '../../props'
import { selectApp } from '../../selectors'
import * as S from './styles'

const EditCompanyUserSettingsModal = ({ settings, onClose, onSave }) => {
  const { isGuest } = useSelector(selectApp)

  const [settingsToEdit, setSettingsToEdit] = useState(settings)

  function handleClose() {
    onClose(settingsToEdit)
  }

  function handleSave() {
    onSave(settingsToEdit)
  }

  return (
    <S.CustomEditFormModal
      title={`Editing Notifications`}
      fields={[
        {
          title: 'SEND TASK UPDATE',
          type: 'checkbox',
          value: settingsToEdit.receive_task_update,
          disabled: isGuest,
          onChange: (value) => {
            setSettingsToEdit({
              ...settingsToEdit,
              receive_task_update: value,
            })
          },
        },
        {
          title: 'SEND DUE DATE REMINDER',
          type: 'checkbox',
          disabled: isGuest,
          value: settingsToEdit.receive_task_reminder,
          onChange: (value) => {
            setSettingsToEdit({
              ...settingsToEdit,
              receive_task_reminder: value,
            })
          },
        },
      ]}
      $isNew={false}
      onClose={handleClose}
      onCancel={handleClose}
      onSave={handleSave}
      canSave={!isGuest}
    />
  )
}

EditCompanyUserSettingsModal.propTypes = {
  settings: CompanyUserSettingsPropType.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

EditCompanyUserSettingsModal.defaultProps = {}

export default EditCompanyUserSettingsModal
