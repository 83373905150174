import styled from 'styled-components'
import FloatingButton from '../FloatingButton'

export const WhiteButton = styled(FloatingButton)`
  border-color: var(--white);
  background: var(--white);
  color: var(--white-62);

  :hover {
    border-color: var(--white);
  }

  :active {
    border-color: var(--white-62);
  }
`
