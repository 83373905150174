import { toolkitApi } from '.'

const projectApi = toolkitApi.injectEndpoints({
  endpoints: (builder) => ({
    createProject: builder.mutation({
      query: ({ companyId, body }) => ({
        url: `/company/${companyId}/project`,
        method: 'POST',
        body,
      }),
    }),
    updateProject: builder.mutation({
      query: ({ companyId, projectId, body }) => ({
        url: `/company/${companyId}/project/${projectId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteProject: builder.mutation({
      query: ({ companyId, projectId }) => ({
        url: `/project/${projectId}?company_id=${companyId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = projectApi
