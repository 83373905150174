import PropTypes from 'prop-types'
import { isNil } from 'ramda'
import { useSelector } from 'react-redux'
import { UserBadge } from '..'
import { useGetTaskOwnerFullname } from '../../hooks'
import { selectApp } from '../../selectors'
import { findId } from '../../utils/ramdaExtensions'
import * as S from './styles'

const TaskOwnerBadge = ({ id, ...rest }) => {
  const { members } = useSelector(selectApp)

  const member = findId(id)(members)

  const ownerName = useGetTaskOwnerFullname(id)

  if (isNil(id)) {
    return <S.Owner {...rest}>—</S.Owner>
  }

  return <UserBadge name={ownerName} pictureUrl={member?.profile?.picture} />
}

TaskOwnerBadge.propTypes = {
  id: PropTypes.string,
}

TaskOwnerBadge.defaultProps = {
  id: null,
}

export default TaskOwnerBadge
