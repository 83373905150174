import PropTypes from 'prop-types'
import iconX from '../../assets/icon_x.svg'
import { hasId } from '../../utils/ramdaExtensions'
import * as S from './styles'

const AttachmentFile = ({
  file,
  style,
  disabled,
  $touchable,
  isRemovable,
  showIcon,
  onClick,
  onXClick,
  ...buttonProps
}) => (
  <S.File
    type="button"
    key={file.id}
    $isNew={!hasId(file)}
    $touchable={$touchable}
    disabled={disabled}
    style={style}
    onClick={onClick}
    {...buttonProps}
  >
    {showIcon && <S.FilePaperclipIcon />}
    <S.FileText>{file.filename}</S.FileText>
    {isRemovable && <S.FileXIcon src={iconX} onClick={onXClick} />}
  </S.File>
)

AttachmentFile.propTypes = {
  file: PropTypes.any.isRequired,
  $touchable: PropTypes.bool,
  style: PropTypes.any,
  onClick: PropTypes.func,
  onXClick: PropTypes.func,
  disabled: PropTypes.bool,
  isRemovable: PropTypes.bool,
  showIcon: PropTypes.bool,
}

AttachmentFile.defaultProps = {
  disabled: false,
  $touchable: false,
  isRemovable: false,
  showIcon: true,
  style: {},
  onXClick: () => {},
  onClick: () => {},
}

export default AttachmentFile
