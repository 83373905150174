import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

export const Container = styled(ToastContainer)`
  @media screen and (max-width: 700px) {
    .Toastify__toast {
      border-top: 2px solid var(--aqua-49);
    }
  }
`
