import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Dropdown, ErrorLabel, FormFooter } from '..'
import * as S from './styles'

const Container = ({ field, errors, children, fullHeight }) => (
  <S.Container fullHeight={fullHeight} isTitle={field.name === 'title'}>
    <S.Label htmlFor={field.name}>{field.label || field.name}</S.Label>
    {children}
    {errors[field.name] && (
      <ErrorLabel>
        {field.requiredMessage || 'This field is required'}
      </ErrorLabel>
    )}
  </S.Container>
)

const Form = ({ onSubmit, fields, actions }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.ScrollBody>
        {fields.map((field) => {
          const containerProps = {
            field,
            errors,
          }

          if (field.type === 'textarea') {
            return (
              <Container {...containerProps} key={field.key} fullHeight>
                <S.Textarea
                  {...field}
                  required={false}
                  {...register(field.name, { required: field.required })}
                />
              </Container>
            )
          }

          if (field.type === 'select') {
            return (
              <Container {...containerProps} key={field.key}>
                <Controller
                  control={control}
                  name={field.name}
                  defaultValue={field.defaultValue?.value}
                  rules={{ required: field.required }}
                  render={(controllerFields) => (
                    <Dropdown
                      {...field}
                      onChange={(val) =>
                        field.isMulti
                          ? controllerFields.field.onChange(val)
                          : controllerFields.field.onChange(val.value)
                      }
                    />
                  )}
                />
              </Container>
            )
          }

          return (
            <Container {...containerProps} key={field.key}>
              <S.Input
                required={false}
                {...field}
                {...register(field.name, { required: field.required })}
              />
            </Container>
          )
        })}
      </S.ScrollBody>

      <FormFooter actions={actions} />
    </S.Form>
  )
}

Container.propTypes = {
  field: PropTypes.object,
  errors: PropTypes.object,
  children: PropTypes.node.isRequired,
  fullHeight: PropTypes.bool,
}

Container.defaultProps = {
  fullHeight: false,
  field: {},
  errors: {},
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      requiredMessage: PropTypes.string,
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      shortcut: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
}

export default Form
