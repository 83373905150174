import { useSelector } from 'react-redux'
import { selectApp } from '../../selectors'
import { findId } from '../../utils/ramdaExtensions'

export default function useGetTaskColor(id) {
  const { stateList } = useSelector(selectApp)

  const currentState = findId(id)(stateList)

  return currentState ? currentState.default_color : 'var(--white-90)'
}
