import { map } from 'ramda'
import { PersonName } from '../../components'
import useGetParsedMemberList from '../useGetParsedMemberList'
import useGetParsedOutsiderList from '../useGetParsedOutsiderList'

export default function useGetParsedPersonList() {
  const memberList = useGetParsedMemberList()
  const outsiderList = useGetParsedOutsiderList()

  const peopleList = map((person) => {
    const title = person.isMember ? 'member' : 'guest'

    const tagProps = {
      tag: {
        title,
      },
      title,
      disabled: true,
      isPeopleTag: true,
    }

    const personNameProps = {
      name: person.full_name,
      tagProps,
    }

    return {
      ...person,
      full_name: <PersonName {...personNameProps} />,
    }
  })([...memberList, ...outsiderList])

  return peopleList
}
