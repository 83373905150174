import { ICONS } from '../../assets'

const { Projects, People, Tags, StatusWave: Status } = ICONS

export const MENU_OPTIONS = [
  {
    name: 'project',
    icon: Projects,
  },
  {
    name: 'people',
    icon: People,
  },
  {
    name: 'status',
    icon: Status,
  },
  {
    name: 'tags',
    icon: Tags,
  },
]
