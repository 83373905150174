import styled from 'styled-components'
import WhiteButton from '../WhiteButton'

export const AddButton = styled(WhiteButton)`
  color: var(--aqua-49);
  font-size: 14px;
  padding: 4px 10px;
  border: 1px solid var(--white-80);
  height: initial;
  font-family: var(--semibold-font);
  width: fit-content;

  :hover {
    color: var(--aqua-49);
    border: 1px solid var(--white-80);
  }
`
