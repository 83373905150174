import _ from 'lodash'
import PropTypes from 'prop-types'
import Shortcut from '../Shortcut'
import * as S from './styles'

const Button = ({
  children,
  shortcut,
  title,
  color,
  onClick,
  disabled,
  ...rest
}) => {
  const shortcutProps = {
    shortcut,
    action: onClick,
    color,
  }

  return (
    <S.Button onClick={onClick} color={color} disabled={disabled} {...rest}>
      {children ?? title}
      {!_.isNil(shortcut) && <Shortcut {...shortcutProps} />}
    </S.Button>
  )
}

Button.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  shortcut: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.oneOf(['primary', 'secondary', 'danger', 'highlight']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  title: '',
  children: null,
  shortcut: [],
  onClick: () => {},
  color: undefined,
  disabled: false,
}

export default Button
