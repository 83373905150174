import PropTypes from 'prop-types'
import { isEmpty, pick } from 'ramda'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { EditFormModal } from '..'
import {
  createProject,
  patchProject,
  setProjectIdToEdit,
  setProjectSelected,
  setShowCreateProjectModal,
} from '../../actions'
import { selectApp } from '../../selectors'
import {
  useCreateProjectMutation,
  useUpdateProjectMutation,
} from '../../services/projectApi'
import { getRandomEmoji } from '../../utils'
import {
  emojiListNegative,
  emojiListPositive,
} from '../../utils/getRandomEmoji'
import { track } from '../../utils/mixpanel'
import { hasId } from '../../utils/ramdaExtensions'

const EditProjectModal = ({ company, project }) => {
  const { selectedCompany } = useSelector(selectApp)

  const [updateProjectOnServer] = useUpdateProjectMutation()
  const [createProjectOnServer] = useCreateProjectMutation()

  function handleCloseModal() {
    setShowCreateProjectModal(false)
    setProjectIdToEdit(null)
  }

  const [editableProject, setEditableProject] = useState(
    project ?? { name: '' }
  )

  async function handleSubmit() {
    if (project) {
      const projectToSend = {
        name: editableProject.name,
      }

      const response = await updateProjectOnServer({
        companyId: company.id,
        projectId: project.id,
        body: projectToSend,
      })

      const { error } = response

      if (!error || error.originalStatus === 200) {
        handleCloseModal()

        patchProject({
          company,
          projectId: project.id,
          projectData: {
            ...projectToSend,
          },
        })

        toast(
          `Project updated successfully! ${getRandomEmoji(emojiListPositive)}`
        )
      } else {
        toast.error(
          `Could not update project. ${getRandomEmoji(emojiListNegative)}`
        )
      }
    } else {
      const body = {
        default_workflow_id: company.default_workflow_id,
        ...pick(['name'], editableProject),
      }

      const response = await createProjectOnServer({
        companyId: company.id,
        body,
      })

      const { error, data } = response

      if (!error || error.originalStatus === 200) {
        handleCloseModal()

        createProject({ company, project: data })

        if (selectedCompany.id === company.id) {
          setProjectSelected({ project: data, selected: true })
        }

        track('Project created')

        toast(
          `Project created successfully! ${getRandomEmoji(emojiListPositive)}`
        )
      } else {
        toast.error(
          `Could not create project. ${getRandomEmoji(emojiListNegative)}`
        )
      }
    }
  }

  return (
    <EditFormModal
      title={`PROJECT`}
      $isNew={!hasId(editableProject)}
      fields={[
        {
          title: 'NAME',
          type: 'text',
          value: editableProject.name,
          placeholder: 'Type Project Name here',
          errors: (() => {
            if (isEmpty(editableProject.name)) {
              return ['Cannot have empty project name.']
            }

            return []
          })(),
          onChange: (name) => {
            setEditableProject({
              ...editableProject,
              name,
            })
          },
        },
      ]}
      onClose={handleCloseModal}
      onCancel={handleCloseModal}
      onSave={handleSubmit}
    />
  )
}

EditProjectModal.propTypes = {
  company: PropTypes.object.isRequired,
  project: PropTypes.object,
}

EditProjectModal.defaultProps = {
  project: null,
}

export default EditProjectModal
