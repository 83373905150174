import PropTypes from 'prop-types'
import { ErrorLabel } from '..'
import Label from '../Label'
import * as S from './styles'

const InputContainer = ({ field, errors, children, fullHeight, hidden }) => (
  <S.Container fullHeight={fullHeight} hidden={hidden}>
    <Label htmlFor={field.name} required={field.required}>
      {field.label || field.name}
    </Label>
    {children}
    {errors[field.name] && (
      <ErrorLabel>
        {field.requiredMessage || 'This field is required'}
      </ErrorLabel>
    )}
  </S.Container>
)

InputContainer.propTypes = {
  field: PropTypes.object,
  errors: PropTypes.object,
  children: PropTypes.node.isRequired,
  fullHeight: PropTypes.bool,
  hidden: PropTypes.bool,
}

InputContainer.defaultProps = {
  fullHeight: false,
  hidden: false,
  field: {},
  errors: {},
}

export default InputContainer
