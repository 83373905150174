import { serverGet, serverPut } from './server'

export async function readGuestTaskOnServer(token) {
  return await serverGet(`/outsider/task`, {}, token)
}

export async function updateGuestTaskOnServer(token, task) {
  return await serverPut(
    `/outsider/task`,
    {
      body: task,
    },
    token
  )
}

export async function uploadGuestAttachmentFilesToServer(
  taskId,
  groupId,
  fileHandlers,
  token
) {
  const formData = new FormData()

  formData.set('group_id', groupId)

  fileHandlers.forEach((fileHandler) => {
    formData.append('files', fileHandler)
  })

  return await fetch(
    `${process.env.REACT_APP_SERVER_LOCATION}/outsider/task/${taskId}/attachment`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  )
}

export async function readAttachmentUrlFromServer(
  taskId,
  attachmentId,
  fileId,
  token
) {
  return await serverGet(
    `/outsider/task/${taskId}/attachment/${attachmentId}/file/${fileId}/url`,
    {},
    token
  )
}
