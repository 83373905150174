import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { defaultTo, prop } from 'ramda'
import { sortByCreatedAt } from '../../utils/ramdaExtensions'

dayjs.extend(relativeTime)

function getTaskField({ task, field }) {
  return defaultTo([], prop(field))(task)
}

export function addTypeToArray({ array = [], type }) {
  return array.map((props) => ({ ...props, type }))
}

export function getTaskAllHistory(task) {
  const typedStateChanges = addTypeToArray({
    array: getTaskField({ task, field: 'history_states' }),
    type: 'historyState',
  })
  const typedOwnerChanges = addTypeToArray({
    array: getTaskField({ task, field: 'history_owners' }),
    type: 'historyOwner',
  })
  const typedRequestorChanges = addTypeToArray({
    array: getTaskField({ task, field: 'history_requestors' }),
    type: 'historyRequestor',
  })
  const typedPriorityChanges = addTypeToArray({
    array: getTaskField({ task, field: 'history_priorities' }),
    type: 'historyPriority',
  })
  const typedDeadlineChanges = addTypeToArray({
    array: getTaskField({ task, field: 'history_deadlines' }),
    type: 'historyDeadline',
  })
  const typedCustomFieldChanges = addTypeToArray({
    array: getTaskField({ task, field: 'history_custom_fields' }),
    type: 'historyCustomField',
  })
  const typedTags = addTypeToArray({
    array: getTaskField({ task, field: 'history_tags' }),
    type: 'historyTag',
  })
  const typedNotes = addTypeToArray({
    array: getTaskField({ task, field: 'notes' }),
    type: 'note',
  })
  const typedAttachments = addTypeToArray({
    array: getTaskField({ task, field: 'attachments' }),
    type: 'attachment',
  })

  const history = sortByCreatedAt([
    ...typedStateChanges,
    ...typedOwnerChanges,
    ...typedRequestorChanges,
    ...typedPriorityChanges,
    ...typedDeadlineChanges,
    ...typedCustomFieldChanges,
    ...typedAttachments,
    ...typedNotes,
    ...typedTags,
  ])

  return history
}
