import PropTypes from 'prop-types'
import {
  and,
  defaultTo,
  filter,
  find,
  isEmpty,
  map,
  pipe,
  prop,
  propEq,
  reject,
} from 'ramda'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { EditFormModal, EditProjectList, Label } from '..'
import { patchCompany, setMemberToEdit } from '../../actions'
import { selectApp } from '../../selectors'
import { updateCompanyMemberOnServer } from '../../services/companyMemberApi'
import { getRandomEmoji } from '../../utils'
import {
  emojiListNegative,
  emojiListPositive,
} from '../../utils/getRandomEmoji'
import {
  decant,
  includesId,
  isNotEmpty,
  mapPatchId,
  pluckId,
} from '../../utils/ramdaExtensions'
import * as S from './styles'

export const SIMPLE_DROPDOWN_STYLES = {
  singleValue: (base) => ({
    ...base,
    fontSize: '14px',
    padding: 1,
    fontFamily: 'var(--font-family)',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  }),
  option: (base) => ({
    ...base,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    padding: 6,
    height: '30px',
  }),
  control: (base) => ({
    ...base,
    fontSize: '14px',
    height: '30px',
    minHeight: '0px',
    padding: 0,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: '30px',
    padding: 0,
  }),
}

const EditCompanyMemberModal = ({ company, member }) => {
  const { members } = useSelector(selectApp)

  const [localMemberToEdit, setLocalMemberToEdit] = useState(member)

  const [projectsToAdd, setProjectsToAdd] = useState([])
  const [projectsToRemove, setProjectsToRemove] = useState([])

  const [nonMemberProjects, memberProjects] = decant(
    pipe(
      prop('project_members'),
      defaultTo([]),
      find(propEq('member_id', member.id))
    )
  )(company.projects ?? [])

  async function handleEditMemberSave() {
    const {
      company_member: { role },
    } = member

    const response = await updateCompanyMemberOnServer(
      company.id,
      localMemberToEdit.company_member.id,
      {
        role,
        projects_to_add: pluckId(projectsToAdd),
        projects_to_remove: pluckId(projectsToRemove),
      }
    )

    if (response.status === 200) {
      const { project_members } = await response.json()

      patchCompany({
        companyId: company.id,
        companyData: {
          members: mapPatchId(
            localMemberToEdit.id,
            {
              ...member,
              company_member: {
                ...member.company_member,
                role,
              },
            },
            members
          ),
          projects: map((project) => {
            const next_project_members = [
              ...reject(() =>
                and(
                  includesId(project.id)(projectsToRemove),
                  propEq('member_id', member.member_id)
                )
              )(project.project_members ?? []),
              ...filter(propEq('project_id', project.id))(project_members),
            ]

            return {
              ...project,
              project_members: next_project_members,
            }
          })(company.projects ?? []),
        },
      })

      toast(`Member updated successfully! ${getRandomEmoji(emojiListPositive)}`)
    } else {
      toast.error(
        `Could not update member. ${getRandomEmoji(emojiListNegative)}`
      )
    }
  }

  function handleEditMemberClose() {
    setMemberToEdit(null)
  }

  return (
    <EditFormModal
      style={{}}
      formProps={{
        autoFocus: false,
      }}
      isOpen={true}
      title={'Editing Company Member'}
      fields={[
        {
          title: 'ROLE',
          type: 'select',
          value: localMemberToEdit.company_member.role,
          style: {
            marginBottom: '3px',
          },
          styles: {
            ...SIMPLE_DROPDOWN_STYLES,
          },
          options: [
            { value: 'admin', label: 'Admin' },
            { value: 'member', label: 'Member' },
            { value: 'viewer', label: 'Viewer' },
          ],
          errors: [],
          onChange: (nextRole) => {
            setLocalMemberToEdit({
              ...localMemberToEdit,
              company_member: {
                ...localMemberToEdit.company_member,
                role: nextRole,
              },
            })
          },
        },
      ]}
      onClose={handleEditMemberClose}
      onSave={async () => {
        await handleEditMemberSave(
          localMemberToEdit,
          pluckId(projectsToAdd),
          pluckId(projectsToRemove)
        )

        setProjectsToAdd([])
        setProjectsToRemove([])
      }}
    >
      <Label>PROJECTS</Label>
      {isNotEmpty(memberProjects) && (
        <>
          <S.EmptyProjectList>
            Member is part of the following projects:
          </S.EmptyProjectList>
          <EditProjectList
            projects={memberProjects}
            projectsToAdd={projectsToAdd}
            projectsToRemove={projectsToRemove}
            isMember={true}
            onChange={(nextProjectsToRemove) => {
              setProjectsToRemove(nextProjectsToRemove)
            }}
          />
        </>
      )}
      {isEmpty(memberProjects) && (
        <S.EmptyProjectList>
          Member is not participating in any projects.
        </S.EmptyProjectList>
      )}
      {isNotEmpty(nonMemberProjects) && (
        <>
          <S.EmptyProjectList>
            Select projects to add member to:
          </S.EmptyProjectList>
          <EditProjectList
            projects={nonMemberProjects}
            projectsToAdd={projectsToAdd}
            projectsToRemove={projectsToRemove}
            isMember={false}
            onChange={(nextProjectsToAdd) => {
              setProjectsToAdd(nextProjectsToAdd)
            }}
          />
        </>
      )}
    </EditFormModal>
  )
}

EditCompanyMemberModal.propTypes = {
  company: PropTypes.object.isRequired,
  member: PropTypes.shape({
    id: PropTypes.string.isRequired,
    role: PropTypes.oneOf(['admin', 'member', 'viewer']),
    company_member: PropTypes.object.isRequired,
    member_id: PropTypes.string.isRequired,
  }).isRequired,
}

EditCompanyMemberModal.defaultProps = {}

export default EditCompanyMemberModal
