import { Auth0Provider } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useNavigatePreserve } from '../../hooks'

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const location = useLocation()
  const navigate = useNavigatePreserve()

  const handleRedirectCallback = (appState) => {
    navigate(`${appState?.returnTo ?? location.pathname}`)
  }
  return (
    <Auth0Provider onRedirectCallback={handleRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  )
}

Auth0ProviderWithRedirectCallback.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Auth0ProviderWithRedirectCallback
