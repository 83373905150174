import styled from 'styled-components'
import UserProfileForm from '../../components/UserProfileForm'
import { fadeIn } from '../../styles/animations'

export const Container = styled.div`
  display: grid;
  place-items: center;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  gap: 20px;
  height: calc(100vh - var(--header-height));
  width: 200px;
  opacity: 0;
  animation: ${fadeIn} 0.6s ease forwards;

  svg {
    transform: none !important;
  }
`

export const Text = styled.p`
  opacity: 0;
  animation: ${fadeIn} 0.6s ease forwards;
  animation-delay: 0.3s;
`

export const StyledForm = styled(UserProfileForm)`
  padding: 20px;
  border-radius: 6px;
  width: 250px;
  opacity: 0;
  animation: ${fadeIn} 0.6s ease forwards;
  animation-delay: 0.6s;
  box-shadow: var(--box-shadow);
`

export const Logo = styled.img`
  width: 100%;
`
