export default function StatusWave(svgProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M18.2528 2.85181H5.75281C4.09595 2.85181 2.75281 4.19495 2.75281 5.85181V18.3518C2.75281 20.0087 4.09595 21.3518 5.75281 21.3518H18.2528C19.9097 21.3518 21.2528 20.0087 21.2528 18.3518V5.85181C21.2528 4.19495 19.9097 2.85181 18.2528 2.85181Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.77283 12.1018H8.38283L10.2728 15.1018L13.7328 9.10181L15.6228 12.1018H17.2328"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

StatusWave.propTypes = {}

StatusWave.defaultProps = {}
