import styled from 'styled-components'
import Calendar from '../../assets/components/icon_calendar'
import IconField from '../../assets/components/icon_field'
import IconNote from '../../assets/components/icon_note'
import Paperclip from '../../assets/components/icon_paperclip'
import People from '../../assets/components/icon_people'
import IconPriority from '../../assets/components/icon_priority'
import Slingshot from '../../assets/components/icon_slingshot'
import StatusWave from '../../assets/components/icon_status'
import Tags from '../../assets/components/icon_tags'
import { fadeIn } from '../../styles/animations'
import AttachmentFile from '../AttachmentFile'
import Column from '../Column'
import Priority from '../Priority'
import Row from '../Row'
import Tag from '../Tag'
import UserBadge from '../UserBadge'

export const UpdateRow = styled(Row)`
  gap: 12px;
  align-items: center;
`

export const HistoryOwner = styled(UpdateRow)``
export const HistoryRequestor = styled(UpdateRow)``
export const HistoryState = styled(UpdateRow)``
export const HistoryTag = styled(UpdateRow)``
export const HistoryDeadline = styled(UpdateRow)``
export const HistoryPriority = styled(UpdateRow)``

export const HistoryCustomFields = styled(Column)`
  gap: 12px;
`

export const HistoryCustomField = styled(UpdateRow)``

export const HistoryPriorityItem = styled(Priority)`
  width: fit-content;
`

export const AddedTag = styled(Tag)`
  color: var(--green-36);
  border-color: var(--green-36);
`

export const IconContainer = styled(Column)``

export const RemovedTag = styled(Tag)`
  color: var(--red-68);
  border-color: var(--red-68);
`

export const Note = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const NoteBody = styled.div`
  display: block;
`

export const HistoryFiles = styled(UpdateRow)``

export const HistoryAttachmentFile = styled(AttachmentFile)`
  color: var(--green-36);
`

export const UpdaterRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`

export const UpdatesColumn = styled(Column)`
  margin-left: 18px;
  padding-left: 32px;
  border-left: 3px solid var(--aqua-79);
`

export const StyledUserBadge = styled(UserBadge)`
  position: relative;
  left: 2px;
  top: 2px;
  background: var(--white);
`

export const Date = styled.p`
  color: var(--white-50);
`

export const HistoryContainer = styled(Column)`
  opacity: 0;
  overflow: hidden;
  animation: ${fadeIn} 1s ease forwards;
`

const DEFAULT_ICON_STYLE = `
  color: var(--white-30);
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  align-self: flex-start;
  margin-top: 3px;
`

export const ReminderIcon = styled(Calendar)`
  ${DEFAULT_ICON_STYLE}
`

export const TagsIcon = styled(Tags)`
  ${DEFAULT_ICON_STYLE}
`

export const RequestorIcon = styled(Slingshot)`
  ${DEFAULT_ICON_STYLE}
`

export const OwnerIcon = styled(People)`
  ${DEFAULT_ICON_STYLE}
`

export const StatusIcon = styled(StatusWave)`
  ${DEFAULT_ICON_STYLE}
`

export const PaperclipIcon = styled(Paperclip)`
  ${DEFAULT_ICON_STYLE}
`

export const PriorityIcon = styled(IconPriority)`
  ${DEFAULT_ICON_STYLE}
  width: 22px;
  height: 22px;
`

export const CustomFieldIcon = styled(IconField)`
  ${DEFAULT_ICON_STYLE}
`

export const NoteIcon = styled(IconNote)`
  ${DEFAULT_ICON_STYLE}
`

export const FieldName = styled.div``

export const FieldValue = styled.div`
  font-family: var(--semibold-font);
`
