import { upperFirst } from 'lodash'
import PropTypes from 'prop-types'
import { LineInput } from '..'
import { TaskCustomFieldPropType } from '../../props/TaskCustomFieldPropType'

const FIELD_TO_INPUT_TYPE = {
  number: 'number',
  text: 'text',
  boolean: 'checkbox',
  data: 'date',
  url: 'text',
  image: 'text',
  document: 'text,',
}

const TaskCustomFieldInput = ({
  value,
  field,
  onChange,
  disabled,
  ...props
}) => {
  const { id, type, required, default_value } = field

  const shownValue = value ?? default_value
  const shownPlaceholder = disabled
    ? `Not set`
    : `Type ${upperFirst(type)} here`

  const defaultProps = {
    disabled,
    name: id,
    value: shownValue,
    required,
    placeholder: shownPlaceholder,
    onChange,
  }

  const inputType = FIELD_TO_INPUT_TYPE[type]

  return <LineInput type={inputType} {...defaultProps} {...props} />
}

TaskCustomFieldInput.propTypes = {
  value: PropTypes.string.isRequired,
  field: TaskCustomFieldPropType.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

TaskCustomFieldInput.defaultProps = {}

export default TaskCustomFieldInput
