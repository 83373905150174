import PropTypes from 'prop-types'
import { filter, map, propEq } from 'ramda'
import { stopPropagationAnd } from '../../utils/event/stopPropagationAnd'
import { findValueEq } from '../../utils/ramdaExtensions'
import Column from '../Column'
import Dropdown from '../Dropdown'
import Label from '../Label'
import * as S from './styles'

const DEFAULT_STATE_COLOR = '#FFFFFF'

const States = ({
  states,
  selected,
  onChange,
  isEditMode,
  required,
  keepMenuOpen,
  onAddStateClick,
  onEditStateClick,
  onEditStateXClick,
  ...rest
}) => {
  const options = map((state) => ({
    ...state,
    value: state.id,
    label: state.title,
    kind: 'value',
  }))(states)

  const finalOptions = [
    { value: 'unstarted', label: 'To Do', kind: 'separator' },
    ...filter(propEq('type', 'unstarted'))(options),
    { value: 'started', label: 'In-Progress', kind: 'separator' },
    ...filter(propEq('type', 'started'))(options),
    { value: 'done', label: 'Completed', kind: 'separator' },
    ...filter(propEq('type', 'done'))(options),
  ]

  const selectedOption = findValueEq(selected, options)

  const renderOption = (optionProps) => {
    const { data } = optionProps

    const { kind } = data

    if (kind === 'separator') {
      return <S.Separator {...optionProps}>{data?.label}</S.Separator>
    }

    return (
      <S.Option
        {...optionProps}
        onClick={stopPropagationAnd(() => onEditStateClick(data.value))}
      >
        <S.CustomRow
          onClick={
            isEditMode &&
            stopPropagationAnd(() => isEditMode && onEditStateClick(data.value))
          }
        >
          <S.ColorIndicator
            $backgroundColor={data.default_color ?? DEFAULT_STATE_COLOR}
          />
          {data?.label}
          {isEditMode && (
            <S.IconX
              onClick={stopPropagationAnd(() => onEditStateXClick(data.value))}
            />
          )}
        </S.CustomRow>
      </S.Option>
    )
  }

  const renderValueContainer = (valueContainerProps) => {
    const option = valueContainerProps.getValue()[0]

    return (
      <S.ValueRow>
        <S.ColorIndicator
          $backgroundColor={option?.default_color ?? DEFAULT_STATE_COLOR}
        ></S.ColorIndicator>
        <S.ValueContainer borderless={true} {...valueContainerProps} />
      </S.ValueRow>
    )
  }

  const renderMenuList = (menuListProps) => (
    <>
      <S.MenuList borderless={true} {...menuListProps} />
      {isEditMode && (
        <S.Add
          onClick={stopPropagationAnd(() => {
            onAddStateClick()
          })}
        >
          + Add State
        </S.Add>
      )}
    </>
  )

  return (
    <Column>
      <Label required={required}>STATUS</Label>
      <Dropdown
        options={finalOptions}
        value={selectedOption}
        onChange={({ value }) => onChange(value)}
        borderless
        components={{
          Option: renderOption,
          ValueContainer: renderValueContainer,
          MenuList: renderMenuList,
        }}
        {...rest}
      />
    </Column>
  )
}

States.propTypes = {
  ...Dropdown.propTypes,
  states: PropTypes.arrayOf(PropTypes.any).isRequired,
  selected: PropTypes.array.isRequired,
  required: PropTypes.bool.isRequired,
  keepMenuOpen: PropTypes.bool.isRequired,
  onEditStateClick: PropTypes.func.isRequired,
  onEditStateXClick: PropTypes.func.isRequired,
  onEditStateColorClick: PropTypes.func.isRequired,
  onAddStateClick: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default States
