import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Route, Routes } from 'react-router-dom'
import { App, Root } from './components'
import { AUTH0_REDIRECT_PATH } from './constants'
import {
  GuestTask,
  Home,
  Login,
  LoginCallback,
  Onboarding,
  SharedTask,
  Token,
} from './pages'

const PrivateOnboarding = withAuthenticationRequired(Onboarding)

const Router = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/" element={<App />}>
      <Route path="home" element={<Home />} />
      <Route path="/task/:task" element={<SharedTask />} />
      {process.env.NODE_ENV === 'development' && (
        <Route path="token" element={<Token />} />
      )}
    </Route>

    <Route path="/guest/task" element={<GuestTask />} />
    <Route path="/onboarding" element={<PrivateOnboarding />} />
    <Route
      path={AUTH0_REDIRECT_PATH}
      element={
        <Root>
          <LoginCallback />
        </Root>
      }
    />
  </Routes>
)

export default Router
