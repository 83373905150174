import { reduceTaskTitle } from '../pages/SharedTask/helpers'

const routeCreator = {
  showTask({ task }) {
    const reducedTaskTitle = reduceTaskTitle(task.title, task.id)

    return `/task/${reducedTaskTitle}`
  },
}

export default routeCreator
