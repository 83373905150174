import { useAuth0 } from '@auth0/auth0-react'
import { pick } from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { patchRoot, setSelectedCompanyId } from '../../actions'
import lottieAnimation from '../../assets/lottie/logo.json'
import { Root, SaveButton } from '../../components'
import useAppAuth from '../../hooks/useAppAuth'
import { selectApp } from '../../selectors'
import { createUserProfileOnServer } from '../../services/authApi'
import { createCompanyOnServer } from '../../services/companyApi'
import { lottieConfig } from '../../utils'
import { track } from '../../utils/mixpanel'
import { isNotEmpty } from '../../utils/ramdaExtensions'
import * as S from './styles'

const Onboarding = () => {
  const { user } = useSelector(selectApp)

  const { user: auth0User } = useAuth0()

  const navigate = useNavigate()

  useAppAuth()

  const defaultProfile = useCallback(
    () =>
      pick(['full_name', 'role', 'industry', 'sector'])(
        user?.profile || {
          full_name: '',
          sector: '',
          industry: '',
          role: '',
        }
      ),
    [user]
  )

  const [profileToEdit, setProfileToEdit] = useState(defaultProfile())

  useEffect(() => {
    setProfileToEdit(defaultProfile())
  }, [user, setProfileToEdit, defaultProfile])

  useEffect(() => {
    if (user?.profile) {
      navigate('/home')
    }
  }, [user, navigate])

  async function handleSave() {
    const profileToSend = {
      ...profileToEdit,
      picture: auth0User.picture,
    }

    const response = await createUserProfileOnServer(profileToSend)

    if (response.status === 200) {
      const newUser = await response.json()

      track(`New user created with ${newUser?.profile?.industry} industry`)
      track(`New user created with ${newUser?.profile?.sector} sector`)
      track(`New user created with ${newUser?.profile?.role} role`)

      patchRoot({
        user: {
          ...user,
          ...newUser,
        },
      })

      const workflowName = 'Basic Workflow'

      if ((user?.companies?.length ?? 0) === 0) {
        const defaultTaskFields = {
          workflow_name: workflowName,
        }

        const companyResponse = await createCompanyOnServer({
          name: `${profileToEdit.full_name}'s Co.`,
          default_workflow_name: workflowName,
          projects: [
            {
              name: 'Sample Project',
              default_workflow_name: workflowName,
              tasks: [
                {
                  ...defaultTaskFields,
                  title: 'slingshot Signup 🚀',
                  state_name: 'Done',
                  description: 'Look at you, you did it. 🎉',
                },
                {
                  ...defaultTaskFields,
                  title: 'Welcome Video! 🎥',
                  state_name: 'In-Progress',
                  description: `One minute video on what's in YOUR app.\nhttps://www.youtube.com/watch?v=ULcwD6dOKeQ\nWatch this video for the quick start guide. 🚀`,
                },
                {
                  ...defaultTaskFields,
                  title: 'Invite That Guest! 👨‍👩',
                  state_name: 'To Do',
                  description: `Quick Video on how you invite people.\nhttps://www.youtube.com/watch?v=PJf4om7KunY\nThat's why you're here... Invite your guest to slingshot! 🤩`,
                },
                {
                  ...defaultTaskFields,
                  title: 'Indepth Tutorial 🤔',
                  state_name: 'To Do',
                  description: `More details on the features here in slingshot.\nhttps://www.youtube.com/watch?v=PJf4om7KunY\nThe rundown of the features in slingshot. 🤓`,
                },
              ],
            },
          ],
          workflows: [
            {
              name: workflowName,
              tags: [
                { title: 'Urgent' },
                { title: 'Important' },
                { title: 'FollowUp' },
                { title: 'Pending' },
                { title: 'Complete' },
                { title: 'Testing' },
                { title: 'Operations' },
                { title: 'Development' },
                { title: 'Marketing' },
                { title: 'Meeting' },
                { title: 'Budget' },
                { title: 'Reporting' },
                { title: 'Training' },
                { title: 'Research' },
                { title: 'Scheduling' },
              ],
              states: {
                unstarted: [{ title: 'To Do', default_color: '#D72D36' }],
                started: [{ title: 'In-Progress', default_color: '#F9C270' }],
                done: [{ title: 'Done', default_color: '#63C9B8' }],
              },
              default_state: 'To Do',
              template: {
                fields: [],
                hide_priority: false,
                hide_deadline: true,
              },
            },
          ],
        })

        if (companyResponse.status === 200) {
          const newCompany = await companyResponse.json()

          patchRoot({
            user: {
              ...user,
              ...newUser,
              companies: [...(user?.companies ?? []), newCompany],
            },
          })

          setSelectedCompanyId(newCompany.id)
        } else {
          toast.error('Error creating sample company.')
        }
      }
    } else {
      toast.error('Error while signing up.')
    }
  }

  function handleChange(profile) {
    setProfileToEdit(profile)
  }

  const canSave =
    isNotEmpty(profileToEdit.full_name) &&
    isNotEmpty(profileToEdit.sector) &&
    isNotEmpty(profileToEdit.industry) &&
    isNotEmpty(profileToEdit.role)

  return (
    <Root>
      <S.Container>
        {lottieAnimation && (
          <Lottie
            {...lottieConfig({ animationData: lottieAnimation })}
            style={{ height: 150 }}
          />
        )}
        <S.Text>Please complete your profile 🤩</S.Text>
        <S.StyledForm
          email={auth0User?.email}
          profile={user?.profile}
          onSave={handleSave}
          onChange={handleChange}
        >
          <SaveButton
            style={{ alignSelf: 'center', marginTop: '9px' }}
            type="button"
            submitLabel={'JOIN'}
            onSave={handleSave}
            canSave={canSave}
          />
        </S.StyledForm>
      </S.Container>
    </Root>
  )
}

export default Onboarding
