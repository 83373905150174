import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  place-items: center;
  align-content: center;
  gap: 40px;
  height: fit-content;
  margin: 0 auto;
  width: 300px;
  height: 100%;
`
