import styled from 'styled-components'
import Row from '../Row'

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--white-30);
  background-color: var(--gray);
  height: 60px;
  justify-content: space-between;
  padding-left: 21px;
  padding-right: 21px;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  box-sizing: border-box;
  height: 60px;
`

export const Title = styled.div`
  font-family: var(--semibold-font);
`

export const Controls = styled(Row)`
  align-content: end;
`
