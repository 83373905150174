import styled from 'styled-components'

const WhiteBox = styled.div`
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  border-radius: 6px;
  margin-bottom: 15px;
`

export default WhiteBox
