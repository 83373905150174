import styled from 'styled-components'
import { EmptyState } from '..'
import { ICONS } from '../../assets'
import { fadeIn } from '../../styles/animations'

export const Container = styled.div`
  display: flex;
  gap: 10px;
  text-align: left;
`

export const Company = styled.div`
  padding: 10px 18px;
  border-radius: var(--border-radius);
  white-space: nowrap;
  color: var(--white-62);
  font-size: 16px;
  background-image: url(${ICONS.company});
  background-repeat: no-repeat;
  background-position: center left 12px;
  background-color: var(--white);
  padding-left: 45px;
  border: 1px solid;
  border-color: var(--white);
  cursor: pointer;
  transition: 0.2s all ease;
  height: 44px;
  box-shadow: var(--box-shadow);
  opacity: 0;
  animation: ${fadeIn} 0.3s forwards;
  animation-delay: ${(props) => props.index && `0.${props.index}s`};

  :hover {
    transform: translate(-2px, 2px);
    box-shadow: var(--box-shadow-hover);
  }

  ${(props) =>
    props.$selected &&
    `
    transform: translate(-2px, 2px);
    box-shadow: none;
    border-color:  var(--white-62);
    pointer-events: none;
  `}
`

export const CustomEmptyState = styled(EmptyState)`
  justify-content: flex-start;
`

export const CompanyPicture = styled.img``
