import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: ${(props) => (props.fullHeight ? '100%' : 'initial')};
  position: relative;

  ${(props) =>
    props.hidden &&
    `
    display: none;
  `}
`
