export default function Paperclip({ ...svgProps }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      {...svgProps}
    >
      <path
        d="M6.21723 11.4693L12.7551 4.91688C13.549 4.12195 14.8395 4.12195 15.6334 4.91688C16.4272 5.7125 16.4272 7.00657 15.6334 7.80219L6.63859 16.6993C5.31345 18.0273 3.16664 18.0273 1.84151 16.6993C0.516373 15.3719 0.516373 13.2189 1.84151 11.8916L10.7393 3.08968C12.5943 1.23052 15.6028 1.23052 17.4552 3.08968C19.3116 4.94953 19.3116 7.96199 17.4565 9.82115L11.0157 16.277"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

Paperclip.propTypes = {}

Paperclip.defaultProps = {}
