export function getPriorityColor(priority) {
  switch (priority) {
    case 'urgent':
      return `red-38`
    case 'high':
      return `orange`
    case 'medium':
      return `yellow-71`
    case 'low':
      return `green-68`
    default:
      return `aqua-89`
  }
}

export function getAnimationDelay(index) {
  if (index > 20) {
    return '0s'
  }

  return `0.${index}s`
}
