import PropTypes from 'prop-types'
import { hasId } from '../../utils/ramdaExtensions'
import * as S from './styles'

const Tag = ({
  tag,
  selected,
  $touchable,
  onClick,
  onXClick,
  disabled,
  $isEditMode,
  isPeopleTag,
  ...rest
}) => (
  <S.Tag
    type="button"
    key={tag.id}
    $isNew={!hasId(tag)}
    onClick={onClick}
    $isEditMode={$isEditMode}
    selected={selected}
    $touchable={$touchable}
    disabled={disabled}
    $isPeopleTag={isPeopleTag}
    {...rest}
  >
    {tag.title}
    {$isEditMode && <S.TagIconX onClick={onXClick} />}
  </S.Tag>
)

Tag.propTypes = {
  tag: PropTypes.any.isRequired,
  selected: PropTypes.bool.isRequired,
  $touchable: PropTypes.bool,
  style: PropTypes.any,
  onClick: PropTypes.func,
  onXClick: PropTypes.func,
  disabled: PropTypes.bool,
  $isEditMode: PropTypes.bool,
  isPeopleTag: PropTypes.bool,
}

Tag.defaultProps = {
  disabled: false,
  $touchable: false,
  $isEditMode: false,
  isPeopleTag: false,
  style: {},
  onXClick: () => {},
  onClick: () => {},
}

export default Tag
