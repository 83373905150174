import PropTypes from 'prop-types'
import PromptModal from '../PromptModal'

const DeleteTagModal = ({ tag, ...modalProps }) => (
  <PromptModal
    title="Removing Tag"
    submitLabel="DELETE"
    message={`Are you sure you want to delete tag "${tag.title}"`}
    {...modalProps}
  />
)

DeleteTagModal.propTypes = {
  tag: PropTypes.object,
}

DeleteTagModal.defaultProps = {
  tag: null,
}

export default DeleteTagModal
