import PropTypes from 'prop-types'
import {
  groupBy,
  isEmpty,
  isNil,
  map,
  pipe,
  prop,
  reverse,
  toPairs,
} from 'ramda'
import { useSelector } from 'react-redux'
import {
  setFilter,
  setMemberToEdit,
  setNewTaskInit,
  setPreview,
  setShowUpsertTaskModal,
} from '../../actions'
import { OWNER_FIELD, STATUS_FIELD } from '../../constants'
import { useGetParsedPersonList } from '../../hooks'
import {
  appGetCompanyMemberById,
  appGetSelectedCompanyWorkflowName,
} from '../../reducers/app/utils'
import { selectApp } from '../../selectors'
import { getOrdenedStatusList, setSelectedFilter } from '../../utils'
import { pluckId } from '../../utils/ramdaExtensions'
import MiniCardList from '../MiniCardList'
import { getEmptyStateMessage, getLoadingMessage } from './helpers'

const PeopleStatusList = ({ type }) => {
  const personList = useGetParsedPersonList()
  personList.unshift({
    full_name: 'No Task Owner',
    id: null,
  })

  const app = useSelector(selectApp)

  const { isLoading, filter, stateList, isGuest } = app

  const orderedStateList = reverse(getOrdenedStatusList(stateList))

  const listToLoad = type === 'people' ? personList : orderedStateList
  const fieldToLoad = type === 'people' ? OWNER_FIELD : STATUS_FIELD
  const hasMissingValues = isNil(listToLoad)

  if (isLoading) {
    return getLoadingMessage(type)
  }

  if (hasMissingValues) {
    return getEmptyStateMessage(type)
  }

  const selectedOwnerSet = new Set(filter[fieldToLoad])
  const selectedList = [...selectedOwnerSet].map((item) => ({ id: item }))

  function handleClearAll() {
    if (isEmpty(filter?.[fieldToLoad] ?? [])) {
      const allFieldIds = pluckId(listToLoad)

      setFilter({
        ...filter,
        [fieldToLoad]: allFieldIds,
      })
    } else {
      setFilter({
        ...filter,
        [fieldToLoad]: [],
      })
    }
  }

  const statusGroups = pipe(
    groupBy(prop('workflow_id')),
    toPairs,
    map(([workflow_id, value]) => {
      const title = appGetSelectedCompanyWorkflowName(app, workflow_id)

      return {
        title,
        list: value,
      }
    })
  )

  const separateBy = type === 'status' ? statusGroups : undefined

  const menuOptions = (item) => {
    if (!item.id) {
      return []
    }

    const member = appGetCompanyMemberById(app, item.id)

    if (!member) {
      return []
    }

    return [
      ...((type === 'people' &&
        member &&
        member?.company_member?.role !== 'owner' && [
          {
            icon: 'edit',
            title: 'Edit',
            onClick: (user) => {
              const companyMember = appGetCompanyMemberById(app, user.id)

              setMemberToEdit(companyMember)
            },
          },
        ]) ||
        []),
      ...(!isGuest
        ? [
            {
              icon: 'slingshot',
              title: 'Task',
              onClick: () => {
                const newTaskInit =
                  type === 'status'
                    ? { [STATUS_FIELD]: item.id }
                    : {
                        [OWNER_FIELD]: item.id,
                      }

                setNewTaskInit(newTaskInit)
                setPreview(false)
                setShowUpsertTaskModal(true)
              },
            },
          ]
        : []),
    ]
  }

  const listProps = {
    list: listToLoad,
    isReverse: type === 'status',
    hasOwnerId: type === 'people',
    selectedList,
    setSelected: (payload) =>
      setSelectedFilter({ payload, filter, fieldToLoad, setFilter }),
    showClearAll: true,
    onClearAll: handleClearAll,
    separateBy,
    menuOptions,
  }

  return <MiniCardList {...listProps} />
}

PeopleStatusList.propTypes = {
  type: PropTypes.oneOf(['people', 'status']).isRequired,
}

export default PeopleStatusList
