import styled from 'styled-components'
import Column from '../Column'
import GreenButton from '../GreenButton'
import Row from '../Row'
import UserBadge from '../UserBadge'

export const Container = styled(Column)`
  width: min(60vh, 440px);
  padding: 24px;
  gap: 24px;

  @media screen and (max-width: 700px) {
    width: 90vw;
  }
`

export const InviteMemberButton = styled(GreenButton)`
  width: 125px;
  align-self: end;
`

export const Members = styled(Column)`
  gap: 15px;
`

export const Invites = styled(Column)`
  gap: 15px;
`

export const InvitedRow = styled(Row)`
  align-items: center;
`

export const InvitedEmail = styled(Row)`
  gap: 15px;
`

export const Member = styled.div`
  display: grid;
  grid-template-columns: 45px minmax(0, 1fr) 65px 60px;
  align-items: center;
  flex-wrap: nowrap;
`

export const MemberProfile = styled(Column)`
  gap: 0;
`

export const MemberPicture = styled(UserBadge)`
  min-width: 36px;
  width: calc(100% - 135px);
`

export const MemberDetails = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  ${(props) =>
    props.$name &&
    `
    font-family: var(--semibold-font);
  `}

  @media screen and (max-width: 700px) {
    width: 90%;
  }
`

export const MemberName = styled(MemberDetails)`
  font-style: italic;
`

export const MemberInvited = styled(MemberDetails)`
  font-style: italic;
`

export const MemberEmail = styled(MemberDetails)`
  font-size: 14px;
`

export const MemberRole = styled.div`
  font-size: 12px;
  border: 1px solid var(--white-30);
  color: var(--white-30);
  justify-content: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px;
  height: fit-content;
  width: 100%;
  height: 32px;
`

export const MemberMenuContainer = styled(Row)`
  align-items: end;
  justify-content: end;
`

export const ProjectList = styled(Row)``
