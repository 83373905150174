import * as S from './styles'

const DashedBox = ({ ...svgProps }) => (
  <S.SVG {...svgProps}>
    <S.Rect
      x={0}
      y={0}
      width={'100%'}
      height={'100%'}
      strokeDasharray={15}
      strokeWidth={6}
      fill={'none'}
      stroke={'var(--aqua-59)'}
    ></S.Rect>
  </S.SVG>
)

DashedBox.propTypes = {
  ...S.SVG.propTypes,
}

DashedBox.defaultProps = {}

export default DashedBox
