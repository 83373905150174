import { forEach } from 'ramda'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  setData,
  setError,
  setIsLoading,
  setIsSuccess,
  setRoot,
  upsertTask,
} from '../../actions'
import { useLogout } from '../../hooks'
import { selectApp } from '../../selectors'
import { useGetRootQuery } from '../../services/rootApi'
import { useGetTasksQuery } from '../../services/taskApi'

const TASKS_PAGINATION_LIMIT = 30

const Root = ({ children }) => {
  const { skip, invalidToken, isGuest } = useSelector(selectApp)

  const { data, isLoading, isSuccess, error } = useGetRootQuery(
    { isGuest },
    {
      refetchOnMountOrArgChange: true,
      skip,
    }
  )

  const { handleLogout } = useLogout()

  const [tasksPagination, setTasksPagination] = useState({
    offset: 0,
    limit: TASKS_PAGINATION_LIMIT,
    state_types: ['unstarted', 'started'],
    fetchDone: false,
    finished: false,
  })

  const { data: paginatedTasks, isSuccess: tasksIsSuccess } = useGetTasksQuery(
    tasksPagination,
    {
      skip:
        !isSuccess ||
        (tasksPagination.offset === null && !tasksPagination.fetchDone) ||
        tasksPagination.finished,
    }
  )

  useEffect(() => {
    if (tasksIsSuccess) {
      const { list: tasks, next_offset } = paginatedTasks

      forEach(upsertTask, tasks)

      if (next_offset === null) {
        if (!tasksPagination.fetchDone) {
          setTasksPagination({
            ...tasksPagination,
            offset: 0,
            state_types: ['done'],
            fetchDone: true,
          })
        } else {
          setTasksPagination({
            ...tasksPagination,
            finished: true,
          })
        }
      } else {
        setTasksPagination({
          ...tasksPagination,
          offset: next_offset,
        })
      }
    }
  }, [tasksPagination, paginatedTasks, tasksIsSuccess])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    setData(data)
  }, [data])

  useEffect(() => {
    setIsSuccess(isSuccess)
  }, [isSuccess])

  useEffect(() => {
    setError(error)
  }, [error])

  useEffect(() => {
    if (isSuccess) {
      setRoot(data)
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (invalidToken && !isGuest) {
      handleLogout()
    }
  }, [handleLogout, invalidToken, isGuest])

  return children
}

export default Root
