import { MenuItem } from '@szhsin/react-menu'
import styled from 'styled-components'
import { fadeIn } from '../../styles/animations'

export const Item = styled(MenuItem)`
  list-style: none;
  border-radius: 6px;
  opacity: 0;
  animation: ${fadeIn} 0.1s ease forwards;
  animation-delay: ${(props) => props.index && `0.${props.index}s`};

  :first-of-type {
    div {
      border-top-right-radius: 9px;
      border-top-left-radius: 9px;
    }
  }

  :last-of-type {
    div {
      border-bottom-right-radius: 9px;
      border-bottom-left-radius: 9px;
    }
  }
`
