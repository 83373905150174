import { getToken } from '../getters'

export async function serverFetch(path, opt = {}, token = getToken()) {
  return await fetch(`${process.env.REACT_APP_SERVER_LOCATION}${path}`, {
    ...opt,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...opt.headers,
    },
    body: opt.body ? JSON.stringify(opt.body) : undefined,
  })
}

export function serverGet(path, opt, token) {
  return serverFetch(path, { method: 'GET', ...opt }, token)
}

export function serverPost(path, opt, token) {
  return serverFetch(path, { method: 'POST', ...opt }, token)
}

export function serverPut(path, opt, token) {
  return serverFetch(path, { method: 'PUT', ...opt }, token)
}

export function serverDelete(path, opt, token) {
  return serverFetch(path, { method: 'DELETE', ...opt }, token)
}
