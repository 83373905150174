import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { setCompanyPictureLoading } from '../../actions'
import { ICONS } from '../../assets'
import { selectApp } from '../../selectors'
import { getCompanyPictureUrlFromServer } from '../../services/companyApi'
import * as S from './styles'

const CompanyPicture = ({ company, isGuest, ...imgProps }) => {
  const { companyPictureLoading } = useSelector(selectApp)

  const [companyPictureUrl, setCompanyPictureUrl] = useState(
    company?.picture_url
  )

  const isCompanyPictureLoading = companyPictureLoading[company?.id]

  useEffect(() => {
    if (isCompanyPictureLoading) {
      return
    }

    if (company) {
      setCompanyPictureLoading({ companyId: company?.id, isLoading: true })
      ;(async () => {
        const response = await getCompanyPictureUrlFromServer(
          company?.id,
          isGuest
        )

        if (response.status === 200) {
          const { url } = await response.json()

          setCompanyPictureUrl(url)
          setCompanyPictureLoading({ companyId: company.id, isLoading: true })
        } else {
          //
        }
      })()
    }
  }, [isCompanyPictureLoading, company, isGuest, setCompanyPictureUrl])

  return (
    <S.CompanyPicture
      src={company?.picture_url ?? companyPictureUrl ?? ICONS.company}
      {...imgProps}
    />
  )
}

CompanyPicture.propTypes = {
  company: PropTypes.any,
  isGuest: PropTypes.bool.isRequired,
}

CompanyPicture.defaultProps = {
  company: null,
}

export default CompanyPicture
