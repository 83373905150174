export default function IconNote({ ...svgProps }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g clipPath="url(#clip0_1519_2875)">
        <path d="M23.0877 4.66597C22.7277 4.13817 22.3199 3.63378 21.8156 3.15378C21.4799 2.81817 20.9756 2.4094 20.3277 1.95378C19.5356 1.40253 18.4799 1.49817 17.8077 2.17034L2.78422 17.1948C2.71203 17.267 2.66422 17.3626 2.61641 17.4592L0.647665 23.0027C0.527665 23.3627 0.599852 23.7705 0.887665 24.0349C1.07985 24.2271 1.34328 24.3227 1.58328 24.3227C1.70328 24.3227 1.79891 24.2984 1.9189 24.2749L7.48762 22.3305C7.58324 22.3062 7.6798 22.2349 7.752 22.1627L22.8242 7.09048C23.4721 6.44267 23.5921 5.41045 23.0877 4.66597ZM3.62422 18.8025C4.58422 19.4981 5.42422 20.3625 6.14422 21.3225L3.6487 22.2103C3.55308 22.0424 3.4087 21.8503 3.26432 21.7059C3.09651 21.5381 2.9287 21.4181 2.75993 21.3215L3.62422 18.8025ZM21.8397 6.10598L7.34422 20.6025C6.52861 19.4503 5.49641 18.4425 4.34422 17.6025L18.792 3.15469C18.9842 2.96251 19.2964 2.93907 19.512 3.10688C20.0876 3.51469 20.5442 3.85127 20.8076 4.13907C21.2398 4.57127 21.5998 5.00346 21.912 5.45907C22.0554 5.62595 22.0076 5.93817 21.8397 6.10598Z" />
      </g>
      <defs>
        <clipPath id="clip0_1519_2875">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.970459)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

IconNote.propTypes = {}

IconNote.defaultProps = {}
