import { omit } from 'ramda'

export const omitId = omit(['id'])
export const omitUserId = omit(['user_id'])
export const omitTaskId = omit(['task_id'])
export const omitCompanyPictureUrl = omit(['picture_url'])
export const omitTitle = omit(['title'])
export const omitType = omit(['type'])
export const omitCompanyId = omit(['company_id'])
export const omitIsPrimitive = omit(['is_primitive'])
export const omitDefaultStateId = omit(['default_state_id'])
export const omitTaskTemplate = omit(['task_template'])
export const omitTaskTemplateId = omit(['task_template_id'])
export const omitParentId = omit(['parent_id'])
export const omitWorkflowId = omit(['workflow_id'])
export const omitDefaultValue = omit(['default_value'])
export const omitDates = omit(['created_at', 'updated_at', 'deleted_at'])
export const omitCompanyWorkflow = omit(['company_workflow'])
export const omitForWorkflow = (workflow) =>
  omitId(
    omitDefaultStateId(
      omitParentId(
        omitIsPrimitive(
          omitTaskTemplate(omitDates(omitCompanyWorkflow(workflow)))
        )
      )
    )
  )
export const omitForTemplate = (template) =>
  omitId(omitWorkflowId(omitDates(template)))
export const omitForField = (field) => omitTaskTemplateId(omitDates(field))
export const omitForTag = (tag) => omitWorkflowId(omitDates(tag))
export const omitForState = (state) =>
  omitWorkflowId(omitType(omitDates(state)))
export const omitForTaskCustomField = (taskField) =>
  omitId(omitDates(omitTaskId(taskField)))
export const omitForCompanyUserSettings = (settings) =>
  omitId(omitUserId(omitCompanyId(omitDates(settings))))
export const omitForCompany = (company) => omitCompanyPictureUrl(company)
