import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const isAfter = (date, otherDate) => dayjs.utc(date).isAfter(otherDate)

export const dateStringToTime = (isoDate) => new Date(isoDate).getTime()

export const timeToDateString = (time) => new Date(time).toISOString()

export const today = () =>
  dayjs().utc().set('hour', 0).startOf('day').toISOString()

export const daysFromNow = (date) => dayjs(today()).diff(dayjs.utc(date), 'day')

export const formatDDMMYYYY = (date) => dayjs.utc(date).format('DD/MM/YYYY')

export const formatMMDDYYYY = (date) => dayjs.utc(date).format('MM/DD/YYYY')

export const isBefore = (date, otherDate) =>
  dayjs.utc(date).isBefore(dayjs(otherDate))
