import PropTypes from 'prop-types'
import { equals, find, isEmpty, pluck } from 'ramda'
import { useState } from 'react'
import { EditFormModal, EditProjectList, Label } from '..'
import { isValidEmail } from '../../utils/email'
import { isNotEmpty, isNotNil, pluckId } from '../../utils/ramdaExtensions'
import { SIMPLE_DROPDOWN_STYLES } from '../EditCompanyMemberModal'
import { EmptyProjectList } from '../EditCompanyMemberModal/styles'

const InviteCompanyMemberModal = ({ company, onClose, onSave }) => {
  const { members, invites } = company

  const [memberToEdit, setMemberToEdit] = useState({
    email: '',
    role: 'member',
    project_ids: [],
  })

  const { projects = [] } = company

  const memberEmails = pluck('email')(members)
  const inviteEmails = pluck('email')(invites)

  const projectsToAdd = memberToEdit.project_ids.map((projectId) =>
    company.projects.find((p) => p.id === projectId)
  )

  return (
    <EditFormModal
      title={'Inviting Member'}
      fields={[
        {
          title: 'EMAIL',
          value: memberToEdit.email,
          placeholder: 'Type Member Email Here',
          errors: (() => {
            if (isEmpty(memberToEdit.email)) {
              return ['Email cannot be empty.']
            }

            const sameEmailMember = find(equals(memberToEdit.email))(
              memberEmails
            )

            const isDuplicatedMemberEmail = isNotNil(sameEmailMember)

            if (isDuplicatedMemberEmail) {
              return ['Member is already part of company.']
            }

            const sameEmailInvite = find(equals(memberToEdit.email))(
              inviteEmails
            )

            const isDuplicatedInviteEmail = isNotNil(sameEmailInvite)

            if (isDuplicatedInviteEmail) {
              return ['Invite is already sent to this email.']
            }

            if (!isValidEmail(memberToEdit.email)) {
              return ['Invalid email format.']
            }

            return []
          })(),
          onChange: (nextEmail) => {
            setMemberToEdit({
              ...memberToEdit,
              email: nextEmail,
            })
          },
        },
        {
          title: 'ROLE',
          type: 'select',
          value: memberToEdit.role,
          styles: SIMPLE_DROPDOWN_STYLES,
          options: [
            { value: 'admin', label: 'Admin' },
            { value: 'member', label: 'Member' },
            { value: 'viewer', label: 'Viewer' },
          ],
          errors: [],
          onChange: (nextRole) => {
            setMemberToEdit({
              ...memberToEdit,
              role: nextRole,
            })
          },
        },
      ]}
      onClose={onClose}
      onSave={onSave.bind(null, memberToEdit)}
    >
      <Label>PROJECTS</Label>
      {isNotEmpty(projects) && (
        <>
          <EmptyProjectList>Select projects to add member to:</EmptyProjectList>
          <EditProjectList
            projects={projects}
            projectsToAdd={projectsToAdd}
            projectsToRemove={[]}
            isMember={false}
            onChange={(nextProjectsToAdd) => {
              setMemberToEdit({
                ...memberToEdit,
                project_ids: [...pluckId(nextProjectsToAdd)],
              })
            }}
          />
        </>
      )}
    </EditFormModal>
  )
}

InviteCompanyMemberModal.propTypes = {
  company: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

InviteCompanyMemberModal.defaultProps = {}

export default InviteCompanyMemberModal
