import _ from 'lodash'
import { filter, isEmpty, map, propEq } from 'ramda'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Menu, Row, Spinner, TaskForm } from '..'
import {
  createTask,
  deleteTask,
  patchCompany,
  patchTask,
  setHasTaskToEdit,
  setPreview,
  setSelectedTask,
  setShowUpsertTaskModal,
} from '../../actions'
import ArrowLeft from '../../assets/components/icon_arrow_left'
import { HOME_PATH } from '../../constants'
import { useGetPersonOptionList } from '../../hooks'
import { appGetCompanyPersonById } from '../../reducers/app/utils'
import { selectApp } from '../../selectors'
import {
  deleteAttachmentFileFromServer,
  readAttachmentUrlFromServer,
  uploadAttachmentFilesToServer,
} from '../../services/attachmentApi'
import {
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
} from '../../services/taskApi'
import { updateWorkflowOnServer } from '../../services/workflowApi'
import { getLastUpdate, getRandomEmoji } from '../../utils'
import {
  emojiListNegative,
  emojiListPositive,
} from '../../utils/getRandomEmoji'
import { track } from '../../utils/mixpanel'
import { capture } from '../../utils/posthog'

import {
  findId,
  mapPatchId,
  omitForField,
  omitForState,
  omitForTag,
  omitForTemplate,
  omitForWorkflow,
  rejectId,
  uniqById,
} from '../../utils/ramdaExtensions'
import {
  getTaskFormFields,
  getUpdatedTaskFields,
  isTaskFormValid,
  processServerTaskHistory,
} from './helpers'
import * as S from './styles'

export async function uploadFiles(
  companyId,
  projectId,
  taskId,
  groupId,
  fileHandlers,
  isGuest
) {
  if (fileHandlers && !isEmpty(fileHandlers)) {
    const formData = new FormData()

    formData.set('group_id', groupId)

    fileHandlers.forEach((fileHandler) => {
      formData.append('files', fileHandler)
    })

    const response = await uploadAttachmentFilesToServer(
      companyId,
      projectId,
      taskId,
      formData,
      isGuest
    )

    if (response.status === 200) {
      const attachmentFromServer = await response.json()

      return attachmentFromServer
    }

    throw new Error(
      `Could not upload attachments. ${getRandomEmoji(emojiListNegative)}`
    )
  }

  return null
}

export function getDefaultWorkflowId(selectedTask, selectedCompany) {
  let defaultWorkflowId =
    selectedTask?.workflow_id ?? selectedCompany?.default_workflow_id

  if (!findId(defaultWorkflowId)(selectedCompany.workflows)) {
    defaultWorkflowId = selectedCompany.workflows[0]?.id
  }

  return defaultWorkflowId
}

export function getWorkflowEntry(workflow) {
  const mapOmitForStateType = (stateType) =>
    map(omitForState)(filter(propEq('type', stateType))(workflow.states))

  return omitForWorkflow({
    ...workflow,
    tags: map(omitForTag)(workflow.tags),
    states: {
      unstarted: mapOmitForStateType('unstarted'),
      started: mapOmitForStateType('started'),
      done: mapOmitForStateType('done'),
    },
    template: omitForTemplate({
      ...(workflow.task_template ?? {}),
      fields: map(omitForField)(workflow.task_template?.fields),
    }),
  })
}

export const NO_TASK_OWNER_LABEL = 'No Task Owner'

const UpsertTask = () => {
  const navigate = useNavigate()

  const app = useSelector(selectApp)

  const {
    user,
    isGuest,
    preview,
    selectedTask,
    selectedCompany,
    hasTaskToEdit,
    selectedProjectList,
    newTaskInit,
    isUserAdmin,
    attachmentsToUpload,
    stateList,
    tagList,
    customFieldList,
    isUserCollaborator,
  } = app

  const getAttachmentUrl = (attachmentId, fileId) =>
    readAttachmentUrlFromServer(
      selectedCompany.id,
      selectedTask.project_id,
      selectedTask.id,
      attachmentId,
      fileId,
      isGuest
    )

  const formRef = useRef({})

  const isViewMode = preview

  const [createTaskOnServer] = useCreateTaskMutation()
  const [updateTaskOnServer] = useUpdateTaskMutation()
  const [deleteTaskOnServer] = useDeleteTaskMutation()

  const [isSaving, setIsSaving] = useState(false)
  const [showFormErrors, setShowFormErrors] = useState(false)

  const personOptionList = useGetPersonOptionList()

  const defaultWorkflowId = getDefaultWorkflowId(selectedTask, selectedCompany)

  const [selectedWorkflowId, setSelectedWorkflowId] =
    useState(defaultWorkflowId)

  const selectedWorkflow = findId(selectedWorkflowId)(
    selectedCompany?.workflows ?? []
  )

  const [isWorkflowEditMode, setIsWorkflowEditMode] = useState(false)

  const fallbackOwner = { value: '', label: NO_TASK_OWNER_LABEL }

  const ownerOptions = [fallbackOwner].concat(personOptionList)

  const requestorOptions = personOptionList

  const isEditMode = !preview || isWorkflowEditMode

  const [animatingModal, setAnimatingModal] = useState(true)

  function handleCloseModal() {
    setHasTaskToEdit(false)
    setPreview(true)
    setShowUpsertTaskModal(false)
    setSelectedTask(null)
    navigate(HOME_PATH)
  }

  async function handleSaveWorkflow(editableWorkflow) {
    const workflowEntry = getWorkflowEntry(editableWorkflow)

    const response = await updateWorkflowOnServer(
      selectedCompany.id,
      selectedWorkflow.id,
      workflowEntry
    )

    if (response.status === 200) {
      const serverWorkflow = await response.json()

      patchCompany({
        companyId: selectedCompany.id,
        companyData: {
          workflows: mapPatchId(
            selectedWorkflowId,
            serverWorkflow
          )(selectedCompany?.workflows ?? []),
        },
      })

      toast(
        `Workflow updated successfully! ${getRandomEmoji(emojiListPositive)}`
      )

      setIsWorkflowEditMode(false)
    } else {
      toast.error(
        `Workflow could not be updated. ${getRandomEmoji(emojiListNegative)}`
      )
    }
  }

  async function handleSaveTask(data, fileHandlers) {
    const isValid = isTaskFormValid(data, selectedWorkflow)

    if (!isValid) {
      setShowFormErrors(true)

      return
    }

    const { project_id } = data

    const requiredFields = {
      company_id: selectedCompany.id,
      project_id,
    }

    const taskFields = {
      title: data.title,
      parent_id: null,
      workflow_id: data.workflow_id,
      state_id: data.state_id,
      priority: data.priority || null,
      owner_id: data.owner || null,
      requestor_id: data.requestor,
      tags: data.tags,
      deadline: data.deadline,
      description: data.description || '',
      custom_fields: data.custom_fields,
      project_id: data.project_id,
    }

    const hasNote = !isEmpty(data.note)

    if (hasNote) {
      taskFields.note = { text: data.note }
    }

    if (hasTaskToEdit) {
      const updatedTaskFields = getUpdatedTaskFields(taskFields, selectedTask)

      const response = await updateTaskOnServer({
        body: { project_id, ...updatedTaskFields },
        id: selectedTask.id,
        companyId: selectedCompany.id,
        projectId: selectedTask.project_id,
        isGuest,
      })

      const { error } = response

      if (!error || error.originalStatus === 200) {
        const updatedTaskHistory = processServerTaskHistory(
          selectedTask,
          response.data
        )

        const fieldsToUpdate = {
          ...updatedTaskFields,
          ...updatedTaskHistory,
          task_tags: map((id) => ({
            tag_id: id,
          }))(data.tags),
        }

        const keys = _.keys(response.data)
        const hasHistoryToUpdate = keys.some((key) => !_.isNil(key))

        let groupId = null

        if (hasHistoryToUpdate) {
          keys.forEach((key) => {
            let finalKey = `${key}s`
            let listToConcat = [response.data[key]]

            if (key === 'history_priority') {
              finalKey = 'history_priorities'
            }

            if (key === 'history_custom_fields') {
              finalKey = 'history_custom_fields'
              listToConcat = [...response.data[key]]
            }

            groupId =
              groupId ||
              response.data[key]?.group_id ||
              response.data[key]?.[0]?.group_id ||
              null

            const listToUpdate = (selectedTask[finalKey] ?? []).concat(
              listToConcat
            )

            fieldsToUpdate[finalKey] = listToUpdate
          })
        }

        try {
          const attachmentFromServer = await uploadFiles(
            selectedCompany.id,
            selectedTask.project_id,
            selectedTask.id,
            groupId,
            fileHandlers,
            isGuest
          )

          const taskToPatch = {
            ...fieldsToUpdate,
            attachments: uniqById([
              ...(selectedTask.attachments ?? []),
              ...(attachmentFromServer ? [attachmentFromServer] : []),
            ]),
          }

          patchTask({ taskId: selectedTask.id, taskData: taskToPatch })

          setPreview(true)

          if (data.priority !== selectedTask?.priority) {
            track('Priority updated')
            capture('task_priority_updated')
          }

          if (data.note !== selectedTask?.priority) {
            track('Priority updated')
            capture('task_priority_updated')
          }

          if (hasNote) {
            track('Note added')
            capture('task_note_added')
          }

          if (attachmentFromServer) {
            track('Attachments added')
            capture('task_attachments_added')
          }

          track('Task updated')
          capture('task_updated')
          track(
            `Company ${selectedCompany.name} (${selectedCompany.id}) updated a task`
          )

          toast(
            `Task updated successfully! ${getRandomEmoji(emojiListPositive)}`
          )
        } catch (err) {
          toast.error(err.message)
        }
      } else {
        toast.error(
          `Task could not be updated. ${getRandomEmoji(emojiListNegative)}`
        )
      }
    } else {
      const response = await createTaskOnServer({
        ...requiredFields,
        ...taskFields,
      })

      const { error } = response

      if (!error || error.originalStatus === 200) {
        const serverTask = response.data

        const groupId = serverTask.history_owners?.[0]?.group_id

        const attachmentFromServer = await uploadFiles(
          selectedCompany.id,
          serverTask.project_id,
          serverTask.id,
          groupId,
          fileHandlers,
          isGuest
        )

        const taskToSave = {
          ...serverTask,
          attachments: [
            ...(serverTask.attachments ?? []),
            ...(attachmentFromServer ? [attachmentFromServer] : []),
          ],
        }

        createTask({
          company: selectedCompany,
          project: findId(project_id)(selectedCompany.projects),
          task: taskToSave,
        })

        handleCloseModal()

        if (data.priority !== null) {
          track('Priority added')
          capture('task_priority_added')
        }

        if (hasNote) {
          track('Note added')
          capture('task_note_added')
        }

        if (attachmentFromServer) {
          track('Attachments added')
          capture('task_attachments_added')
        }

        track('Task created')
        capture('task_created')
        track(
          `Company ${selectedCompany.name} (${selectedCompany.id}) created a task`
        )

        toast(`Task created successfully! ${getRandomEmoji(emojiListPositive)}`)
      } else {
        toast.error(
          `Task could not be created. ${getRandomEmoji(emojiListNegative)}`
        )
      }
    }
  }

  async function handleSave() {
    if (isSaving) {
      return
    }

    setIsSaving(true)

    if (isWorkflowEditMode) {
      const workflow = formRef.current.getWorkflowData()

      await handleSaveWorkflow(workflow)
    } else {
      const { data, fileHandlers } = formRef.current.getTaskData()

      await handleSaveTask(data, fileHandlers)
    }

    setIsSaving(false)
  }

  const fields = getTaskFormFields(
    selectedTask,
    preview,
    hasTaskToEdit,
    user.id,
    ownerOptions,
    newTaskInit.owner_id,
    fallbackOwner,
    requestorOptions
  )

  useEffect(() => {
    const nextWorkflowId = getDefaultWorkflowId(selectedTask, selectedCompany)

    setSelectedWorkflowId(nextWorkflowId)
  }, [selectedTask, selectedCompany, setSelectedWorkflowId])

  const handleWorkflowIdChange = (nextWorkflowId) => {
    setSelectedWorkflowId(nextWorkflowId)
  }

  async function handleDeleteTask() {
    const response = await deleteTaskOnServer({
      id: selectedTask.id,
      companyId: selectedCompany.id,
      projectId: selectedTask.project_id,
    })

    const { error } = response

    if (!error || error.originalStatus === 200) {
      deleteTask(selectedTask)

      toast(`Task deleted successfully! ${getRandomEmoji(emojiListPositive)}`)

      setSelectedTask(null)
    } else {
      navigate(HOME_PATH)

      toast.error(
        `Task could not be deleted. ${getRandomEmoji(emojiListNegative)}`
      )
    }

    navigate('/home')

    setHasTaskToEdit(false)
  }

  const handleRemoveAttachmentFile = async (attachment, file) => {
    const response = await deleteAttachmentFileFromServer(
      selectedCompany.id,
      selectedTask.project_id,
      selectedTask.id,
      attachment.id,
      file.id
    )

    if (response.status === 200) {
      const nextSelectedTask = {
        ...selectedTask,
        attachments: mapPatchId(
          attachment.id,
          {
            ...attachment,
            files: rejectId(file.id, attachment.files),
          },
          selectedTask?.attachments ?? []
        ),
      }

      patchTask({ taskId: selectedTask.id, taskData: nextSelectedTask })

      toast(
        `Attachment file deleted successfully! ${getRandomEmoji(
          emojiListPositive
        )}`
      )
    } else {
      toast.error(
        `Could not delete attachment file. ${getRandomEmoji(emojiListNegative)}`
      )
    }
  }

  const companyWorkflows = selectedCompany.workflows ?? []

  function handleCancel() {
    setShowFormErrors(false)

    if (isWorkflowEditMode) {
      setIsWorkflowEditMode(false)
    } else if (!preview && hasTaskToEdit) {
      setPreview(true)
    } else {
      handleCloseModal()
    }
  }

  const title =
    (!isEditMode && 'Viewing Task') ||
    (hasTaskToEdit && isWorkflowEditMode && 'Editing Workflow') ||
    (hasTaskToEdit && 'Updating Task') ||
    'Creating Task'

  const isTaskOwner = selectedTask?.owner_id === user.id

  const canUpdateTask = isUserCollaborator || isTaskOwner

  const defaultSelectedProjectId =
    selectedProjectList?.length === 1 ? selectedProjectList?.[0].id : ''

  const fallbackProjectId = defaultSelectedProjectId

  const handleModalEntered = useCallback(() => {
    setAnimatingModal(false)
  }, [setAnimatingModal])

  const getCompanyPersonById = (userId) => appGetCompanyPersonById(app, userId)

  return (
    <S.StyledModal
      onClose={handleCloseModal}
      title={title}
      headerChildren={[
        hasTaskToEdit && (
          <S.LastUpdate>{getLastUpdate(fields?.updated_at)}</S.LastUpdate>
        ),
      ]}
      onEntered={handleModalEntered}
      controls={[
        <>
          <S.Flex center={true}>
            {!isEditMode && canUpdateTask && (
              <S.UpdateButton
                type="button"
                color={'primary'}
                onClick={() => setPreview(false)}
              >
                Update
              </S.UpdateButton>
            )}
            {(!isViewMode || isEditMode) && (
              <Row style={{ justifyContent: 'end', gap: '15px' }}>
                <S.CancelButton
                  type="button"
                  color={'secondary'}
                  disabled={isSaving}
                  onClick={handleCancel}
                >
                  {isMobile ? <ArrowLeft /> : 'Cancel'}
                </S.CancelButton>
                <S.SaveButton type="submit" onClick={handleSave}>
                  {(isSaving && <Spinner />) || 'Save'}
                </S.SaveButton>
              </Row>
            )}
            {isUserCollaborator && (
              <Menu
                small={true}
                disabled={isEditMode}
                items={[
                  {
                    icon: 'trash',
                    title: 'Delete',
                    onClick: handleDeleteTask,
                  },
                ]}
              />
            )}
          </S.Flex>
        </>,
      ]}
    >
      <S.Content>
        <TaskForm
          company={selectedCompany}
          task={selectedTask ?? newTaskInit}
          workflow={selectedWorkflow}
          workflows={companyWorkflows}
          projects={selectedCompany.projects}
          isViewMode={isViewMode}
          isEditMode={isWorkflowEditMode}
          showErrors={showFormErrors}
          isAdmin={isUserAdmin}
          fields={fields}
          hasTaskToEdit={hasTaskToEdit}
          fallbackProjectId={fallbackProjectId}
          initAttachmentsToAdd={attachmentsToUpload}
          onWorkflowSave={handleSaveWorkflow}
          onWorkflowIdChange={handleWorkflowIdChange}
          onRemoveAttachmentFile={handleRemoveAttachmentFile}
          onTaskDelete={handleDeleteTask}
          animatingEnter={animatingModal}
          onCancel={() => {
            handleCancel()
          }}
          onSubmit={handleSave}
          childRef={formRef}
          stateList={stateList}
          tagList={tagList}
          customFieldList={customFieldList}
          getCompanyPersonById={getCompanyPersonById}
          getAttachmentUrl={getAttachmentUrl}
        />
      </S.Content>
    </S.StyledModal>
  )
}

export default UpsertTask
