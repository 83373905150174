import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'ramda'
import { getRelativeTime } from '../../utils'
import Menu from '../Menu'
import TaskCounter from '../TaskCounter'
import TaskOwnerBadge from '../TaskOwnerBadge'
import * as S from './styles'

const MiniCard = ({
  icon,
  owner_id,
  title,
  menuOptions,
  timestamp,
  onClick,
  selected,
  color,
  taskCount,
  isPeopleList,
}) => (
  <S.Container onClick={onClick} selected={selected} color={color}>
    <S.Row>
      <S.TitleContainer title={title}>
        {isPeopleList && (
          <S.Icon>
            {icon ? <img src={icon} /> : <TaskOwnerBadge id={owner_id} />}
          </S.Icon>
        )}

        {!isNil(taskCount) && <TaskCounter amount={taskCount} />}
        <S.Title $isPeopleList={isPeopleList}>{title}</S.Title>
      </S.TitleContainer>
      <Menu items={menuOptions} small={true} disabled={isEmpty(menuOptions)} />
    </S.Row>
    {timestamp && (
      <S.Row>
        <S.LastUpdate>{`Last Update: ${getRelativeTime(
          timestamp
        )}`}</S.LastUpdate>
      </S.Row>
    )}
  </S.Container>
)

MiniCard.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  owner_id: PropTypes.string,
  title: PropTypes.string.isRequired,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  timestamp: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  color: PropTypes.string,
  taskCount: PropTypes.number,
  isPeopleList: PropTypes.bool,
}

MiniCard.defaultProps = {
  icon: null,
  owner_id: null,
  menuOptions: [],
  timestamp: null,
  onClick: () => {},
  selected: false,
  color: null,
  taskCount: null,
  isPeopleList: false,
}

export default MiniCard
