import styled from 'styled-components'
import Anchor from '../Anchor'
import Column from '../Column'
import GreenButton from '../GreenButton'
import Modal from '../TitleModal'

export const StyledModal = styled(Modal)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Container = styled(Column)`
  width: 480px;
  background: var(--white);
  align-items: center;
  padding: 30px;
  gap: 21px;

  svg {
    transform: none !important;
    height: 150px !important;
    top: -95px !important;
    position: relative;
  }
`

export const SubContainer = styled.div``

export const Title = styled.div`
  font-family: var(--semibold-font);
  font-size: 21px;
`

export const Message = styled.div`
  font-size: 21px;
  text-align: center;
`

export const SubMessage = styled.div`
  font-size: 14px;
  text-align: center;
`

export const SignUpButton = styled(GreenButton)`
  padding: 0 20px;
`

export const SignUpAnchor = styled(Anchor)`
  color: var(--aqua-39);
`

export const ReturnToTaskButton = styled.div``

export const Logo = styled.img`
  width: 150px;
`
