import styled from 'styled-components'
import CenteredTitleModal from '../CenteredTitleModal'
import GrayButton from '../GrayButton'
import GreenButton from '../GreenButton'

export const StyledModal = styled(CenteredTitleModal)`
  width: 345px;
`

export const Container = styled.div`
  padding: 20px;
`

export const Footer = styled.div`
  padding: 20px;
  margin: 0;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;

  > input:focus {
    border: none !important;
  }
`

export const SaveButton = styled(GreenButton)`
  width: 80px;
`

export const CancelButton = styled(GrayButton)`
  width: 80px;
`
