import { motion } from 'framer-motion'
import styled from 'styled-components'
import { DEFAULT_BUTTON_STYLE } from '../Button/styles'

export const Actions = styled.div`
  margin: 10px 0 0;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;

  > input:focus {
    border: none !important;
  }
`

export const Submit = styled(motion.input)`
  ${DEFAULT_BUTTON_STYLE}
  background: var(--aqua-89);
  color: var(--aqua-49);

  ${(props) =>
    props.disabled &&
    `
    opacity: .4;
    pointer-events: none;
  `}
`
