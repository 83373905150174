import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    * {
      box-sizing: border-box;
      margin: 0;
      outline: 0;
    }

    html {
      scroll-behavior: smooth;
    }

    p, a, span {
      line-height: 1.5;
    }

    body {
      overflow: hidden;
      overscroll-behavior-y: none;
      overscroll-behavior-x: none;
      background-color: var(--global-background);
    }

    *::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 3px;
      height: 3px;
    }

    *::-webkit-scrollbar-track {
      background-color: #00000000;
    }

    *::-webkit-scrollbar-thumb {
      opacity: 0.5;
      background-color: var(--white-80);
    }

    *::-webkit-scrollbar-corner {
      display: none;
    }

    * {
      scrollbar-width: thin;
      scrollbar-color: currentColor #00000000;
    }

    p::selection,
    h1::selection,
    h2::selection,
    h3::selection,
    h4::selection,
    span::selection,
    a::selection,
    b::selection,
    div::selection,
    i::selection,
    strong::selection,
    b::selection,
    br::selection,
    textarea::selection,
    button::selection,
    input::selection,
    #emoji::selection,
    #industry::selection {
      background: #212121;
      color: #ffffff;
    }

    img {
      user-select: none;
    }

    strong, b {
      font-family: var(--semibold-font);
    }

    ul {
      line-height: 1.8;
      list-style: circle;
      padding-left: 20px;
    }

    input, textarea{
      border-radius: var(--border-radius);

      :focus {
        border-color: var(--aqua-59);
      }
    }

    #updateToast {
      border: 2px solid var(--teal-42);
      text-align: center;
    }
`
