import { flatten, isEmpty, isNil, sortBy as ramdaSortBy, uniq } from 'ramda'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { setShowDetails, setSortBy } from '../../actions'
import iconClosedEye from '../../assets/icon_closed_eye.svg'
import iconEye from '../../assets/icon_eye.svg'
import { OWNER_FIELD, STATUS_FIELD } from '../../constants'
import { selectApp } from '../../selectors'
import { getOrdenedStatusList } from '../../utils'
import { sortByCreatedAt, sortByDate } from '../../utils/ramdaExtensions'
import Loading from '../Loading'
import TaskColumn from '../TaskColumn'
import * as S from './styles'

const PRIORITY_ORDER = ['low', 'medium', 'high']

const TaskArea = () => {
  const {
    isLoading,
    stateList,
    filteredTasks,
    showDetails,
    sortBy,
    activeSidebarMenu,
    filter,
    selectedProjectList,
    selectedFilterTags,
  } = useSelector(selectApp)

  const noFilterSelected = isEmpty([
    ...(filter[OWNER_FIELD] ?? []),
    ...(filter[STATUS_FIELD] ?? []),
    ...(selectedProjectList ?? []),
    ...(selectedFilterTags ?? []),
  ])

  const tasksWithTags = filteredTasks.filter((task) => !isEmpty(task.task_tags))

  const sortedByTags =
    selectedFilterTags?.length > 0
      ? uniq(
          flatten(
            selectedFilterTags.map((tagId) =>
              tasksWithTags.filter((task) =>
                task.task_tags?.some((tag) => tag.tag_id === tagId)
              )
            )
          )
        )
      : filteredTasks

  const sortByFieldToFunction = {
    child_updated_at: sortByDate('child_updated_at'),
    created_at: sortByCreatedAt,
    due_date: sortByDate('deadline'),
    priority: ramdaSortBy((a) => {
      const aPriorityIndex =
        (a.priority && PRIORITY_ORDER.indexOf(a.priority)) || -1

      return aPriorityIndex
    }),
  }

  const sortingFunction = sortByFieldToFunction[sortBy.field]

  let sortedTaskList = sortingFunction(sortedByTags)

  if (sortBy.order === 'asc') {
    sortedTaskList = sortedTaskList.reverse()
  }

  const orderedStateList = getOrdenedStatusList(stateList)

  if (isLoading) {
    return <Loading message="Loading tasks" />
  }

  const sortByList = ['child_updated_at', 'created_at', 'due_date', 'priority']

  const sortByToName = {
    child_updated_at: 'Last Updated',
    created_at: 'Creation Date',
    due_date: 'Due Date',
    priority: 'Priority',
  }

  const sortByTitle = sortByToName[sortBy.field]

  const handleSortByFieldClick = () => {
    const index = sortByList.indexOf(sortBy.field)

    const nextIndex = index + 1 === sortByList.length ? 0 : index + 1

    setSortBy({ field: sortByList[nextIndex], order: sortBy.order })
  }

  const handleSortByOrderClick = () => {
    setSortBy({
      field: sortBy.field,
      order: sortBy.order === 'asc' ? 'desc' : 'asc',
    })
  }

  const sortByOrderIconProps = {
    alt: 'sort by order',
    onClick: handleSortByOrderClick,
  }

  function getFramerProps() {
    if (isMobile) {
      return null
    }

    if (!isNil(activeSidebarMenu)) {
      return S.CONTAINER_FRAMER_CONFIG
    }

    return null
  }

  return (
    <S.General $isSideMenuOpen={!isNil(activeSidebarMenu)}>
      {!isMobile && (
        <S.ManageContainer>
          <S.ManageSortBy id="sort_by_button">
            <S.SortTitle onClick={handleSortByFieldClick}>
              {sortByTitle}
            </S.SortTitle>
            {sortBy.order === 'desc' ? (
              <S.CustomSortAscending name="up" {...sortByOrderIconProps} />
            ) : (
              <S.CustomSortDescending name="down" {...sortByOrderIconProps} />
            )}
          </S.ManageSortBy>
          <S.ManageDetails
            id="hide_show_button"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? 'Hide Details' : 'View Details'}
            <S.Image
              src={showDetails ? iconEye : iconClosedEye}
              alt="closed eye"
            />
          </S.ManageDetails>
        </S.ManageContainer>
      )}
      <S.Container
        key="taskArea"
        $noFilterSelected={noFilterSelected}
        {...getFramerProps()}
      >
        {orderedStateList.map((state) => {
          const tasksByState = sortedTaskList.filter(
            (task) => task.state_id === state.id
          )

          if (
            !isEmpty(filter[STATUS_FIELD] ?? []) &&
            !filter[STATUS_FIELD].includes(state.id)
          ) {
            return null
          }

          return (
            <TaskColumn
              key={state.id}
              state={state}
              tasks={tasksByState}
              showDetails={showDetails}
            />
          )
        })}
      </S.Container>
    </S.General>
  )
}

export default TaskArea
