import PropTypes from 'prop-types'
import { isEmpty, trim } from 'ramda'
import { createRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Collapsable, EditForm, Label, PromptModal, RedButton } from '..'
import { selectApp } from '../../selectors'
import { hasId } from '../../utils/ramdaExtensions'
import * as S from './styles'

export const MAX_COMPANY_NAME_LENGTH = 200
export const MIN_COMPANY_NAME_LENGTH = 3
export const MAX_COMPANY_PICTURE_SIZE = 25 * 2 ** 20

const EditCompanyModal = ({ company, onSave, onDelete, ...modalProps }) => {
  const { isUserOwner, isGuest } = useSelector(selectApp)

  const [companyToEdit, setCompanyToEdit] = useState(company)
  const [pictureFileToEdit, setPictureFileToEdit] = useState(null)
  const [showDeletePrompt, setShowDeletePrompt] = useState(false)

  const pictureRef = createRef(null)

  async function handleSave() {
    await onSave(companyToEdit, pictureFileToEdit)
  }

  function handlePictureClick() {
    pictureRef.current.click()
  }

  function handleDeleteClick() {
    setShowDeletePrompt(true)
  }

  function handlePromptDeleteCancel() {
    setShowDeletePrompt(false)
  }

  async function handlePromptDeleteSave() {
    await onDelete()
  }

  const isNew = !hasId(company)

  const disabled = !isUserOwner && !isNew

  return (
    <S.Modal
      title={isNew ? `Creating Company` : `Editing Company`}
      controls={[]}
      canSave={isUserOwner}
      onSave={handleSave}
      {...modalProps}
    >
      <S.Container>
        <Label>IMAGE</Label>
        <S.Picture
          company={companyToEdit}
          isGuest={isGuest}
          onClick={handlePictureClick}
          $disabled={disabled}
        />
        <EditForm
          fields={[
            {
              title: 'IMAGE',
              type: 'file',
              inputRef: pictureRef,
              disabled,
              accept: 'image/*',
              hidden: true,
              style: {
                display: 'none',
              },
              value: null,
              onChange: () => {
                const file = pictureRef.current?.files[0]

                if (file.type === 'image/png' || file.type === 'image/jpeg') {
                  if (file.size > MAX_COMPANY_PICTURE_SIZE) {
                    toast.error(
                      `Cannot have company picture larger than ${MAX_COMPANY_PICTURE_SIZE} bytes.`
                    )

                    return
                  }

                  const url = URL.createObjectURL(file)

                  setPictureFileToEdit(file)
                  setCompanyToEdit({ ...companyToEdit, picture_url: url })
                } else {
                  toast.error('Image should be either PNG or JPEG.')
                }
              },
            },
            {
              title: 'NAME',
              type: 'text',
              value: companyToEdit.name,
              disabled,
              maxLength: MAX_COMPANY_NAME_LENGTH,
              minLength: MIN_COMPANY_NAME_LENGTH,
              placeholder: 'Type Name Here',
              errors: (() => {
                if (isEmpty(trim(companyToEdit.name))) {
                  return ['Cannot have empty name.']
                }

                if (companyToEdit.name.length > MAX_COMPANY_NAME_LENGTH) {
                  return [
                    `Cannot have company name longer than ${MAX_COMPANY_NAME_LENGTH} characters.`,
                  ]
                }

                if (companyToEdit.name.length < MIN_COMPANY_NAME_LENGTH) {
                  return [
                    `Cannot have company name smaller than ${MIN_COMPANY_NAME_LENGTH} characters.`,
                  ]
                }

                return []
              })(),
              onChange: (name) => {
                setCompanyToEdit({
                  ...companyToEdit,
                  name,
                })
              },
            },
          ]}
        />
        {!isNew && isUserOwner && (
          <Collapsable title="ADVANCED">
            <RedButton onClick={handleDeleteClick}>DELETE</RedButton>
          </Collapsable>
        )}
      </S.Container>
      {showDeletePrompt && (
        <PromptModal
          title="Deleting Company"
          submitLabel="DELETE"
          message={
            <>
              Are you sure you want to delete company{' '}
              <strong>{company.name}</strong>? All the data (projects, tasks,
              etc.) will be deleted as well. This action is irreversible.
            </>
          }
          saveButtonColor="danger"
          onCancel={handlePromptDeleteCancel}
          onSave={handlePromptDeleteSave}
        />
      )}
    </S.Modal>
  )
}

EditCompanyModal.propTypes = {
  company: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
}

EditCompanyModal.defaultProps = {
  onDelete: () => {},
}

export default EditCompanyModal
