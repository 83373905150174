import { serverDelete, serverPost } from './server'

export async function acceptInviteOnServer(emailInviteId) {
  return await serverPost(`/join/company`, {
    body: {
      email_invite_id: emailInviteId,
    },
  })
}

export async function deleteAccountOnServer() {
  return await serverDelete(`/user`, {})
}
