import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  flex-wrap: wrap;
`

export default Row
