import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import Column from '../Column'
import GrayButton from '../GrayButton'
import GreenButton from '../GreenButton'
import TitleModal from '../TitleModal'

export const StyledModal = styled(TitleModal)`
  width: calc(100% - 30px);
  max-width: 900px;

  @media screen and (max-width: 700px) {
    position: fixed;
  }
`

export const UpdateButton = styled(GreenButton)`
  width: 75px;
  height: 36px;
`

export const LastUpdate = styled.p`
  font-size: 12px;
  font-family: var(--semibold-font);
  color: var(--white-30);
  margin-right: 9px;
`

export const Content = styled(Column)`
  padding: 24px;
  overflow: overlay;
  max-height: calc(100vh - 300px);

  @media screen and (max-width: 700px) {
    max-height: calc(100vh - 250px);
  }
`

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  ${(props) =>
    props.$dim &&
    `
    opacity: .2;
  `}
`

export const Flex = styled.div`
  display: flex;
  gap: 15px;

  ${(props) =>
    props.center &&
    `
    align-items: center;
  `}
`

export const MenuOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

export const SaveButton = styled(GreenButton)`
  width: 75px;
`

export const CancelButton = styled(GrayButton)`
  width: 75px;

  ${isMobile &&
  `
    width: 35px;
    padding: 0;
    padding-top: 3px;
  `}
`
