import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { fadeIn } from '../../styles/animations'
import Column from '../Column'
import CompanyPicture from '../CompanyPicture'
import EmptyState from '../EmptyState'
import GreenButton from '../GreenButton'
import { WhiteButton } from '../WhiteButton/styles'

export const Container = styled.div`
  display: flex;
  gap: 10px;
  text-align: left;
`

export const Company = styled.div`
  display: flex;
  padding: 10px;
  border-radius: var(--border-radius);
  white-space: nowrap;
  color: var(--white-62);
  font-size: 16px;
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  gap: 10px;
  border-color: var(--white);
  cursor: pointer;
  transition: 0.2s all ease;
  height: 44px;

  box-shadow: var(--box-shadow);
  opacity: 0;
  animation: ${fadeIn} 0.3s forwards;
  animation-delay: ${(props) => props.index && `0.${props.index}s`};

  :hover {
    transform: translate(-2px, 2px);
    box-shadow: var(--box-shadow-hover);
  }

  ${(props) =>
    props.$selected &&
    `
    transform: translate(-2px, 2px);
    box-shadow: var(--box-shadow-hover);
    border-color:  var(--white-62);
  `}
`

export const SelectedCompany = styled(WhiteButton)`
  height: 44px;
  width: fit-content;
  padding: 18px 10px;

  ${isMobile &&
  `
  max-width: 100px;
`}
`

export const CustomEmptyState = styled(EmptyState)`
  justify-content: flex-start;
`

export const MiniCompanyPicture = styled(CompanyPicture)`
  width: 30px;
  height: 30px;
  border-radius: 4px;
`

export const CompanyList = styled(Column)`
  padding: 10px;
  background-color: var(--white);
  border: 1px solid var(--white-62);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
`

export const NewCompanyButton = styled(GreenButton)`
  opacity: 0;
  animation: ${fadeIn} 0.3s forwards;
  animation-delay: ${(props) => props.index && `0.${props.index}s`};
`
