import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Bell from '../../assets/components/icon_bell'
import Button from '../Button'
import Column from '../Column'
import HoverShadow from '../HoverShadow'
import Row from '../Row'
import WhiteButton from '../WhiteButton'

export const StyledButton = styled(WhiteButton)`
  height: 44px;
  min-width: 44px;
  padding: 0;
  margin: 0 20px;
  position: relative;

  @media screen and (max-width: 700px) {
    margin: 0 15px 0 10px;
  }
`

export const StyledBell = styled(Bell)`
  fill: currentColor;
  width: 21px;
  height: 21px;
`

export const Root = styled(Column)`
  max-height: ${isMobile ? 'calc(100vh - 170px)' : 'calc(100vh - 88px)'};
`

export const Notifications = styled(Column)`
  position: relative;
  max-width: min(520px, calc(100% - 30px));
  height: fit-content;
  box-sizing: content-box;
  gap: 12px;
  padding: 12px;
  background: var(--white);
  border-radius: var(--border-radius);
  z-index: 10000;
  border: 1px solid var(--white-62);
  overflow: auto;
  box-shadow: var(--box-shadow);

  ${isMobile &&
  `
    max-width: calc(100vw - 20px);
    max-height: ${(props) =>
      props.showPager ? 'calc(100vh - 242px)' : 'calc(100vh - 200px)'};
  `}
`

export const EmptyMessage = styled.div`
  text-align: center;
`

export const ClearAllButton = styled(Button)`
  width: fit-content;
  align-self: end;
`

export const Message = styled.div``

export const CustomRow = styled(Row)`
  gap: 12px;
  align-items: center;
`

export const CustomHoverShadow = styled(HoverShadow)`
  width: 100%;
  padding: 12px;
`

export const CustomLink = styled(Link)`
  text-decoration: none;
  color: currentcolor;
`
export const NotificationCount = styled.span`
  font-size: 10px;
  font-family: var(--semibold-font);
  position: absolute;
  bottom: -10px;
  right: -5px;
  padding: 2px;
  background-color: var(--red);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: var(--white);
`
