import PropTypes from 'prop-types'
import { map, mapObjIndexed } from 'ramda'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { CenteredTitleModal, EditCompanyWorkflowModal, Menu, Row } from '..'
import { patchCompany } from '../../actions'
import { selectApp } from '../../selectors'
import {
  createWorkflowOnServer,
  deleteWorkflowOnServer,
  updateWorkflowOnServer,
} from '../../services/workflowApi'
import { getRandomEmoji } from '../../utils'
import {
  emojiListNegative,
  emojiListPositive,
} from '../../utils/getRandomEmoji'
import { track } from '../../utils/mixpanel'
import {
  hasId,
  mapPatchId,
  omitId,
  rejectId,
} from '../../utils/ramdaExtensions'
import { getWorkflowEntry } from '../UpsertTask'
import * as S from './styles'

export const TEMPLATE_WORKFLOW = {
  name: '',
  tags: [{ title: 'Special' }, { title: 'MVP' }, { title: 'Important' }],
  states: [
    {
      id: '0',
      title: 'To Do',
      default_color: '#D72D36',
      type: 'unstarted',
    },
    {
      id: '1',
      title: 'Doing',
      default_color: '#F9C270',
      type: 'started',
    },
    {
      id: '2',
      title: 'Done',
      default_color: '#63C9B8',
      type: 'done',
    },
  ],
  default_state: 'To Do',
  task_template: {
    fields: [],
    hide_priority: false,
    hide_deadline: true,
  },
}

const EditCompanyWorkflowsModal = ({ company, ...modalProps }) => {
  const { isUserAdmin } = useSelector(selectApp)

  const [workflowToEdit, setWorkflowToEdit] = useState(null)

  const { company_workflows, workflows } = company

  const isNewWorkflow = !hasId(workflowToEdit)

  function handleWorkflowClick(workflow) {
    setWorkflowToEdit(workflow)
  }

  function handleAddWorkflowClick() {
    setWorkflowToEdit(TEMPLATE_WORKFLOW)
  }

  function handleWorkflowModalClose() {
    setWorkflowToEdit(null)
  }

  async function handleWorkflowSave(newWorkflow) {
    const worklfowToSend = getWorkflowEntry(newWorkflow)

    if (isNewWorkflow) {
      const response = await createWorkflowOnServer(company.id, {
        ...worklfowToSend,
        states: mapObjIndexed(map(omitId))(worklfowToSend.states),
      })

      if (response.status === 200) {
        const { company_workflow, workflow } = await response.json()

        patchCompany({
          companyId: company.id,
          companyData: {
            company_workflows: [...company_workflows, company_workflow],
            workflows: [...company.workflows, workflow],
          },
        })

        setWorkflowToEdit(null)

        track('Workflow created')

        toast(
          `Workflow created successfully! ${getRandomEmoji(emojiListPositive)}`
        )
      } else {
        toast.error(
          `Could not create workflow. ${getRandomEmoji(emojiListNegative)}`
        )
      }
    } else {
      const response = await updateWorkflowOnServer(
        company.id,
        newWorkflow.id,
        worklfowToSend
      )

      if (response.status === 200) {
        const workflowFromServer = await response.json()

        patchCompany({
          companyId: company.id,
          companyData: {
            workflows: mapPatchId(
              workflowToEdit.id,
              workflowFromServer
            )(workflows),
          },
        })

        setWorkflowToEdit(null)

        track('Workflow updated')

        toast(
          `Workflow updated successfully! ${getRandomEmoji(emojiListPositive)}`
        )
      } else if (response.status === 401) {
        const { message } = await response.json()

        toast.error(message)
      } else {
        toast.error(
          `Could not update workflow. ${getRandomEmoji(emojiListNegative)}`
        )
      }
    }
  }

  function handleEditGuest(guest) {
    setWorkflowToEdit(guest)
  }

  async function handleDeleteWorkflow(workflow) {
    if (workflows.length === 1) {
      return toast(
        `You need to have at least 1 workflow per company. ${getRandomEmoji(
          emojiListPositive
        )}`
      )
    }

    const response = await deleteWorkflowOnServer(company.id, workflow.id)

    if (response.status === 200) {
      patchCompany({
        companyId: company.id,
        companyData: {
          workflows: rejectId(workflow.id, workflows),
        },
      })

      toast(
        `Workflow deleted successfully! ${getRandomEmoji(emojiListPositive)}`
      )
    } else {
      toast.error(
        `Could not delete workflow. ${getRandomEmoji(emojiListNegative)}`
      )
    }

    return null
  }

  return (
    <CenteredTitleModal title="Editing Workflows" {...modalProps}>
      <>
        <S.Container>
          <S.Workflows>
            {workflows.map((workflow) => {
              const { id } = workflow

              const { name } = workflow

              return (
                <S.Workflow
                  disabled={!isUserAdmin}
                  $isUserAdmin={isUserAdmin}
                  key={id}
                  onClick={
                    isUserAdmin && handleWorkflowClick.bind(null, workflow)
                  }
                >
                  <S.WorkflowRow>
                    <S.WorkflowDetails>
                      <Row>
                        <S.WorkflowName>{name}</S.WorkflowName>
                      </Row>
                    </S.WorkflowDetails>
                    {isUserAdmin && (
                      <Menu
                        small
                        items={[
                          {
                            icon: 'edit',
                            title: 'Edit',
                            onClick: handleEditGuest.bind(null, workflow),
                          },
                          {
                            icon: 'trash',
                            title: 'Delete',
                            onClick: handleDeleteWorkflow.bind(null, workflow),
                          },
                        ]}
                      />
                    )}
                  </S.WorkflowRow>
                </S.Workflow>
              )
            })}
          </S.Workflows>
          {isUserAdmin && (
            <S.CreateWorkflowButton
              key="0"
              title="+ Create Workflow"
              onClick={handleAddWorkflowClick}
            />
          )}
        </S.Container>
        {workflowToEdit && (
          <EditCompanyWorkflowModal
            company={company}
            $isNew={isNewWorkflow}
            workflow={workflowToEdit}
            onSave={handleWorkflowSave}
            onCancel={handleWorkflowModalClose}
          />
        )}
      </>
    </CenteredTitleModal>
  )
}

EditCompanyWorkflowsModal.propTypes = {
  company: PropTypes.object.isRequired,
}

EditCompanyWorkflowsModal.defaultProps = {}

export default EditCompanyWorkflowsModal
