import { isEmpty, without } from 'ramda'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import {
  setFilter,
  setProjectSelected,
  setSelectedFilterTags,
  setSelectedProjectIdList,
  setShowMobileSidebar,
} from '../../actions'
import { OWNER_FIELD, STATUS_FIELD } from '../../constants'
import { useGetParsedPersonList } from '../../hooks'
import { selectApp } from '../../selectors'
import { setSelectedFilter } from '../../utils'
import { getParsedCollection, renderItem } from './helpers'
import * as S from './styles'

const SelectedFilters = () => {
  const personList = useGetParsedPersonList()

  const {
    stateList,
    selectedCompanyProjects,
    selectedProjectList,
    filter,
    showMobileSidebar,
    tagList,
    selectedFilterTags,
  } = useSelector(selectApp)

  const parsedSelectedPersonList = getParsedCollection({
    collection: personList,
    selectedCollection: filter[OWNER_FIELD],
  })

  const parsedSelectedStateList = getParsedCollection({
    collection: stateList,
    selectedCollection: filter[STATUS_FIELD],
  })

  const parsedSelectedFilterTags = selectedFilterTags ?? []

  const noFilterSelected = isEmpty([
    ...selectedProjectList,
    ...parsedSelectedPersonList,
    ...parsedSelectedStateList,
    ...parsedSelectedFilterTags,
  ])

  function handleClosePeopleOrStatus({ payload, fieldToLoad }) {
    setSelectedFilter({
      payload,
      filter,
      fieldToLoad,
      setFilter,
    })
  }

  function handleCloseProject({ payload }) {
    setProjectSelected({ project: payload, selected: false })
  }

  function handleCloseTags({ payload }) {
    return setSelectedFilterTags(without(payload.id, selectedFilterTags))
  }

  function handleClearAll() {
    setFilter({
      ...filter,
      [OWNER_FIELD]: [],
      [STATUS_FIELD]: [],
    })
    setSelectedProjectIdList([])
    setSelectedFilterTags([])
  }

  function renderClearAll() {
    return (
      <>
        Selected Filters
        <S.Button $clear onClick={handleClearAll} disabled={noFilterSelected}>
          Clear All
        </S.Button>
      </>
    )
  }

  function handleShowSidebar() {
    setShowMobileSidebar(!showMobileSidebar)
  }

  function renderFilters() {
    if (noFilterSelected) {
      return (
        <S.Container>
          {['projects', 'people', 'status', 'tags'].map((category) => (
            <S.Item key={category}>
              <S.StrongText>{`All ${category}`}</S.StrongText>
            </S.Item>
          ))}
        </S.Container>
      )
    }

    return (
      <S.Container>
        {renderItem({
          collection: selectedCompanyProjects,
          selectedCollection: selectedProjectList,
          category: 'projects',
          field: 'name',
          onClose: handleCloseProject,
        })}
        {renderItem({
          collection: personList,
          selectedCollection: parsedSelectedPersonList,
          category: 'people',
          field: 'full_name',
          fieldToLoad: OWNER_FIELD,
          onClose: handleClosePeopleOrStatus,
        })}
        {renderItem({
          collection: stateList,
          selectedCollection: parsedSelectedStateList,
          category: 'status',
          field: 'title',
          fieldToLoad: STATUS_FIELD,
          onClose: handleClosePeopleOrStatus,
        })}
        {renderItem({
          collection: tagList,
          selectedCollection: tagList.filter((tag) =>
            selectedFilterTags?.includes(tag.id)
          ),
          category: 'tags',
          field: 'title',
          onClose: handleCloseTags,
        })}
      </S.Container>
    )
  }

  return (
    <>
      <S.ClearContainer>
        {isMobile && (
          <S.Button $filter onClick={handleShowSidebar}>
            Filters
          </S.Button>
        )}
        {renderClearAll()}
      </S.ClearContainer>
      {renderFilters()}
    </>
  )
}

export default SelectedFilters
