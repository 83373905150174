export default function IconPriority({ ...svgProps }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path d="M8.88003 5.60902C8.56784 5.29683 8.08784 5.29683 7.77565 5.60902C7.46346 5.92121 7.46346 6.40121 7.77565 6.71341L8.71126 7.64902H7.00688C3.47912 7.64902 0.599121 10.529 0.599121 14.0568C0.599121 17.5845 3.47912 20.4645 7.00688 20.4645H9.93464C10.3668 20.4645 10.7024 20.1045 10.7024 19.6967C10.7024 19.2645 10.3424 18.9289 9.93464 18.9289H7.00784C4.32008 18.8802 2.16008 16.6967 2.16008 14.0324C2.16008 11.368 4.32008 9.18469 7.00784 9.18469H8.71223L7.77661 10.1203C7.46442 10.4325 7.46442 10.9125 7.77661 11.2247C7.92098 11.3691 8.13661 11.4647 8.3288 11.4647C8.52099 11.4647 8.73661 11.3925 8.88099 11.2247L11.1366 8.96907C11.281 8.8247 11.3766 8.63345 11.3766 8.41688C11.3766 8.20125 11.3044 8.00907 11.1366 7.86469L8.88003 5.60902Z" />
      <path d="M14.0646 9.18454H22.609C23.0412 9.18454 23.3769 8.82454 23.3769 8.41673C23.3769 8.00893 23.0169 7.64893 22.609 7.64893H14.0646C13.6324 7.64893 13.2968 8.00893 13.2968 8.41673C13.2958 8.82454 13.6324 9.18454 14.0646 9.18454Z" />
      <path d="M22.6323 18.88H14.0646C13.6324 18.88 13.2968 19.24 13.2968 19.6478C13.2968 20.08 13.6568 20.4156 14.0646 20.4156H22.609C23.0412 20.4156 23.3768 20.0556 23.3768 19.6478C23.4003 19.2166 23.0402 18.88 22.6323 18.88Z" />
      <path d="M22.6323 15.1368H14.0646C13.6324 15.1368 13.2968 15.4968 13.2968 15.9046C13.2968 16.3125 13.6568 16.6725 14.0646 16.6725H22.609C23.0412 16.6725 23.3768 16.3125 23.3768 15.9046C23.3759 15.4968 23.0402 15.1368 22.6323 15.1368Z" />
      <path d="M22.6323 11.3923H14.0646C13.6324 11.3923 13.2968 11.7523 13.2968 12.1601C13.2968 12.5923 13.6568 12.9279 14.0646 12.9279H22.609C23.0412 12.9279 23.3768 12.5679 23.3768 12.1601C23.4003 11.7289 23.0402 11.3923 22.6323 11.3923Z" />
    </svg>
  )
}

IconPriority.propTypes = {}

IconPriority.defaultProps = {}
