import PropTypes from 'prop-types'
import { ICONS } from '../../assets'
import { useLogout } from '../../hooks'
import Button from '../Button'

const Logout = ({ children, ...props }) => {
  const { handleLogout } = useLogout()

  return (
    <Button
      id="logout_button"
      onClick={handleLogout}
      color="secondary"
      {...props}
    >
      <img src={ICONS.logout} /> {children || 'Log Out'}
    </Button>
  )
}

Logout.propTypes = {
  children: PropTypes.string,
}

Logout.defaultProps = {
  children: 'Log out',
}

export default Logout
