import MenuButton from '../MenuButton'
import * as S from './styles'

const MenuItem = ({ onClick, index, ...rest }) => (
  <S.Item onClick={onClick} index={index}>
    <MenuButton {...rest} />
  </S.Item>
)

MenuItem.propTypes = {
  ...MenuButton.propTypes,
}

MenuItem.defaultProps = {}

export default MenuItem
