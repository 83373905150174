import PropTypes from 'prop-types'
import * as S from './styles'

const ProjectName = ({ project, ...rest }) => (
  <S.ProjectName {...rest}>{project.name}</S.ProjectName>
)

ProjectName.propTypes = {
  project: PropTypes.object.isRequired,
}

export default ProjectName
