import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { HEADER_HEIGHT } from '../../constants'

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  :nth-child(1) {
    background-color: var(--white-90);
    padding: 20px;
  }

  :nth-child(2) {
    padding: 0 20px;
    display: flex;
    gap: 10px;
    height: 30px;

    ${isMobile &&
    `
      flex-wrap: wrap;
    `}
  }
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: ${HEADER_HEIGHT}px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
`
