import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import iconSearch from '../../assets/icon_search.svg'

export const Container = styled.div`
  position: relative;
  width: 400px;
  display: block;
  margin-left: auto;
  z-index: 1;

  @media screen and (max-width: 700px) {
    margin-left: 10px;
  }
`

export const Search = styled.input`
  background: none;
  border: none;
  border-radius: unset;
  border-bottom: 2px solid var(--white-70);
  height: 40px;
  width: 100%;
  font-size: 16px;
  padding-right: 40px;
  background: url(${iconSearch}) no-repeat center right 10px;
`

export const SearchResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  max-height: 750px;
  width: 100%;
  overflow-y: auto;
  background: var(--white);
  padding: 10px 0;
  border-radius: 8px;
  position: absolute;
  box-shadow: var(--box-shadow);

  ${isMobile &&
  `
    width: calc(100vw - 20px);
    margin-left: -10px;
  `}
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  padding: 10px;
  color: var(--white-30);
  font-family: var(--light-font);
  gap: 10px;
  margin-top: -10px;
  transition: 0.2s all ease;

  :not(:last-of-type) {
    border-bottom: 1px solid var(--white-90);
  }

  :hover {
    color: var(--black);
    background: var(--white-90);
  }
`

export const ProjectLabel = styled.div`
  font-size: 12px;
  width: fit-content;
  white-space: nowrap;
  color: var(--white-50);

  span {
    color: var(--aqua-39);
    text-transform: lowercase;
  }
`

export const TaskTitle = styled.p`
  font-family: var(--semibold-font);
  color: var(--white-30);
`
