import PropTypes from 'prop-types'
import * as S from './styles'

const TaskCounter = ({ amount }) => (
  <S.Counter amount={amount}>{amount}</S.Counter>
)

TaskCounter.propTypes = {
  amount: PropTypes.number.isRequired,
}

export default TaskCounter
