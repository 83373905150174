import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import Tag from '../Tag'
import * as S from './styles'

const PersonName = ({ name, tagProps }) => (
  <S.Container>
    <S.Name title={`${name}`}>{name}</S.Name>
    {!isEmpty(tagProps) && <Tag {...tagProps} />}
  </S.Container>
)

PersonName.propTypes = {
  name: PropTypes.string.isRequired,
  tagProps: PropTypes.shape({
    tag: PropTypes.shape({
      title: PropTypes.string,
    }),
    title: PropTypes.string,
    disabled: PropTypes.bool,
    isPeopleTag: PropTypes.bool,
  }),
}

PersonName.defaultProps = {
  tagProps: {},
}

export default PersonName
