import styled from 'styled-components'

const LABEL_SIZE = '12px'

const Label = styled.label`
  font-size: ${LABEL_SIZE};
  color: var(--white-70);
  font-family: var(--semibold-font);
  text-transform: uppercase;
  user-select: none;

  ::first-letter {
    text-transform: capitalize;
  }

  ${(props) =>
    props.required &&
    `
    ::after {
      content: '*';
      color: var(--red);
      margin-left: 4px;
    }
  `}
`

export default Label
