import PropTypes from 'prop-types'
import { find, isEmpty, propEq, trim } from 'ramda'
import { useEffect, useState } from 'react'
import { EditFormModal } from '..'

export const MAX_STATE_NAME_LENGTH = 20

const EditStateModal = ({
  state,
  isDefault,
  states,
  $isNew,
  onClose,
  onSave,
}) => {
  const [stateToEdit, setStateToEdit] = useState(state)
  const [isDefaultToEdit, setIsDefaultToEdit] = useState(isDefault)

  useEffect(() => {
    setIsDefaultToEdit(isDefault)
  }, [isDefault])

  function handleClose() {
    onClose(stateToEdit)
  }

  function handleSave() {
    onSave(stateToEdit, isDefaultToEdit)
  }

  const statesOfThisType = states.filter(propEq('type', stateToEdit.type))

  const shownDisplayOrder = stateToEdit.display_order || 0

  const maxShownDisplayOrder = statesOfThisType.length

  return (
    <EditFormModal
      title={$isNew ? `Adding Status` : `Editing Status`}
      fields={[
        {
          title: 'NAME',
          value: stateToEdit.title,
          maxLength: MAX_STATE_NAME_LENGTH,
          placeholder: 'Type Tag Name Here',
          errors: (() => {
            if (isEmpty(trim(stateToEdit.title))) {
              return ['Cannot have empty state title.']
            }

            if (stateToEdit.title.length > MAX_STATE_NAME_LENGTH) {
              return [
                `Cannot have state title longer than ${MAX_STATE_NAME_LENGTH} characters.`,
              ]
            }

            const sameTitleState = find(propEq('title', stateToEdit.title))(
              states
            )

            const isDuplicatedStateName =
              sameTitleState && ($isNew || stateToEdit.id !== sameTitleState.id)

            if (isDuplicatedStateName) {
              return ['Cannot have duplicated state title.']
            }

            return []
          })(),
          onChange: (title) => {
            setStateToEdit({
              ...stateToEdit,
              title,
            })
          },
        },
        {
          title: 'TYPE',
          type: 'radiogroup',
          value: stateToEdit.type,
          errors: [],
          options: [
            {
              value: 'unstarted',
              label: 'To Do',
            },
            {
              value: 'started',
              label: 'In-Progress',
            },
            {
              value: 'done',
              label: 'Done',
            },
          ],
          onChange: (type) => {
            setStateToEdit({
              ...stateToEdit,
              type,
            })
          },
        },
        {
          title: 'COLOR',
          type: 'color',
          value: stateToEdit.default_color,
          errors: [],
          onChange: (default_color) => {
            setStateToEdit({
              ...stateToEdit,
              default_color,
            })
          },
        },
        {
          title: 'DISPLAY ORDER',
          type: 'number',
          value: shownDisplayOrder + 1,
          min: 1,
          max: maxShownDisplayOrder,
          errors: [],
          onChange: (display_order) => {
            setStateToEdit({
              ...stateToEdit,
              display_order: display_order - 1,
            })
          },
        },
        {
          title: 'DEFAULT STATUS',
          type: 'checkbox',
          value: isDefaultToEdit,
          disabled: isDefault,
          errors: [],
          onChange: (newIsDefault) => {
            setIsDefaultToEdit(newIsDefault)
          },
        },
      ]}
      $isNew={$isNew}
      onClose={handleClose}
      onCancel={handleClose}
      onSave={handleSave}
    />
  )
}

EditStateModal.propTypes = {
  state: PropTypes.object.isRequired,
  states: PropTypes.array.isRequired,
  $isNew: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isDefault: PropTypes.bool.isRequired,
}

EditStateModal.defaultProps = {}

export default EditStateModal
