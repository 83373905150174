import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const Text = styled.span`
  font-family: var(--semibold-font);
  color: var(--white-50);
  font-size: 12px;
`
