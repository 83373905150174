import PropTypes from 'prop-types'
import { createRef, useState } from 'react'
import { Spinner, TaskForm } from '..'
import { hasId } from '../../utils/ramdaExtensions'
import { getTaskFormFields } from '../UpsertTask/helpers'
import * as S from './styles'

const EditCompanyWorkflowModal = ({
  company,
  workflow,
  onCancel,
  onSave,
  ...modalProps
}) => {
  const owner_id = '0'
  const requestor_id = '0'
  const project_id = '1'
  const state_id = '4'

  const task = {
    title: 'Make Your Own Workflow',
    description:
      'Create a workflow that for a particular process in your company, with custom Status and Tags.',
    state_id,
    project_id,
    owner_id,
    requestor_id,
  }

  const [isSaving, setIsSaving] = useState(false)

  const requestorOptionList = [
    {
      value: requestor_id,
      label: 'You',
    },
  ]

  const ownerOptionList = [
    {
      value: owner_id,
      label: 'Joe Doe',
    },
  ]

  const projectOptionList = [
    {
      value: project_id,
      label: 'Sample Project',
    },
  ]

  const disabledFields = [
    'title',
    'description',
    'project',
    'requestor',
    'owner',
    'notes',
    'workflow',
    'priority',
    'due_date',
    'attachments',
  ]

  const fields = getTaskFormFields(
    task,
    false,
    true,
    task.owner_id,
    ownerOptionList,
    null,
    requestorOptionList,
    projectOptionList,
    null,
    null,
    disabledFields
  )

  const formRef = createRef()

  async function handleSave() {
    const newWorkflow = formRef.current.getWorkflowData()

    setIsSaving(true)

    await onSave(newWorkflow)

    setIsSaving(false)
  }

  return (
    <S.Modal
      title={!hasId(workflow) ? `Creating Workflow` : `Editing Workflow`}
      onClose={onCancel}
      controls={[
        <S.Actions key="0">
          <S.CancelButton type="button" color={'secondary'} onClick={onCancel}>
            Cancel
          </S.CancelButton>
          <S.SaveButton type="submit" onClick={handleSave}>
            {(isSaving && <Spinner />) || 'Save'}
          </S.SaveButton>
        </S.Actions>,
      ]}
      {...modalProps}
    >
      <S.Container>
        <TaskForm
          company={company}
          task={task}
          workflow={workflow}
          workflows={[workflow]}
          fields={fields}
          hasTaskToEdit={true}
          isEditMode={true}
          isViewMode={false}
          isAdmin={false}
          disabledFields={disabledFields}
          onSubmit={() => {}}
          childRef={formRef}
        />
      </S.Container>
    </S.Modal>
  )
}

EditCompanyWorkflowModal.propTypes = {
  company: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

EditCompanyWorkflowModal.defaultProps = {}

export default EditCompanyWorkflowModal
