import styled from 'styled-components'
import { getColor } from './helpers'

export const Shortcut = styled.span`
  font-family: var(--semibold-font);
  padding: 1.5px 5px;
  text-transform: uppercase;
  font-size: 12px;
  position: relative;
  z-index: 0;
  ${(props) => getColor(props.color)}
`

export const ShortcutContainer = styled.div`
  display: flex;
  gap: 14px;

  ${(props) =>
    props.shortcutSize > 1 &&
    `
      ${Shortcut}:not(:last-child) {
        ::after {
          content: '+';
          position: absolute;
          right: -10px;
          z-index: 0;
        }
      }
  `}
`
