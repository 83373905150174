export default function SortDescending({ ...svgProps }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M11.0028 9.10181H17.0028"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.0028 5.10181H19.0028"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.0028 13.1018H15.0028"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.0028 17.1018L7.35636 19.7483C7.1611 19.9435 6.84452 19.9435 6.64925 19.7483L4.00281 17.1018"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.00281 5.10181V19.1018"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

SortDescending.propTypes = {}

SortDescending.defaultProps = {}
