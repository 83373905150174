import { TaskPropType } from '../../props/TaskPropType'
import { daysFromNow } from '../../utils/date'
import * as S from './styles'

const TaskReminderCounter = ({ task, ...rest }) => {
  const { deadline } = task

  const countFromNow = daysFromNow(deadline)

  const color = countFromNow > 0 ? 'var(--red)' : 'var(--white-30)'

  const absoluteCount = Math.abs(countFromNow)

  const shownCount = absoluteCount < 100 ? `${absoluteCount}` : `...`

  const shownCountText = deadline ? shownCount : '?'

  return (
    <S.ReminderContainer {...rest}>
      <S.ReminderIcon $strokeColor={color}></S.ReminderIcon>
      <S.ReminderCounter $strokeColor={color}>
        {shownCountText}
      </S.ReminderCounter>
    </S.ReminderContainer>
  )
}

TaskReminderCounter.propTypes = {
  task: TaskPropType.isRequired,
}

TaskReminderCounter.defaultProps = {}

export default TaskReminderCounter
