import styled from 'styled-components'
import Paperclip from '../../assets/components/icon_paperclip'
import AttachmentFile from '../AttachmentFile'
import DashedBox from '../DashedBox'

export const Container = styled('div')`
  position: relative;
  width: 100%;
  height: 120px;
  min-height: 90px;
  cursor: pointer;
`

export const Text = styled('div')`
  font-family: var(--semibold-font);
  color: var(--aqua-59);
`

export const AttachmentDashedBox = styled(DashedBox)`
  top: 0;
  left: 0;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  transform: translate(-2px, -2px);
  position: absolute;
`

export const GreenPaperclip = styled(Paperclip)`
  color: var(--aqua-59);
`

export const NewAttachmentFile = styled(AttachmentFile)`
  background: var(--aqua-79);
  color: var(--aqua-39);
  font-family: var(--semibold-font);
  cursor: default;
`

export const NewAttachmentsRow = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  padding: 9px;
  width: 100%;
  height: 100%;
  gap: 9px;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
`
