import PropTypes from 'prop-types'
import X from '../../assets/components/icon_x'
import * as S from './styles'

const BackButton = ({ onClick, children }) => (
  <S.BackButton onClick={onClick} whileHover="animate">
    <X
      style={{ paddingTop: '2px', width: '21px', height: '21px' }}
      alt="x icon"
    />
    {children || 'Go Back'}
  </S.BackButton>
)

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string,
}

BackButton.defaultProps = {
  children: null,
}

export default BackButton
