import { AnimatePresence } from 'framer-motion'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { setActiveSidebarMenu, setShowMobileSidebar } from '../../actions'
import { ICONS } from '../../assets'
import { selectApp } from '../../selectors'
import { MENU_OPTIONS } from './constants'
import { getDrawerContent } from './helpers'
import * as S from './styles'

const Sidebar = () => {
  const { activeSidebarMenu } = useSelector(selectApp)

  function closeSidebar() {
    setActiveSidebarMenu(null)
  }

  const isSidebarOpen = !isNil(activeSidebarMenu)

  useEffect(() => {
    if (isMobile && !isSidebarOpen) {
      setActiveSidebarMenu('project')
    }
  }, [isSidebarOpen])

  const isProjectsSelected = activeSidebarMenu === 'project'

  return (
    <>
      <S.Background $isSidebarOpen={isSidebarOpen} />
      <AnimatePresence>
        {isSidebarOpen && (
          <S.Drawer
            key="drawer"
            $isFirstSelected={isProjectsSelected}
            {...S.DRAWER_FRAMER_CONFIG}
          >
            <S.Header>
              {activeSidebarMenu}
              <S.BackButton
                onClick={
                  isMobile ? () => setShowMobileSidebar(false) : closeSidebar
                }
              >
                <img src={ICONS.arrowLeft} />
              </S.BackButton>
            </S.Header>
            {getDrawerContent(activeSidebarMenu)}
          </S.Drawer>
        )}
      </AnimatePresence>
      <S.Container $isSidebarOpen={isSidebarOpen}>
        {MENU_OPTIONS.map((item) => (
          <div style={{ position: 'relative' }} key={item.name}>
            {item.name === activeSidebarMenu && <S.Selector />}
            <S.Item
              key={item.name}
              title={item.name}
              onClick={() => setActiveSidebarMenu(item.name)}
              $active={item.name === activeSidebarMenu}
              selected={item.name === activeSidebarMenu}
            >
              <item.icon />
              <S.Label>{item.name}</S.Label>
            </S.Item>
          </div>
        ))}
      </S.Container>
    </>
  )
}

export default Sidebar
