import PropTypes from 'prop-types'
import * as S from './styles'

const FloatingButton = ({ ...rest }) => <S.FloatingButton {...rest} />

FloatingButton.propTypes = {
  ...S.FloatingButton.propTypes,
  title: PropTypes.string,
}

FloatingButton.defaultProps = {
  title: '',
}

export default FloatingButton
