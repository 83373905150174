import PropTypes from 'prop-types'
import { Tab } from '..'
import * as S from './styles'

const Tabs = ({ tabs, selected, disabled, onTabSelected }) => {
  function handleTabClick(tab) {
    onTabSelected(tab)
  }

  return (
    <S.Tabs>
      {tabs.map(({ id, title }) => (
        <Tab
          key={id}
          tab={title}
          selected={selected === id}
          disabled={disabled}
          onClick={handleTabClick.bind(null, id)}
        />
      ))}
    </S.Tabs>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onTabSelected: PropTypes.func.isRequired,
}

Tabs.defaultProps = {}

export default Tabs
