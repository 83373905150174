import EmptyState from '../EmptyState'
import Loading from '../Loading'

export function getLoadingMessage(type) {
  return <Loading message={`Loading ${type}`} />
}

export function getEmptyStateMessage(type) {
  return <EmptyState>{`No ${type} found`}</EmptyState>
}
