import TextareaAutosize from 'react-autosize-textarea'
import styled from 'styled-components'

const LABEL_SIZE = '14px'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: ${(props) => (props.fullHeight ? '100%' : '65px')};
  width: 70%;

  ${(props) =>
    props.isTitle &&
    `
    width: 100%;
  `}
`

export const Label = styled.label`
  font-size: ${LABEL_SIZE};
  color: var(--white-30);
  font-family: var(--semibold-font);

  ::first-letter {
    text-transform: capitalize;
  }
`

export const Input = styled.input`
  border: var(--border);
  height: 40px;
  padding: 0 10px;
  border-radius: var(--border-radius);
  color: var(--white-20);

  ::placeholder {
    text-transform: capitalize;
  }

  ${(props) =>
    props.bigField &&
    `
    width: 100%;
    padding: 25px 10px;
    font-size: 24px;
    font-family: var(--semibold-font);

    ::placeholder {
      opacity: .5;
    }
  `}
`

export const Textarea = styled(TextareaAutosize)`
  border: var(--border);
  border-radius: var(--border-radius);
  color: var(--white-20);
  resize: none;
  width: 100%;
  padding: 10px;
  font-family: var(--semibold-font);
  height: 100%;
  font-size: 16px;

  ::placeholder {
    opacity: 0.5;
    text-transform: capitalize;
  }

  ${(props) =>
    props.name === 'title' &&
    `
    font-size: 24px;
  `}
`

export const ScrollBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  overflow-y: auto;
  max-height: 340px;
  padding-bottom: 40px;
`
