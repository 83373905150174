export default function Calendar({ ...svgProps }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentcolor"
      {...svgProps}
    >
      <g clipPath="url(#clip0_3415_23543)">
        <path
          d="M19.9546 4.16406H5.90545C4.79701 4.16406 3.89844 5.06263 3.89844 6.17108V20.2202C3.89844 21.3286 4.79701 22.2272 5.90545 22.2272H19.9546C21.063 22.2272 21.9616 21.3286 21.9616 20.2202V6.17108C21.9616 5.06263 21.063 4.16406 19.9546 4.16406Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0762 1.57812V5.55407"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.7832 1.57812V5.55407"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.89844 7.96094H21.9616"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3415_23543">
          <rect
            width="24"
            height="24"
            transform="translate(0.929688 0.0664062)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

Calendar.propTypes = {}

Calendar.defaultProps = {}
