import PropTypes from 'prop-types'
import { indexBy, prop } from 'ramda'
import { Column, TaskCustomField } from '..'
import { TaskPropType } from '../../props/TaskPropType'
import { mapPatchId } from '../../utils/ramdaExtensions'
import * as S from './styles'

const TaskCustomForm = ({
  $isEditMode,
  isEditable,
  task,
  fields,
  showErrors,
  onChange,
  onFieldClick,
  onFieldInputRef,
  onTemplateChange,
  onAddCustomFieldClick,
  onRemoveCustomFieldClick,
}) => {
  const handleFieldChange = (field, value) => {
    onChange(field, value)
  }

  const handleFieldTemplateChange = (field, data) => {
    const nextFields = mapPatchId(field.id, data)(fields)

    onTemplateChange(nextFields)
  }

  const handleCustomFieldClick = (field) => {
    onFieldClick(field)
  }

  const handleAddCustomFieldClick = () => {
    onAddCustomFieldClick()
  }

  const customFieldMap = indexBy(prop('field_id'), task?.custom_fields ?? [])

  return (
    <Column>
      <Column>
        {fields.map((field) => (
          <TaskCustomField
            key={field.id}
            value={customFieldMap[field.id]?.value ?? ''}
            showRequiredError={showErrors}
            field={field}
            isEditable={isEditable}
            $isEditMode={$isEditMode}
            inputRef={(input) => onFieldInputRef(field.id, input)}
            onChange={handleFieldChange}
            onClick={handleCustomFieldClick}
            onRemoveClick={onRemoveCustomFieldClick}
            onTemplateChange={handleFieldTemplateChange}
          />
        ))}
      </Column>
      {$isEditMode && (
        <S.AddFieldButton type="button" onClick={handleAddCustomFieldClick}>
          {'+ Add a Custom Field'}
        </S.AddFieldButton>
      )}
    </Column>
  )
}

TaskCustomForm.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  $isEditMode: PropTypes.bool.isRequired,
  task: TaskPropType.isRequired,
  fields: PropTypes.arrayOf(TaskCustomField.propTypes.field).isRequired,
  showErrors: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onFieldClick: PropTypes.func,
  onFieldInputRef: PropTypes.func,
  onTemplateChange: PropTypes.func.isRequired,
  onAddCustomFieldClick: PropTypes.func.isRequired,
  onRemoveCustomFieldClick: PropTypes.func.isRequired,
}

TaskCustomForm.defaultProps = {
  onFieldClick: () => {},
  onFieldInputRef: () => {},
}

export default TaskCustomForm
