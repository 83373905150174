import { serverDelete, serverPost, serverPut } from './server'

export async function createCompanyGuestOnServer(companyId, guest) {
  return await serverPost(`/company/${companyId}/outsider`, {
    body: guest,
  })
}

export async function updateCompanyGuestOnServer(companyId, outsiderId, guest) {
  return await serverPut(`/company/${companyId}/outsider/${outsiderId}`, {
    body: guest,
  })
}

export async function deleteCompanyGuestOnServer(companyId, outsiderId) {
  return await serverDelete(`/company/${companyId}/outsider/${outsiderId}`)
}
