import styled, { css } from 'styled-components'
import { getColor } from './helpers'

export const DEFAULT_BUTTON_STYLE = css`
  border: none;
  font-family: var(--semibold-font);
  border-radius: 4px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  white-space: nowrap;
  box-shadow: var(--box-shadow);
  border: 1.5px solid transparent;
  transition: 0.2s all ease;
  font-size: 1rem;
  color: var(--white-50);
  user-select: none;
  font-size: 14px;

  :hover {
    cursor: pointer;
    border: 1.5px solid var(--white-70);
  }
`

export const Button = styled.button`
  ${DEFAULT_BUTTON_STYLE}
  ${(props) => getColor(props.color)}
  ${(props) =>
    props.disabled &&
    `
    opacity: .4;
    cursor: default;
    pointer-events: none;

    :hover {
    }
  `}
`
