import PropTypes from 'prop-types'
import { AddOrRemoveList } from '..'
import * as S from './styles'

const EditProjectMemberList = ({
  members,
  isMember,
  membersToAdd,
  membersToRemove,
  onChange,
}) => (
  <AddOrRemoveList
    options={members}
    isPart={isMember}
    toAdd={membersToAdd}
    toRemove={membersToRemove}
    renderContent={(memberOption) => (
      <S.MemberOption>
        <S.MemberOptionPicture
          name={memberOption.profile.full_name}
          pictureUrl={memberOption.profile.picture}
        />
        <S.MemberOptionColumn>
          <S.MemberOptionName>
            {memberOption.profile.full_name}
          </S.MemberOptionName>
          <S.MemberOptionEmail>{memberOption.email}</S.MemberOptionEmail>
        </S.MemberOptionColumn>
      </S.MemberOption>
    )}
    onChange={onChange}
  />
)

EditProjectMemberList.propTypes = {
  members: PropTypes.any.isRequired,
  isMember: PropTypes.bool.isRequired,
  membersToAdd: PropTypes.array.isRequired,
  membersToRemove: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

EditProjectMemberList.defaultProps = {}

export default EditProjectMemberList
