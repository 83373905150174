import _ from 'lodash'

export default function getInitials(name) {
  if (_.isNil(name)) {
    return null
  }

  const nameSegments = name.split(' ')
  const segmentsCount = nameSegments.length

  if (segmentsCount === 1) {
    const letters = name.split('')
    return `${letters[0]}${letters[1]}`
  }

  const initials = name.split(' ').map((word) => _.head(word))
  const firstAndLast = [initials[0], initials[initials.length - 1]]

  return firstAndLast.join('')
}
