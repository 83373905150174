import styled from 'styled-components'
import IconX from '../../assets/components/icon_x'
import HoverShadow from '../HoverShadow'
import Label from '../Label'
import TaskCustomFieldInput from '../TaskCustomFieldInput'

export const Field = styled(HoverShadow)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: left;
  font-size: 14px;
  gap: 9px;
  font-family: var(--semibold-font);
  border-radius: var(--border-radius);
  box-sizing: content-box;
  outline-offset: 3px;

  ${(props) => `
    ${
      props.$isEditMode &&
      `
        cursor: pointer;
      `
    }`}
`

export const FieldLabel = styled(Label)`
  ${(props) => `
  ${
    props.$isEditMode &&
    `
      cursor: pointer;
    `
  }`}
`

export const FieldInput = styled(TaskCustomFieldInput)`
  ${(props) => `
  ${
    props.$isEditMode &&
    `
      cursor: pointer;
    `
  }`}
`

export const XIcon = styled(IconX)`
  color: var(--white-40);
  width: 24px;
  height: 24px;
  cursor: pointer;
`
