export default function IconField({ ...svgProps }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g clipPath="url(#clip0_1519_2874)">
        <path d="M22.1251 18.9375H10.5C10.0856 18.9375 9.75 18.6019 9.75 18.1875C9.75 17.7731 10.0856 17.4375 10.5 17.4375H22.1251C22.3314 17.4375 22.5001 17.2688 22.5001 17.0625V8.8125C22.5001 8.60531 22.3314 8.4375 22.1251 8.4375H10.5C10.0856 8.4375 9.75 8.10188 9.75 7.6875C9.75 7.27312 10.0856 6.9375 10.5 6.9375H22.1251C23.1592 6.9375 24.0001 7.77937 24.0001 8.8125V17.0625C24.0001 18.0966 23.1592 18.9375 22.1251 18.9375Z" />
        <path d="M4.5 18.9375H1.87488C0.84175 18.9375 -0.00012207 18.0966 -0.00012207 17.0625V8.8125C-0.00012207 7.77937 0.84175 6.9375 1.87488 6.9375H4.5C4.91438 6.9375 5.25 7.27312 5.25 7.6875C5.25 8.10188 4.91438 8.4375 4.5 8.4375H1.87488C1.66769 8.4375 1.49988 8.60531 1.49988 8.8125V17.0625C1.49988 17.2688 1.66769 17.4375 1.87488 17.4375H4.5C4.91438 17.4375 5.25 17.7731 5.25 18.1875C5.25 18.6019 4.91438 18.9375 4.5 18.9375Z" />
        <path d="M7.5 21.9375C7.08562 21.9375 6.75 21.6019 6.75 21.1875V4.6875C6.75 4.27312 7.08562 3.9375 7.5 3.9375C7.91438 3.9375 8.25 4.27312 8.25 4.6875V21.1875C8.25 21.6019 7.91438 21.9375 7.5 21.9375Z" />
        <path d="M9.75 5.4375H5.25C4.83562 5.4375 4.5 5.10188 4.5 4.6875C4.5 4.27312 4.83562 3.9375 5.25 3.9375H9.75C10.1644 3.9375 10.5 4.27312 10.5 4.6875C10.5 5.10188 10.1644 5.4375 9.75 5.4375Z" />
        <path d="M9.75 21.9375H5.25C4.83562 21.9375 4.5 21.6019 4.5 21.1875C4.5 20.7731 4.83562 20.4375 5.25 20.4375H9.75C10.1644 20.4375 10.5 20.7731 10.5 21.1875C10.5 21.6019 10.1644 21.9375 9.75 21.9375Z" />
      </g>
      <defs>
        <clipPath id="clip0_1519_2874">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.9375)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

IconField.propTypes = {}

IconField.defaultProps = {}
