import styled from 'styled-components'

export const Anchor = styled.a`
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;

  &:visited,
  &:hover,
  &:active {
    color: inherit;
  }
`
