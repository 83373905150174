export default function Tags(svgProps) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M24.9983 15.376C24.9671 14.3954 24.5686 13.4759 23.8616 12.8018L12.9836 1.95067C12.4003 1.36879 11.6321 1.03114 10.8015 1H10.6478L2.90487 2.2871C2.56638 2.34816 2.32155 2.59361 2.25911 2.93127L1 10.6863V10.8396C1.03121 11.6669 1.36848 12.4332 1.95303 13.0163L12.8298 23.8662C13.5368 24.5714 14.4586 24.9701 15.4104 25H15.5029C16.4559 25 17.3465 24.6324 17.9923 23.9883L23.9841 18.0113C24.6598 17.2762 25.0283 16.3566 24.9983 15.376L24.9983 15.376ZM22.8774 16.8774L16.8856 22.8544C16.0862 23.6518 14.7347 23.6206 13.8741 22.7622L2.99727 11.9123C2.7212 11.6369 2.53636 11.2382 2.50514 10.8395L3.70301 3.72875L10.8314 2.53385C11.2311 2.56498 11.5996 2.74817 11.9069 3.02476L22.7837 13.8746C23.6455 14.7319 23.6767 16.0813 22.8773 16.8775L22.8774 16.8774Z"
        fill="currentColor"
      />
      <path
        d="M6.83785 6.85454C5.70119 7.98838 5.70119 9.82745 6.83785 10.9612C7.39118 11.5131 8.12814 11.8196 8.89632 11.8196C9.6645 11.8196 10.4015 11.5131 10.9548 10.9612C11.5081 10.4092 11.8154 9.67407 11.8154 8.90779C11.8154 8.14151 11.5081 7.40638 10.9548 6.85441C9.84933 5.72057 7.97451 5.72057 6.83797 6.85441L6.83785 6.85454ZM9.87935 9.8885C9.63329 10.1639 9.2648 10.2872 8.89632 10.2872C8.52784 10.2872 8.18935 10.134 7.9133 9.8885C7.35996 9.33654 7.35996 8.47806 7.9133 7.92732C8.18936 7.65194 8.52784 7.52861 8.89632 7.52861C9.2648 7.52861 9.60329 7.65074 9.87935 7.92732C10.1254 8.20271 10.279 8.54035 10.279 8.90791C10.279 9.27548 10.1566 9.64424 9.87935 9.8885V9.8885Z"
        fill="currentColor"
      />
    </svg>
  )
}
