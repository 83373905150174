import { Button } from '..'
import { preventDefaultAnd } from '../../utils/event/preventDefaultAnd'
import * as S from './styles'

const AddButton = ({ ...buttonProps }) => (
  <S.AddButton
    {...buttonProps}
    onClick={preventDefaultAnd(() => {
      buttonProps.onClick()
    })}
  />
)

AddButton.propTypes = {
  ...Button.propTypes,
}

AddButton.defaultProps = {}

export default AddButton
