import PropTypes from 'prop-types'

export default function Slingshot(svgProps) {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6562 4.46983L8.19675 1.08046C8.10513 0.990182 7.99623 0.918547 7.87627 0.869669C7.75637 0.82079 7.62777 0.795629 7.49791 0.795629C7.36805 0.795629 7.23945 0.82079 7.11955 0.869669C6.99965 0.918547 6.89075 0.990182 6.79913 1.08046L1.50196 6.27644C0.737261 7.03769 0.308594 8.06353 0.308594 9.13224C0.308594 10.201 0.737261 11.2267 1.50196 11.988C1.88305 12.3675 2.33721 12.6689 2.83805 12.8747C3.33889 13.0806 3.87644 13.1867 4.41947 13.1871C4.9624 13.1861 5.49974 13.0796 6.00049 12.8738C6.50122 12.668 6.95548 12.367 7.33695 11.988L16.9004 2.60727L18.8929 4.55777L13.5957 9.75373V17.0801H10.7842V9.70577L9.19914 11.2606V17.6556C9.20018 17.9129 9.30487 18.1593 9.49031 18.3412C9.6758 18.5232 9.927 18.6258 10.1893 18.6269H14.1906C14.4532 18.6269 14.7051 18.5245 14.8908 18.3424C15.0765 18.1603 15.1808 17.9132 15.1808 17.6556V10.3853L20.4168 5.24523C20.5089 5.15536 20.5819 5.04855 20.6317 4.93091C20.6816 4.81328 20.7072 4.68715 20.7072 4.55976C20.7072 4.43238 20.6816 4.30625 20.6317 4.18862C20.5819 4.07098 20.5089 3.96417 20.4168 3.87429L17.589 1.07645C17.4029 0.896076 17.1518 0.794922 16.8902 0.794922C16.6285 0.794922 16.3775 0.896076 16.1913 1.07645L6.23274 10.9049C5.78957 11.3404 5.19683 11.5983 4.57023 11.6283H4.43169C3.81181 11.631 3.21214 11.4122 2.74475 11.0128L2.61437 10.8849C2.14845 10.4167 1.88762 9.78847 1.88762 9.13422C1.88762 8.48002 2.14845 7.85169 2.61437 7.38357L7.48772 2.59129L10.5275 5.56898L11.6562 4.46983Z"
        fill={'currentcolor'}
      />
    </svg>
  )
}

Slingshot.propTypes = {
  fill: PropTypes.string,
}

Slingshot.defaultProps = {
  fill: '#808080',
}
