import { isEmpty, without } from 'ramda'
import { useSelector } from 'react-redux'
import { Loading, MiniCardList, Tags } from '..'
import { setSelectedFilterTags } from '../../actions'
import { selectApp } from '../../selectors'

const TagFilterList = () => {
  const { isLoading, tagList, selectedFilterTags } = useSelector(selectApp)

  if (isLoading) {
    return <Loading message="Loading tags" />
  }

  function handleTagClick(tag) {
    if (selectedFilterTags?.includes(tag.id)) {
      return setSelectedFilterTags(without(tag.id, selectedFilterTags))
    }

    return setSelectedFilterTags([...(selectedFilterTags ?? []), tag.id])
  }

  function handleClearAll() {
    if (isEmpty(selectedFilterTags)) {
      const allTags = tagList.map((tag) => tag.id)
      return setSelectedFilterTags(allTags)
    }

    return setSelectedFilterTags([])
  }

  const tagProps = {
    tags: tagList,
    onTagClick: handleTagClick,
    selected: selectedFilterTags,
  }

  const listProps = {
    renderContent: () => <Tags {...tagProps} />,
    showClearAll: true,
    selectedList: selectedFilterTags,
    onClearAll: handleClearAll,
  }

  return <MiniCardList {...listProps} />
}

export default TagFilterList
