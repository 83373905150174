import PropTypes from 'prop-types'
import { concat, includes, isEmpty, pick, pipe, prop, reject, __ } from 'ramda'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { EditModal, EditProjectMemberList, Label } from '..'
import {
  createProject,
  patchProject,
  setProjectIdToPeople,
  setProjectSelected,
  setShowCreateProjectModal,
} from '../../actions'
import { selectApp } from '../../selectors'
import {
  useCreateProjectMutation,
  useUpdateProjectMutation,
} from '../../services/projectApi'
import { getRandomEmoji } from '../../utils'
import {
  emojiListNegative,
  emojiListPositive,
} from '../../utils/getRandomEmoji'
import { track } from '../../utils/mixpanel'
import {
  filterNotNil,
  hasId,
  isNotEmpty,
  pluckId,
} from '../../utils/ramdaExtensions'
import * as S from './styles'

const EditProjectPeopleModal = ({ company, project }) => {
  const { selectedCompany } = useSelector(selectApp)

  const [updateProjectOnServer] = useUpdateProjectMutation()
  const [createProjectOnServer] = useCreateProjectMutation()

  const [membersToAdd, setMembersToAdd] = useState([])
  const [membersToRemove, setMembersToRemove] = useState([])

  const projectMembers = filterNotNil(
    project?.project_members?.map((project_member) =>
      company.members.find((member) => member.id === project_member.member_id)
    ) ?? []
  )

  const nonProjectMembers = company.members.filter(
    (member) =>
      member.company_member.role !== 'owner' &&
      !project?.project_members?.find(
        (projectMember) => projectMember.member_id === member.id
      )
  )

  function handleCloseModal() {
    setShowCreateProjectModal(false)
    setProjectIdToPeople(null)
  }

  async function handleSubmit() {
    if (project) {
      const projectToSend = {
        members_to_add: pluckId(membersToAdd),
        members_to_remove: pluckId(membersToRemove),
      }

      const response = await updateProjectOnServer({
        companyId: company.id,
        projectId: project.id,
        body: projectToSend,
      })

      const { error, data } = response

      if (!error || error.originalStatus === 200) {
        const { added_members } = data

        const nextProjectMembers = pipe(
          reject(
            pipe(prop('member_id'), includes(__, pluckId(membersToRemove)))
          ),
          concat(added_members)
        )(project.project_members ?? [])

        patchProject({
          company,
          projectId: project.id,
          projectData: {
            project_members: nextProjectMembers,
          },
        })

        setMembersToAdd([])
        setMembersToRemove([])

        toast(
          `Project updated successfully! ${getRandomEmoji(emojiListPositive)}`
        )
      } else {
        toast.error(
          `Could not update project. ${getRandomEmoji(emojiListNegative)}`
        )
      }
    } else {
      const body = {
        default_workflow_id: company.default_workflow_id,
        ...pick(['name'], project),
      }

      const response = await createProjectOnServer({
        companyId: company.id,
        body,
      })

      const { error, data } = response

      if (!error || error.originalStatus === 200) {
        handleCloseModal()

        createProject({ company, project: data })

        if (selectedCompany.id === company.id) {
          setProjectSelected({ project: data, selected: true })
        }

        track('Project created')

        toast(
          `Project created successfully! ${getRandomEmoji(emojiListPositive)}`
        )
      } else {
        toast.error(
          `Could not create project. ${getRandomEmoji(emojiListNegative)}`
        )
      }
    }
  }

  return (
    <EditModal
      title={`PROJECT`}
      $isNew={!hasId(project)}
      onClose={handleCloseModal}
      onCancel={handleCloseModal}
      onSave={handleSubmit}
    >
      <Label>MEMBERS</Label>
      {isEmpty(projectMembers) && (
        <S.MemberMessage>There are no members in this project.</S.MemberMessage>
      )}
      {isNotEmpty(projectMembers) && (
        <>
          <S.MemberMessage>Select members to remove:</S.MemberMessage>
          <EditProjectMemberList
            members={projectMembers}
            isMember={true}
            membersToAdd={[]}
            membersToRemove={membersToRemove}
            onChange={(nextMembersToRemove) => {
              setMembersToRemove(nextMembersToRemove)
            }}
          />
        </>
      )}
      {isNotEmpty(nonProjectMembers) && (
        <>
          <S.MemberMessage>Select members to add:</S.MemberMessage>
          <EditProjectMemberList
            members={nonProjectMembers}
            isMember={false}
            membersToAdd={membersToAdd}
            membersToRemove={[]}
            onChange={(nextMembersToAdd) => {
              setMembersToAdd(nextMembersToAdd)
            }}
          />
        </>
      )}
    </EditModal>
  )
}

EditProjectPeopleModal.propTypes = {
  company: PropTypes.object.isRequired,
  project: PropTypes.object,
}

EditProjectPeopleModal.defaultProps = {
  project: null,
}

export default EditProjectPeopleModal
