import styled from 'styled-components'
import Calendar from '../../assets/components/icon_calendar'

export const ReminderIcon = styled(Calendar)`
  position: absolute;
  width: 21px;
  height: 21px;
  color: var(--red);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${(props) =>
    props.$strokeColor &&
    `
    stroke: ${props.$strokeColor};
  `}
`

export const ReminderCounter = styled.div`
  position: absolute;
  color: var(--red);
  font-family: var(--semibold-font);
  font-size: 8px;
  top: calc(50% + 2px);
  left: calc(50% + 0.5px);
  transform: translate(-50%, -50%);

  ${(props) =>
    props.$strokeColor &&
    `
    color: ${props.$strokeColor};
  `}
`

export const ReminderText = styled.div``

export const ReminderContainer = styled.div`
  position: relative;
  padding-right: 18px;
`
