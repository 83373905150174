import PropTypes from 'prop-types'
import { includesId, rejectId } from '../../utils/ramdaExtensions'
import * as S from './styles'

const AddOrRemoveList = ({
  options,
  isPart,
  toAdd,
  toRemove,
  onChange,
  renderContent,
  ...rest
}) => {
  const renderOption = (option, index) => {
    const isToAdd = includesId(option.id, toAdd)
    const isToRemove = includesId(option.id, toRemove)

    const color =
      (isToAdd ? 'green' : undefined) ??
      (isToRemove ? 'red' : 'var(--white-30)')

    const border =
      isToAdd || isToRemove ? `1px solid ${color}` : `1px solid var(--white-50)`

    const isIconRotated = (isPart && isToRemove) || (!isPart && !isToAdd)

    const iconTransform = isIconRotated ? 'rotate(45deg)' : 'rotate(0deg)'

    return (
      <S.Item
        style={{
          color,
          border,
          ...rest?.style,
        }}
        key={index}
        {...rest}
      >
        {renderContent(option, color)}
        <S.ItemIcon
          style={{
            transform: iconTransform,
            color,
          }}
          onClick={() => {
            if (isPart) {
              if (isToRemove) {
                onChange(rejectId(option.id, toRemove))
              } else {
                onChange([...toRemove, option])
              }
            } else if (isToAdd) {
              onChange(rejectId(option.id, toAdd))
            } else {
              onChange([...toAdd, option])
            }
          }}
        />
      </S.Item>
    )
  }

  return <S.List>{options.map(renderOption)}</S.List>
}

AddOrRemoveList.propTypes = {
  options: PropTypes.any.isRequired,
  isPart: PropTypes.bool.isRequired,
  toAdd: PropTypes.array.isRequired,
  toRemove: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
}

AddOrRemoveList.defaultProps = {}

export default AddOrRemoveList
