import _ from 'lodash'
import { isEmpty } from 'ramda'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { EmptyState, Loading, MiniCardList } from '..'
import {
  setNewTaskInit,
  setPreview,
  setProjectIdToEdit,
  setProjectIdToPeople,
  setProjectSelected,
  setProjectToDelete,
  setSelectedProjectIdList,
  setShowCreateProjectModal,
  setShowUpsertTaskModal,
} from '../../actions'
import { selectApp } from '../../selectors'
import { getFilteredEntities, getRandomEmoji } from '../../utils'
import { emojiListPositive } from '../../utils/getRandomEmoji'
import { pluckId } from '../../utils/ramdaExtensions'

const ProjectList = () => {
  const {
    isLoading,
    isUserAdmin,
    selectedCompanyProjects,
    selectedProjectList,
    filter,
  } = useSelector(selectApp)

  if (isLoading) {
    return <Loading message="Loading projects" />
  }

  if (_.isEmpty(selectedCompanyProjects)) {
    return <EmptyState>No projects found</EmptyState>
  }

  function handleEditProject(project) {
    setProjectIdToEdit(project.id)
    setShowCreateProjectModal(project)
  }

  function handlePeopleProject(project) {
    setProjectIdToPeople(project.id)
  }

  function handleTaskProject(project) {
    setNewTaskInit({
      project_id: project.id,
    })
    setPreview(false)
    setShowUpsertTaskModal(true)
  }

  function handleDeleteProject(project) {
    if (selectedCompanyProjects.length === 1) {
      return toast(
        `You need to have at least 1 project per company. ${getRandomEmoji(
          emojiListPositive
        )}`
      )
    }

    setProjectToDelete(project)
    return null
  }

  function handleCreateProject() {
    setShowCreateProjectModal(true)
  }

  function getTaskCount(project) {
    const filteredTasksByProject = getFilteredEntities({
      entityList: project.tasks,
      filter,
    })
    return _.size(filteredTasksByProject)
  }

  function handleClearAll() {
    if (isEmpty(selectedProjectList ?? [])) {
      setSelectedProjectIdList(pluckId(selectedCompanyProjects))
    } else {
      setSelectedProjectIdList([])
    }
  }

  const listProps = {
    actionName: isUserAdmin ? '+ Add Project' : undefined,
    action: isUserAdmin ? handleCreateProject : undefined,
    list: selectedCompanyProjects,
    isReverse: true,
    menuOptions: [
      ...(isUserAdmin
        ? [
            { icon: 'task', title: 'Task', onClick: handleTaskProject },
            { icon: 'edit', title: 'Edit', onClick: handleEditProject },
            { icon: 'people', title: 'People', onClick: handlePeopleProject },
            {
              icon: 'trash',
              title: 'Delete',
              onClick: handleDeleteProject,
            },
          ]
        : []),
    ],
    selectedList: selectedProjectList,
    setSelected: (project, selected) =>
      setProjectSelected({ project, selected }),
    showClearAll: true,
    onClearAll: handleClearAll,
    getTaskCount,
  }

  return <MiniCardList {...listProps} />
}

export default ProjectList
