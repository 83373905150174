import { append, chain, equals, find, pipe, prop } from 'ramda'
import {
  getCompanyCustomFields,
  getCompanyStates,
  getCompanyWorkflowName,
  getTaskWorkflowTags,
} from '../../getters'
import { reduceTaskTitle } from '../../pages/SharedTask/helpers'
import {
  findId,
  groupByHead,
  mapPatchId,
  rejectId,
} from '../../utils/ramdaExtensions'

export const appGetCompanyById = (app, companyId) =>
  findId(companyId)(app.root?.user?.companies ?? [])

export const appFindInProjects = (app, predicate) =>
  pipe(chain(prop('projects')), predicate)(app.root?.user?.companies ?? [])

export const appGetProjectById = (app, projectId) =>
  appFindInProjects(app, findId(projectId))

export const appGetTaskProjectAndCompany = (app, task) => {
  const project = appGetProjectById(app, task.project_id)
  const company = appGetCompanyById(app, project?.company_id)

  return { project, company }
}

export const appGetTaskById = (app, taskId) =>
  appFindInProjects(app, pipe(chain(prop('tasks')), findId(taskId)))

export const appGetOutsiderGroupById = (app, outsiderGroupId) =>
  pipe(
    chain(prop('outsider_groups')),
    groupByHead(prop('id')),
    prop(outsiderGroupId)
  )(app.root?.user?.companies ?? [])

export const appGetSelectedCompany = (app) =>
  appGetCompanyById(app, app.selectedCompanyId)

export const appPatch = (app, appData) => ({
  ...app,
  ...appData,
})

export const appPatchRoot = (app, rootData) =>
  appPatch(app, {
    root: {
      ...app.root,
      ...rootData,
    },
  })

export const appPatchUser = (app, userData) =>
  appPatchRoot(app, {
    user: {
      ...app.root.user,
      ...userData,
    },
  })

export const appUpdateCompanies = (app, companies) =>
  appPatchUser(app, {
    companies,
  })

export const appPatchCompany = (app, companyId, companyData) =>
  appUpdateCompanies(
    app,
    mapPatchId(companyId, companyData)(app.root.user.companies)
  )

export const appUpdateProjects = (app, company, projects) =>
  appPatchCompany(app, company.id, {
    projects,
  })

export const appPatchProject = (app, company, projectId, projectData) =>
  appUpdateProjects(
    app,
    company,
    mapPatchId(projectId, projectData)(company.projects)
  )

export const appPatchTask = (app, taskId, taskData) => {
  const task = appGetTaskById(app, taskId)

  if (taskData.project_id && taskData.project_id !== task.project_id) {
    const currentProject = appGetProjectById(app, task.project_id)
    const nextProject = appGetProjectById(app, taskData.project_id)
    const company = appGetCompanyById(app, currentProject.company_id)

    return appPatchCompany(app, company.id, {
      projects: pipe(
        mapPatchId(currentProject.id, {
          tasks: rejectId(taskId)(currentProject.tasks),
        }),
        mapPatchId(nextProject.id, {
          tasks: append({ ...task, ...taskData })(nextProject.tasks),
        })
      )(company.projects),
    })
  }

  const project = appGetProjectById(app, task.project_id)
  const company = appGetCompanyById(app, project.company_id)

  return appPatchProject(app, company, project.id, {
    tasks: mapPatchId(taskId, taskData)(project.tasks),
  })
}

export const appCreateTask = (app, company, project, task) =>
  appPatchProject(app, company, project.id, {
    tasks: project.tasks.concat([task]),
  })

export const appGetCompanyMemberById = (app, userId) => {
  const selectedCompany = appGetSelectedCompany(app)

  return findId(userId)(selectedCompany?.members ?? [])
}

export const appGetCompanyGuestById = (app, userId) => {
  const selectedCompany = appGetSelectedCompany(app)

  return findId(userId)(selectedCompany.outsiders ?? [])
}

export const appGetCompanyPersonById = (app, userId) =>
  appGetCompanyGuestById(app, userId) || appGetCompanyMemberById(app, userId)

export const appGetTaskTags = (app, task) => {
  const selectedCompany = appGetSelectedCompany(app)

  const taskWorkflow = findId(task.workflow_id)(
    selectedCompany?.workflows ?? []
  )

  return getTaskWorkflowTags(task, taskWorkflow)
}

export const appTaskByReducedTitle = (app, reducedTaskTitle) => {
  const { root } = app

  const { user } = root ?? {}

  const allProjects = chain(prop('projects'))(user?.companies ?? [])
  const allTasks = chain(prop('tasks'))(allProjects)

  return find(
    pipe(
      (task) => reduceTaskTitle(task.title, task.id),
      equals(reducedTaskTitle)
    )
  )(allTasks)
}

export const appGetSelectedCompanyStates = (app) => {
  const selectedCompany = appGetSelectedCompany(app)

  return getCompanyStates(selectedCompany)
}

export const appGetSelectedCompanyCustomFields = (app) => {
  const selectedCompany = appGetSelectedCompany(app)

  return getCompanyCustomFields(selectedCompany)
}

export const appGetSelectedCompanyWorkflowName = (app, workflowId) => {
  const selectedCompany = appGetSelectedCompany(app)

  return getCompanyWorkflowName(selectedCompany, workflowId)
}
