import { motion } from 'framer-motion'
import { isMobile } from 'react-device-detect'
import styled, { css } from 'styled-components'
import SortAscending from '../../assets/components/icon_sort_ascending'
import SortDescending from '../../assets/components/icon_sort_descending'
import { HEADER_HEIGHT } from '../../constants'

const sharedStyle = css`
  position: relative;
  width: 28px;
  top: 2px;
  stroke: var(--white-50);
`

export const CONTAINER_FRAMER_CONFIG = {
  animate: {
    x: 350,
  },
  transition: {
    type: 'spring',
    duration: 0.6,
    bounce: 0.1,
  },
}

export const General = styled.div`
  position: absolute;
  left: 0;
  transition: 0.2s all ease;
  z-index: 0;
  padding-left: 90px;

  ${(props) =>
    props.$isSideMenuOpen
      ? `
    width: 100%;
    height: 100%;
  `
      : `
    left: 0px;
    width: calc(100%);
  `};

  ${isMobile &&
  `
  padding-left: 0px;
  `}
`

export const Column = styled.div`
  min-width: 360px;
  max-width: 360px;
  scroll-snap-align: start;

  @media screen and (max-width: 490px) {
    min-width: 100vw;
    max-width: 100vw;
    padding: 0 10px;
  }
`

export const Container = styled(motion.div)`
  gap: 20px;
  position: absolute;

  display: flex;
  flex-flow: row;
  overflow-y: visible;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  width: calc(100%);
  padding-right: 100px;
  ${isMobile &&
  `
    top: 25px;
    padding-top: 25px;
  `}

  ${(props) =>
    props.$noFilterSelected &&
    isMobile &&
    `
    top: 25px;
    height: calc(100vh - 140px - 10px);
  `}
`

export const Title = styled.p`
  text-transform: capitalize;
  height: 40px;
  font-family: var(--semibold-font);
  padding-left: 10px;
  cursor: default;

  ${isMobile &&
  `
    display: flex;
    align-items: center;
  `}
`

export const Content = styled.div`
  height: calc(100% - 50px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 5px 10px 100px;

  ::-webkit-scrollbar-thumb {
    border-left: 10px solid var(--global-background);
  }
`

export const ManageContainer = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 81px;
  padding-right: 20px;
  justify-content: end;
`

export const ManageSortBy = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--white-50);
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
  padding: 0px 10px;
  height: 40px;
  transition: 0.2s all ease;
  user-select: none;
  gap: 10px;

  border-radius: 6px;

  :hover {
    color: var(--white-30);
    background: var(--white);
  }

  svg:hover {
    stroke: var(--teal-42) !important;
  }
`

export const ManageDetails = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
  color: var(--white-50);
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
  padding: 0px 15px;
  width: 146px;
  height: 40px;
  transition: 0.2s all ease;
  user-select: none;

  border-radius: 6px;

  :hover {
    color: var(--white-30);
    background: var(--white);
  }
`

export const SortTitle = styled.div`
  :hover {
    color: var(--teal-42);
  }
`

export const CustomSortAscending = styled(SortAscending)`
  ${sharedStyle}
`

export const CustomSortDescending = styled(SortDescending)`
  ${sharedStyle}
`

export const Image = styled.img`
  width: 24px;
`
