import { motion } from 'framer-motion'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { expandDrawer, expandDrawerMobile } from '../../styles/animations'
import WhiteButton from '../WhiteButton'

export const FillColor = 'var(--aqua-59)'

export const DRAWER_FRAMER_CONFIG = {
  initial: { x: -50, opacity: 1 },
  animate: {
    x: 0,
    opacity: 1,
    boxShadow: 'var(--box-shadow);',
    transition: {
      duration: 0.2,
    },
  },
  exit: {
    x: -100,
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
}

export const Background = styled.div`
  position: absolute;
  top: 0;
  width: 75px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 33px;
  z-index: 3;
  background-color: var(--global-background);
  margin: 0;

  ${(props) =>
    props.$isSidebarOpen &&
    !isMobile &&
    `
    width: 430px;
  `}

  ${isMobile &&
  `
    width: 100vw;
    align-items: flex-start;
    top: 0px;
    left: 0;
    position: fixed;
    margin-top: 140px;
    height: calc(100% - 130px);
}
  `}
`

export const Container = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 33px;

  z-index: 3;
  padding: 4.5px 10px 15px 15px;
  margin: 0;
`

export const Item = styled(WhiteButton)`
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: var(--border-radius);
  display: grid;
  gap: 0px;
  place-items: center;
  position: relative;
  border: 1.5px solid transparent;
  transition: 0.2s all ease;
  z-index: 6;

  :hover {
    cursor: pointer;
    border: 1.5px solid var(--white-70);
  }

  ${(props) =>
    props.$active &&
    `
    box-shadow: none;
    border: 1px solid var(--white-70);

    :hover {
      cursor: default;
      border-color: var(--white-70);
    }
  `}

  svg {
    color: #808080;
  }
`

export const Separator = styled.div`
  border-top: 1px solid var(--white-70);
  width: 50px;
`

export const Drawer = styled(motion.div)`
  background: var(--white-90);
  border-radius: var(--border-radius);
  border-top-left-radius: 0;
  max-height: calc(100vh - 230px);
  width: ${isMobile ? 'calc(100vw - 90px)' : '350px'};
  padding: 10px 15px;
  z-index: 3;
  position: absolute;
  left: 80px;
  top: 0.5px;
  animation: ${isMobile ? expandDrawerMobile : expandDrawer} 0.1s ease forwards;

  ${(props) =>
    !props.$isFirstSelected &&
    `
    border-top-left-radius: var(--border-radius);
  `}
`

export const BackButton = styled.div`
  margin-left: auto;
  display: grid;
  place-items: flex-end;
  width: 35px;
  text-align: right;
  padding: 10px 5px;
  border-radius: 6px;
  transition: 0.2s all ease;

  :hover {
    cursor: pointer;
    background: var(--white);
  }
`

export const Label = styled.span`
  font-size: 12px;
  color: var(--white-50);
  text-transform: capitalize;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-family: var(--semibold-font);
  color: var(--white-50);
  text-transform: uppercase;
`

export const Selector = styled.div`
  background: var(--white-90);
  height: 60px;
  width: 71px;
  position: absolute;
  left: -6px;
  top: -4px;
  z-index: 5;
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: var(--box-shadow);
`
