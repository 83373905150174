import * as S from './styles'

const Anchor = ({ ...anchorProps }) => <S.Anchor {...anchorProps} />

Anchor.propTypes = {
  ...S.Anchor.propTypes,
}

Anchor.defaultProps = {}

export default Anchor
