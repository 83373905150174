import _ from 'lodash'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Priority, ProjectName, Tags, TaskCardHeader, TaskOwnerBadge } from '..'
import { setHasTaskToEdit, setSelectedTask } from '../../actions'
import { useGetTaskColor } from '../../hooks'
import { TaskPropType } from '../../props/TaskPropType'
import { appGetProjectById, appGetTaskTags } from '../../reducers/app/utils'
import { selectApp } from '../../selectors'
import { routeCreator } from '../../utils'
import * as S from './styles'

const TaskCard = ({ task, showDetails, index }) => {
  const [{ style }, dragRef] = useDrag(
    {
      type: 'task',
      item: task,
      collect: (monitor) => ({
        style: {
          opacity: monitor.isDragging() ? 0.5 : 1,
          display: monitor.isDragging() ? 'none' : undefined,
        },
      }),
    },
    []
  )

  const app = useSelector(selectApp)

  const navigate = useNavigate()

  const taskColor = useGetTaskColor(task.state_id)

  const tags = appGetTaskTags(app, task)

  const taskProject = appGetProjectById(app, task.project_id)

  const routeToReplace = routeCreator.showTask({
    task,
  })

  function handleClick() {
    setSelectedTask(task)
    setHasTaskToEdit(true)
    navigate(routeToReplace)
  }

  return (
    <S.Task
      key={task.id}
      forwardRef={dragRef}
      onClick={handleClick}
      title={_.size(task.title) > 35 ? task.title : ''}
      color={taskColor}
      index={index}
      style={style}
    >
      <S.Header color={taskColor}>
        <TaskCardHeader task={task} />
      </S.Header>

      <S.Body>
        <S.TitleContainer>
          {showDetails && <ProjectName project={taskProject} />}
          <S.TitleWrapper>
            <S.Title>{task.title}</S.Title>
            <TaskOwnerBadge id={task.owner_id} />
          </S.TitleWrapper>
        </S.TitleContainer>

        <S.Details isVisible={showDetails}>
          <Tags tags={tags} disabled={true} $touchable={true} />
          <Priority priority={task.priority} />
        </S.Details>
      </S.Body>
    </S.Task>
  )
}

TaskCard.propTypes = {
  task: TaskPropType.isRequired,
  showDetails: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
}

export default TaskCard
