import { find, pick, propEq } from 'ramda'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  EditCompanyGuestsModal,
  EditCompanyMembersModal,
  EditCompanyModal,
  EditCompanyUserSettingsModal,
  EditCompanyWorkflowsModal,
  Menu,
  UserInvitesModal,
} from '..'
import {
  patchCompany,
  patchUser,
  setSelectedCompanyId,
  setShowInvites,
} from '../../actions'
import { useLogout } from '../../hooks'
import { selectApp } from '../../selectors'
import { updateUserProfileOnServer } from '../../services/authApi'
import {
  removeCompanyOnServer,
  updateCompanyOnServer,
  uploadCompanyPictureToServer,
} from '../../services/companyApi'
import { updateCompanyUserSettingsOnServer } from '../../services/companyUserSettingsApi'
import { deleteAccountOnServer } from '../../services/userApi'
import { getRandomEmoji } from '../../utils'
import {
  emojiListNegative,
  emojiListPositive,
} from '../../utils/getRandomEmoji'
import {
  mapPatchId,
  omitForCompanyUserSettings,
  propNotEq,
  rejectId,
} from '../../utils/ramdaExtensions'
import EditUserAccountModal from '../EditUserAccountModal'

const UserMenu = () => {
  const { user, selectedCompany, showInvites, companyList } =
    useSelector(selectApp)
  const { handleLogout } = useLogout()
  const navigate = useNavigate()

  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [showWorkflowsModal, setShowWorkflowsModal] = useState(false)
  const [showMembersModal, setShowMembersModal] = useState(false)
  const [showGuestsModal, setShowGuestModal] = useState(false)
  const [showCompanyModal, setShowCompanyModal] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)

  const allCompanyUserSettings = user?.company_user_settings ?? []

  const foundCompanyUserSettings = find(
    propEq('company_id', selectedCompany?.id)
  )(allCompanyUserSettings)

  const companyUserSettings = foundCompanyUserSettings ?? {
    user_id: user?.id,
    company_id: selectedCompany?.id,
    receive_task_update: true,
    receive_task_reminder: true,
  }

  async function handleNoficationsSave(settings) {
    const response = await updateCompanyUserSettingsOnServer(
      selectedCompany.id,
      omitForCompanyUserSettings(settings)
    )

    if (response.status === 200) {
      const updatedCompanyUserSettings = await response.json()

      if (foundCompanyUserSettings) {
        patchUser({
          company_user_settings: mapPatchId(
            updatedCompanyUserSettings.id,
            updatedCompanyUserSettings,
            allCompanyUserSettings
          ),
        })
      } else {
        patchUser({
          company_user_settings: [
            ...user.company_user_settings,
            updatedCompanyUserSettings,
          ],
        })
      }

      toast(
        `Company settings updated successfully! ${getRandomEmoji(
          emojiListPositive
        )}`
      )
    } else {
      toast.error(
        `Company settings could not be updated. ${getRandomEmoji(
          emojiListNegative
        )}`
      )
    }

    setShowSettingsModal(false)
  }

  async function handleDeleteCompany() {
    const response = await removeCompanyOnServer(selectedCompany.id)

    if (response.status === 200) {
      const nextSelectedCompany =
        find(propNotEq('id', selectedCompany.id))(companyList) ?? null

      setShowCompanyModal(false)

      setSelectedCompanyId(nextSelectedCompany.id)

      patchUser({
        companies: rejectId(selectedCompany.id, user?.companies),
      })
    } else {
      toast.error('Error deleting company.')
    }
  }

  function handleInvitesModalCancel() {
    setShowInvites(false)

    navigate(window.location.pathname)
  }

  function handleNoficationsClose() {
    setShowSettingsModal(false)
  }

  function handleGuestsClose() {
    setShowGuestModal(false)
  }

  function handleMembersClose() {
    setShowMembersModal(false)
  }

  function handleWorkflowsClose() {
    setShowWorkflowsModal(false)
  }

  function handleCompanyClose() {
    setShowCompanyModal(false)
  }

  async function handleCompanySave(data, pictureToUpload) {
    const companyToSend = pick(['name'])(data)

    const response = await updateCompanyOnServer(
      selectedCompany.id,
      companyToSend
    )

    if (response.status === 200) {
      if (pictureToUpload) {
        const formData = new FormData()

        formData.append('files', pictureToUpload)

        const uploadResponse = await uploadCompanyPictureToServer(
          selectedCompany.id,
          formData
        )

        if (uploadResponse.status === 200) {
          const { url } = await uploadResponse.json()

          patchCompany({
            companyId: selectedCompany.id,
            companyData: {
              ...companyToSend,
              picture_url: url,
            },
          })
        } else {
          toast.error('Company picture could not be uploaded.')

          patchCompany({ companyId: selectedCompany.id, companyData: data })
        }
      } else {
        patchCompany({ companyId: selectedCompany.id, companyData: data })
      }
    } else {
      toast.error('Company could not be updated.')
    }

    setShowCompanyModal(false)
  }

  function handleProfileClose() {
    setShowAccountModal(false)
  }

  async function handleDeleteUser() {
    const response = await deleteAccountOnServer()

    if (response.status === 200) {
      handleLogout()
    } else {
      toast.error('Error deleting account.')
    }
  }

  async function handleProfileSave(profile) {
    const response = await updateUserProfileOnServer(profile)

    if (response.status === 200) {
      patchUser({
        profile: {
          ...user.profile,
          ...profile,
        },
        companies: mapPatchId(selectedCompany.id, {
          members: mapPatchId(user.id, {
            profile: {
              ...user.profile,
              ...profile,
            },
          })(selectedCompany.members),
        })(user?.companies),
      })

      setShowAccountModal(false)
    } else {
      toast.error('Error while updating profile.')
    }
  }

  return (
    <>
      <Menu
        disabled={!user}
        items={[
          {
            icon: 'none',
            title: 'Account',
            disabled: false,
            onClick: () => setShowAccountModal(true),
          },
          {
            icon: 'none',
            title: 'Company',
            disabled: false,
            onClick: () => setShowCompanyModal(true),
          },
          {
            icon: 'none',
            title: 'Invites',
            onClick: () => setShowInvites(true),
          },
          {
            icon: 'none',
            title: 'Notifications',
            disabled: false,
            onClick: () => {
              setShowSettingsModal(true)
            },
          },
          {
            icon: 'none',
            title: 'Workflows',
            disabled: false,
            onClick: () => {
              setShowWorkflowsModal(true)
            },
          },
          {
            icon: 'none',
            title: 'Members',
            disabled: false,
            onClick: () => {
              setShowMembersModal(true)
            },
          },
          {
            icon: 'none',
            title: 'Guests',
            disabled: false,
            onClick: () => {
              setShowGuestModal(true)
            },
          },
          {
            icon: 'none',
            title: 'Log out',
            onClick: () => {
              handleLogout()
            },
          },
        ]}
      />
      {showInvites && <UserInvitesModal onClose={handleInvitesModalCancel} />}
      {showSettingsModal && (
        <EditCompanyUserSettingsModal
          settings={companyUserSettings}
          onSave={handleNoficationsSave}
          onClose={handleNoficationsClose}
        />
      )}
      {showMembersModal && (
        <EditCompanyMembersModal
          company={selectedCompany}
          onClose={handleMembersClose}
        />
      )}
      {showGuestsModal && (
        <EditCompanyGuestsModal
          company={selectedCompany}
          onClose={handleGuestsClose}
        />
      )}
      {showWorkflowsModal && (
        <EditCompanyWorkflowsModal
          company={selectedCompany}
          onClose={handleWorkflowsClose}
        />
      )}
      {showCompanyModal && (
        <EditCompanyModal
          company={selectedCompany}
          onSave={handleCompanySave}
          onCancel={handleCompanyClose}
          onDelete={handleDeleteCompany}
        />
      )}
      {showAccountModal && (
        <EditUserAccountModal
          email={user?.email}
          profile={user?.profile}
          onSave={handleProfileSave}
          onCancel={handleProfileClose}
          onDelete={handleDeleteUser}
        />
      )}
    </>
  )
}

UserMenu.propTypes = {}

UserMenu.defaultProps = {}

export default UserMenu
