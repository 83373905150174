import * as S from './styles'

const CenteredTitleModal = ({ ...titleModalProps }) => (
  <S.CenteredTitleModal {...titleModalProps} />
)

CenteredTitleModal.propTypes = {
  ...CenteredTitleModal.propTypes,
}

CenteredTitleModal.defaultProps = {}

export default CenteredTitleModal
