import _ from 'lodash'
import PropTypes from 'prop-types'
import { useHotkeys } from 'react-hotkeys-hook'
import * as S from './styles'

const Shortcut = ({ shortcut, action, color, config }) => {
  const shortcutSize = _.size(shortcut)
  const hasShortcut = shortcutSize > 0

  useHotkeys(
    shortcut.join('+'),
    (e) => {
      e.preventDefault()
      return action()
    },
    { ...config }
  )

  if (!hasShortcut) {
    return null
  }

  return (
    <S.ShortcutContainer shortcutSize={shortcutSize}>
      {shortcut.map((key, index) => (
        <S.Shortcut color={color} key={index}>
          {key}
        </S.Shortcut>
      ))}
    </S.ShortcutContainer>
  )
}

Shortcut.propTypes = {
  shortcut: PropTypes.arrayOf(PropTypes.string).isRequired,
  action: PropTypes.func,
  color: PropTypes.string,
  config: PropTypes.object,
}

Shortcut.defaultProps = {
  color: 'default',
  config: {},
  action: () => {},
}

export default Shortcut
