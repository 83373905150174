import { pipe, replace, toLower } from 'ramda'

const simpleIdHash = (id) => {
  let hash = 0

  for (let i = 0; i < id.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = id.charCodeAt(i) + ((hash << 5) - hash)
  }

  return Math.abs(Math.floor(hash / 10 ** 8))
}

export const reduceTaskTitle = (title, id) =>
  `${pipe(
    replace(/[/?#]/g, ''),
    replace(/\s{2,}/g, ' '),
    replace(/\s/g, '-'),
    toLower
  )(title)}-${simpleIdHash(id)}`
