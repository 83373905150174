import styled from 'styled-components'
import X from '../../assets/components/icon_x'
import Button from '../Button'
import Column from '../Column'
import GreenButton from '../GreenButton'
import HoverShadow from '../HoverShadow'
import Row from '../Row'

export const Container = styled(Column)`
  padding: 21px;
  width: min(40vh, 400px);
  gap: 24px;
`

export const AddGuestButton = styled(GreenButton)`
  width: 100px;
  align-self: end;
`

export const Guests = styled(Column)`
  gap: 15px;
`

export const Guest = styled(HoverShadow)`
  width: 100%;
  outline-offset: 3px;
`

export const GuestRow = styled(Row)`
  justify-content: space-between;
`

export const GuestName = styled.div`
  font-family: var(--semibold-font);
`

export const Tab = styled(Button)``

export const ProjectsList = styled(Column)`
  margin-top: 12px;
  margin-bottom: 12px;
  gap: 6px;
`

export const EmptyProjectList = styled.div`
  font-size: 12px;
  margin-top: 9px;
  margin-bottom: 9px;
  color: var(--white-50);
`

export const ProjectItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  align-items: center;
  border-radius: 3px;
  padding 6px;
  transition: all 0.2s ease-in-out;
  user-select: none;
  border: 1px solid currentColor;

  :hover {
    border-color: var(--white-30);
  }
`

export const ProjectItemName = styled.div`
  transition: all 0.1s ease-in-out;
  font-size: 14px;
`

export const ProjectItemIcon = styled(X)`
  justify-self: end;
  color: var(--white-30);
  cursor: pointer;
  transition: all 0.1s ease-in-out;
`
