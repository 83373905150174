import _ from 'lodash'

function applyFilter({ entity, filter }) {
  return (fieldName) => {
    const fieldValues = filter[fieldName]

    return _.isEmpty(fieldValues)
      ? entity
      : fieldValues.includes(entity[fieldName])
  }
}

export default function getFilteredEntities({ entityList, filter }) {
  const filterKeys = _.keys(filter)

  return entityList.filter((entity) =>
    _.every(filterKeys, applyFilter({ entity, filter }))
  )
}
