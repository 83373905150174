import styled from 'styled-components'
import IconX from '../../assets/components/icon_x'

export const Tag = styled.div`
  height: 32px;
  border-radius: 12px;
  background: transparent;
  border: 1px solid var(--white-40);
  color: var(--white-40);
  padding: 5px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  transition: all 0.2s ease;

  :hover {
    box-shadow: 2px 2px;
  }

  ${(props) =>
    props.selected &&
    `
    background: var(--aqua-59);
    color: var(--white);
    border-color: var(--aqua-59);
  `}

  ${(props) =>
    props.$touchable &&
    `
    cursor: pointer;
  `}

  ${(props) =>
    props.disabled &&
    `
    :hover {
      box-shadow: none;
    }
  `}

  ${(props) =>
    props.$isPeopleTag &&
    `
    color: var(--white-40);
    border-color: var(--white-70);
    height: 24px;
    font-size: 11px;
    width: 60px;
    padding: 0;
    line-height: 1.5;
  `}
`

export const TagIconX = styled(IconX)`
  margin-left: 6px;
`
