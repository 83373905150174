import { Button } from '..'
import * as S from './styles'

const WhiteButton = ({ ...buttonProps }) => <S.WhiteButton {...buttonProps} />

WhiteButton.propTypes = {
  ...Button.propTypes,
}

WhiteButton.defaultProps = {}

export default WhiteButton
