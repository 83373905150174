import styled from 'styled-components'
import ChevronUp from '../../assets/components/icon_chevron_up'
import Column from '../Column'
import CompanyPicture from '../CompanyPicture'
import EditModal from '../EditModal'
import Label from '../Label'
import Row from '../Row'

export const Modal = styled(EditModal)`
  width: 300px;
  max-width: 900px;
  top: 60px;
  transform: translateX(-50%);
  left: 50%;

  @media screen and (max-width: 700px) {
    top: 10px;
  }
`

export const Container = styled(Column)`
  overflow: overlay;

  @media screen and (max-width: 700px) {
  }
`

export const AccessNote = styled.p`
  font-size: 12px;
`

export const Picture = styled(CompanyPicture)`
  width: 100px;
  height: 100px;
  border: 1px solid currentColor;
  border-radius: 6px;
  align-self: center;
  margin-bottom: 12px;
  cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
`

export const CustomRow = styled(Row)`
  gap: 20px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`

export const CustomLabel = styled(Label)`
  cursor: pointer;
`

export const CustomChevronUp = styled(ChevronUp)`
  transform: ${(props) =>
    props.$showAdvanced ? 'rotate(180deg)' : 'rotate(0deg)'};
  cursor: pointer;
  user-select: none;
  transition: 0.2s all ease;
  will-change: transform;
`

export const CustomColumn = styled(Column)`
  max-height: ${(props) => (props.$showAdvanced ? '1000px' : 0)};
  overflow: hidden;
  transition: max-height 0.2s linear;
`
