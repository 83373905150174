import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { ICONS } from '../../assets'
import X from '../../assets/components/icon_x'

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-height: 50px;
  gap: 10px;
  display: flex;
  overflow-x: auto;
  overflow-x: overlay;
  scroll-behavior: smooth;
  width: 99vw;
  padding-left: 4px;
  padding-bottom: 4px;

  ${isMobile &&
  `
    width: 100%;
    margin-top: 5px;
    padding-bottom: 10px;
  `}
`

export const Item = styled.div`
  // background: var(--white);
  padding: 5px 10px 5px 10px;
  gap: 3px;
  justify-content: center;
  font-size: 13px;
  cursor: default;
  border-radius: 4px;
  text-transform: uppercase;
  scroll-snap-align: start;
  flex-shrink: 0;
  // box-shadow: var(--box-shadow);
  justify-content: center;
  display: flex;
  align-items: center;
  height: 30px;
`

export const CloseIcon = styled(X)`
  border: none;
  border-radius: 4px;
  margin: 0 0 0 6px;
  padding: 0 0px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  transition: 0.2s all ease;

  :hover {
    color: var(--red);
    cursor: pointer;
  }
`

export const Button = styled.button`
  border-radius: 8px;
  padding: 5px 10px;
  text-transform: uppercase;
  border: 1px solid var(--white-50);
  text-align: left;
  cursor: pointer;
  transition: 0.2 ease all;
  color: var(--black);
  box-shadow: var(--box-shadow);
  transition: 0.1s all ease;

  :hover {
    transform: translate(-2px, 2px);
    box-shadow: none;
    border-color: var(--white-62);
  }

  ${(props) =>
    props.$clear &&
    `
    padding-right: 35px;
    background: url(${ICONS.wind}) no-repeat center right 10px;
  `}

  ${(props) =>
    props.$filter &&
    `
    padding-left: 35px;
    background: url(${ICONS.controller}) no-repeat center left 10px;
    background-size: 15px;
  `}

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
    pointer-events: none;
  `}

  :hover {
    background-color: var(--white);
  }
`

export const ClearContainer = styled.div`
  color: var(--white-62);
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;

  ${isMobile &&
  `
    justify-content: space-between;
    width: 100%;
  `}
`

export const StrongText = styled.strong`
  margin-left: 4px;
`
