import PropTypes from 'prop-types'
import { equals, find, isEmpty, map, pipe, prop } from 'ramda'
import { useState } from 'react'
import { EditFormModal } from '..'
import { isValidEmail } from '../../utils/email'
import { isNotNil } from '../../utils/ramdaExtensions'

const EditCompanyGuestModal = ({ company, guest, $isNew, onClose, onSave }) => {
  const { outsiders } = company

  const [guestToEdit, setGuestToEdit] = useState(guest)

  const outsiderNames = map(pipe(prop('company_outsider'), prop('name')))(
    outsiders
  )
  const outsiderEmails = map(prop('email'))(outsiders)

  return (
    <EditFormModal
      title={'GUEST'}
      $isNew={$isNew}
      fields={[
        {
          title: 'NAME',
          value: guestToEdit?.company_outsider?.name,
          placeholder: 'Type Guest Name Here',
          errors: (() => {
            if (isEmpty(guestToEdit.company_outsider.name)) {
              return ['Cannot have empty guest name.']
            }

            if ($isNew) {
              const sameNameGuest = find(
                equals(guestToEdit?.company_outsider?.name)
              )(outsiderNames)

              const isDuplicatedName = isNotNil(sameNameGuest)

              if (isDuplicatedName) {
                return ['Cannot have duplicated guest name.']
              }
            }

            return []
          })(),
          onChange: (name) => {
            setGuestToEdit({
              ...guestToEdit,
              company_outsider: {
                ...guestToEdit.company_outsider,
                name,
              },
            })
          },
        },
        {
          title: 'EMAIL',
          value: guestToEdit?.email,
          placeholder: 'Type Guest Email Here',
          disabled: !$isNew,
          errors: (() => {
            if (isEmpty(guestToEdit.email)) {
              return ['Cannot have empty guest email.']
            }

            if ($isNew) {
              const sameEmailGuest = find(equals(guestToEdit.email))(
                outsiderEmails
              )

              const isDuplicatedEmail = isNotNil(sameEmailGuest)

              if (isDuplicatedEmail) {
                return ['Cannot have duplicated guest email.']
              }
            }

            if (!isValidEmail(guestToEdit.email)) {
              return ['Invalid email format.']
            }

            return []
          })(),
          onChange: (email) => {
            setGuestToEdit({
              ...guestToEdit,
              email,
            })
          },
        },
      ]}
      onClose={onClose}
      onSave={onSave.bind(null, guestToEdit)}
    />
  )
}

EditCompanyGuestModal.propTypes = {
  company: PropTypes.object.isRequired,
  guest: PropTypes.object.isRequired,
  $isNew: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

EditCompanyGuestModal.defaultProps = {}

export default EditCompanyGuestModal
