import PropTypes from 'prop-types'
import * as S from './styles'

const EmptyState = ({ children, ...rest }) => (
  <S.Container {...rest}>{children}</S.Container>
)

export default EmptyState

EmptyState.propTypes = {
  children: PropTypes.node.isRequired,
}
