import PropTypes from 'prop-types'

export default function Companies({ fill }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8438 8.01619L10.4042 7.3256C10.145 7.20127 9.86118 7.13672 9.57372 7.13672H8.94918C8.45373 7.13672 7.97746 7.32825 7.61994 7.67125L6.93451 8.32887C6.55711 8.69096 6.34375 9.19133 6.34375 9.71434L6.34375 14.0008C6.34375 14.6557 6.67753 15.2654 7.22921 15.6183L10.6848 17.8285C11.3777 18.2717 12.2759 18.2243 12.9183 17.7107L17.3438 14.1725"
        stroke={fill}
        strokeWidth="1.44"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4418 12.5953C13.862 11.799 12.7737 11.5707 11.9227 12.0667L10.4739 12.9113C9.93392 13.2261 9.24894 13.133 8.81259 12.6854V12.6854C8.26295 12.1216 8.30436 11.2106 8.90287 10.6991L12.5318 7.59723C12.8795 7.30002 13.3219 7.13672 13.7793 7.13672H14.3646C14.6694 7.13672 14.9697 7.20924 15.2408 7.34829L17.2999 8.40431C17.9407 8.73296 18.3437 9.39257 18.3437 10.1127L18.3437 12.0554C18.3437 13.9144 15.9658 14.6884 14.8716 13.1856L14.4418 12.5953Z"
        stroke={fill}
        strokeWidth="1.44"
      />
      <rect
        x="1.34375"
        y="7.13672"
        width="3"
        height="9"
        rx="0.5"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="20.3438"
        y="7.13672"
        width="3"
        height="9"
        rx="0.5"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

Companies.propTypes = {
  fill: PropTypes.string,
}

Companies.defaultProps = {
  fill: '#808080',
}
