import cohere from 'cohere-js'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { Auth0ProviderWithRedirectCallback, Toast } from './components'
import { AUTH0_REDIRECT_URI } from './constants'
import Routes from './routes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './store'
import './styles/fonts.css'
import GlobalStyles from './styles/globalStyles'
import './styles/variables.css'

const persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} persistor={persistor}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyles />
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            redirectUri={AUTH0_REDIRECT_URI}
            useRefreshTokens={true}
            cacheLocation={'localstorage'}
          >
            <Routes />
          </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
      </PersistGate>
    </Provider>
    <div id="modal-root"></div>
    <Toast />
  </React.StrictMode>,
  document.getElementById('root')
)

if (process.env.NODE_ENV !== 'development') {
  cohere.init(process.env.REACT_APP_COHERE_TOKEN)
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
