import PropTypes from 'prop-types'
import { isEmpty, pick } from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import { isNotEmpty } from '../../utils/ramdaExtensions'
import * as S from './styles'

const UserProfileForm = ({
  email,
  profile,
  children,
  disabled,
  onSave,
  onChange,
  ...formProps
}) => {
  const defaultProfile = useCallback(
    () =>
      pick(['full_name', 'role', 'industry', 'sector'])(
        profile || {
          full_name: '',
          industry: '',
          sector: 'NONE',
          role: '',
        }
      ),
    [profile]
  )

  const [profileToEdit, setProfileToEdit] = useState(defaultProfile())

  const handleChange = (newProfile) => {
    setProfileToEdit(newProfile)

    if (onChange) {
      onChange(newProfile)
    }
  }

  useEffect(() => {
    setProfileToEdit(defaultProfile())
  }, [profile, setProfileToEdit, defaultProfile])

  const canSave =
    isNotEmpty(profileToEdit.full_name) &&
    isNotEmpty(profileToEdit.sector) &&
    isNotEmpty(profileToEdit.industry) &&
    isNotEmpty(profileToEdit.role)

  return (
    <S.StyledForm
      fields={[
        {
          title: 'EMAIL',
          disabled: true,
          value: email,
        },
        {
          title: 'FULL NAME',
          required: true,
          value: profileToEdit.full_name,
          disabled,
          placeholder: 'Type Your Name Here',
          errors: (() => {
            if (isEmpty(profileToEdit.full_name)) {
              return ['Cannot have empty full name.']
            }

            return []
          })(),
          onChange: (full_name) => {
            handleChange({
              ...profileToEdit,
              full_name,
            })
          },
        },
        {
          title: 'INDUSTRY',
          type: 'select',
          required: true,
          disabled,
          options: [
            { value: 'Energy', label: 'Energy' },
            { value: 'Materials', label: 'Materials' },
            { value: 'Industrials', label: 'Industrials' },
            { value: 'Education', label: 'Education' },
            { value: 'Entertainment', label: 'Entertainment' },
            {
              value: 'Consumer Discretionary',
              label: 'Consumer Discretionary',
            },
            { value: 'Consumer Staples', label: 'Consumer Staples' },
            { value: 'Health Care', label: 'Health Care' },
            { value: 'Financials', label: 'Financials' },
            {
              value: 'Information Technology',
              label: 'Information Technology',
            },
            {
              value: 'Communication Services',
              label: 'Communication Services',
            },
            { value: 'Utilities', label: 'Utilities' },
            { value: 'Real Estate', label: 'Real Estate' },
            { value: 'Fitness', label: 'Fitness' },
            { value: 'Government', label: 'Government' },
            { value: 'Non-Profit', label: 'Non-Profit' },
            { value: 'Other', label: 'Other' },
          ],
          value: profileToEdit.industry,
          errors: (() => {
            if (isEmpty(profileToEdit.industry)) {
              return ['Cannot have empty industry.']
            }

            return []
          })(),
          onChange: (industry) => {
            handleChange({
              ...profileToEdit,
              industry,
            })
          },
        },
        {
          title: 'ROLE',
          type: 'select',
          required: true,
          options: [
            { value: 'Sales Representative', label: 'Sales Representative' },
            { value: 'Manager', label: 'Manager' },
            {
              value: 'Administrative Assistant',
              label: 'Administrative Assistant',
            },
            {
              value: 'Customer Service Representative',
              label: 'Customer Service Representative',
            },
            { value: 'Accountant', label: 'Accountant' },
            { value: 'Software Developer', label: 'Software Developer' },
            { value: 'Marketing Manager', label: 'Marketing Manager' },
            {
              value: 'Human Resources Specialist',
              label: 'Human Resources Specialist',
            },
            { value: 'Project Manager', label: 'Project Manager' },
            { value: 'Financial Analyst', label: 'Financial Analyst' },
            { value: 'Entrepreneur', label: 'Entrepreneur' },
            { value: 'Influencer', label: 'Influencer' },
            { value: 'Other', label: 'Other' },
          ],
          disabled,
          value: profileToEdit.role,
          errors: (() => {
            if (isEmpty(profileToEdit.role)) {
              return ['Cannot have empty role.']
            }

            return []
          })(),
          onChange: (role) => {
            handleChange({
              ...profileToEdit,
              role,
            })
          },
        },
      ]}
      onSave={canSave && (() => onSave(profileToEdit))}
      {...formProps}
    >
      {children}
    </S.StyledForm>
  )
}

UserProfileForm.propTypes = {
  ...S.StyledForm.propTypes,
  email: PropTypes.string.isRequired,
  children: PropTypes.node,
  onSave: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

UserProfileForm.defaultProps = {
  children: null,
}

export default UserProfileForm
