import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string'
import { getToken } from '../getters'

export default function getReducerBaseQuery() {
  return fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_LOCATION}`,
    paramsSerializer: queryString.stringify,
    prepareHeaders: (headers) => {
      const token = getToken()

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  })
}
