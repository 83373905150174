export const stopPropagation = (event) => {
  const { syntheticEvent } = event

  if (syntheticEvent) {
    syntheticEvent.stopPropagation()
  } else {
    event.stopPropagation()
  }
}

export const stopPropagationAnd = (callback) => (event) => {
  stopPropagation(event)

  callback()
}
