import _ from 'lodash'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import Dropdown from '../Dropdown'
import InputContainer from '../InputContainer'

const Select = ({ containerProps, control, onChange, hidden, ...rest }) => {
  const { field } = containerProps

  return (
    <InputContainer {...containerProps} key={field?.key} hidden={hidden}>
      <Controller
        control={control}
        name={field?.name}
        defaultValue={field?.defaultValue?.value}
        rules={{ required: field?.required }}
        render={(controllerFields) => (
          <Dropdown
            {...field}
            borderless
            onChange={(option) => {
              if (!_.isNil(option.color)) {
                onChange(option.color)
              }

              return field?.isMulti
                ? controllerFields.field.onChange(option)
                : controllerFields.field.onChange(option.value)
            }}
            {...rest}
          />
        )}
      />
    </InputContainer>
  )
}

Select.propTypes = {
  ...Dropdown.propTypes,
  containerProps: PropTypes.shape({
    field: PropTypes.object,
    errors: PropTypes.array,
  }).isRequired,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
}

export default Select
