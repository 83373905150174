export default function SortAscending(svgProps) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentcolor"
      {...svgProps}
    >
      <path
        d="M11.0028 15.1677H17.0028"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.0028 19.1677H19.0028"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.0028 11.1677H15.0028"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.0028 7.16772L7.35636 4.52128C7.1611 4.32602 6.84452 4.32602 6.64925 4.52128L4.00281 7.16772"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.00281 19.1677V5.16772"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

SortAscending.propTypes = {}

SortAscending.defaultProps = {}
