import styled from 'styled-components'
import { getCounterColor } from './helpers'

export const Counter = styled.span`
  background: var(--${(props) => getCounterColor(props.amount)});
  font-family: var(--semibold-font);
  color: var(--white);
  padding: 3px 10px;
  border-radius: 6px;
  width: 45px;
  display: grid;
  place-items: center;
`
