import propTypes from 'prop-types'
import * as S from './styles'

const Input = ({
  type,
  value,
  checked,
  onClick,
  onFocus,
  onChange,
  autoSelectOnFocus,
  inputRef,
  ...inputProps
}) => {
  let finalProps = {
    type,
    value,
    checked,
    autoSelectOnFocus,
    ...inputProps,
  }

  const isCheckbox = type === 'checkbox'
  const isColor = type === 'color'
  const isDate = type === 'date'
  const isNumber = type === 'number'

  function handleFocus(event) {
    if (autoSelectOnFocus) {
      event.target.select()
    }

    if (isDate) {
      event.target.showPicker()
    }

    if (onFocus) {
      onFocus(event)
    }
  }

  function handleChange(event) {
    if (onChange) {
      let newValue = event.target.value

      if (isCheckbox) {
        newValue = event.target.checked
      }

      if (isColor) {
        newValue = event.target.value.toUpperCase()
      }

      if (isNumber) {
        newValue = Number.parseInt(event.target.value, 10)
      }

      onChange(newValue)
    }
  }

  function handleClick(event) {
    if (isDate) {
      event.target.showPicker()
    }

    if (onClick) {
      onClick(event)
    }
  }

  if (isCheckbox) {
    finalProps = {
      ...finalProps,
      checked: checked ?? value,
    }
  }

  return (
    <S.Input
      {...finalProps}
      ref={inputRef}
      onFocus={handleFocus}
      onChange={handleChange}
      onClick={handleClick}
    />
  )
}

Input.propTypes = {
  ...S.Input.propTypes,
  autoSelectOnFocus: propTypes.bool,
}

Input.defaultProps = {
  autoSelectOnFocus: false,
}

export default Input
