import PropTypes from 'prop-types'
import { EditFormFooter } from '..'
import * as S from './styles'

const EditModal = ({
  title,
  children,
  cancelLabel,
  submitLabel,
  onSave,
  onCancel,
  saveButtonColor,
  canSave,
}) => (
  <S.StyledModal title={title} onClose={onCancel} controls={[]}>
    <S.Container>{children}</S.Container>
    <EditFormFooter
      onCancel={onCancel}
      onSave={onSave}
      canSave={canSave}
      submitLabel={submitLabel}
      cancelLabel={cancelLabel}
      saveButtonColor={saveButtonColor}
    />
  </S.StyledModal>
)

EditModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saveButtonColor: PropTypes.string,
  canSave: PropTypes.bool,
}

EditModal.defaultProps = {
  cancelLabel: 'CANCEL',
  submitLabel: 'SAVE',
  saveButtonColor: 'primary',
  canSave: true,
}

export default EditModal
