import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setInvalidToken } from '../../actions'
import { HOME_PATH } from '../../constants'
import { useNavigatePreserve } from '../../hooks'
import useAppAuth from '../../hooks/useAppAuth'
import { selectApp } from '../../selectors'
import { track } from '../../utils/mixpanel'
import * as S from './styles'

const LoginCallback = () => {
  const location = useLocation()

  const navigateWithHash = useNavigatePreserve()

  const { isSuccess, error, isLoading } = useSelector(selectApp)

  const errorMessage = error?.data?.message
  const isTokenInvalid = errorMessage?.includes('invalid token')
  const isUserNotFound = errorMessage?.includes('user does not exist')

  useAppAuth()

  useEffect(() => {
    ;(() => {
      if (isTokenInvalid) {
        setInvalidToken(true)
      }

      if (isUserNotFound) {
        return navigateWithHash('/onboarding')
      }

      if (!isSuccess) {
        return null
      }

      track('Login')

      const { pathname } = location

      if (pathname.startsWith('/task/')) {
        return navigateWithHash(pathname)
      }

      return navigateWithHash(HOME_PATH)
    })()
  }, [isUserNotFound, isSuccess, navigateWithHash, isTokenInvalid, location])

  if (isLoading) {
    return <S.Center>Loading application data...</S.Center>
  }

  return <S.Center>Loading access data...</S.Center>
}

export default LoginCallback
