import { isNil, pipe, prop } from 'ramda'
import { findId } from '../../utils/ramdaExtensions'
import useGetParsedMemberList from '../useGetParsedMemberList'
import useGetParsedOutsiderList from '../useGetParsedOutsiderList'

export default function useGetTaskOwnerFullname(id, fallback = null) {
  const memberList = useGetParsedMemberList()
  const outsiderList = useGetParsedOutsiderList()

  if (isNil(id)) {
    return fallback
  }

  return (
    pipe(findId(id), prop('full_name'))([...memberList, ...outsiderList]) ??
    'Not Found'
  )
}
