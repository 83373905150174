import PropTypes from 'prop-types'
import { find, isEmpty, propEq, trim } from 'ramda'
import { useState } from 'react'
import { EditFormModal } from '..'

const MAX_TAG_TITLE_LENGTH = 20

const EditTagModal = ({ tag, tags, $isNew, onClose, onSave }) => {
  const [tagToEdit, setTagIdToEdit] = useState(tag)

  function handleClose() {
    onClose(tagToEdit)
  }

  function handleSave() {
    onSave(tagToEdit)
  }

  return (
    <EditFormModal
      title={$isNew ? `Adding Tag` : `Editing Tag`}
      fields={[
        {
          title: 'NAME',
          value: tagToEdit.title,
          maxLength: MAX_TAG_TITLE_LENGTH,
          placeholder: 'Type Tag Name Here',
          errors: (() => {
            if (isEmpty(trim(tagToEdit.title))) {
              return ['Cannot have empty tag title.']
            }

            if (tagToEdit.title.length > MAX_TAG_TITLE_LENGTH) {
              return [
                `Cannot have tag title longer than ${MAX_TAG_TITLE_LENGTH} characters.`,
              ]
            }

            const sameTitleTag = find(propEq('title', tagToEdit.title))(tags)

            const isDuplicatedTagName =
              sameTitleTag && ($isNew || tag.title !== sameTitleTag.title)

            if (isDuplicatedTagName) {
              return ['Cannot have duplicated tag title.']
            }

            return []
          })(),
          onChange: (title) => {
            setTagIdToEdit({
              ...tagToEdit,
              title,
            })
          },
        },
      ]}
      $isNew={$isNew}
      onClose={handleClose}
      onCancel={handleClose}
      onSave={handleSave}
    />
  )
}

EditTagModal.propTypes = {
  tag: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  $isNew: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

EditTagModal.defaultProps = {}

export default EditTagModal
