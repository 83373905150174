import PropTypes from 'prop-types'
import { useState } from 'react'
import { SaveButton } from '..'
import * as S from './styles'

const EditFormFooter = ({
  cancelLabel,
  submitLabel,
  onSave,
  onCancel,
  canSave,
  saveButtonColor,
}) => {
  const [isSaving, setIsSaving] = useState(false)

  async function handleSave() {
    if (isSaving) {
      return
    }

    setIsSaving(true)

    await onSave()

    setIsSaving(false)
  }

  return (
    <S.Footer>
      <S.CancelButton onClick={onCancel} disabled={isSaving}>
        {cancelLabel}
      </S.CancelButton>
      <SaveButton
        isSaving={isSaving}
        onSave={handleSave}
        canSave={canSave}
        submitLabel={submitLabel}
        color={saveButtonColor}
      />
    </S.Footer>
  )
}

EditFormFooter.propTypes = {
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  canSave: PropTypes.bool,
  saveButtonColor: PropTypes.string,
}

EditFormFooter.defaultProps = {
  cancelLabel: 'CANCEL',
  submitLabel: 'SAVE',
  saveButtonColor: 'primary',
  canSave: true,
}

export default EditFormFooter
