import PropTypes from 'prop-types'
import { TaskPropType } from '../../props'
import TaskForm from '../TaskForm'
import { getTaskFormFields } from '../UpsertTask/helpers'

const GuestTaskForm = ({
  company,
  task,
  workflow,
  project_name,
  requestor_name,
  guest_name,
  formRef,
  onSubmit,
  ...taskFormProps
}) => {
  const requestorOptionList = [
    {
      value: task.requestor_id,
      label: requestor_name,
    },
  ]

  const ownerOptionList = [
    {
      value: task.owner_id,
      label: guest_name,
    },
  ]

  const disabledFields = [
    'title',
    'description',
    'project',
    'requestor',
    'owner',
    'workflow',
    'priority',
    'due_date',
    'attachments_remove',
  ]

  const fields = getTaskFormFields(
    task,
    false,
    true,
    task.owner_id,
    ownerOptionList,
    null,
    null,
    requestorOptionList
  )

  const stateList = workflow.states
  const tagList = workflow.tags
  const customFieldList = workflow?.task_template?.custom_fields ?? []

  const getCompanyPersonById = (userId) => {
    if (userId === task.requestor_id) {
      return {
        profile: {
          full_name: requestor_name,
        },
      }
    }

    if (userId === task.owner_id) {
      return {
        profile: {
          full_name: guest_name,
        },
      }
    }

    return {}
  }

  const projects = [{ id: task.project_id, name: project_name }]

  return (
    <TaskForm
      company={company}
      task={task}
      projects={projects}
      workflow={workflow}
      showErrors={true}
      workflows={[workflow]}
      fields={fields}
      hasTaskToEdit={true}
      isViewMode={false}
      isAdmin={false}
      disabledFields={disabledFields}
      onSubmit={onSubmit}
      childRef={formRef}
      stateList={stateList}
      tagList={tagList}
      customFieldList={customFieldList}
      getCompanyPersonById={getCompanyPersonById}
      {...taskFormProps}
    />
  )
}

GuestTaskForm.propTypes = {
  ...TaskForm.propTypes,
  company: PropTypes.object.isRequired,
  task: TaskPropType.isRequired,
  workflow: PropTypes.any.isRequired,
  project_name: PropTypes.string.isRequired,
  guest_name: PropTypes.string.isRequired,
  requestor_name: PropTypes.string.isRequired,
  formRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default GuestTaskForm
