import styled from 'styled-components'
import CenteredTitleModal from '../CenteredTitleModal'
import Column from '../Column'
import GrayButton from '../GrayButton'
import GreenButton from '../GreenButton'
import HoverShadow from '../HoverShadow'
import Row from '../Row'

export const Modal = styled(CenteredTitleModal)`
  width: calc(100% - 30px);
  max-width: 900px;

  @media screen and (max-width: 700px) {
    top: 10px;
  }
`

export const Container = styled(Column)`
  padding: 24px;
  overflow: overlay;
  max-height: calc(100vh - 300px);

  @media screen and (max-width: 700px) {
    max-height: calc(100vh - 250px);
  }
`

export const AddWorkflowButton = styled(GreenButton)`
  width: 100px;
  align-self: end;
`

export const Workflows = styled(Column)`
  gap: 15px;
`

export const Workflow = styled(HoverShadow)`
  width: 100%;
  outline-offset: 3px;
`

export const WorkflowRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`

export const WorkflowName = styled.div`
  font-family: var(--semibold-font);
`

export const SaveButton = styled(GreenButton)`
  width: 80px;
`

export const CancelButton = styled(GrayButton)`
  width: 80px;
`

export const Actions = styled(Row)`
  justify-content: flex-end;
  gap: 15px;
`
