import _ from 'lodash'
import { keyMap } from './ramdaExtensions'

export default function getDropdownOptions({ list, label }) {
  return _.sortBy(list, 'display_order').map(
    keyMap({
      value: 'id',
      label,
      color: 'default_color',
    })
  )
}
