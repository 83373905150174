import styled from 'styled-components'

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: visible;
  padding-bottom: 6px;
  width: 100%;
`
