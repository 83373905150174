import { Button } from '..'
import * as S from './styles'

const RedButton = ({ ...buttonProps }) => <S.RedButton {...buttonProps} />

RedButton.propTypes = {
  ...Button.propTypes,
}

RedButton.defaultProps = {}

export default RedButton
