export function getColor(color) {
  if (color === 'primary') {
    return `
      background: var(--aqua-79);
      border-color: var(--aqua-79);
      color: var(--aqua-49);
    
      :hover {
        background: var(--aqua-79);
        border-color: var(--aqua-79);
      }
    `
  }

  if (color === 'secondary') {
    return `
      color: var(--white-50);
      background: transparent;

      :hover {
        background: var(--white-95);
      }
    `
  }

  if (color === 'danger') {
    return `
      background: var(--red-78);
      color: var(--red);
    `
  }

  if (color === 'highlight') {
    return `
      background: var(--black);
      color: var(--white);
    `
  }

  return `
    background: var(--white);
    color: var(--black);
  `
}
