import { isNil } from 'lodash'
import { assoc, dissoc, isEmpty } from 'ramda'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { CenteredTitleModal, Spinner } from '..'
import { patchUser, setSelectedCompanyId } from '../../actions'
import { selectApp } from '../../selectors'
import { getCompanyFromServer } from '../../services/companyApi'
import { acceptInviteOnServer } from '../../services/userApi'
import { rejectId } from '../../utils/ramdaExtensions'
import { sleep } from '../../utils/sleep'
import * as S from './styles'

export const acceptCompanyInvite = async (user, invite) => {
  const response = await acceptInviteOnServer(invite.email_invite.id)

  if (response.status === 200) {
    toast(`You have joined ${invite.company?.name}!`)

    const companyResponse = await getCompanyFromServer(invite.company?.id)

    if (companyResponse.status === 200) {
      const company = await companyResponse.json()

      patchUser({
        companies: [...user.companies, company],
        company_invites: rejectId(invite.id)(user.company_invites),
      })

      await sleep(0)

      setSelectedCompanyId(company.id)
    } else {
      patchUser({
        company_invites: rejectId(invite.id)(user.company_invites),
      })

      toast.error('Error while loading new company.')
    }
  } else {
    toast.error(`There was an error accepting the invite.`)
  }
}

const UserInvitesModal = ({ ...modalProps }) => {
  const { user } = useSelector(selectApp)

  const { company_invites } = user

  const valid_company_invites = company_invites.filter(
    (company_invite) => !isNil(company_invite.company)
  )

  const [isAcceptingMap, setIsAcceptingMap] = useState({})

  async function handleAcceptInvite(invite) {
    setIsAcceptingMap(assoc(invite.id, true)(isAcceptingMap))

    await acceptCompanyInvite(user, invite)

    setIsAcceptingMap(dissoc(invite.id)(isAcceptingMap))
  }

  return (
    <CenteredTitleModal title="Company Invites" {...modalProps}>
      <S.Container>
        {valid_company_invites.map((invite) => (
          <S.InviteRow key={invite.id}>
            <S.InviteCompanyName>
              {invite.company?.name ?? '?'}
            </S.InviteCompanyName>
            <S.AcceptInviteButton
              onClick={handleAcceptInvite.bind(null, invite)}
            >
              {isAcceptingMap[invite.id] ? <Spinner /> : 'Accept'}
            </S.AcceptInviteButton>
          </S.InviteRow>
        ))}
        {isEmpty(valid_company_invites) &&
          'There are no active company invites.'}
      </S.Container>
    </CenteredTitleModal>
  )
}

UserInvitesModal.propTypes = {
  ...CenteredTitleModal.propTypes,
}

UserInvitesModal.defaultProps = {}

export default UserInvitesModal
