import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { HEADER_HEIGHT } from '../../constants'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 0 1fr;
  grid-template-rows: 1fr;
  height: calc(90vh - ${HEADER_HEIGHT}px);
  padding-top: ${HEADER_HEIGHT}px;
`

export const SidebarContainer = styled.div`
  position: relative;
  height: calc(90vh - 65px);
  top: 60px;
  ${isMobile && `top: 0px;`}
  width: fit-content;
  padding: 0 0px;
  display: flex;
  align-items: center;
  gap: 20px;
`

export const ContentContainer = styled.div`
  padding: 0 0px;
`

export const Wrapper = styled.div`
  height: 100%;
  place-items: center;
`

export const WrappedContainer = styled.div`
  height: 90%;
  align-self: center;
  display: flex;
  width: fit-content;
  gap: 40px;
  margin: 0 auto 20px;

  ${isMobile &&
  `
    position: fixed;
    width: 100%;
  `}
`

export const Center = styled.div`
  height: 100%;
  white-space: nowrap;
  width: 100vw;
`
