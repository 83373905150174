import _ from 'lodash'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  setHasTaskToEdit,
  setSelectedCompanyId,
  setSelectedTask,
} from '../../actions'
import {
  appGetTaskById,
  appGetTaskProjectAndCompany,
  appTaskByReducedTitle,
} from '../../reducers/app/utils'
import { selectApp } from '../../selectors'

const SharedTask = () => {
  const app = useSelector(selectApp)

  const { task: taskIdOrTitle } = useParams()

  const task =
    appGetTaskById(app, taskIdOrTitle) ??
    appTaskByReducedTitle(app, taskIdOrTitle)

  const { project, company } = (task &&
    appGetTaskProjectAndCompany(app, task)) || { project: null, company: null }

  useEffect(() => {
    if (!_.isNil(task)) {
      if (!project || !company) {
        return
      }

      setSelectedCompanyId(company.id)
      setSelectedTask(task)
      setHasTaskToEdit(true)
    }
  }, [taskIdOrTitle, task, project, company])

  return null
}

export default SharedTask
