import { useSelector } from 'react-redux'
import { selectApp } from '../../selectors'

export default function useGetParsedMemberList() {
  const { members } = useSelector(selectApp)

  return members.map((member) => ({
    ...member.profile,
    id: member.id,
    isMember: true,
  }))
}
