import styled from 'styled-components'
import Column from '../Column'
import GreenButton from '../GreenButton'
import Row from '../Row'

export const Container = styled(Column)`
  width: min(60vh, 400px);
  padding: 24px;
  gap: 24px;

  @media screen and (max-width: 700px) {
    width: 90vw;
  }
`

export const AddGuestButton = styled(GreenButton)`
  width: 100px;
  align-self: end;
`

export const Guests = styled(Column)`
  gap: 15px;
`

export const Guest = styled.div`
  width: 100%;
  cursor: ${(props) => (props.$isUserAdmin ? 'pointer' : 'inherit')};
`

export const GuestRow = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
`

export const GuestDetails = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  ${(props) =>
    props.$name &&
    `
    font-family: var(--semibold-font);
  `}
`

export const NameColumn = styled(Column)`
  width: calc(100% - 45px);
  gap: 0;

  :hover {
    color: var(--teal-42);
  }
`
