import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled(motion.div)`
  margin: 10px auto 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  height: calc(100vh - 250px);
  overflow: auto;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;

  span {
    font-family: var(--semibold-font);
    color: var(--white-50);
  }
`

export const Button = styled.button`
  font-size: 16px;
  font-family: var(--semibold-font);
  color: var(--white-50);
  border: none;
  background: transparent;
  border-radius: 6px;
  padding: 5px 8px;
  transition: 0.2s all ease;
  color: var(--black);

  :hover {
    background: var(--white);
    cursor: pointer;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: calc(100% - 80px);
  padding: 5px 5px 20px;

  ::-webkit-scrollbar-thumb {
    border-left: 10px solid var(--white-90);
  }
`

export const ClearAllButton = styled(Button)`
  width: fit-content;
  align-self: end;
`

export const Section = styled.div`
  color: var(--white-50);
  font-family: var(--semibold-font);
  user-select: none;
`
