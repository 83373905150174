export const emojiListPositive = [
  '🤓',
  '😀',
  '🤩',
  '🥳',
  '👍',
  '👏',
  '🎉',
  '🎊',
]

export const emojiListNegative = ['😢', '😭', '😟', '😕', '🙁', '🤯', '🫠', '🤬']

export default function getRandomEmojiInList(emojiList) {
  return emojiList[Math.floor(Math.random() * emojiList.length)]
}
