export default function X({ ...svgProps }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth={'1.5'}
      {...svgProps}
    >
      <path d="M12.187 12.4851L4.64456 4.9426" strokeLinecap="round" />
      <path d="M12.187 4.9426L4.64451 12.4851" strokeLinecap="round" />
    </svg>
  )
}

X.propTypes = {}

X.defaultProps = {}
