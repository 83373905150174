import styled from 'styled-components'
import Button from '../Button'

export const FloatingButton = styled(Button)`
  font-family: var(--light-font);
  border-radius: var(--border-radius);
  padding: 10px 18px;
  box-shadow: var(--box-shadow);
  font-size: 16px;
  box-sizing: border-box;

  :hover {
    transform: translate(-1px, 1px);
    box-shadow: var(--box-shadow-hover);
  }

  :active {
    transform: translate(-2px, 2px);
    box-shadow: none;
  }

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
  `}

  ${(props) =>
    props.selected &&
    `
    transform: translate(-1px, 1px);
    box-shadow: var(--box-shadow-hover);

    :hover {
      box-shadow: var(--box-shadow-selected-hover);
    }
  `}
`
