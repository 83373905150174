import { createSlice } from '@reduxjs/toolkit'
import {
  assoc,
  assocPath,
  complement,
  dissocPath,
  equals,
  filter as ramdaFilter,
  forEachObjIndexed,
  groupBy,
  isNil,
  prop,
} from 'ramda'
import { isMobile } from 'react-device-detect'
import { mapPatchId, pluckId, rejectId } from '../../utils/ramdaExtensions'
import {
  appCreateTask,
  appGetCompanyById,
  appGetProjectById,
  appGetTaskById,
  appGetTaskProjectAndCompany,
  appPatchCompany,
  appPatchProject,
  appPatchRoot,
  appPatchTask,
  appPatchUser,
} from './utils'

const initialState = {
  token: null,
  data: null,
  skip: true,
  root: null,
  isGuest: false,
  guestToken: null,
  isLoading: true,
  isSuccess: undefined,
  error: undefined,
  companyOpenSidebarMenu: {},
  companyFilters: {},
  companyShowDetails: {},
  companySortBy: {},
  companySelectedProjectIdList: {},
  companySelectedFilteredTags: {},
  companyPictureLoading: {},
  projectIdToEdit: null,
  projectIdToPeople: null,
  projectToDelete: null,
  selectedCompanyId: null,
  preview: true,
  selectedTask: null,
  showInvites: false,
  showUpsertTaskModal: false,
  hasTaskToEdit: false,
  showCreateProjectModal: false,
  lastSeenDate: new Date(null).toISOString(),
  attachmentsToUpload: [],
  showMobileSidebar: !isMobile,
  memberToEdit: null,
  newTaskInit: {},
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsGuest(state, { payload: isGuest }) {
      return assoc('isGuest', isGuest, state)
    },
    setGuestToken(state, { payload: guestToken }) {
      return assoc('guestToken', guestToken, state)
    },
    setToken(state, { payload: token }) {
      return assoc('token', token, state)
    },
    setRoot(state, { payload: root }) {
      return assoc('root', root, state)
    },
    setIsLoading(state, { payload: isLoading }) {
      return assoc('isLoading', isLoading, state)
    },
    setData(state, { payload: data }) {
      return assoc('data', data, state)
    },
    setIsSuccess(state, { payload: isSuccess }) {
      return assoc('isSuccess', isSuccess, state)
    },
    setError(state, { payload: error }) {
      return assoc('error', error, state)
    },
    setSelectedTask(state, { payload: task }) {
      return assoc('selectedTask', task, state)
    },
    setAttachmentsToUpload(state, { payload: dropFiles }) {
      return assoc('attachmentsToUpload', dropFiles, state)
    },
    setSkip(state, { payload: skip }) {
      return assoc('skip', skip, state)
    },
    setWorkflows(state, { payload: workflows }) {
      return assoc('workflows', workflows, state)
    },
    setPreview(state, { payload: preview }) {
      return assoc('preview', preview, state)
    },
    setLastSeenDate(state, { payload: lastSeenDate }) {
      return assoc('lastSeenDate', lastSeenDate, state)
    },
    setShowUpsertTaskModal(state, { payload: showUpsertTaskModal }) {
      return assoc('showUpsertTaskModal', showUpsertTaskModal, state)
    },
    setShowCreateProjectModal(state, { payload: showCreateProjectModal }) {
      return assoc('showCreateProjectModal', showCreateProjectModal, state)
    },
    setHasTaskToEdit(state, { payload: hasTaskToEdit }) {
      return assoc('hasTaskToEdit', hasTaskToEdit, state)
    },
    setShowDetails(state, { payload: showDetails }) {
      const { selectedCompanyId } = state

      return assocPath(
        ['companyShowDetails', selectedCompanyId],
        showDetails,
        state
      )
    },
    setSortBy(state, { payload: sortBy }) {
      const { selectedCompanyId } = state

      return assocPath(['companySortBy', selectedCompanyId], sortBy, state)
    },
    setFilter(state, { payload: filter }) {
      const { selectedCompanyId } = state

      if (!selectedCompanyId) {
        return state
      }

      if (isNil(filter)) {
        return dissocPath(['companyFilters', selectedCompanyId], state)
      }

      return assocPath(['companyFilters', selectedCompanyId], filter, state)
    },
    setNewTaskInit(state, { payload: newTaskInit }) {
      return assoc('newTaskInit', newTaskInit, state)
    },
    setSelectedProjectIdList(state, { payload: selectedProjectIdList }) {
      const { selectedCompanyId } = state

      return assocPath(
        ['companySelectedProjectIdList', selectedCompanyId],
        selectedProjectIdList,
        state
      )
    },
    setSelectedCompanyId(state, { payload: selectedCompanyId }) {
      return assoc('selectedCompanyId', selectedCompanyId, state)
    },
    setShowInvites(state, { payload: showInvites }) {
      return assoc('showInvites', showInvites, state)
    },
    setInvalidToken(state, { payload: invalidToken }) {
      return assoc('invalidToken', invalidToken, state)
    },
    setProjectIdToEdit(state, { payload: projectIdToEdit }) {
      return assoc('projectIdToEdit', projectIdToEdit, state)
    },
    setProjectIdToPeople(state, { payload: projectIdToPeople }) {
      return assoc('projectIdToPeople', projectIdToPeople, state)
    },
    setProjectToDelete(state, { payload: projectToDelete }) {
      return assoc('projectToDelete', projectToDelete, state)
    },
    setProjectSelected(state, { payload: { project, selected } }) {
      const { selectedCompanyId, companySelectedProjectIdList } = state

      const selectedProjectIdList =
        companySelectedProjectIdList[selectedCompanyId] ?? []

      const newSelectedProjectList = selected
        ? [...selectedProjectIdList, project.id]
        : ramdaFilter(complement(equals(project.id)))(selectedProjectIdList)

      return assocPath(
        ['companySelectedProjectIdList', selectedCompanyId],
        newSelectedProjectList,
        state
      )
    },
    setActiveSidebarMenu(state, { payload: activeSidebarMenu }) {
      const { selectedCompanyId } = state

      if (isNil(activeSidebarMenu)) {
        return dissocPath(['companyOpenSidebarMenu', selectedCompanyId], state)
      }

      return assocPath(
        ['companyOpenSidebarMenu', selectedCompanyId],
        activeSidebarMenu,
        state
      )
    },
    createWorkflow(state, { payload: workflow }) {
      return appPatchRoot(state, {
        workflows: [...state.workflows, workflow],
      })
    },
    createCompany(state, { payload: company }) {
      return appPatchUser(state, {
        companies: [...(state.root.user?.companies ?? []), company],
      })
    },
    createProject(state, { payload: { company, project } }) {
      return appPatchCompany(state, company.id, {
        projects: company.projects.concat([project]),
      })
    },
    createTask(state, { payload: { company, project, task } }) {
      return appPatchProject(state, company, project.id, {
        tasks: project.tasks.concat([task]),
      })
    },
    patchRoot(state, { payload: rootData }) {
      return appPatchRoot(state, rootData)
    },
    patchUser(state, { payload: userData }) {
      return appPatchUser(state, userData)
    },
    patchCompany(state, { payload: { companyId, companyData } }) {
      return appPatchCompany(state, companyId, companyData)
    },
    patchProject(state, { payload: { company, projectId, projectData } }) {
      return appPatchProject(state, company, projectId, projectData)
    },
    patchTask(state, { payload: { taskId, taskData } }) {
      return appPatchTask(state, taskId, taskData)
    },
    deleteProject(state, { payload: { company, projectId } }) {
      return appPatchCompany(state, company.id, {
        projects: rejectId(projectId)(company.projects),
      })
    },
    deleteTask(state, { payload: taskToDelete }) {
      const { project, company } = appGetTaskProjectAndCompany(
        state,
        taskToDelete
      )

      if (!project || !company) {
        return state
      }

      return appPatchProject(state, company, project.id, {
        tasks: rejectId(taskToDelete.id)(project.tasks),
      })
    },
    deleteTasks(state, { payload: tasksToDelete }) {
      const tasksByProject = groupBy(prop('project_id'))(tasksToDelete)

      let companies = state.root.user.companies ?? []

      forEachObjIndexed((tasks, projectId) => {
        const project = appGetProjectById(state, projectId)
        const company = appGetCompanyById(state, project.company_id)

        if (!project || !company) {
          return
        }

        const taskIds = pluckId(tasks)

        const taskIdSet = new Set(taskIds)

        companies = mapPatchId(company.id, {
          projects: mapPatchId(project.id, {
            tasks: project.tasks.filter((task) => !taskIdSet.has(task.id)),
          })(company.projects),
        })(companies)
      })(tasksByProject)

      return appPatchUser(state, {
        companies,
      })
    },
    upsertTask(state, { payload: task }) {
      const { project, company } = appGetTaskProjectAndCompany(state, task)

      if (!project || !company) {
        return state
      }

      const currentTask = appGetTaskById(state, task.id)

      if (currentTask) {
        return appPatchTask(state, task.id, task)
      }

      return appCreateTask(state, company, project, task)
    },
    setShowMobileSidebar(state, { payload: show }) {
      return assoc('showMobileSidebar', show, state)
    },
    setSelectedFilterTags(state, { payload: selectedFilterTags }) {
      const { selectedCompanyId } = state

      return assocPath(
        ['companySelectedFilteredTags', selectedCompanyId],
        selectedFilterTags,
        state
      )
    },
    setMemberToEdit(state, { payload: memberToEdit }) {
      return assoc('memberToEdit', memberToEdit, state)
    },
    setCompanyPictureLoading: (
      state,
      { payload: { companyId, isLoading } }
    ) => {
      if (isLoading) {
        return assocPath(['companyPictureLoading', companyId], isLoading, state)
      }

      return dissocPath(['companyPictureLoading', companyId], state)
    },
  },
})

export const {
  setIsGuest,
  setGuestToken,
  setToken,
  setRoot,
  setIsLoading,
  setData,
  setIsSuccess,
  setError,
  setSelectedTask,
  setAttachmentsToUpload,
  setSkip,
  setWorkflows,
  setPreview,
  setLastSeenDate,
  setShowUpsertTaskModal,
  setShowCreateProjectModal,
  setCompanyPictureLoading,
  setHasTaskToEdit,
  setShowDetails,
  setSortBy,
  setFilter,
  setNewTaskInit,
  setSelectedProjectIdList,
  setSelectedCompanyId,
  setShowInvites,
  setInvalidToken,
  setProjectIdToEdit,
  setProjectIdToPeople,
  setProjectToDelete,
  setProjectSelected,
  setActiveSidebarMenu,
  createWorkflow,
  createCompany,
  createProject,
  createTask,
  patchRoot,
  patchUser,
  patchCompany,
  patchProject,
  patchTask,
  deleteProject,
  deleteTask,
  deleteTasks,
  upsertTask,
  setShowMobileSidebar,
  setSelectedFilterTags,
  setMemberToEdit,
} = appSlice.actions

export default appSlice.reducer
