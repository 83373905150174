import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import * as S from './styles'

const HoverShadow = ({ forwardRef, disabled, ...props }) => (
  <S.HoverShadow
    ref={forwardRef}
    whileHover={{ outlineColor: disabled ? '#00000000' : '#AAA' }}
    disabled={disabled}
    {...props}
  />
)

HoverShadow.propTypes = {
  ...motion.div.propTypes,
  forwardRef: PropTypes.object,
  disabled: PropTypes.bool,
}

HoverShadow.defaultProps = {
  forwardRef: null,
  disabled: false,
}

export default HoverShadow
