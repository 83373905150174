import { isMobile } from 'react-device-detect'
import { setPreview, setShowUpsertTaskModal } from '../../actions'
import * as S from './styles'

const newTaskMobileStyle = {
  position: 'absolute',
  top: 'calc(100vh - 135px)',
  right: 20,
}
const desktopStyle = { marginLeft: 'auto' }

const NewTask = () => {
  function handleNewTask() {
    setPreview(false)
    setShowUpsertTaskModal(true)
  }

  const newTaskProps = {
    onClick: handleNewTask,
    shortcut: isMobile ? null : ['shift', 'n'],
    color: 'primary',
    style: isMobile ? newTaskMobileStyle : desktopStyle,
  }

  return <S.NewTaskButton {...newTaskProps}>New Task</S.NewTaskButton>
}

export default NewTask
