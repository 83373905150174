import PropTypes from 'prop-types'
import { toUpper } from 'ramda'
import { Column, LabelInput, Row } from '..'
import { TaskCustomFieldPropType } from '../../props/TaskCustomFieldPropType'
import { stopPropagationAnd } from '../../utils/event/stopPropagationAnd'
import * as S from './styles'

const TaskCustomField = ({
  value,
  field,
  isEditable,
  $isEditMode,
  showRequiredError,
  onChange,
  onClick,
  onRemoveClick,
  ...inputProps
}) => {
  const { name, required } = field

  return (
    <S.Field
      disabled={!$isEditMode}
      $isEditMode={$isEditMode}
      onClick={() => onClick(field)}
    >
      <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
        <Column style={{ width: '100%' }}>
          <LabelInput
            title={name}
            showRequiredError={showRequiredError}
            value={$isEditMode ? toUpper(field.type) : value}
            $isEditMode={$isEditMode}
            field={field}
            required={required}
            disabled={!isEditable}
            onChange={(nextValue) => {
              onChange(field, nextValue)
            }}
            {...inputProps}
          />
        </Column>
        {$isEditMode ? (
          <S.XIcon
            onClick={stopPropagationAnd(() => {
              onRemoveClick(field)
            })}
          />
        ) : null}
      </Row>
    </S.Field>
  )
}

TaskCustomField.propTypes = {
  value: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  $isEditMode: PropTypes.bool.isRequired,
  showRequiredError: PropTypes.bool.isRequired,
  field: TaskCustomFieldPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
}

TaskCustomField.defaultProps = {}

export default TaskCustomField
