import styled from 'styled-components'

export const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 5px;
`

export const LastUpdate = styled.p`
  font-size: 12px;
  color: var(--white-30);
`

export const DueDate = styled.p`
  font-size: 12px;
`
