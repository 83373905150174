export default function Edit(svgProps) {
  return (
    <svg
      width="43"
      height="44"
      viewBox="0 0 43 44"
      fill="none"
      stroke="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3358 12.3556C27.1168 11.5746 28.3832 11.5746 29.1642 12.3556L31.3358 14.5272C32.1168 15.3082 32.1168 16.5746 31.3358 17.3556L18.1782 30.5132C17.899 30.7924 17.5434 30.9827 17.1562 31.0602L11.7942 32.1326C11.6542 32.1606 11.5308 32.0372 11.5588 31.8972L12.6312 26.5352C12.7087 26.148 12.899 25.7924 13.1782 25.5132L26.3358 12.3556Z"
        strokeWidth="2"
      />
      <path d="M24 14.6914L29 19.6914" strokeWidth="2" />
    </svg>
  )
}
