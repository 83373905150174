import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

export const Column = styled.div`
  min-width: 360px;
  max-width: 360px;
  scroll-snap-align: start;

  @media screen and (max-width: 490px) {
    min-width: 100vw;
    max-width: 100vw;
    padding: 0 10px;
  }
`

export const Title = styled.p`
  text-transform: capitalize;
  height: 40px;
  font-family: var(--semibold-font);
  padding-left: 10px;
  cursor: default;

  ${isMobile &&
  `
    display: flex;
    align-items: center;
  `}
`

export const Content = styled.div`
  height: calc(100% - 50px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 5px 10px 100px;

  ::-webkit-scrollbar-thumb {
    border-left: 10px solid var(--global-background);
  }
`
