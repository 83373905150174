import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Collapsable, PromptModal, RedButton, UserProfileForm } from '..'
import { selectApp } from '../../selectors'
import * as S from './styles'

const EditUserAccountModal = ({
  email,
  profile,
  onSave,
  onDelete,
  ...modalProps
}) => {
  const { isGuest } = useSelector(selectApp)

  const [profileToEdit, setProfileToEdit] = useState()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const handleDeleteClick = () => {
    setShowDeleteModal(true)
  }

  const handleDeleteConfirm = () => {
    onDelete()
  }

  return (
    <S.Modal
      title={`Updating Account`}
      controls={[]}
      onSave={() => onSave(profileToEdit)}
      canSave={!isGuest}
      {...modalProps}
    >
      <UserProfileForm
        email={email}
        disabled={isGuest}
        profile={profile}
        onSave={onSave}
        onChange={setProfileToEdit}
      />
      {!isGuest && (
        <Collapsable
          style={{ marginTop: '12px' }}
          title={'ADVANCED'}
          maxHeight={100}
        >
          <RedButton onClick={handleDeleteClick}>DELETE</RedButton>
        </Collapsable>
      )}
      {showDeleteModal && (
        <PromptModal
          title="Delete Account"
          submitLabel="DELETE"
          message={`Are you sure you want delete your account. All your companies, projects, and respective tasks will be deleted. This action is irreversible.`}
          saveButtonColor={'danger'}
          onCancel={() => setShowDeleteModal(false)}
          onSave={handleDeleteConfirm}
        />
      )}
    </S.Modal>
  )
}

EditUserAccountModal.propTypes = {
  email: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

EditUserAccountModal.defaultProps = {}

export default EditUserAccountModal
