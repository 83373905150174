import styled from 'styled-components'
import Button from '../Button'
import Column from '../Column'
import EditModal from '../EditModal'

export const Modal = styled(EditModal)`
  width: 300px;
  max-width: 900px;
  top: 60px;
  transform: translateX(-50%);
  left: 50%;

  @media screen and (max-width: 700px) {
    top: 10px;
  }
`

export const Container = styled(Column)`
  overflow: overlay;

  @media screen and (max-width: 700px) {
  }
`

export const AccessNote = styled.p`
  font-size: 12px;
`

export const RedButton = styled(Button)`
  width: 90px;
  font-size: 14px;
  color: var(--red);
  margin: 4px;
  background-color: var(--red-78);
`
