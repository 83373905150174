import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { patchTask } from '../../actions'
import { TaskPropType } from '../../props'
import { selectApp } from '../../selectors'
import { useUpdateTaskMutation } from '../../services/taskApi'
import { getRandomEmoji } from '../../utils'
import { emojiListPositive } from '../../utils/getRandomEmoji'
import TaskCard from '../TaskCard'
import * as S from './styles'

const TaskColumn = ({ state, tasks, showDetails }) => {
  const { selectedCompany, isGuest } = useSelector(selectApp)

  const [updateTaskOnServer] = useUpdateTaskMutation()

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: 'task',
    async drop(task) {
      if (task.state_id === state.id) {
        return
      }

      const previousStateId = task.state_id

      patchTask({ taskId: task.id, taskData: { state_id: state.id } })

      const response = await updateTaskOnServer({
        body: { state_id: state.id },
        id: task.id,
        companyId: selectedCompany.id,
        projectId: task.project_id,
        isGuest,
      })

      const { error } = response

      if (!error || error.originalStatus === 200) {
        toast(
          `Task status updated successfully! ${getRandomEmoji(
            emojiListPositive
          )}`
        )
      } else {
        toast.error('Error moving task')

        patchTask({ taskId: task.id, taskData: { state_id: previousStateId } })
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  return (
    <S.Column
      key={state.id}
      ref={dropRef}
      style={{
        borderRadius: 6,
        outlineOffset: -2,
        outline: isOver && canDrop ? '2px solid var(--gray)' : 'none',
      }}
    >
      <S.Title>{state.title}</S.Title>
      <S.Content>
        {tasks.map((task, index) => (
          <TaskCard
            key={task.id}
            task={task}
            index={index}
            showDetails={showDetails}
          />
        ))}
      </S.Content>
    </S.Column>
  )
}

TaskColumn.propTypes = {
  state: PropTypes.object.isRequired,
  tasks: PropTypes.arrayOf(TaskPropType).isRequired,
  showDetails: PropTypes.bool.isRequired,
}

export default TaskColumn
