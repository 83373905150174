import styled from 'styled-components'
import Column from '../Column'
import GreenButton from '../GreenButton'
import Row from '../Row'

export const Container = styled(Column)`
  width: min(40vh, 400px);
  padding: 24px;
  gap: 24px;
`

export const CreateWorkflowButton = styled(GreenButton)`
  width: 150px;
  align-self: end;
`

export const Workflows = styled(Column)`
  gap: 15px;
`

export const Workflow = styled.div`
  width: 100%;
  outline-offset: 3px;
  cursor: ${(props) => (props.$isUserAdmin ? 'pointer' : 'inherit')};
`

export const WorkflowRow = styled(Row)`
  align-items: center;
`

export const WorkflowName = styled.div`
  font-family: var(--semibold-font);
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    color: var(--teal-42);
  }
`

export const WorkflowDetails = styled(Column)`
  display: block;
  width: calc(100% - 48px);
`
