import { Row, TaskReminderCounter } from '..'
import { TaskPropType } from '../../props/TaskPropType'
import { getRelativeTime } from '../../utils'
import { formatMMDDYYYY } from '../../utils/date'
import * as S from './styles'

const TaskCardHeader = ({ task }) => (
  <S.FlexContainer>
    <Row style={{ gap: '8px' }}>
      <TaskReminderCounter task={task} />
      <S.DueDate>{task.deadline && formatMMDDYYYY(task.deadline)}</S.DueDate>
    </Row>

    <Row>
      <S.LastUpdate>{getRelativeTime(task.updated_at)}</S.LastUpdate>
    </Row>
  </S.FlexContainer>
)

TaskCardHeader.propTypes = {
  task: TaskPropType.isRequired,
}

export default TaskCardHeader
