import { upperCase } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  CenteredTitleModal,
  DeleteInviteModal,
  DeleteMemberModal,
  Menu,
  Row,
} from '..'
import { patchCompany, setMemberToEdit } from '../../actions'
import { selectApp } from '../../selectors'
import {
  deleteCompanyInviteOnServer,
  deleteCompanyMemberOnServer,
  inviteMember,
} from '../../services/companyMemberApi'
import { getRandomEmoji } from '../../utils'
import {
  emojiListNegative,
  emojiListPositive,
} from '../../utils/getRandomEmoji'
import { track } from '../../utils/mixpanel'
import { isNotEmpty, rejectId } from '../../utils/ramdaExtensions'
import InviteCompanyMemberModal from '../InviteCompanyMemberModal'
import * as S from './styles'

const EditCompanyMembers = ({ company, ...modalProps }) => {
  const { selectedCompany, isUserAdmin } = useSelector(selectApp)

  const [isShowInviteModal, setIsShowInviteModal] = useState(null)
  const [memberToDelete, setMemberToDelete] = useState(null)
  const [inviteToDelete, setInviteToDelete] = useState(null)

  const { members = [], invites = [] } = company

  function handleInviteButtonClick() {
    setIsShowInviteModal(true)
  }

  function handleInviteMemberModalClose() {
    setIsShowInviteModal(false)
  }

  function handleDeleteInviteClick(invite) {
    setInviteToDelete(invite)
  }

  function handleDeleteMemberClose() {
    setMemberToDelete(null)
  }

  async function handleInviteSave(member) {
    const response = await inviteMember(selectedCompany.id, {
      ...member,
    })

    if (response.status === 200) {
      const { invite, project_invites } = await response.json()

      patchCompany({
        companyId: company.id,
        companyData: {
          invites: [...company.invites, invite],
          project_invites: [...company.project_invites, ...project_invites],
        },
      })

      track('Invite created')

      toast(`Member invited successfully! ${getRandomEmoji(emojiListPositive)}`)

      setIsShowInviteModal(false)
    } else if (response.status === 400) {
      const {
        error: { message },
      } = await response.json()

      toast.error(message)
    } else {
      toast.error(
        `Could not remove member. ${getRandomEmoji(emojiListNegative)}`
      )
    }
  }

  function handleEditMember(member) {
    setMemberToEdit(member)
  }

  async function handleDeleteMemberClick(member) {
    setMemberToDelete(member)
  }

  async function handleDeleteMember(member) {
    const response = await deleteCompanyMemberOnServer(
      company.id,
      member.company_member.id
    )

    if (response.status === 200) {
      patchCompany({
        companyId: company.id,
        companyData: {
          members: rejectId(member.id, members),
        },
      })

      toast(`Member removed successfully! ${getRandomEmoji(emojiListPositive)}`)

      setMemberToDelete(null)
    } else {
      toast.error(
        `Could not remove member. ${getRandomEmoji(emojiListNegative)}`
      )
    }
  }

  function handleDeleteInviteClose() {
    setInviteToDelete(null)
  }

  async function handleDeleteInvite(invite) {
    const response = await deleteCompanyInviteOnServer(company.id, invite.id)

    if (response.status === 200) {
      patchCompany({
        companyId: company.id,
        companyData: {
          invites: rejectId(invite.id, invites),
        },
      })

      toast(`Invite deleted successfully! ${getRandomEmoji(emojiListPositive)}`)

      setInviteToDelete(null)
    } else {
      toast.error(
        `Could not delete invite. ${getRandomEmoji(emojiListNegative)}`
      )
    }
  }

  function renderMember(name, picture, email, role, menuItems, isInvited) {
    return (
      <S.Member key={email}>
        <S.MemberPicture name={name} pictureUrl={picture} />
        <S.MemberProfile>
          <Row>
            <S.MemberName>
              {isInvited ? <S.MemberInvited>Invited</S.MemberInvited> : name}
            </S.MemberName>
          </Row>
          <S.MemberEmail>{email}</S.MemberEmail>
        </S.MemberProfile>
        <S.MemberRole>{upperCase(role)}</S.MemberRole>
        <S.MemberMenuContainer>
          <Menu
            items={menuItems}
            small={true}
            disabled={role === 'owner' || !isUserAdmin}
          />
        </S.MemberMenuContainer>
      </S.Member>
    )
  }

  return (
    <>
      <CenteredTitleModal title="Editing Company Members" {...modalProps}>
        <S.Container>
          <S.Members>
            {members.map((member) => {
              const {
                email,
                company_member: { role },
                profile: { full_name, picture },
              } = member

              return renderMember(
                full_name,
                picture,
                email,
                role,
                [
                  ...(role !== 'owner'
                    ? [
                        {
                          icon: 'edit',
                          title: 'Edit',
                          onClick: handleEditMember.bind(null, member),
                        },
                        {
                          icon: 'trash',
                          title: 'Delete',
                          onClick: handleDeleteMemberClick.bind(null, member),
                        },
                      ]
                    : []),
                ],
                false
              )
            })}
          </S.Members>
          {isNotEmpty(invites) && (
            <S.Invites>
              {invites.map((invite) => {
                const {
                  role,
                  email_invite: {
                    email_message: { email },
                  },
                } = invite

                return renderMember(
                  '...',
                  null,
                  email,
                  role,
                  [
                    {
                      icon: 'trash',
                      title: 'Delete',
                      onClick: handleDeleteInviteClick.bind(null, invite),
                    },
                  ],
                  true
                )
              })}
            </S.Invites>
          )}
          {isUserAdmin && (
            <S.InviteMemberButton
              key="0"
              title="+ Invite Member"
              onClick={handleInviteButtonClick}
            />
          )}
        </S.Container>
      </CenteredTitleModal>
      {isShowInviteModal && (
        <InviteCompanyMemberModal
          company={company}
          onSave={handleInviteSave}
          onClose={handleInviteMemberModalClose}
        />
      )}
      {memberToDelete && (
        <DeleteMemberModal
          name={memberToDelete.profile.full_name}
          email={memberToDelete.email}
          onSave={handleDeleteMember.bind(null, memberToDelete)}
          onCancel={handleDeleteMemberClose}
        />
      )}
      {inviteToDelete && (
        <DeleteInviteModal
          email={inviteToDelete.email_invite.email_message.email}
          onSave={handleDeleteInvite.bind(null, inviteToDelete)}
          onCancel={handleDeleteInviteClose}
        />
      )}
    </>
  )
}

EditCompanyMembers.propTypes = {
  company: PropTypes.object.isRequired,
}

EditCompanyMembers.defaultProps = {}

export default EditCompanyMembers
