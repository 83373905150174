export default function ArrowLeft({ ...svgProps }) {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.5"
      {...svgProps}
    >
      <path
        d="M20.3984 7.08301L2.39844 7.08301"
        stroke="#808080"
        strokeLinecap="round"
      />
      <path
        d="M8.39844 1.08301L2.75199 6.72945C2.55673 6.92472 2.55673 7.2413 2.75199 7.43656L8.39844 13.083"
        stroke="#808080"
        strokeLinecap="round"
      />
    </svg>
  )
}

ArrowLeft.propTypes = {}

ArrowLeft.defaultProps = {}
