import { isNil } from 'ramda'
import { useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import Popover from 'react-popover'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { EditCompanyModal } from '..'
import { createCompany, setSelectedCompanyId } from '../../actions'
import { selectApp } from '../../selectors'
import {
  createCompanyOnServer,
  uploadCompanyPictureToServer,
} from '../../services/companyApi'
import { getRandomEmoji } from '../../utils'
import {
  emojiListNegative,
  emojiListPositive,
} from '../../utils/getRandomEmoji'
import { omitForCompany } from '../../utils/ramdaExtensions'
import * as S from './styles'

const CompanyList = () => {
  const { companyList, selectedCompany, isGuest } = useSelector(selectApp)

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const [newCompanyToEdit, setNewCompanyToEdit] = useState(null)

  const buttonRef = useRef(null)

  const handleClick = (company) => {
    setSelectedCompanyId(company.id)
    setIsPopoverOpen(false)
  }

  function handleNewCompanyClick() {
    const workflowName = 'Basic Workflow'

    const defaultTaskFields = {
      workflow_name: workflowName,
    }

    setNewCompanyToEdit({
      name: `New Company`,
      default_workflow_name: workflowName,
      projects: [
        {
          name: 'Sample Project',
          default_workflow_name: workflowName,
          tasks: [
            {
              ...defaultTaskFields,
              title: 'Sample Task',
              state_name: 'Done',
              description: 'Create a new company in slingshot.',
            },
          ],
        },
      ],
      workflows: [
        {
          name: workflowName,
          tags: [{ title: 'Special' }, { title: 'MVP' }],
          states: {
            unstarted: [{ title: 'To Do', default_color: '#D72D36' }],
            started: [{ title: 'In-Progress', default_color: '#F9C270' }],
            done: [{ title: 'Done', default_color: '#63C9B8' }],
          },
          default_state: 'To Do',
          template: {
            fields: [],
            hide_priority: false,
            hide_deadline: true,
          },
        },
      ],
    })
  }

  async function handleCompanySave(company, pictureToUpload) {
    const companyToSend = omitForCompany(company)

    const response = await createCompanyOnServer(companyToSend)

    if (response.status === 200) {
      let newCompany = await response.json()

      if (pictureToUpload) {
        const formData = new FormData()

        formData.append('files', pictureToUpload)

        const uploadResponse = await uploadCompanyPictureToServer(
          newCompany.id,
          formData
        )

        if (uploadResponse.status === 200) {
          const { url } = await uploadResponse.json()

          newCompany = {
            ...newCompany,
            picture_url: url,
          }
        } else {
          toast.error('Could not upload new company picture.')
        }
      }

      createCompany(newCompany)
      setNewCompanyToEdit(null)
      setSelectedCompanyId(newCompany.id)
      setIsPopoverOpen(false)

      toast(
        `Company successfully created! ${getRandomEmoji(emojiListPositive)}`
      )
    } else {
      toast.error(
        `Error creating company. ${getRandomEmoji(emojiListNegative)}`
      )
    }
  }

  function handleCompanyCancel() {
    setNewCompanyToEdit(null)
  }

  return (
    <>
      {newCompanyToEdit && (
        <EditCompanyModal
          company={newCompanyToEdit}
          onSave={handleCompanySave}
          onCancel={handleCompanyCancel}
        />
      )}
      <S.Container>
        <Popover
          isOpen={isPopoverOpen}
          preferPlace={'below'}
          style={{
            zIndex: 3,
          }}
          target={buttonRef}
          appendTarget={document.getElementById('modal-root')}
          tipSize={6}
          enterExitTransitionDurationMs={200}
          onOuterAction={() => {
            if (!newCompanyToEdit) {
              setIsPopoverOpen(false)
            }
          }}
          body={
            <>
              <S.CompanyList>
                {companyList.map((company, index) => {
                  const isSelected = isNil(selectedCompany)
                    ? false
                    : selectedCompany?.id === company.id

                  return (
                    <S.Company
                      key={company.id}
                      $selected={isSelected}
                      onClick={() => handleClick(company)}
                      index={index}
                    >
                      <S.MiniCompanyPicture company={company} />
                      {company.name}
                    </S.Company>
                  )
                })}

                <S.NewCompanyButton
                  disabled={isGuest}
                  index={companyList.length - 1}
                  onClick={handleNewCompanyClick}
                >
                  New Company
                </S.NewCompanyButton>
              </S.CompanyList>
            </>
          }
        >
          <S.SelectedCompany
            selected={isPopoverOpen}
            ref={buttonRef}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <S.MiniCompanyPicture company={selectedCompany} isGuest={isGuest} />
            {selectedCompany && !isMobile && selectedCompany?.name}
          </S.SelectedCompany>
        </Popover>
      </S.Container>
    </>
  )
}

export default CompanyList
