import styled from 'styled-components'
import { fadeIn } from '../../styles/animations'
import { getHslaFromHex } from '../../utils'
import HoverShadow from '../HoverShadow'
import Row from '../Row'
import { getAnimationDelay, getPriorityColor } from './helpers'

export const Title = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85%;
  font-size: 18px;
  font-family: var(--semibold-font);
`

export const TitleContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
`

export const BadgeContainer = styled.div`
  display: flex;
  gap: 10px;
  height: 50px;
  align-items: center;
`

export const Badge = styled.div`
  font-size: 12px;
  background: var(--orange-87);
  color: var(--orange-37);
  padding: 2px 10px;
  border-radius: 20px;
`

export const Details = styled(Row)`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  gap: 6px;
  flex-wrap: nowrap;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const Task = styled(HoverShadow)`
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  border-left: 8px solid
    ${(props) => getHslaFromHex({ hex: props.color, opacity: 0.5 })};
  transition: 0.2s all ease;
  opacity: 0;
  animation: ${fadeIn} 0.3s forwards;
  animation-delay: ${(props) => props.index && getAnimationDelay(props.index)};
`

export const Header = styled.div`
  padding: 5px 10px;
  background: ${(props) => getHslaFromHex({ hex: props.color, opacity: 0.1 })};
  border-top-right-radius: 8px;
`

export const Body = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Priority = styled.div`
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 30px;
  font-family: var(--semibold-font);
  background-color: var(--white-95);

  ::after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    background: var(--${(props) => getPriorityColor(props.priority)});
  }
`

export const ProjectName = styled.p`
  font-size: 13px;
  font-family: var(--semibold-font);
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--white-62);
`
