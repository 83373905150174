export default function SquarePlus({ ...svgProps }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      stroke="currentColor"
      fill="none"
      {...svgProps}
    >
      <path
        d="M14.3086 9.70312H4.30859"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.30859 4.70312V14.7031"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect x="0.808594" y="1.20312" width="17" height="17" rx="2.5" />
    </svg>
  )
}

SquarePlus.propTypes = {}

SquarePlus.defaultProps = {}
