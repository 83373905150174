import * as S from './styles'

export function renderItem({
  collection,
  selectedCollection,
  field,
  fieldToLoad,
  category,
  onClose,
}) {
  if (!selectedCollection.length) {
    return null
  }

  if (selectedCollection.length === collection.length) {
    return (
      <S.Item>
        <strong>{`All ${category}`}</strong>
      </S.Item>
    )
  }
  return selectedCollection.map((item, index) => (
    <S.Item key={index}>
      {item[field]}{' '}
      <S.CloseIcon onClick={() => onClose({ payload: item, fieldToLoad })} />
    </S.Item>
  ))
}

export function getParsedCollection({ collection, selectedCollection }) {
  return collection.filter((item) => selectedCollection?.includes(item.id))
}
