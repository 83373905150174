import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { CenteredTitleModal, EditCompanyGuestModal, Menu, Row } from '..'
import { patchCompany } from '../../actions'
import { selectApp } from '../../selectors'
import {
  createCompanyGuestOnServer,
  deleteCompanyGuestOnServer,
  updateCompanyGuestOnServer,
} from '../../services/companyGuestApi'
import { getRandomEmoji } from '../../utils'
import {
  emojiListNegative,
  emojiListPositive,
} from '../../utils/getRandomEmoji'
import { track } from '../../utils/mixpanel'
import { hasId, mapPatchId, rejectId } from '../../utils/ramdaExtensions'
import * as S from './styles'

const EditCompanyGuestsModal = ({ company, ...modalProps }) => {
  const { isUserAdmin } = useSelector(selectApp)

  const [guestToEdit, setGuestToEdit] = useState(null)

  const { outsiders } = company

  const isNewGuest = !hasId(guestToEdit)

  function handleGuestClick(guest) {
    setGuestToEdit(guest)
  }

  function handleAddGuestClick() {
    setGuestToEdit({
      email: '',
      company_outsider: {
        name: '',
      },
    })
  }

  function handleGuestModalClose() {
    setGuestToEdit(null)
  }

  async function handleGuestSave(guest) {
    if (isNewGuest) {
      const guestToSend = {
        email: guest.email,
        name: guest.company_outsider.name,
      }

      const response = await createCompanyGuestOnServer(company.id, guestToSend)

      if (response.status === 200) {
        const { user, company_outsider } = await response.json()

        patchCompany({
          companyId: company.id,
          companyData: {
            outsiders: [...outsiders, { ...user, company_outsider }],
          },
        })

        setGuestToEdit(null)

        track('Guest created')

        toast(
          `Guest created successfully! ${getRandomEmoji(emojiListPositive)}`
        )
      } else {
        toast.error(
          `Could not create guest. ${getRandomEmoji(emojiListNegative)}`
        )
      }
    } else {
      const guestToSend = {
        name: guest.company_outsider.name,
      }

      const response = await updateCompanyGuestOnServer(
        company.id,
        guest.company_outsider.id,
        guestToSend
      )

      if (response.status === 200) {
        patchCompany({
          companyId: company.id,
          companyData: {
            outsiders: mapPatchId(guest.id, {
              ...guest,
              company_outsider: {
                ...guest.company_outsider,
                ...guestToSend,
              },
            })(outsiders),
          },
        })

        setGuestToEdit(null)

        toast(
          `Guest updated successfully! ${getRandomEmoji(emojiListPositive)}`
        )
      } else {
        toast.error(
          `Could not update guest. ${getRandomEmoji(emojiListNegative)}`
        )
      }
    }
  }

  function handleEditGuest(guest) {
    setGuestToEdit(guest)
  }

  async function handleDeleteGuest(guest) {
    const response = await deleteCompanyGuestOnServer(
      company.id,
      guest.company_outsider.id
    )

    if (response.status === 200) {
      patchCompany({
        companyId: company.id,
        companyData: {
          outsiders: rejectId(guest.id, outsiders),
        },
      })

      toast(`Guest deleted successfully! ${getRandomEmoji(emojiListPositive)}`)
    } else {
      toast.error(
        `Could not delete guest. ${getRandomEmoji(emojiListNegative)}`
      )
    }
  }

  return (
    <CenteredTitleModal title="Editing Guests" {...modalProps}>
      <>
        <S.Container>
          <S.Guests>
            {outsiders.length === 0 && "There aren't any guests yet."}
            {outsiders.map((outsider) => {
              const {
                email,
                company_outsider: { name },
              } = outsider

              return (
                <S.Guest
                  disabled={!isUserAdmin}
                  key={email}
                  onClick={isUserAdmin && handleGuestClick.bind(null, outsider)}
                  $isUserAdmin={isUserAdmin}
                >
                  <S.GuestRow>
                    <S.NameColumn>
                      <Row>
                        <S.GuestDetails $name>{name}</S.GuestDetails>
                      </Row>
                      <Row>
                        <S.GuestDetails>{email}</S.GuestDetails>
                      </Row>
                    </S.NameColumn>
                    {isUserAdmin && (
                      <Menu
                        small
                        items={[
                          {
                            icon: 'edit',
                            title: 'Edit',
                            onClick: handleEditGuest.bind(null, outsider),
                          },
                          {
                            icon: 'trash',
                            title: 'Delete',
                            onClick: handleDeleteGuest.bind(null, outsider),
                          },
                        ]}
                      />
                    )}
                  </S.GuestRow>
                </S.Guest>
              )
            })}
          </S.Guests>
          {isUserAdmin && (
            <S.AddGuestButton
              key="0"
              title="+ Add Guest"
              onClick={handleAddGuestClick}
            />
          )}
        </S.Container>
        {guestToEdit && (
          <EditCompanyGuestModal
            company={company}
            $isNew={isNewGuest}
            guest={guestToEdit}
            onSave={handleGuestSave}
            onClose={handleGuestModalClose}
          />
        )}
      </>
    </CenteredTitleModal>
  )
}

EditCompanyGuestsModal.propTypes = {
  company: PropTypes.object.isRequired,
}

EditCompanyGuestsModal.defaultProps = {}

export default EditCompanyGuestsModal
