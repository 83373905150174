import PropTypes from 'prop-types'
import { any, both, pipe, prop } from 'ramda'
import { LabelInput } from '..'
import { isNotEmpty, isNotNil } from '../../utils/ramdaExtensions'
import * as S from './styles'

const EditForm = ({ fields, onSave, children, autoFocus, ...formProps }) => {
  const canSave = !any(pipe(prop('errors'), both(isNotNil, isNotEmpty)))(fields)

  return (
    <S.Form
      onSubmit={(event) => {
        event.preventDefault()
        event.stopPropagation()

        if (canSave) {
          onSave()
        }
      }}
      {...formProps}
    >
      {fields.map((fieldProps, index) => (
        <LabelInput
          key={index}
          autoSelectOnFocus={index === 0}
          autoFocus={autoFocus ? index === 0 : false}
          {...fieldProps}
        />
      ))}
      {children}
    </S.Form>
  )
}

EditForm.propTypes = {
  ...S.Form.propTypes,
  autoFocus: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  children: PropTypes.node,
  onSave: PropTypes.func.isRequired,
}

EditForm.defaultProps = {
  autoFocus: true,
  children: null,
}

export default EditForm
