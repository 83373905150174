import PropTypes from 'prop-types'
import { groupBy, groupWith, mapObjIndexed, prop } from 'ramda'
import { TaskHistoryEntry } from '..'
import * as S from './styles'

const TaskHistoryList = ({
  task,
  project,
  history,
  stateList,
  tagList,
  customFieldList,
  getCompanyPersonById,
  showTask,
}) => {
  const groupedHistory = groupWith(
    (a, b) => a.group_id !== null && a.group_id === b.group_id
  )(history)

  return (
    <>
      <S.TaskHistoryList>
        {showTask && (
          <div>
            <S.TaskHistoryListProject project={project} />
            <S.TaskHistoryListTask>{task.title}</S.TaskHistoryListTask>
          </div>
        )}
        {groupedHistory.map((group, index) => {
          const userId = group[0].user_id
          const date = group[0].created_at

          const groupByType = groupBy(prop('type'))(group)

          const finalGroupByType = mapObjIndexed((value, key) =>
            key === 'historyCustomField' ? value : value[0]
          )(groupByType)

          return (
            <TaskHistoryEntry
              key={index}
              userId={userId}
              date={date}
              group={finalGroupByType}
              stateList={stateList}
              tagList={tagList}
              customFieldList={customFieldList}
              getCompanyPersonById={getCompanyPersonById}
            />
          )
        })}
      </S.TaskHistoryList>
    </>
  )
}

TaskHistoryList.propTypes = {
  task: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  stateList: PropTypes.array.isRequired,
  tagList: PropTypes.array.isRequired,
  customFieldList: PropTypes.array.isRequired,
  getCompanyPersonById: PropTypes.func.isRequired,
  showTask: PropTypes.bool,
}

TaskHistoryList.defaultProps = {
  showTask: false,
}

export default TaskHistoryList
