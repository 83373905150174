import PropTypes from 'prop-types'
import { TitleContent } from '..'
import Modal from '../Modal'

const TitleModal = ({ title, controls, children, ...rest }) => (
  <Modal {...rest}>
    <TitleContent title={title} controls={controls}>
      {children}
    </TitleContent>
  </Modal>
)

TitleModal.propTypes = {
  ...Modal.propTypes,
  title: PropTypes.string.isRequired,
  controls: PropTypes.node,
  children: PropTypes.node.isRequired,
}

TitleModal.defaultProps = {
  controls: null,
}

export default TitleModal
