import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`
export const fadeInHorizontal = keyframes`
  from {
    opacity: 0;
    transform: translateX(-3px);
    overflow-x: hidden;
  }
  to {
    opacity: 1;
    transform: translateX(0);
    overflow-x: hidden;
  }
`

export const expandDrawer = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 350px;
  }
`
export const expandDrawerMobile = keyframes`
  from {
    width: 0%;
  }
  to {
    width: calc(100vw - 90px);
  }
`
