import { map } from 'ramda'
import useGetParsedPersonList from '../useGetParsedPersonList'

export default function useGetPersonOptionList() {
  const personList = useGetParsedPersonList()

  return map((person) => ({
    value: person.id,
    label: person.full_name,
  }))(personList)
}
