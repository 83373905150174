import PropTypes from 'prop-types'
import PromptModal from '../PromptModal'

const DeleteMemberModal = ({ name, email, ...modalProps }) => (
  <PromptModal
    title="Removing Member"
    submitLabel="REMOVE"
    message={`Are you sure you want to remove member "${name}" (${email}) from company?`}
    {...modalProps}
  />
)

DeleteMemberModal.propTypes = {
  ...PromptModal.propTypes,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
}

DeleteMemberModal.defaultProps = {}

export default DeleteMemberModal
