import parse from 'html-react-parser'
import Linkify from 'linkify-react'
import PropTypes from 'prop-types'
import {
  any,
  append,
  count,
  equals,
  find,
  includes,
  indexBy,
  isEmpty,
  isNil,
  map,
  mergeDeepRight,
  pluck,
  prop,
  propEq,
  reject,
  remove,
} from 'ramda'
import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnNumberedList,
  BtnStrikeThrough,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg'
import { toast } from 'react-toastify'
import {
  Attachments,
  Collapsable,
  Column,
  EditFieldTemplateModal,
  EditStateModal,
  EditTagModal,
  EditWorkflowModal,
  Label,
  LabelInput,
  Row,
  SelectWorkflow,
  States,
  Tags,
  TaskCustomForm,
  TaskHistory,
} from '..'
import { MOTION_SHOWUP_VARIANTS } from '../../constants'
import { getDropdownOptions, getOrdenedStatusList } from '../../utils'
import { track } from '../../utils/mixpanel'
import { openInNewTab } from '../../utils/openInNewTab'
import {
  findId,
  findName,
  hasId,
  keyMap,
  mapPatch,
  mapPatchId,
  mapPatchName,
  mapPatchTitle,
  omitForTaskCustomField,
  rejectName,
  rejectTitle,
} from '../../utils/ramdaExtensions'
import {
  isTaskDeadlineValid,
  isTaskFieldsValid,
  isTaskProjectValid,
  isTaskTitleValid,
} from '../UpsertTask/helpers'
import Select from './Select'
import * as S from './styles'

const MAX_FILE_UPLOAD_COUNT = 12
const MAX_FILE_SIZE_MB = 25
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024
const MAX_FILE_SIZE_LABEL = `${MAX_FILE_SIZE_MB} MB`

export function getDefaultStateId(selectedTask, selectedWorkflow) {
  if (findId(selectedTask?.state_id)(selectedWorkflow?.states ?? [])) {
    return selectedTask?.state_id
  }

  return selectedWorkflow?.default_state_id
}

export function handleAttachmentDrop(event, callback) {
  event.preventDefault()

  const { dataTransfer } = event

  if (dataTransfer) {
    const { items, files } = dataTransfer

    const dropFiles = []

    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
          const file = items[i].getAsFile()

          dropFiles.push(file)
        }
      }
    } else {
      for (let i = 0; i < files.length; i++) {
        const file = items[i].getAsFile()

        dropFiles.push(file)
      }
    }

    callback(dropFiles)
  }
}

const LINKIFY_OPTIONS = {
  target: '_blank',
  format: (value, type) => {
    let currentValue = value
    if (type === 'url' && value.length > 30) {
      currentValue = `${value.slice(0, 30)}…`
    }
    return currentValue
  },
}

const TaskForm = ({
  task,
  fields,
  workflow,
  projects,
  hasTaskToEdit,
  isViewMode,
  workflows,
  isEditMode,
  disabledFields,
  onSubmit,
  childRef,
  animatingEnter,
  initAttachmentsToAdd,
  fallbackProjectId,
  showErrors,
  stateList,
  tagList,
  customFieldList,
  onRemoveAttachmentFile,
  onWorkflowIdChange,
  getCompanyPersonById,
  getAttachmentUrl,
}) => {
  const {
    register,
    control,
    formState: { errors },
    reset,
  } = useForm()

  const formRef = createRef()

  const getTaskWorkflowTagIds = useCallback(
    (nextWorkflow) => {
      if (task?.workflow_id === nextWorkflow?.id) {
        return map(prop('tag_id'))(task?.task_tags ?? [])
      }

      return []
    },
    [task]
  )

  const [tagToEdit, setTagToEdit] = useState(null)
  const [isAddingTag, setIsAddingTag] = useState(false)
  const [stateToEdit, setStateToEdit] = useState(null)
  const [isAddingState, setIsAddingState] = useState(false)
  const [fieldNameToEdit, setFieldNameToEdit] = useState(null)
  const [newFieldToEdit, setNewFieldToEdit] = useState(null)
  const [editableWorkflow, setEditableWorkflow] = useState(workflow)
  const [editableTask, setEditableTask] = useState(task)
  const [editableNote, setEditableNote] = useState('')
  const [showEditWorkflowModal, setShowWorkflowModal] = useState(false)

  const editableCompanyWorkflows = mapPatchId(
    editableWorkflow?.id,
    editableWorkflow,
    workflows
  )

  const editableWorkflowTags = editableWorkflow?.tags ?? []
  const editableWorkflowStates = getOrdenedStatusList(
    editableWorkflow?.states ?? []
  )

  const editableWorkflowFields = editableWorkflow?.task_template?.fields ?? []

  const fieldToEdit =
    findName(fieldNameToEdit)(editableWorkflowFields) ?? newFieldToEdit

  const fallbackStateId = find(propEq('type', 'unstarted'))(
    editableWorkflowStates
  )?.id
  const defaultTagIdList = getTaskWorkflowTagIds(editableWorkflow)
  const defaultStatedId = getDefaultStateId(task, workflow) ?? fallbackStateId

  const [tagIdList, setTagIdList] = useState(defaultTagIdList)
  const [selectedStateId, setSelectedStateId] = useState(defaultStatedId)
  const [newAttachmentFiles, setNewAttachmentFiles] =
    useState(initAttachmentsToAdd)

  const customFieldsRef = useRef({})

  const attachmentsToAdd = map(
    keyMap({
      filename: 'name',
      size: 'size',
      mimetype: 'type',
    })
  )(newAttachmentFiles)

  const selectedWorkflowState = findId(selectedStateId)(
    editableWorkflow?.states ?? []
  )

  const selectedStateColor = selectedWorkflowState?.default_color

  const disabledFieldSet = new Set(disabledFields)

  const customFields = editableWorkflow?.task_template?.fields ?? []

  const shownProjectId = editableTask?.project_id ?? fallbackProjectId ?? ''

  const focusedInputRef = useRef(null)

  const projectOptions = useMemo(
    () =>
      getDropdownOptions({
        list: projects,
        label: 'name',
      }),
    [projects]
  )

  useEffect(() => {
    setEditableWorkflow(workflow)
  }, [workflow, setEditableWorkflow])

  useEffect(() => {
    if (!isEditMode) {
      setEditableWorkflow(workflow)
    }
  }, [workflow, isEditMode, setEditableWorkflow])

  useEffect(() => {
    setNewAttachmentFiles(initAttachmentsToAdd)
  }, [initAttachmentsToAdd, setNewAttachmentFiles])

  useEffect(() => {
    if (isViewMode) {
      const nextTagIdList = getTaskWorkflowTagIds(editableWorkflow)

      setTagIdList(nextTagIdList)
    }
  }, [isViewMode, editableWorkflow, getTaskWorkflowTagIds, setTagIdList])

  useEffect(() => {
    if (isViewMode) {
      const nextDefaultStateId =
        getDefaultStateId(task, workflow) ?? fallbackStateId

      setSelectedStateId(nextDefaultStateId)
    }
  }, [isViewMode, task, workflow, fallbackStateId, setSelectedStateId])

  useEffect(() => {
    const nextSelectedStateId = getDefaultStateId(task, editableWorkflow)

    setSelectedStateId(nextSelectedStateId)
  }, [task, editableWorkflow, setSelectedStateId])

  useEffect(() => {
    setEditableNote('')
  }, [task, setEditableNote])

  useEffect(() => {
    if (!isViewMode && hasTaskToEdit) {
      reset()
    }
  }, [hasTaskToEdit, isViewMode, reset])

  useEffect(() => {
    if (isViewMode) {
      setNewAttachmentFiles([])
      setEditableNote('')
    }
  }, [
    task,
    isViewMode,
    setNewAttachmentFiles,
    setEditableTask,
    setEditableNote,
  ])

  useEffect(() => {
    setEditableTask(task)
  }, [task, setEditableTask])

  useEffect(() => {
    if (isViewMode) {
      setTagIdList(pluck('tag_id', task?.task_tags ?? []))
    }
  }, [task, isViewMode, setTagIdList])

  useEffect(() => {
    if (isEditMode && !hasId(workflow)) {
      setShowWorkflowModal(true)
    }
  }, [isEditMode, workflow, setShowWorkflowModal])

  const titleInputRef = useRef(null)
  const projectInputRef = useRef(null)
  const deadlineInputRef = useRef(null)

  const focusOnTitle = useCallback(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus()
    }
  }, [titleInputRef])

  const focusOnProject = useCallback(() => {
    if (projectInputRef.current) {
      projectInputRef.current.focus()
    }
  }, [projectInputRef])

  const focusOnDeadline = useCallback(() => {
    if (deadlineInputRef.current) {
      deadlineInputRef.current.focus()
    }
  }, [deadlineInputRef])

  const focusOnCustomField = useCallback(
    (fieldId) => {
      const customFieldInput = customFieldsRef.current[fieldId]

      if (customFieldInput) {
        customFieldInput.focus()
      }
    },
    [customFieldsRef]
  )

  const getTaskData = useCallback(() => {
    const formData = new FormData(formRef.current)

    const parsedFormData = {}

    formData.forEach((value, key) => {
      parsedFormData[key] = value
    })

    const data = {
      ...parsedFormData,
      workflow_id: workflow.id,
      tags: tagIdList,
      state_id: selectedStateId,
      custom_fields: map(
        omitForTaskCustomField,
        editableTask?.custom_fields ?? []
      ),
      deadline: editableTask?.deadline,
      project_id: editableTask?.project_id ?? shownProjectId,
      title: editableTask?.title,
      description: editableTask?.description,
      note: editableNote,
    }

    return { data, fileHandlers: newAttachmentFiles }
  }, [
    editableTask,
    shownProjectId,
    formRef,
    editableNote,
    tagIdList,
    workflow,
    newAttachmentFiles,
    selectedStateId,
  ])

  const getWorkflowData = () => editableWorkflow

  useEffect(() => {
    if (showErrors && !focusedInputRef.current) {
      const { data: visibleTask } = getTaskData()

      if (!isTaskProjectValid(visibleTask)) {
        focusOnProject()
      } else if (!isTaskTitleValid(visibleTask)) {
        focusOnTitle()
      } else if (!isTaskDeadlineValid(visibleTask)) {
        focusOnDeadline()
      } else if (!isTaskFieldsValid(visibleTask, workflow)) {
        const customFieldMap = indexBy(prop('field_id'))(
          visibleTask?.custom_fields ?? []
        )

        const firstInvalidField = find((field) => {
          if (field.required) {
            const taskField = customFieldMap[field.id]

            if (!taskField || isEmpty(taskField.value)) {
              return true
            }
          }

          return false
        })(workflow?.task_template?.fields ?? [])

        if (firstInvalidField) {
          focusOnCustomField(firstInvalidField.id)
        }
      }
    }
  }, [
    showErrors,
    workflow,
    focusedInputRef,
    getTaskData,
    focusOnTitle,
    focusOnProject,
    focusOnDeadline,
    focusOnCustomField,
  ])

  function getProps(fieldName) {
    const field = fields[fieldName]

    const disabled = isViewMode || disabledFieldSet.has(fieldName)
    const required = field.required && !disabled

    return {
      control,
      register,
      containerProps: {
        field: { ...field, disabled, required },
        errors,
      },
    }
  }

  const handleProjectChange = useCallback(
    (project_id) => {
      setEditableTask({
        ...editableTask,
        project_id,
      })
    },
    [editableTask, setEditableTask]
  )

  const handleTaskTitleChange = (title) => {
    setEditableTask({
      ...editableTask,
      title,
    })
  }

  const handleStateChange = (nextStateId) => {
    setSelectedStateId(nextStateId)
  }

  const handleEditStateClick = (stateId) => {
    const state = findId(stateId)(editableWorkflowStates)

    setIsAddingState(false)
    setStateToEdit(state)
  }

  const handleAddStateClick = () => {
    setIsAddingState(true)
    setStateToEdit({
      type: 'unstarted',
      title: 'New State',
      default_color: '#000000',
      display_order: 0,
    })
  }

  const handleEditStateModalClose = () => {
    setIsAddingState(false)
    setStateToEdit(null)
  }

  const handleEditStateSave = (state, isDefault) => {
    if (isAddingState) {
      setEditableWorkflow({
        ...editableWorkflow,
        ...(isDefault
          ? {
              default_state: state.title,
            }
          : {}),
        states: append(state)(editableWorkflow.states),
      })
    } else {
      if (
        stateToEdit.type !== state.type &&
        count(propEq('type', stateToEdit.type))(editableWorkflowStates) === 1
      ) {
        toast.warn('Cannot have less than one state of a type.')

        return
      }

      setEditableWorkflow({
        ...editableWorkflow,
        ...(isDefault
          ? {
              default_state: state.title,
            }
          : {}),
        states: mapPatchTitle(
          stateToEdit.title,
          state
        )(
          map((other_state) => {
            if (
              state.display_order !== stateToEdit.display_order &&
              other_state.type === state.type &&
              other_state.title !== state.title
            ) {
              if (
                other_state.display_order >= stateToEdit.display_order &&
                other_state.display_order <= state.display_order
              ) {
                return {
                  ...other_state,
                  display_order: other_state.display_order - 1,
                }
              }

              if (
                other_state.display_order >= state.display_order &&
                other_state.display_order <= stateToEdit.display_order
              ) {
                return {
                  ...other_state,
                  display_order: other_state.display_order + 1,
                }
              }

              return other_state
            }

            return other_state
          })(editableWorkflow.states)
        ),
      })
    }

    setStateToEdit(null)
  }

  const handleEditStateXClick = (stateId) => {
    const state = findId(stateId)(editableWorkflowStates)

    if (count(propEq('type', state.type))(editableWorkflowStates) === 1) {
      toast.warn('Cannot have less than one state of a type.')

      return
    }

    setEditableWorkflow({
      ...editableWorkflow,
      states: rejectTitle(state.title)(editableWorkflowStates),
    })
  }

  const handleFieldInputRef = useCallback(
    (fieldId, input) => {
      customFieldsRef.current[fieldId] = input
    },
    [customFieldsRef]
  )

  const handleCustomFieldChange = (field, value) => {
    if (
      includes(field.id)(pluck('field_id')(editableTask?.custom_fields ?? []))
    ) {
      setEditableTask({
        ...editableTask,
        custom_fields: mapPatch(
          'field_id',
          field.id,
          { value },
          editableTask?.custom_fields ?? []
        ),
      })
    } else {
      setEditableTask({
        ...editableTask,
        custom_fields: append(
          { field_id: field.id, value },
          editableTask?.custom_fields ?? []
        ),
      })
    }
  }

  const handleWorkflowModalClose = () => {
    setShowWorkflowModal(false)
  }

  const handleWorkflowModalSave = (nextWorkflow) => {
    setEditableWorkflow(mergeDeepRight(editableWorkflow, nextWorkflow))
    setShowWorkflowModal(false)
  }

  const handleCustomFieldClick = (field) => {
    if (isEditMode) {
      setFieldNameToEdit(field.name)
    }
  }

  const handleAddCustomFieldClick = () => {
    setNewFieldToEdit({
      name: '',
      type: 'text',
      required: false,
      is_multiple_value: false,
      default_value: '',
    })
  }

  const handleRemoveCustomFieldClick = (field) => {
    setEditableWorkflow({
      ...editableWorkflow,
      task_template: {
        ...(editableWorkflow.task_template ?? {}),
        fields: rejectName(field.name)(editableWorkflow.task_template?.fields),
      },
    })
  }

  const handleEditWorkflowClick = () => {
    setShowWorkflowModal(true)
  }

  const handleTemplateChange = (template) => {
    setEditableWorkflow({ ...editableWorkflow, task_template: template })
  }

  const handleTagClick = (tag) => {
    if (isEditMode) {
      setIsAddingTag(false)
      setTagToEdit(tag)
    } else {
      if (includes(tag.id)(tagIdList)) {
        setTagIdList(reject(equals(tag.id))(tagIdList))

        return
      }

      setTagIdList(append(tag.id)(tagIdList))
    }
  }

  const handleTagXClick = (tag) => {
    setEditableWorkflow({
      ...editableWorkflow,
      tags: rejectTitle(tag.title)(editableWorkflow.tags),
    })
  }

  const handleAddTagClick = () => {
    setIsAddingTag(true)
    setTagToEdit({ title: 'New Tag' })
  }

  const handleTagTemplateModalClose = () => {
    setTagToEdit(null)
    setIsAddingTag(false)
  }

  const handleFieldTemplateModalClose = () => {
    setFieldNameToEdit(null)
    setNewFieldToEdit(null)
  }

  const handleTagTemplateSave = (tag) => {
    if (isAddingTag) {
      setEditableWorkflow({
        ...editableWorkflow,
        tags: append(tag)(editableWorkflow.tags),
      })

      track('Tag created')
    } else {
      setEditableWorkflow({
        ...editableWorkflow,
        tags: mapPatchTitle(tagToEdit.title, tag)(editableWorkflow.tags),
      })
    }

    setTagToEdit(null)
  }

  const handleCustomFieldSave = (field) => {
    if (newFieldToEdit) {
      setEditableWorkflow({
        ...editableWorkflow,
        task_template: {
          ...(editableWorkflow.task_template ?? {}),
          fields: append(field)(editableWorkflow.task_template?.fields ?? []),
        },
      })

      track('Custom field created')

      setNewFieldToEdit(null)
    } else {
      setEditableWorkflow({
        ...editableWorkflow,
        task_template: {
          ...(editableWorkflow.task_template ?? {}),
          fields: mapPatchName(
            fieldToEdit.name,
            field
          )(editableWorkflow.task_template?.fields ?? []),
        },
      })

      setFieldNameToEdit(null)
    }
  }

  const handleAttachmentFileClick = async (attachment, file) => {
    const response = await getAttachmentUrl(attachment.id, file.id)

    if (response.status === 200) {
      const url = await response.text()

      await openInNewTab(url)
    } else {
      toast.error('Could not get attachment url.')
    }
  }

  const handleAttachmentFileXClick = async (attachment, file) => {
    onRemoveAttachmentFile(attachment, file)
  }

  function handleAttachmentFilesAdded(files) {
    const isFileTooLarge = any(({ size }) => size > MAX_FILE_SIZE)(files)

    if (isFileTooLarge) {
      toast.warn(`File cannot be larger than ${MAX_FILE_SIZE_LABEL}.`)

      return
    }

    if (newAttachmentFiles.length + files.length > MAX_FILE_UPLOAD_COUNT) {
      return
    }

    if (newAttachmentFiles.length + files.length > MAX_FILE_UPLOAD_COUNT) {
      toast.warn(
        `You can only upload ${MAX_FILE_UPLOAD_COUNT} files at a time.`
      )

      return
    }

    setNewAttachmentFiles([...newAttachmentFiles, ...files])
  }

  function handleAttachmentFileRemoved(file, index) {
    setNewAttachmentFiles(remove(index, 1, newAttachmentFiles))
  }

  const extraFieldsDisabled = (isViewMode && hasTaskToEdit) || isEditMode

  // eslint-disable-next-line
  childRef.current = {
    getTaskData,
    getWorkflowData,
  }

  function handleSubmit() {
    onSubmit()
  }

  function handleDescriptionChange(event) {
    const description = event?.target?.value
    setEditableTask({ ...editableTask, description })
  }

  function handleNoteChange(event) {
    const note = event?.target?.value
    setEditableNote(note)
  }

  const handleTitleFocus = useCallback(() => {
    focusedInputRef.current = 'title'
  }, [focusedInputRef])

  const handleTitleBlur = useCallback(() => {
    focusedInputRef.current = null
  }, [focusedInputRef])

  const handleProjectFocus = useCallback(() => {
    focusedInputRef.current = 'project'
  }, [focusedInputRef])

  const handleProjectBlur = useCallback(() => {
    focusedInputRef.current = null
  }, [focusedInputRef])

  const handleDeadlineFocus = useCallback(() => {
    focusedInputRef.current = 'deadline'
  }, [focusedInputRef])

  const handleDeadlineBlur = useCallback(() => {
    focusedInputRef.current = null
  }, [focusedInputRef])

  const isUpdateDisabled = isViewMode || isEditMode

  const isDescriptionDisabled =
    isUpdateDisabled || disabledFieldSet.has('description')

  const shouldAutoFocusProject =
    !animatingEnter &&
    !isUpdateDisabled &&
    isNil(editableTask?.project_id) &&
    !fallbackProjectId

  const shouldAutoFocusTitle =
    !animatingEnter && !isUpdateDisabled && !shouldAutoFocusProject

  useEffect(() => {
    if (shouldAutoFocusProject) {
      focusOnProject()
    }

    if (shouldAutoFocusTitle) {
      focusOnTitle()
    }
  }, [
    shouldAutoFocusProject,
    shouldAutoFocusTitle,
    focusOnProject,
    focusOnTitle,
  ])

  const filteredTags =
    isViewMode && !isEditMode
      ? editableWorkflowTags.filter((tag) => includes(tag.id, tagIdList))
      : editableWorkflowTags

  const descriptionEditorProps = {
    disabled: isDescriptionDisabled,
    value: editableTask?.description ?? '',
    onChange: handleDescriptionChange,
  }

  const notesEditorProps = {
    value: editableNote ?? '',
    onChange: handleNoteChange,
    disabled: isUpdateDisabled,
  }

  return (
    <>
      <S.Form
        ref={formRef}
        onSubmit={handleSubmit}
        onDragOver={(event) => {
          event.preventDefault()
        }}
        onDrop={(event) => {
          handleAttachmentDrop(event, handleAttachmentFilesAdded)
        }}
      >
        <Column style={{ gap: '24px' }}>
          <Row
            style={{
              gap: '24px',
              width: '100%',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <S.FirstColumn>
              <Row
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <LabelInput
                  key={animatingEnter ? '0' : '1'}
                  title={'PROJECT'}
                  type={'select'}
                  required={true}
                  placeholder={'Select Project'}
                  options={projectOptions}
                  value={shownProjectId}
                  disabled={isUpdateDisabled || disabledFieldSet.has('project')}
                  showRequiredError={showErrors}
                  inputRef={projectInputRef}
                  onChange={handleProjectChange}
                  onFocus={handleProjectFocus}
                  onBlur={handleProjectBlur}
                />
                <SelectWorkflow
                  task={editableTask}
                  required={!isViewMode && !isEditMode}
                  workflow={editableWorkflow}
                  workflows={editableCompanyWorkflows}
                  $isEditMode={isEditMode}
                  disabled={isViewMode || disabledFieldSet.has('workflow')}
                  onWorkflowChange={onWorkflowIdChange}
                  onEditWorkflowClick={handleEditWorkflowClick}
                />
              </Row>
              <LabelInput
                key={animatingEnter ? '0' : '1'}
                type={'autoresize'}
                style={{
                  fontSize: '24px',
                  fontFamily: 'var(--semibold-font)',
                  minHeight: '20px',
                }}
                title={'TITLE'}
                required={true}
                value={editableTask?.title}
                disabled={isUpdateDisabled || disabledFieldSet.has('title')}
                maxLength={80}
                placeholder={isUpdateDisabled ? '' : 'Type Task Title Here'}
                showRequiredError={showErrors}
                inputRef={titleInputRef}
                onChange={handleTaskTitleChange}
                onFocus={handleTitleFocus}
                onBlur={handleTitleBlur}
              />
              <S.StatusRow>
                <S.StatusContainer
                  initial="hidden"
                  whileInView="visible"
                  variants={MOTION_SHOWUP_VARIANTS}
                  color={selectedStateColor}
                >
                  <States
                    isEditMode={isEditMode}
                    states={editableWorkflowStates}
                    disabled={
                      (isViewMode && !isEditMode) ||
                      disabledFieldSet.has('state')
                    }
                    required={!isViewMode && !isEditMode}
                    selected={selectedStateId}
                    onChange={handleStateChange}
                    onAddStateClick={handleAddStateClick}
                    onEditStateClick={handleEditStateClick}
                    onEditStateXClick={handleEditStateXClick}
                  />
                </S.StatusContainer>
                <Select {...getProps('requestor')} />
                <Select {...getProps('owner')} />
              </S.StatusRow>
              <S.FieldContainer>
                <Label>DESCRIPTION</Label>
                {isViewMode ? (
                  <Linkify options={LINKIFY_OPTIONS}>
                    <S.Preline>{parse(task?.description ?? '')}</S.Preline>
                  </Linkify>
                ) : (
                  <EditorProvider>
                    <Editor {...descriptionEditorProps}>
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <BtnStrikeThrough />
                        <BtnUnderline />
                        <BtnClearFormatting />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                )}
              </S.FieldContainer>
              <S.FieldContainer>
                <Label>TAGS</Label>
                <Tags
                  tags={filteredTags}
                  disabled={isViewMode && hasTaskToEdit && !isEditMode}
                  touchable={!isViewMode || !hasTaskToEdit || isEditMode}
                  selected={tagIdList}
                  isEditMode={isEditMode}
                  onTagClick={handleTagClick}
                  onTagXClick={handleTagXClick}
                  onAddTagClick={handleAddTagClick}
                />
              </S.FieldContainer>
              {editableTask?.attachments?.length > 0 && (
                <S.FieldContainer>
                  <Label>ATTACHMENTS</Label>
                  <Attachments
                    attachments={editableTask?.attachments ?? []}
                    disabled={false}
                    $touchable={true}
                    isRemovable={
                      !isViewMode &&
                      !disabledFields.includes('attachments_remove')
                    }
                    onAttachmentFileClick={handleAttachmentFileClick}
                    onAttachmentFileXClick={handleAttachmentFileXClick}
                  />
                </S.FieldContainer>
              )}
              {!isViewMode &&
                !isEditMode &&
                !disabledFieldSet.has('attachments') && (
                  <S.TaskAttachmentSpace
                    filesToAdd={attachmentsToAdd}
                    onFilesAdded={handleAttachmentFilesAdded}
                    onFileRemoved={handleAttachmentFileRemoved}
                  />
                )}
              {!isViewMode && (
                <S.FieldContainer>
                  <Label>NOTES</Label>
                  <EditorProvider>
                    <Editor {...notesEditorProps}>
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <BtnStrikeThrough />
                        <BtnUnderline />
                        <BtnClearFormatting />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                </S.FieldContainer>
              )}
            </S.FirstColumn>
            <S.SecondColumn>
              {!editableWorkflow?.task_template?.hide_priority && (
                <Select {...getProps('priority')} />
              )}
              {!editableWorkflow?.task_template?.hide_deadline && (
                <S.DueDate
                  title={'DUE DATE'}
                  required={!isViewMode && !isEditMode}
                  showRequiredError={showErrors}
                  type="date"
                  disabled={
                    extraFieldsDisabled || disabledFieldSet.has('deadline')
                  }
                  inputRef={deadlineInputRef}
                  value={editableTask?.deadline?.substring(0, 10) ?? ''}
                  onChange={(deadline) => {
                    setEditableTask({
                      ...editableTask,
                      deadline,
                    })

                    track('Due date changed')
                  }}
                  onFocus={handleDeadlineFocus}
                  onBlur={handleDeadlineBlur}
                />
              )}
              {!(
                !isEditMode && workflow?.task_template?.fields.length === 0
              ) && (
                <TaskCustomForm
                  task={editableTask}
                  fields={customFields}
                  $isEditMode={isEditMode}
                  isEditable={!isViewMode}
                  showErrors={!isViewMode && !isEditMode && showErrors}
                  onChange={handleCustomFieldChange}
                  onFieldClick={handleCustomFieldClick}
                  onFieldInputRef={handleFieldInputRef}
                  onTemplateChange={handleTemplateChange}
                  onAddCustomFieldClick={handleAddCustomFieldClick}
                  onRemoveCustomFieldClick={handleRemoveCustomFieldClick}
                />
              )}
            </S.SecondColumn>
          </Row>
          {hasTaskToEdit && (
            <S.FieldContainer>
              <Collapsable title="ACTIVITY" maxHeight="unset">
                <TaskHistory
                  task={task}
                  stateList={stateList}
                  tagList={tagList}
                  customFieldList={customFieldList}
                  getCompanyPersonById={getCompanyPersonById}
                />
              </Collapsable>
            </S.FieldContainer>
          )}
        </Column>
      </S.Form>
      {tagToEdit && (
        <EditTagModal
          $isNew={isAddingTag}
          tag={tagToEdit}
          tags={editableWorkflowTags}
          onClose={handleTagTemplateModalClose}
          onSave={handleTagTemplateSave}
        />
      )}
      {stateToEdit && (
        <EditStateModal
          $isNew={isAddingState}
          state={stateToEdit}
          states={editableWorkflowStates}
          isDefault={
            stateToEdit.id === workflow.default_state_id ||
            stateToEdit.title === workflow.default_state
          }
          onClose={handleEditStateModalClose}
          onSave={handleEditStateSave}
        />
      )}
      {fieldToEdit && (
        <EditFieldTemplateModal
          field={fieldToEdit}
          fields={editableWorkflowFields}
          $isNew={!!newFieldToEdit}
          onClose={handleFieldTemplateModalClose}
          onSave={handleCustomFieldSave}
        />
      )}
      {showEditWorkflowModal && (
        <EditWorkflowModal
          $isNew={!hasId(workflow)}
          workflow={editableWorkflow}
          workflows={workflows}
          onSave={handleWorkflowModalSave}
          onClose={handleWorkflowModalClose}
        />
      )}
    </>
  )
}

TaskForm.propTypes = {
  company: PropTypes.object.isRequired,
  task: PropTypes.any.isRequired,
  workflow: PropTypes.any.isRequired,
  workflows: PropTypes.any.isRequired,
  projects: PropTypes.any.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  hasTaskToEdit: PropTypes.bool,
  isViewMode: PropTypes.any,
  isEditMode: PropTypes.bool,
  animatingEnter: PropTypes.bool,
  disabledFields: PropTypes.arrayOf(
    PropTypes.oneOf(
      'title',
      'project',
      'owner',
      'requestor',
      'workflow',
      'description',
      'notes'
    )
  ),
  showErrors: PropTypes.bool,
  onWorkflowSave: PropTypes.func,
  onRemoveAttachmentFile: PropTypes.func,
  onWorkflowIdChange: PropTypes.func,
  onSubmit: PropTypes.func,
  childRef: PropTypes.any.isRequired,
  initAttachmentsToAdd: PropTypes.array,
  fallbackProjectId: PropTypes.string,
  stateList: PropTypes.array.isRequired,
  tagList: PropTypes.array.isRequired,
  customFieldList: PropTypes.array.isRequired,
  getCompanyPersonById: PropTypes.func.isRequired,
  getAttachmentUrl: PropTypes.func.isRequired,
}

TaskForm.defaultProps = {
  hasTaskToEdit: false,
  isViewMode: true,
  isEditMode: false,
  disabledFields: [],
  showErrors: false,
  initAttachmentsToAdd: [],
  fallbackProjectId: null,
  animatingEnter: false,
  onSubmit: () => {},
  onWorkflowSave: () => {},
  onWorkflowIdChange: () => {},
  onRemoveAttachmentFile: () => {},
}

export default TaskForm
