import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;

  > :first-child {
    margin-top: auto;
  }
`
export const EmptyContainer = styled.div`
  height: 100px;
  opacity: 0.5;
`
