import { Menu as ReactMenu } from '@szhsin/react-menu'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { ICONS } from '../../assets'
import {
  stopPropagation,
  stopPropagationAnd,
} from '../../utils/event/stopPropagationAnd'
import Button from '../Button'
import MenuItem from '../MenuItem'
import * as S from './styles'

const { verticalDots } = ICONS

const Menu = ({
  items,
  itemsWithSubmenu,
  disabled,
  small,
  buttonProps,
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  function handleOpen(menuState) {
    setOpen(menuState.open)
  }

  return (
    <div>
      <ReactMenu
        menuStyle={S.MENU_STYLE}
        menuButton={
          <S.MenuButtonContainer onClick={stopPropagation} disabled={disabled}>
            <S.StyledButton $small={small} selected={open}>
              {small ? <img src={verticalDots} /> : <S.SettingsIcon />}
            </S.StyledButton>
          </S.MenuButtonContainer>
        }
        onMenuChange={handleOpen}
        {...rest}
      >
        {itemsWithSubmenu?.map(({ title, collection, ...item }, index) => (
          <S.StyledSubmenu key={index} label={title} {...item}>
            {collection.map(({ onClick, ...restSubmenu }, childIndex) => (
              <MenuItem
                key={childIndex}
                {...restSubmenu}
                onClick={stopPropagationAnd(() => {
                  if (onClick) {
                    onClick(restSubmenu)
                  }
                })}
              />
            ))}
          </S.StyledSubmenu>
        ))}
        {items.map(({ onClick, ...item }, index) => (
          <MenuItem
            index={index}
            key={index}
            {...item}
            onClick={stopPropagationAnd(() => {
              if (onClick) {
                onClick(item)
              }
            })}
          />
        ))}
      </ReactMenu>
    </div>
  )
}

Menu.propTypes = {
  ...Button.propTypes,
  buttonProps: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  itemsWithSubmenu: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      collection: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        })
      ),
    })
  ),
}

Menu.defaultProps = {
  ...Button.defaultProps,
  items: [],
  itemsWithSubmenu: [],
}

export default Menu
