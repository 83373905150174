export default function ChevronUp({ ...svgProps }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="9"
      viewBox="0 0 16 9"
      stroke="currentcolor"
      fill="none"
      {...svgProps}
    >
      <path
        d="M14.3086 1.70313L8.30859 7.70312L2.30859 1.70312"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

ChevronUp.propTypes = {}

ChevronUp.defaultProps = {}
