import PropTypes from 'prop-types'
import EditModal from '../EditModal'
import * as S from './styles'

const PromptModal = ({ message, ...modalProps }) => (
  <EditModal {...modalProps}>
    <S.Message>{message}</S.Message>
  </EditModal>
)

PromptModal.propTypes = {
  ...EditModal.propTypes,
  message: PropTypes.string.isRequired,
}

PromptModal.defaultProps = {}

export default PromptModal
