import PropTypes from 'prop-types'
import Lottie from 'react-lottie-player'
import { Modal } from '..'
import lottieAnimation from '../../assets/lottie/logo.json'
import { lottieConfig } from '../../utils'
import * as S from './styles'

const GuestThanks = ({ requestorName, ...modalProps }) => (
  <S.StyledModal {...modalProps}>
    <S.Container>
      {lottieAnimation && (
        <Lottie
          {...lottieConfig({ animationData: lottieAnimation })}
          style={{ height: 50 }}
        />
      )}
      <S.Message>{`Thanks for updating ${requestorName}!`}</S.Message>
      <S.SubMessage>
        {`Want to centralize your project tasks with your clients?`}
        <br /> {`Better organize with Slingshot!`}
      </S.SubMessage>
      <S.SignUpButton>
        <S.SignUpAnchor href="https://www.slingshot-inc.com/">
          {"I'm ready for less emails!"}
        </S.SignUpAnchor>
      </S.SignUpButton>
    </S.Container>
  </S.StyledModal>
)

GuestThanks.propTypes = {
  ...Modal.propTypes,
  requestorName: PropTypes.string.isRequired,
}

export default GuestThanks
