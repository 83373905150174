import styled from 'styled-components'
import HoverShadow from '../HoverShadow'

export const WorkflowContainer = styled(HoverShadow)`
  outline-offset: 3px;

  ${(props) => `
    ${
      props.$isEditMode &&
      `
        cursor: pointer;
      `
    }`}
`
