import PropTypes from 'prop-types'
import { any, both, pipe, prop } from 'ramda'
import { EditModal } from '..'
import { isNotEmpty, isNotNil } from '../../utils/ramdaExtensions'
import EditForm from '../EditForm'

const EditFormModal = ({
  title,
  fields,
  formProps,
  onClose,
  onSave,
  children,
  canSave,
}) => {
  const finalCanSave =
    canSave && !any(pipe(prop('errors'), both(isNotNil, isNotEmpty)))(fields)

  return (
    <EditModal
      title={title}
      onClose={onClose}
      onCancel={onClose}
      onSave={onSave}
      canSave={finalCanSave}
    >
      <EditForm fields={fields} onSave={onSave} {...formProps} />
      {children}
    </EditModal>
  )
}

EditFormModal.propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formProps: PropTypes.object,
  children: PropTypes.node,
  canSave: PropTypes.bool,
}

EditFormModal.defaultProps = {
  children: null,
  formProps: {},
  canSave: true,
}

export default EditFormModal
