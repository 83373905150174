import PropTypes from 'prop-types'
import {
  append,
  chain,
  concat,
  filter,
  forEachObjIndexed,
  includes,
  map,
  max,
  not,
  pipe,
  pluck,
  prop,
  reduce,
  reject,
  uniqBy,
  unnest,
  values,
  __,
} from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteTasks, setLastUpdateDate, upsertTask } from '../../actions'
import { selectApp, selectPoller } from '../../selectors'
import { getLatestFromServer } from '../../services/latestApi'
import { dateStringToTime, timeToDateString } from '../../utils/date'
import {
  findId,
  keyMap,
  mapPatchId,
  uniqById,
} from '../../utils/ramdaExtensions'

const Poller = ({ t }) => {
  const app = useSelector(selectApp)

  const { lastUpdateDate } = useSelector(selectPoller)

  const { user, isGuest } = app

  const [timer, setTimer] = useState(null)

  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false

      if (timer) {
        clearTimeout(timer)
        setTimer(null)
      }
    }
  })

  useEffect(() => {
    if (timer) {
      return
    }

    if (!user) {
      return
    }

    const resetTimer = () => {
      if (!mountedRef.current) {
        setTimeout(resetTimer, 0)

        return
      }

      clearTimeout(timer)

      setTimer(
        setTimeout(async () => {
          try {
            const response = await getLatestFromServer(lastUpdateDate, isGuest)

            const data = await response.json()

            if (response.status === 200) {
              if (!mountedRef.current) {
                resetTimer()

                return
              }

              const {
                tasks = [],
                deleted_tasks = [],
                notes = [],
                attachments = [],
                history_owners = [],
                history_states = [],
                history_tags = [],
              } = data

              const bulkTaskUpdate = {}

              const makeProcessTaskEntry = (type) => (entry) => {
                const { task_id } = entry

                const task = pipe(
                  chain(prop('projects')),
                  pipe(chain(prop('tasks')), findId(task_id))
                )(user?.companies ?? [])

                const list = uniqById([
                  ...(task?.[type] ?? []),
                  ...((bulkTaskUpdate[task_id] &&
                    bulkTaskUpdate[task_id][type]) ||
                    []),
                ])

                const isUnique = pipe(
                  prop('id'),
                  includes(__, pluck('id')(list)),
                  not
                )(entry)

                bulkTaskUpdate[task_id] = {
                  ...task,
                  ...bulkTaskUpdate[task_id],
                  [type]: isUnique
                    ? append(entry, list)
                    : mapPatchId(entry.id, entry, list),
                }
              }

              const processTask = (task) => {
                const { id } = task

                bulkTaskUpdate[id] = {
                  ...bulkTaskUpdate[id],
                  ...task,
                }
              }

              tasks.forEach(processTask)
              notes.forEach(makeProcessTaskEntry('notes'))
              attachments.forEach(makeProcessTaskEntry('attachments'))
              history_owners.forEach(makeProcessTaskEntry('history_owners'))
              history_states.forEach(makeProcessTaskEntry('history_states'))
              history_tags.forEach(makeProcessTaskEntry('history_tags'))
              history_tags.forEach((history_tag) => {
                const { task_id, added, removed } = history_tag

                const task = pipe(
                  chain(prop('projects')),
                  pipe(chain(prop('tasks')), findId(task_id))
                )(user?.companies ?? [])

                const addedTaskTags = pipe(map(keyMap({ tag_id: 'tag_id' })))(
                  added
                )
                const removedTaskIdTags = pluck('tag_id')(removed)

                const allTaskTags = uniqBy(prop('tag_id'))([
                  ...(task?.task_tags ?? []),
                  ...((bulkTaskUpdate[task_id] ?? {}).task_tags ?? []),
                ])

                const currentTaskTagIds = pluck('tag_id')(allTaskTags)

                const uniqueAddedTaskTags = filter(
                  pipe(prop('tag_id'), includes(__, currentTaskTagIds), not)
                )(addedTaskTags)

                const nextTaskTags = pipe(
                  reject(pipe(prop('tag_id'), includes(__, removedTaskIdTags))),
                  concat(uniqueAddedTaskTags)
                )(allTaskTags)

                bulkTaskUpdate[task_id] = {
                  ...task,
                  ...bulkTaskUpdate[task_id],
                  task_tags: nextTaskTags,
                }
              })

              const latestUpdatedAt = timeToDateString(
                pipe(
                  unnest,
                  pluck('updated_at'),
                  map(dateStringToTime),
                  reduce(max, dateStringToTime(lastUpdateDate))
                )(values(data))
              )

              forEachObjIndexed(upsertTask, bulkTaskUpdate)

              if (deleted_tasks.length > 0) {
                deleteTasks(deleted_tasks)
              }

              setLastUpdateDate(latestUpdatedAt)
              setTimer(null)
            } else {
              setLastUpdateDate(new Date().toISOString())
            }
          } catch (err) {
            setLastUpdateDate(new Date().toISOString())
          }
        }, t * 1000)
      )
    }

    resetTimer()
  }, [t, user, timer, lastUpdateDate, isGuest])

  return null
}

Poller.propTypes = {
  t: PropTypes.number.isRequired, // seconds
}

export default Poller
