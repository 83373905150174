import { motion } from 'framer-motion'
import styled from 'styled-components'
import { HEADER_HEIGHT } from '../../constants'

export const Container = styled(motion.div)`
  margin: 0 auto;
  display: grid;
  place-items: center;
`

export const List = styled.div`
  width: 450px;
  height: calc(90vh - ${HEADER_HEIGHT}px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;

  ::-webkit-scrollbar-thumb {
    border-left: 10px solid var(--global-background);
  }
`

export const ManageDetails = styled.div`
  width: 100%;
  height: 25px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
  color: var(--white-50);
  font-size: 14px;
  cursor: pointer;

  :hover {
    color: var(--white-30);
  }
`

export const EmptyContainer = styled.div`
  width: 450px;
  opacity: 0.5;
  height: calc(100% - 20px);
  border: 2px dashed var(--white-70);
`
