import { components } from 'react-select'
import styled from 'styled-components'
import X from '../../assets/components/icon_x'
import Button from '../Button'
import Row from '../Row'

export const ValueContainer = styled(components.ValueContainer)`
  width: 100% !important;
  min-width: 80px !important;

  ${(props) =>
    props.borderless &&
    `
    padding-left: 0 !important;
    padding-right: 0 !important;
  `}
`

export const MenuList = styled(components.MenuList)`
  ${(props) =>
    props.borderless &&
    `
    padding: 0 !important;
    border-radius: 8px !important;
  `}
`

export const Option = styled(components.Option)`
  min-height: 40px;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 0 !important;

  ${(props) =>
    props.borderless &&
    `
    color: var(--black) !important;
    background-color: var(--white) !important;

    :not(:last-child) {
      border-bottom: 1px solid var(--white-90);
    }

    :hover {
      background-color: var(--white-95) !important;
      cursor: pointer !important;
    }

  `}

  ${(props) =>
    props.isFocused &&
    `
      background-color: var(--white-95) !important;
    `}

  ${(props) =>
    props.isSelected &&
    `
      background-color: var(--aqua-59) !important;
      color: var(--white) !important;
    `}
`

export const Separator = styled(components.Option)`
  width: 100% !important;
  pointer-events: none;
  border-bottom: 1px solid var(--white-90);
  border-top: 1px solid var(--white-90);
  font-family: var(--semibold-font);
`

export const Add = styled(Button)`
  width: 100% !important;
  border-top: 1px solid var(--white-90);
  font-family: var(--semibold-font);
  color: var(--aqua-59) !important;
`

export const CustomRow = styled(Row)`
  padding: 8px 12px;
  flex-wrap: nowrap;
  width: 100%;
`

export const ColorIndicator = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 3px;
  background ${(props) => props.$backgroundColor};
`

export const IconX = styled(X)`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 3px;
  justify-self: end;
  color: var(--white-30);
`

export const ValueRow = styled(Row)`
  gap: 0;
  align-items: center;
`
