import { map, pick } from 'ramda'
import { useSelector } from 'react-redux'
import { appGetOutsiderGroupById } from '../../reducers/app/utils'
import { selectApp } from '../../selectors'

export default function useGetParsedOutsiderList() {
  const app = useSelector(selectApp)

  const { outsiders } = app

  return map((outsider) => ({
    ...pick(['id', 'email', 'profile_id'])(outsider),
    ...pick(['company_id', 'group_id'])(outsider.company_outsider),
    group: appGetOutsiderGroupById(app, outsider?.company_outsider?.group_id),
    full_name: outsider.company_outsider.name,
    isMember: false,
  }))(outsiders)
}
