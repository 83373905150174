import styled from 'styled-components'
import { fadeIn } from '../../styles/animations'
import Column from '../Column'
import ProjectName from '../ProjectName'

export const Container = styled.div`
  height: 100%;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;

  > :first-child {
    margin-top: auto;
  }
`

export const TaskHistoryList = styled(Column)`
  gap: 12px;
`

export const TaskHistoryListTask = styled.div`
  color: var(--black);
  font-family: var(--semibold-font);
  animation: ${fadeIn} 1s ease forwards;
`
export const TaskHistoryListProject = styled(ProjectName)`
  animation: ${fadeIn} 1s ease forwards;
`
