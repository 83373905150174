import { components } from 'react-select'
import styled from 'styled-components'
import { fadeInHorizontal } from '../../styles/animations'

export const IndicatorsContainer = styled(components.IndicatorsContainer)`
  svg {
    fill: var(--black);
    opacity: 1;
  }

  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;

  ${(props) =>
    props.borderless &&
    `
    svg {
      fill: var(--white-50) !important;
    }
  `}

  ${(props) =>
    props.$hidden &&
    `
    svg {
      opacity: 0;
    }
  `}
`

export const Control = styled(components.Control)`
  background-color: transparent !important;
  width: 100% !important;
  cursor: pointer !important;
  border-width: 0 !important;
  border-radius: var(--border-radius) !important;
  box-shadow: none !important;
  flex-wrap: nowrap !important;

  :focus-within {
    border-color: var(--aqua-59) !important;
    box-shadow: 0 0 0px 2px var(--aqua-59) !important;
  }

  ${(props) =>
    props.borderless &&
    `
    box-shadow: '0 0 0 2px var(--white-70)' !important;

    :focus-within {
      box-shadow: none !important;
      border-radius: none !important;

      ${IndicatorsContainer} {
        svg {
          fill: var(--aqua-59) !important;
        }
      }
    }
  `}
`

export const NoOptionsMessage = styled.div`
  display: grid;
  place-items: center;
`

export const SingleValue = styled(components.SingleValue)`
  font-family: var(--semibold-font); !important;
  color: var(--white-20) !important;
`

export const ValueContainer = styled(components.ValueContainer)`
  width: 100% !important;
  min-width: 135px !important;

  span {
    font-family: var(--semibold-font) !important;
  }

  ${(props) =>
    props.borderless &&
    `
    padding-left: 0 !important;
  `}
`

export const Menu = styled(components.Menu)`
  overflow: hidden !important;

  ${(props) =>
    props.borderless &&
    `
    border-radius: 8px !important;
  `}
`

export const MenuList = styled(components.MenuList)`
  ${(props) =>
    props.borderless &&
    `
    padding: 0 !important;
    border-radius: 8px !important;
  `}
`

export const Option = styled(components.Option)`
  min-height: 40px;
  opacity: 0;
  animation: ${fadeInHorizontal} 0.4s ease forwards;
  animation-delay: ${(props) => props.index && `0.${props.index}s`};

  ${(props) =>
    props.borderless &&
    `
    color: var(--black) !important;
    background-color: var(--white) !important;

    :not(:last-child) {
      border-bottom: 1px solid var(--white-90);
    }

    :hover {
      background-color: var(--white-95) !important;
      cursor: pointer !important;
    }

  `}

  ${(props) =>
    props.isFocused &&
    `
      background-color: var(--white-95) !important;
    `}

  ${(props) =>
    props.isSelected &&
    `
      background-color: var(--aqua-59) !important;
      color: var(--white) !important;
      pointer-events: none;

      div {
        border-color: var(--white);
        color: var(--white);
      }
    `}
`
