import {
  any,
  curry,
  filter,
  findIndex,
  includes,
  isEmpty,
  isNil,
  join,
  map,
  pipe,
  prop,
  reduce,
  reject,
  replace,
  slice,
  split,
  startsWith,
  take,
  toLower,
  toUpper,
  trim,
} from 'ramda'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { setShowMobileSidebar } from '../../actions'
import { appGetProjectById, appGetTaskTags } from '../../reducers/app/utils'
import { selectApp } from '../../selectors'
import { routeCreator } from '../../utils'
import { decant } from '../../utils/ramdaExtensions'
import EmptyState from '../EmptyState'
import * as S from './styles'

const SEARCH_LIMIT = isMobile ? 7 : 10

const removeWhitespace = replace(/\s+/g, '')

const splitIntoWords = pipe(toUpper, replace(/\s+/g, ' '), trim, split(' '))

const checkWordInList = (word, list) => {
  const index = findIndex(includes(word))(list)
  const hasMatch = index >= 0
  return {
    hasMatch,
    remainingTextList: hasMatch ? slice(index + 1, Infinity, list) : list,
  }
}

const smartSearch = curry((searchText, text) => {
  if (isEmpty(searchText) || isEmpty(text)) {
    return false
  }

  const searchList = splitIntoWords(searchText)
  const textList = splitIntoWords(text)

  return pipe(
    reduce(
      (acc, searchWord) => {
        const { hasMatch, remainingTextList } = checkWordInList(
          searchWord,
          acc.remainingTextList
        )

        return {
          remainingTextList,
          hasMatch: acc.hasMatch && hasMatch,
        }
      },
      {
        remainingTextList: textList,
        hasMatch: true,
      }
    ),
    prop('hasMatch')
  )(searchList)
})

const searchInTitle = (searchText, task) => smartSearch(searchText, task.title)

const searchInTags = (getTaskTags, tags, task) => {
  if (tags.length === 0) {
    return true
  }

  const taskTags = getTaskTags(task)

  const taskTagTitles = map(pipe(prop('title'), removeWhitespace, toLower))(
    taskTags
  )

  const isMatch = any((tag) => any(smartSearch(tag))(taskTagTitles))(tags)

  return isMatch
}

const taskSearch = curry((getTaskTags, searchText, task) => {
  const [titleTextList, tagsTextList] = pipe(
    split(' '),
    decant(startsWith('#'))
  )(searchText)

  const tagsInText = pipe(
    map(slice(1, Infinity)),
    reject(isEmpty)
  )(tagsTextList)
  const titleText = pipe(join(' '), trim)(titleTextList)

  const isTitleMatch = searchInTitle(titleText, task)
  const isTagsMatch = searchInTags(getTaskTags, tagsInText, task)

  if (isEmpty(titleText)) {
    return isTagsMatch
  }

  return isTitleMatch && isTagsMatch
})

const SearchBox = () => {
  const [value, setValue] = useState('')

  const app = useSelector(selectApp)

  const { allTasks } = app

  const getTaskTags = (task) => appGetTaskTags(app, task)

  const selectedList = pipe(
    filter(taskSearch(getTaskTags, value)),
    take(SEARCH_LIMIT),
    map((task) => {
      const project = appGetProjectById(app, task.project_id)

      return (
        <S.StyledLink
          key={task.id}
          to={routeCreator.showTask({
            task,
          })}
        >
          <S.ProjectLabel>
            project: <span>{project?.name}</span>
          </S.ProjectLabel>
          <S.TaskTitle>{task.title}</S.TaskTitle>
        </S.StyledLink>
      )
    })
  )(allTasks)

  function handleChange(event) {
    setValue(event.target.value)

    if (isMobile) {
      setShowMobileSidebar(false)
    }
  }

  function handleBlur() {
    setTimeout(() => setValue(''), 200)
  }

  return (
    <S.Container>
      <S.Search
        type="text"
        placeholder="Search task"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {!isEmpty(value) && !isNil(value) && (
        <S.SearchResultContainer>
          {isEmpty(selectedList) ? (
            <EmptyState>No results for “{value}”</EmptyState>
          ) : (
            selectedList
          )}
        </S.SearchResultContainer>
      )}
    </S.Container>
  )
}

export default SearchBox
