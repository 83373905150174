import PropTypes from 'prop-types'
import { equals, find, isEmpty, pipe, prop, toLower, trim } from 'ramda'
import { useState } from 'react'
import { EditFormModal } from '..'

export const MAX_FIELD_NAME_LENGTH = 20

const EditFieldTemplateModal = ({ field, fields, $isNew, onClose, onSave }) => {
  const [fieldToEdit, setFieldIdToEdit] = useState(field)

  function handleClose() {
    onClose(fieldToEdit)
  }

  function handleSave() {
    onSave(fieldToEdit)
  }

  return (
    <EditFormModal
      title={$isNew ? `Adding Custom Field` : `Editing Custom Field`}
      fields={[
        {
          title: 'NAME',
          type: 'text',
          value: fieldToEdit.name,
          maxLength: MAX_FIELD_NAME_LENGTH,
          placeholder: 'Type Name Here',
          errors: (() => {
            const sameNameCustomField = find(
              pipe(prop('name'), toLower, equals(fieldToEdit.name))
            )(fields)

            if (isEmpty(trim(fieldToEdit.name))) {
              return ['Cannot have empty field name.']
            }

            if (fieldToEdit.name.length > MAX_FIELD_NAME_LENGTH) {
              return [
                `Cannot have field name longer than ${MAX_FIELD_NAME_LENGTH} characters.`,
              ]
            }

            const isDuplicatedTagName =
              sameNameCustomField &&
              ($isNew || fieldToEdit.id !== sameNameCustomField.id)

            if (isDuplicatedTagName) {
              return ['Cannot have duplicated field name.']
            }

            return []
          })(),
          onChange: (value) => {
            setFieldIdToEdit({
              ...fieldToEdit,
              name: value,
            })
          },
        },
        {
          title: 'REQUIRED',
          type: 'checkbox',
          value: fieldToEdit.required,
          onChange: (value) => {
            setFieldIdToEdit({
              ...fieldToEdit,
              required: value,
            })
          },
        },
      ]}
      $isNew={$isNew}
      onClose={handleClose}
      onCancel={handleClose}
      onSave={handleSave}
    />
  )
}

EditFieldTemplateModal.propTypes = {
  field: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  $isNew: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

EditFieldTemplateModal.defaultProps = {}

export default EditFieldTemplateModal
