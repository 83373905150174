import _ from 'lodash'
import { any, indexBy, isEmpty, prop, trim } from 'ramda'
import { PRIORITY_OPTIONS } from '../../constants'
import { getDropdownOptions } from '../../utils'
import { findValueEq, isNotEmpty, isNotNil } from '../../utils/ramdaExtensions'

export function getStatusOptions({ list, label }) {
  const { unstarted, started, done } = _.groupBy(list, 'type')

  return [
    ...getDropdownOptions({ list: unstarted, label }),
    ...getDropdownOptions({ list: started, label }),
    ...getDropdownOptions({ list: done, label }),
  ]
}

export function getInitialValue({
  options,
  selectedOption,
  hasTaskToEdit,
  defaultStateId,
}) {
  if (!_.isNil(defaultStateId)) {
    return hasTaskToEdit
      ? options.find((state) => state.value === selectedOption)
      : options.find((state) => state.value === defaultStateId)
  }

  return hasTaskToEdit
    ? options.find((state) => state.value === selectedOption)
    : _.head(options)
}

export const getTaskFormFields = (
  task,
  preview,
  hasTaskToEdit,
  defaultUserId,
  ownerOptions,
  initialOwnerId,
  fallbackOwnerOption,
  requestorOptions
) => {
  const priorityInitialValue = getInitialValue({
    options: PRIORITY_OPTIONS,
    selectedOption: task?.priority ?? '',
    hasTaskToEdit,
  })

  const deadlineInitialValue = task?.deadline ?? ''

  const ownerInitialValue =
    (hasTaskToEdit
      ? findValueEq(task?.owner_id)(ownerOptions)
      : findValueEq(initialOwnerId)(ownerOptions) ??
        findValueEq(defaultUserId)(ownerOptions)) ?? fallbackOwnerOption

  const requestorInitialValue = hasTaskToEdit
    ? findValueEq(task?.requestor_id)(requestorOptions)
    : findValueEq(defaultUserId)(requestorOptions)

  const fields = {
    priority: {
      name: 'priority',
      label: 'Priority',
      type: 'select',
      defaultValue: priorityInitialValue,
      value: preview ? priorityInitialValue : undefined,
      options: PRIORITY_OPTIONS,
    },
    deadline: {
      name: 'deadline',
      label: 'Deadline',
      required: true,
      type: 'date',
      defaultValue: deadlineInitialValue,
      value: preview ? deadlineInitialValue : undefined,
    },
    requestor: {
      name: 'requestor',
      label: `Requestor`,
      type: 'select',
      required: true,
      defaultValue: requestorInitialValue,
      value: preview ? requestorInitialValue : undefined,
      options: requestorOptions,
    },
    owner: {
      name: 'owner',
      label: `Owner`,
      type: 'select',
      required: true,
      defaultValue: ownerInitialValue,
      value: preview ? ownerInitialValue : undefined,
      options: ownerOptions,
    },
    updated_at: task?.updated_at,
  }

  return fields
}

export const getUpdatedTaskFields = (newTask, oldTask) => {
  const updatedTaskFields = {
    ..._.keys(newTask).reduce((accumulator, key) => {
      if (JSON.stringify(newTask[key]) !== JSON.stringify(oldTask[key])) {
        accumulator[key] = newTask[key]
      }
      return accumulator
    }, {}),
  }

  return updatedTaskFields
}

export const isTaskProjectValid = (task) =>
  isNotNil(task.project_id) && isNotEmpty(task.project_id)
export const isTaskTitleValid = (task) =>
  task.title && !isEmpty(trim(task.title))
export const isTaskDeadlineValid = (task, workflow) =>
  workflow?.task_template?.hide_deadline || task.deadline
export const isTaskFieldsValid = (task, workflow) => {
  const fields = workflow?.task_template?.fields ?? []

  const customFieldMap = indexBy(prop('field_id'))(task?.custom_fields ?? [])

  return !any((field) => {
    if (field.required) {
      const taskField = customFieldMap[field.id]

      if (!taskField || isEmpty(taskField.value)) {
        return true
      }
    }

    return false
  })(fields)
}

export const isTaskFormValid = (task, workflow) => {
  if (!isTaskTitleValid(task)) {
    return false
  }

  if (!isTaskProjectValid(task)) {
    return false
  }

  if (!isTaskDeadlineValid(task, workflow)) {
    return false
  }

  if (!isTaskFieldsValid(task, workflow)) {
    return false
  }

  return true
}

export const processServerTaskHistory = (task, serverTaskHistory) => {
  const keys = _.keys(serverTaskHistory)
  const hasHistoryToUpdate = keys.some((key) => !_.isNil(key))

  const fieldsToUpdate = {}

  if (hasHistoryToUpdate) {
    keys.forEach((key) => {
      let finalKey = `${key}s`
      let listToConcat = [serverTaskHistory[key]]

      if (key === 'history_priority') {
        finalKey = 'history_priorities'
      }

      if (key === 'history_custom_fields') {
        finalKey = 'history_custom_fields'
        listToConcat = [...serverTaskHistory[finalKey]]
      }

      const listToUpdate = (task[finalKey] ?? []).concat(listToConcat)

      fieldsToUpdate[finalKey] = listToUpdate
    })
  }

  return fieldsToUpdate
}
