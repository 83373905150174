import PropTypes from 'prop-types'
import { filter, propEq } from 'ramda'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { deleteProject, setProjectToDelete } from '../../actions'
import { selectApp } from '../../selectors'
import { useDeleteProjectMutation } from '../../services/projectApi'
import PromptModal from '../PromptModal'

const DeleteProject = ({ company, project }) => {
  const { taskList } = useSelector(selectApp)

  const projectTaskList = filter(propEq('project_id', project.id))(taskList)

  const [deleteProjectOnServer] = useDeleteProjectMutation()

  async function handleSubmit() {
    return deleteProjectOnServer({
      companyId: company.id,
      projectId: project.id,
    })
      .unwrap()
      .then(() => {
        deleteProject({ company, projectId: project.id })
        setProjectToDelete(null)
      })
      .catch(() => {
        toast.error('Could not delete project.')
      })
  }

  function handleClose() {
    setProjectToDelete(null)
  }

  return (
    <PromptModal
      title="Deleting Project"
      message={`You are about to delete ${projectTaskList.length} tasks. This action is irreversible. Are you
    sure?`}
      onCancel={handleClose}
      onSave={handleSubmit}
      submitLabel="DELETE"
    ></PromptModal>
  )
}

DeleteProject.propTypes = {
  company: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

DeleteProject.defaultProps = {}

export default DeleteProject
