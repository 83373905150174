import styled from 'styled-components'
import GreenButton from '../GreenButton'

export const NewTaskButton = styled(GreenButton)`
  background: var(--aqua-89);
  color: var(--aqua-49);
  border-color: var(--aqua-89);

  :hover {
    background: var(--aqua-89);
    color: var(--aqua-49);
    border-color: var(--aqua-89);
  }

  padding-left: 6px;
  padding-right: 6px;
`
