import PropTypes from 'prop-types'
import * as S from './styles'

const Tab = ({ tab, selected, ...buttonProps }) => (
  <S.Tab type="button" key={tab} $selected={selected} {...buttonProps}>
    {tab}
  </S.Tab>
)

Tab.propTypes = {
  ...S.Tab.propTypes,
  tab: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
}

Tab.defaultProps = {}

export default Tab
