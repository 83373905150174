import PropTypes from 'prop-types'
import { useRef } from 'react'
import ReactDOM from 'react-dom'
import { BackButton, WhiteBox } from '..'
import * as S from './styles'

const Modal = ({
  children,
  onClose,
  headerChildren,
  onEnter,
  onEntered,
  ...rest
}) => {
  const nodeRef = useRef(null)

  function handleAnimationComplete() {
    return onEntered()
  }

  return ReactDOM.createPortal(
    <S.Container>
      <S.Overlay onClick={onClose} />
      <S.Content
        ref={nodeRef}
        onAnimationComplete={handleAnimationComplete}
        {...rest}
        {...S.MODAL_FRAMER_CONFIG}
      >
        <S.Header onClick={onClose}>
          <BackButton>Close</BackButton>
        </S.Header>
        <WhiteBox>{children}</WhiteBox>
      </S.Content>
    </S.Container>,
    document.getElementById('modal-root')
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  headerChildren: PropTypes.node,
  onEnter: PropTypes.func,
  onEntered: PropTypes.func,
}

Modal.defaultProps = {
  onClose: () => {},
  headerChildren: null,
}

export default Modal
