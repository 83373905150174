export default function setSelectedFilter({
  payload,
  filter,
  fieldToLoad,
  setFilter,
}) {
  if (filter[fieldToLoad]?.includes(payload.id)) {
    const newEntries = filter[fieldToLoad].filter((item) => item !== payload.id)

    return setFilter({
      ...filter,
      [fieldToLoad]: newEntries,
    })
  }

  const currentEntries = filter[fieldToLoad] ?? []

  return setFilter({
    ...filter,
    [fieldToLoad]: [...currentEntries, payload.id],
  })
}
