import { useAuth0 } from '@auth0/auth0-react'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { useNavigatePreserve } from '../../hooks'
import * as S from './style'

const Login = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0()

  const navigate = useNavigatePreserve()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect()
    } else {
      navigate('/home')
    }
  }, [navigate, loginWithRedirect, isAuthenticated, isLoading])

  if (isLoading) {
    return <S.Center>Checking credentials...</S.Center>
  }

  if (!isNil(error)) {
    return <S.Center>Error: {error.message}</S.Center>
  }

  if (!isAuthenticated) {
    return <S.Center>Being redirected to login...</S.Center>
  }

  return <S.Center>Being redirected to authenticated area...</S.Center>
}

export default Login
