import { motion } from 'framer-motion'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

export const MODAL_FRAMER_CONFIG = {
  initial: { y: '0%', x: '-50%', opacity: 0 },
  animate: {
    y: '-50%',
    x: '-50%',
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 0.6,
      bounce: 0.4,
    },
  },
  exit: {
    y: '0%',
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'backOut',
    },
  },
}

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`

export const Overlay = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(5px);
  transition: 0.2s all ease;
`

export const Content = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;

  ${isMobile &&
  `
    width: calc(fit-content - 10%);
  `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
`

export const ViewOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const Menu = styled.div``

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
