import { motion } from 'framer-motion'
import styled from 'styled-components'

export const HoverShadow = styled(motion.div)`
  border-radius: 3px;
  width: fit-content;
  height: fit-content;
  outline-width: 2px;
  outline-style: solid;
  outline-color: #00000000;
`
