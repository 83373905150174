import styled from 'styled-components'
import { Column } from '../../components'
import GreenButton from '../../components/GreenButton'
import Row from '../../components/Row'
import { fadeIn } from '../../styles/animations'

const HEADER_HEIGHT = 80

export const Container = styled(Column)`
  align-items: center;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow: auto;
`

export const Wrapper = styled.div`
  background-color: var(--white);
  margin: 30px;
  border-radius: var(--border-radius);
  width: calc(100% - 30px);
  max-width: 900px;
  box-shadow: var(--box-shadow);
`

export const Form = styled.div`
  padding: 21px;
  opacity: 0;
  animation: ${fadeIn} 0.6s ease forwards;
  animation-delay: 0.3s;
`

export const UpdateButton = styled(GreenButton)`
  font-size: 16px;
  width: 75px;
  height: 45px;
`

export const Company = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  height: 44px;
  border-radius: var(--border-radius);
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  white-space: nowrap;
`

export const CompanyName = styled.div``

export const CompanyPicture = styled.img`
  width: 30px;
  height: 30px;
  border: 1px solid currentcolor;
  border-radius: 6px;
`

export const GuestHeader = styled(Row)`
  align-items: center;
  padding-left: 15px;
  background-color: var(--white-90);
  height: ${HEADER_HEIGHT}px;
  padding: 20px;
`
