import PropTypes from 'prop-types'
import Button from '../Button'
import * as S from './styles'

const MenuButton = ({ icon, title, style, ...rest }) => (
  <S.Item {...rest}>{title}</S.Item>
)

MenuButton.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

MenuButton.defaultProps = {
  ...Button.defaultProps,
}

export default MenuButton
