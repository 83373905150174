import PropTypes from 'prop-types'
import { useRef } from 'react'
import { Centerer, Row } from '..'
import { stopPropagationAnd } from '../../utils/event/stopPropagationAnd'
import * as S from './styles'

const AttachmentSpace = ({
  filesToAdd,
  onFilesAdded,
  onFileRemoved,
  ...divProps
}) => {
  function handleInputChange(event) {
    const { files } = event.target

    if (files) {
      onFilesAdded(files)
    }
  }

  const fileRef = useRef()

  return (
    <S.Container
      {...divProps}
      onClick={() => {
        fileRef.current.click()
      }}
    >
      <input
        type="file"
        multiple={true}
        ref={fileRef}
        style={{
          display: 'none',
        }}
        onChange={handleInputChange}
      ></input>
      <Centerer>
        <S.AttachmentDashedBox />
        {filesToAdd.length === 0 && (
          <Row>
            <S.GreenPaperclip />
            <S.Text>Attach Files</S.Text>
          </Row>
        )}
      </Centerer>
      {filesToAdd.length > 0 && (
        <S.NewAttachmentsRow>
          {filesToAdd.map((file, i) => (
            <S.NewAttachmentFile
              key={i}
              file={file}
              disabled={true}
              $touchable={false}
              isRemovable={true}
              onXClick={stopPropagationAnd(() => {
                onFileRemoved(file, i)
              })}
            >
              {file.filename}
            </S.NewAttachmentFile>
          ))}
        </S.NewAttachmentsRow>
      )}
    </S.Container>
  )
}

AttachmentSpace.propTypes = {
  ...S.Container.propTypes,
  filesToAdd: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFilesAdded: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func.isRequired,
}

AttachmentSpace.defaultProps = {}

export default AttachmentSpace
