import PropTypes from 'prop-types'

export default function Filter({ fill }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5078 7.71094L12.5078 17.7109"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.5078 10.7109L15.5078 14.7109"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.5078 8.71094L18.5078 16.7109"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21.5078 13.7109L21.5078 11.7109"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.50781 4.71094L9.50781 20.7109"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.50781 9.71094L6.50781 15.7109"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.50781 13.7109L3.50781 11.7109"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

Filter.propTypes = {
  fill: PropTypes.string,
}

Filter.defaultProps = {
  fill: '#808080',
}
