import PropTypes from 'prop-types'
import * as S from './styles'

const Loading = ({ message, children }) => (
  <S.Container>{message || children}</S.Container>
)

export default Loading

Loading.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
}

Loading.defaultProps = {
  message: 'Loading',
  children: null,
}
