import PropTypes from 'prop-types'
import { flatten, map } from 'ramda'
import { AttachmentFile } from '..'
import { stopPropagationAnd } from '../../utils/event/stopPropagationAnd'
import { hasId } from '../../utils/ramdaExtensions'
import * as S from './styles'

const Attachments = ({
  attachments,
  $touchable,
  isRemovable,
  onAttachmentFileClick,
  onAttachmentFileXClick,
  disabled,
}) => (
  <S.Attachments>
    {flatten(
      map((attachment) =>
        map((file) => (
          <AttachmentFile
            key={file.filename}
            file={file}
            disabled={disabled}
            $isNew={!hasId(file)}
            $touchable={$touchable}
            isRemovable={isRemovable}
            onClick={() => {
              if (!disabled) {
                onAttachmentFileClick(attachment, file)
              }
            }}
            onXClick={stopPropagationAnd(() => {
              onAttachmentFileXClick(attachment, file)
            })}
          />
        ))(attachment.files ?? [])
      )(attachments ?? [])
    )}
  </S.Attachments>
)

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.any).isRequired,
  $touchable: PropTypes.bool,
  isRemovable: PropTypes.bool,
  onAttachmentFileClick: PropTypes.func,
  onAttachmentFileXClick: PropTypes.func,
  disabled: PropTypes.bool,
}

Attachments.defaultProps = {
  $touchable: false,
  isRemovable: false,
  disabled: false,
  onAttachmentFileXClick: () => {},
  onAttachmentFileClick: () => {},
}

export default Attachments
