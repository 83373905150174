import { serverDelete, serverPost, serverPut } from './server'

export async function createWorkflowOnServer(companyId, workflow) {
  return await serverPost(`/company/${companyId}/workflow`, {
    body: workflow,
  })
}

export async function updateWorkflowOnServer(companyId, workflowId, workflow) {
  return await serverPut(`/company/${companyId}/workflow/${workflowId}`, {
    body: workflow,
  })
}

export async function deleteWorkflowOnServer(companyId, workflowId) {
  return await serverDelete(`/company/${companyId}/workflow/${workflowId}`)
}
