import styled from 'styled-components'
import Button from '../Button'
import Column from '../Column'
import GreenButton from '../GreenButton'
import HoverShadow from '../HoverShadow'
import Row from '../Row'

export const Container = styled(Column)`
  padding: 21px;
  width: min(40vh, 400px);
  gap: 24px;
`

export const AcceptInviteButton = styled(GreenButton)`
  width: 75px;
  align-self: end;
`

export const Guests = styled(Column)`
  gap: 15px;
`

export const Guest = styled(HoverShadow)`
  width: 100%;
  outline-offset: 3px;
`

export const InviteRow = styled(Row)`
  justify-content: space-between;
`

export const InviteCompanyName = styled.div`
  font-family: var(--semibold-font);
`

export const Tab = styled(Button)``
