import PropTypes from 'prop-types'

export const TaskCustomFieldPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'number',
    'text',
    'boolean',
    'date',
    'url',
    'image',
    'document',
  ]).isRequired,
  default_value: PropTypes.string,
})
