import { map, pipe, prop, uniqBy, unnest } from 'ramda'
import store from './store'
import { filterNotNil, findId, propBy } from './utils/ramdaExtensions'
import indexById from './utils/ramdaExtensions/indexById'

export const getToken = () => {
  const {
    app: { isGuest, guestToken, token },
  } = store.getState()

  return isGuest ? guestToken : token
}

export const getTaskWorkflowTags = (task, workflow) =>
  filterNotNil(
    map(pipe(prop('tag_id'), propBy(indexById(workflow?.tags ?? []))))(
      uniqBy(prop('tag_id'), task?.task_tags ?? [])
    )
  )

export const getCompanyWorkflows = (company) => company?.workflows ?? []

export const getCompanyWorkflow = (company, workflowId) =>
  findId(workflowId)(getCompanyWorkflows(company))

export const getCompanyWorkflowName = (company, workflowId) =>
  prop('name')(getCompanyWorkflow(company, workflowId))

export const getCompanyStates = (company) =>
  unnest(map(prop('states'))(getCompanyWorkflows(company)))

export const getCompanyCustomFields = (company) =>
  unnest(
    map(pipe(prop('task_template'), prop('fields')))(
      getCompanyWorkflows(company)
    )
  )
