import _ from 'lodash'
import PropTypes from 'prop-types'
import iconFile from '../../assets/icon_file.svg'
import iconGreenCheckmark from '../../assets/icon_green_checkmark.svg'
import iconWalkingPerson from '../../assets/icon_walking_person.svg'
import * as S from './styles'

export function getStatusIcon(type) {
  switch (type) {
    case 'unstarted':
      return iconFile
    case 'started':
      return iconWalkingPerson
    case 'done':
      return iconGreenCheckmark
    default:
      return new Error('Invalid status type.')
  }
}

const Status = ({ type, children }) => (
  <S.Container>
    {!_.isNil(type) && <img src={getStatusIcon(type)} alt="status icon" />}
    <S.Text>{children}</S.Text>
  </S.Container>
)

Status.propTypes = {
  type: PropTypes.oneOf(['unstarted', 'started', 'done']),
  children: PropTypes.node.isRequired,
}

Status.defaultProps = {
  type: null,
}

export default Status
