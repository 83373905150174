import { isEmpty } from 'lodash'
import { filter, last, map, unnest } from 'ramda'
import { useCallback, useRef, useState } from 'react'
import Popover from 'react-popover'
import { useSelector } from 'react-redux'
import { Row, TaskHistoryList } from '..'
import { setLastSeenDate } from '../../actions'
import ChevronUp from '../../assets/components/icon_chevron_up'
import {
  appGetCompanyPersonById,
  appGetProjectById,
  appGetTaskById,
} from '../../reducers/app/utils'
import { selectApp } from '../../selectors'
import { routeCreator } from '../../utils'
import { isAfter } from '../../utils/date'
import { sortByCreatedAt } from '../../utils/ramdaExtensions'
import { getTaskAllHistory } from '../TaskHistory/helpers'
import * as S from './styles'

const NotificationBell = () => {
  const app = useSelector(selectApp)

  const {
    allSelectedCompanyTasks,
    lastSeenDate,
    tagList,
    stateList,
    customFieldList,
    selectedTask,
  } = app

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const buttonRef = useRef()

  const isRecentHistoryItem = (item) => isAfter(item.created_at, lastSeenDate)

  const allRecentHistoryItems = sortByCreatedAt(
    filter(isRecentHistoryItem)(
      unnest(map(getTaskAllHistory)(allSelectedCompanyTasks))
    )
  )

  const allHistoryGroups = allRecentHistoryItems.reduce((acc, item) => {
    const lastHistoryGroup = last(acc)

    if (lastHistoryGroup) {
      const firstLastHistoryGroupItem = lastHistoryGroup[0]

      if (firstLastHistoryGroupItem.task_id === item.task_id) {
        lastHistoryGroup.push(item)
      } else {
        acc.push([item])
      }
    } else {
      acc.push([item])
    }

    return acc
  }, [])

  let groupId = allRecentHistoryItems[0]?.group_id

  const totalUpdateCount = allRecentHistoryItems
    .slice(1)
    .reduce((acc, item) => {
      if (item.group_id !== groupId) {
        groupId = item.group_id

        return acc + 1
      }

      groupId = item.group_id

      return acc
    }, 1)

  const isEmptyList = isEmpty(allHistoryGroups)

  const handleBellClick = () => {
    setIsPopoverOpen(!isPopoverOpen)
  }

  const handleClearAllClick = () => {
    setLastSeenDate(new Date().toISOString())

    setIsPopoverOpen(false)
  }

  const getCompanyPersonById = (userId) => appGetCompanyPersonById(app, userId)

  function getNotificationCount() {
    return totalUpdateCount > 99 ? '99+' : totalUpdateCount
  }

  const maxPerPage = 20

  const [page, setPage] = useState(0)

  const totalPages = Math.ceil(allHistoryGroups.length / maxPerPage)

  const handlePreviousPageClick = useCallback(() => {
    setPage(Math.max(page - 1, 0))
  }, [page])

  const handleNextPageClick = useCallback(() => {
    setPage(Math.min(page + 1, totalPages - 1))
  }, [page, totalPages])

  const showPager = totalPages > 1

  return (
    <Popover
      isOpen={isPopoverOpen}
      preferPlace={'below'}
      style={{
        zIndex: 3,
        marginRight: '10px',
      }}
      target={buttonRef}
      appendTarget={document.getElementById('modal-root')}
      tipSize={6}
      enterExitTransitionDurationMs={200}
      onOuterAction={() => {
        if (!selectedTask) {
          handleBellClick(false)
        }
      }}
      body={
        <S.Root style={{ userSelect: 'none' }}>
          <S.Notifications>
            <S.CustomRow>
              <S.Message>
                {!isEmptyList
                  ? `There have been ${totalUpdateCount} update${
                      totalUpdateCount > 1 ? 's' : ''
                    } since you last checked.`
                  : 'No recent updates.'}
              </S.Message>
              {!isEmptyList && (
                <S.ClearAllButton onClick={handleClearAllClick}>
                  {'Clear all'}
                </S.ClearAllButton>
              )}
            </S.CustomRow>
            {allHistoryGroups
              .slice(page * maxPerPage, (page + 1) * maxPerPage)
              .map((subHistory, index) => {
                const firstItem = subHistory[0]

                const task = appGetTaskById(app, firstItem.task_id)

                if (!task) {
                  return null
                }

                const project = appGetProjectById(app, task.project_id)

                const taskUrl = routeCreator.showTask({
                  task,
                })

                return (
                  <S.CustomHoverShadow key={index}>
                    <S.CustomLink to={taskUrl}>
                      <TaskHistoryList
                        showTask={true}
                        task={task}
                        project={project}
                        stateList={stateList}
                        tagList={tagList}
                        customFieldList={customFieldList}
                        getCompanyPersonById={getCompanyPersonById}
                        history={subHistory}
                      />
                    </S.CustomLink>
                  </S.CustomHoverShadow>
                )
              })}
          </S.Notifications>
          {showPager && (
            <Row
              style={{
                alignItems: 'center',
                alignSelf: 'center',
                background: 'white',
                padding: '6px',
                borderRadius: '6px',
                border: '1px solid var(--white-62)',
                boxShadow: 'var(--box-shadow)',
              }}
            >
              <div
                style={{
                  cursor: page === 0 ? 'auto' : 'pointer',
                  position: 'relative',
                }}
                onClick={handlePreviousPageClick}
              >
                <div
                  style={{
                    position: 'absolute',
                    width: '36px',
                    height: '36px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
                <ChevronUp
                  style={{
                    transform: 'rotate(90deg)',
                    color: page === 0 ? 'gray' : 'currentcolor',
                  }}
                />
              </div>
              <span
                style={{
                  fontFamily: 'var(--semibold-font)',
                  userSelect: 'none',
                }}
              >{`${page + 1}/${totalPages}`}</span>
              <div
                style={{
                  cursor: page === totalPages - 1 ? 'auto' : 'pointer',
                  position: 'relative',
                }}
                onClick={handleNextPageClick}
              >
                <div
                  style={{
                    position: 'absolute',
                    width: '36px',
                    height: '36px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
                <ChevronUp
                  style={{
                    transform: 'rotate(270deg)',
                    color: page === totalPages - 1 ? 'gray' : 'currentcolor',
                  }}
                />
              </div>
            </Row>
          )}
        </S.Root>
      }
    >
      <S.StyledButton
        ref={buttonRef}
        disabled={isEmptyList}
        selected={isPopoverOpen}
        onClick={handleBellClick}
      >
        <S.StyledBell />
        {!isEmptyList && (
          <S.NotificationCount>{getNotificationCount()}</S.NotificationCount>
        )}
      </S.StyledButton>
    </Popover>
  )
}

NotificationBell.propTypes = {}

NotificationBell.defaultProps = {}

export default NotificationBell
