import styled from 'styled-components'
import Column from '../Column'
import Row from '../Row'
import UserBadge from '../UserBadge'

export const MemberOption = styled(Row)`
  align-items: center;
`

export const MemberOptionPicture = styled(UserBadge)`
  margin-left: 4px;
  margin-right: 2px;
`

export const MemberOptionColumn = styled(Column)`
  gap: 3px;
`

export const MemberOptionName = styled.div`
  font-style: italic;
`

export const MemberOptionEmail = styled.div``
