import styled from 'styled-components'
import FloatingButton from '../FloatingButton'

export const GreenButton = styled(FloatingButton)`
  background: var(--aqua-79);
  border-color: var(--aqua-79);
  color: var(--aqua-39);
  font-size: 14px;
  cursor: pointer;
  font-family: var(--semibold-font);

  :hover {
    background: var(--aqua-79);
    border-color: var(--aqua-79);
  }
`
