import _ from 'lodash'
import PropTypes from 'prop-types'
import { TaskHistoryList } from '..'
import EmptyState from '../EmptyState'
import { getTaskAllHistory } from './helpers'
import * as S from './styles'

const TaskHistory = ({
  task,
  stateList,
  tagList,
  customFieldList,
  getCompanyPersonById,
  ...containerProps
}) => {
  const history = getTaskAllHistory(task)

  function renderHistoryContent() {
    if (_.isEmpty(history)) {
      return (
        <S.EmptyContainer>
          <EmptyState>No content to show</EmptyState>
        </S.EmptyContainer>
      )
    }

    return (
      <TaskHistoryList
        task={task}
        stateList={stateList}
        tagList={tagList}
        customFieldList={customFieldList}
        getCompanyPersonById={getCompanyPersonById}
        history={history}
      />
    )
  }

  return (
    <S.Container {...containerProps}>
      <S.Content>{renderHistoryContent()}</S.Content>
    </S.Container>
  )
}

TaskHistory.propTypes = {
  task: PropTypes.object.isRequired,
  stateList: PropTypes.array.isRequired,
  tagList: PropTypes.array.isRequired,
  customFieldList: PropTypes.array.isRequired,
  getCompanyPersonById: PropTypes.func.isRequired,
}

export default TaskHistory
