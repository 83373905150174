import styled from 'styled-components'
import X from '../../assets/components/icon_x'
import Column from '../Column'

export const List = styled(Column)`
  margin-top: 15px;
  gap: 6px;
  margin-bottom: 15px;
  max-height: 200px;
  overflow: auto;
`

export const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  align-items: center;
  border-radius: 3px;
  padding 6px;
  transition: all 0.2s ease-in-out;
  user-select: none;
  border: 1px solid currentColor;

  :hover {
    border-color: var(--white-30);
  }
`

export const ItemName = styled.div`
  transition: all 0.1s ease-in-out;
  font-size: 14px;
`

export const ItemIcon = styled(X)`
  justify-self: end;
  color: var(--white-30);
  cursor: pointer;
  transition: all 0.1s ease-in-out;
`
