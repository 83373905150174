import PropTypes from 'prop-types'

export const TaskPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  owner_id: PropTypes.string,
  state_id: PropTypes.string.isRequired,
  project_id: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  task_tags: PropTypes.arrayOf(
    PropTypes.shape({
      tag_id: PropTypes.string.isRequired,
    })
  ),
  priority: PropTypes.string,
  deadline: PropTypes.string,
})
