export const fields = [
  {
    key: 0,
    name: 'name',
    label: 'Name',
    type: 'text',
    placeholder: 'Company name',
    required: true,
  },
  {
    key: 1,
    name: 'project_name',
    label: 'Project',
    type: 'text',
    placeholder: 'Enter project name',
    required: true,
  },
]
