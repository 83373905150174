import ArrowLeft from './components/icon_arrow_left'
import Bell from './components/icon_bell'
import Calendar from './components/icon_calendar'
import ChevronUp from './components/icon_chevron_up'
import Companies from './components/icon_companies'
import Edit from './components/icon_edit'
import IconField from './components/icon_field'
import Filter from './components/icon_filter'
import IconNote from './components/icon_note'
import Paperclip from './components/icon_paperclip'
import People from './components/icon_people'
import IconPriority from './components/icon_priority'
import Projects from './components/icon_projects'
import Settings from './components/icon_settings'
import SlingshotSidebar from './components/icon_slingshot'
import SortAscending from './components/icon_sort_ascending'
import SortDescending from './components/icon_sort_descending'
import SquareMinus from './components/icon_square_minus'
import SquarePlus from './components/icon_square_plus'
import StatusWave from './components/icon_status'
import Tags from './components/icon_tags'
import X from './components/icon_x'
import arrowHeadLeft from './icon_arrow_head_left.svg'
import arrowLeft from './icon_arrow_left.svg'
import arrowRight from './icon_arrow_right.svg'
import bars from './icon_bars.svg'
import branch from './icon_branch.svg'
import closedEye from './icon_closed_eye.svg'
import company from './icon_companies.svg'
import controller from './icon_controller.svg'
import eye from './icon_eye.svg'
import file from './icon_file.svg'
import gears from './icon_gears.svg'
import greenCheckmark from './icon_green_checkmark.svg'
import logout from './icon_logout.svg'
import none from './icon_none.svg'
import slingshot from './icon_slingshot.svg'
import summary from './icon_summary.svg'
import trash from './icon_trash.svg'
import verticalDots from './icon_vertical_dots.svg'
import walkingPerson from './icon_walking_person.svg'
import wind from './icon_wind.svg'

export const ICONS = {
  arrowLeft,
  arrowRight,
  bars,
  branch,
  closedEye,
  eye,
  file,
  greenCheckmark,
  slingshot,
  summary,
  trash,
  walkingPerson,
  verticalDots,
  gears,
  logout,
  wind,
  none,
  company,
  arrowHeadLeft,
  controller,

  SlingshotSidebar,
  Filter,
  People,
  Projects,
  Settings,
  Companies,
  X,
  Paperclip,
  SquarePlus,
  SquareMinus,
  ChevronUp,
  StatusWave,
  Tags,
  Edit,
  Bell,
  Calendar,
  SortAscending,
  SortDescending,
  IconField,
  IconNote,
  IconPriority,
  ArrowLeft,
}
