import styled from 'styled-components'
import Column from '../Column'
import ErrorLabel from '../ErrorLabel'

export const Container = styled(Column)`
  ${(props) =>
    props.$showErrorOutline &&
    `
      outline: 3px solid var(--red);
      outline-offset: 6px;
      border-radius: 3px;
  `}
`

export const ErrorMessage = styled(ErrorLabel)`
  margin: 0;
`
