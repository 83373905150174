import styled from 'styled-components'

export const Owner = styled.div`
  border-radius: 50%;
  color: var(--white-30);
  border: 1px solid var(--white-30);
  font-size: 14px;
  height: 35px;
  width: 35px;
  display: grid;
  place-items: center;
`

export const OwnerPicture = styled.img`
  border-radius: 50%;
  border: 1px solid var(--white-30);
  font-size: 14px;
  height: 35px;
  width: 35px;
`
