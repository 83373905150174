import { omit } from 'ramda'
import { toolkitApi } from '.'

const taskApi = toolkitApi.injectEndpoints({
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getTasks: builder.query({
      query: ({ offset, limit, state_types }) => {
        const search = new URLSearchParams({})

        search.set('offset', offset.toString())
        search.set('limit', limit.toString())
        state_types.forEach((state_type) => {
          search.append('state_types', state_type)
        })

        return {
          url: `/task?${search.toString()}`,
          method: 'GET',
        }
      },
    }),
    createTask: builder.mutation({
      query: (body) => ({
        url: '/task',
        method: 'POST',
        body,
      }),
    }),
    updateTask: builder.mutation({
      query: ({ body, companyId, projectId, id, isGuest }) => ({
        url: isGuest
          ? `/guest/task/${id}`
          : `/company/${companyId}/project/${projectId}/task/${id}`,
        method: 'PUT',
        body: isGuest
          ? omit([
              'owner',
              'project_id',
              'requestor',
              'project',
              'notes',
              'priority',
              'description',
              'title',
            ])(body)
          : body,
      }),
    }),
    deleteTask: builder.mutation({
      query: ({ id, companyId, projectId }) => ({
        url: `/task/${id}?company_id=${companyId}&project_id=${projectId}`,
        method: 'DELETE',
      }),
    }),
    fetchTask: builder.query({
      query: ({ id, params }) => ({
        url: `/task/${id}`,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  useGetTasksQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useFetchTaskQuery,
} = taskApi
