const { hash } = window.location

export const AUTH0_REDIRECT_PATH = '/login/callback'
export const AUTH0_REDIRECT_URI = `${window.location.origin}${AUTH0_REDIRECT_PATH}${hash}`
export const DEV_GRID_COLUMNS = 14
export const HEADER_HEIGHT = 140
export const OWNER_FIELD = 'owner_id'
export const STATUS_FIELD = 'state_id'
export const HOME_PATH = '/home'

export const PRIORITY_OPTIONS = [
  { value: '', label: 'No Priority' },
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
]

export const NOT_DONE_STATE_TYPE_LIST = ['unstarted', 'started']

export const MOTION_CONTAINER_VARIANTS = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

export const MOTION_CASCADE_VARIANTS = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

export const MOTION_OVERLAY_VARIANTS = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.05,
    },
  },
}

export const MOTION_SHOWUP_VARIANTS = {
  hidden: {
    y: '10%',
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 0.2,
    },
  },
}

export const ORDENED_STATUS_LIST = ['unstarted', 'started', 'done']
