import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectApp } from '../../selectors'

const Home = () => {
  const { root, data, isLoading, skip, isGuest } = useSelector(selectApp)

  const userData = root || data

  const navigate = useNavigate()

  if (
    !isGuest &&
    !isLoading &&
    !skip &&
    userData &&
    (!userData.user || !userData.user.profile)
  ) {
    navigate('/onboarding')
  }

  return null
}

export default Home
