import { isEmpty, keys, map, omit, reduce } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { GuestTaskForm, GuestThanks, TitleContent } from '../../components'
import {
  getUpdatedTaskFields,
  isTaskFormValid,
  processServerTaskHistory,
} from '../../components/UpsertTask/helpers'
import {
  readAttachmentUrlFromServer,
  readGuestTaskOnServer,
  updateGuestTaskOnServer,
  uploadGuestAttachmentFilesToServer,
} from '../../services/guestApi'
import { getRandomEmoji } from '../../utils'
import { emojiListPositive } from '../../utils/getRandomEmoji'
import { uniqById } from '../../utils/ramdaExtensions'
import { getQueryParam } from '../../utils/url'
import * as S from './styles'

const GuestTask = () => {
  const [guestData, setGuestData] = useState(null)
  const [error, setError] = useState(null)
  const [isShowThanksModal, setIsShowThanksModal] = useState(false)
  const [showFormErrors, setShowFormErrors] = useState(false)
  const [initAttachments, setInitAttachments] = useState([])

  const token = getQueryParam('token')

  const formRef = useRef(null)

  useEffect(() => {
    ;(async () => {
      if (token) {
        const response = await readGuestTaskOnServer(token)

        if (response.status === 200) {
          const data = await response.json()

          setGuestData(data)
        } else if (response.status === 404) {
          setError('Task cannot be found.')
        } else {
          setError('Cannot find task.')
        }
      }
    })()
  }, [token, setGuestData, setError])

  if (!token) {
    return 'Token is missing'
  }

  function handleThanksClose() {
    setIsShowThanksModal(false)
  }

  async function handleSubmit(formData, files) {
    const newTask = omit([
      'owner',
      'requestor',
      'project',
      'notes',
      'priority',
      'description',
      'title',
    ])({
      ...formData,
      note: isEmpty(formData.note) ? undefined : { text: formData.note },
    })

    const isValidTask = await isTaskFormValid(formData, guestData.workflow)

    if (!isValidTask) {
      setShowFormErrors(true)

      return
    }

    const updatedTaskFields = getUpdatedTaskFields(newTask, guestData.task)

    const response = await updateGuestTaskOnServer(token, updatedTaskFields)

    if (response.status === 200) {
      const taskHistoryFromServer = await response.json()

      const groupId = reduce(
        (acc, key) =>
          acc ||
          taskHistoryFromServer[key]?.group_id ||
          taskHistoryFromServer[key]?.[0]?.group_id ||
          null,
        null
      )(keys(taskHistoryFromServer))

      let attachmentFromServer = null

      if (files.length > 0) {
        const attachmentResponse = await uploadGuestAttachmentFilesToServer(
          guestData.task.id,
          groupId,
          files,
          token
        )

        if (attachmentResponse.status === 200) {
          attachmentFromServer = await attachmentResponse.json()

          setInitAttachments([])
        } else {
          toast.error(
            `Could not upload attachment${files.length > 0 ? 's' : ''}.`
          )
        }
      }

      const updatedTaskHistory = processServerTaskHistory(
        newTask,
        taskHistoryFromServer
      )

      const taskToUpdate = {
        ...updatedTaskFields,
        ...updatedTaskHistory,
        task_tags: map((id) => ({
          tag_id: id,
        }))(formData.tags),
        attachments: uniqById([
          ...(guestData.task.attachments ?? []),
          ...(attachmentFromServer ? [attachmentFromServer] : []),
        ]),
      }

      setGuestData({
        ...guestData,
        task: {
          ...guestData.task,
          ...taskToUpdate,
        },
      })

      toast(`Task updated successfully! ${getRandomEmoji(emojiListPositive)}`)

      setIsShowThanksModal(true)
    } else {
      toast.error('Could not update task.')
    }
  }

  async function handleSave() {
    const { data, fileHandlers } = formRef.current.getTaskData()

    await handleSubmit(data, fileHandlers)
  }

  if (error) {
    return error
  }

  if (!guestData) {
    return null
  }

  const getAttachmentUrl = (attachmentId, fileId) =>
    readAttachmentUrlFromServer(guestData.task.id, attachmentId, fileId, token)

  return (
    <>
      <S.GuestHeader>
        <S.Company>
          <S.CompanyPicture src={guestData?.company_picture_url} />
          {guestData?.company.name}
        </S.Company>
      </S.GuestHeader>
      <S.Container>
        <S.Wrapper>
          <TitleContent
            title="Updating Task"
            controls={[
              <S.UpdateButton key="0" onClick={handleSave}>
                Send
              </S.UpdateButton>,
            ]}
          >
            <S.Form>
              <GuestTaskForm
                {...guestData}
                showFormErrors={showFormErrors}
                onSubmit={handleSubmit}
                formRef={formRef}
                initAttachmentsToAdd={initAttachments}
                getAttachmentUrl={getAttachmentUrl}
              />
            </S.Form>
          </TitleContent>
        </S.Wrapper>
      </S.Container>
      {isShowThanksModal && (
        <GuestThanks
          title="That's all!"
          requestorName={guestData.requestor_name}
          onClose={handleThanksClose}
        />
      )}
    </>
  )
}

export default GuestTask
