import styled from 'styled-components'

const LABEL_SIZE = '12px'

export const ErrorLabel = styled.span`
  display: block;
  font-size: ${LABEL_SIZE};
  color: var(--theme-color-alert);
  margin-top: -4px;
  margin-left: auto;
  margin-bottom: 2px;
  user-select: none;
`
