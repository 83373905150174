import { PropTypes } from 'prop-types'
import { isEmpty, isNil, map } from 'ramda'
import { useState } from 'react'
import { Label, LineInput } from '..'
import * as S from './styles'

const LabelInput = ({
  title,
  value,
  required,
  disabled,
  hidden,
  showRequiredError,
  errors,
  ...inputProps
}) => {
  const [showErrors, setShowErrors] = useState(false)

  const handleBlur = () => {
    setShowErrors(true)

    if (inputProps.onBlur) {
      inputProps.onBlur()
    }
  }

  const handleInput = () => {
    setShowErrors(true)

    if (inputProps.onInput) {
      inputProps.onInput()
    }
  }

  return (
    <S.Container
      $showErrorOutline={
        required && showRequiredError && (isNil(value) || isEmpty(value))
      }
    >
      {!hidden && <Label required={!disabled && required}>{title}</Label>}
      <LineInput
        value={value}
        disabled={disabled}
        {...inputProps}
        onBlur={handleBlur}
        onInput={handleInput}
      />
      {showErrors &&
        map((message) => <S.ErrorMessage key={message} message={message} />)(
          errors
        )}
    </S.Container>
  )
}

LabelInput.propTypes = {
  ...LineInput.propTypes,
  hidden: PropTypes.bool,
  title: PropTypes.string.isRequired,
  showRequiredError: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.array,
}

LabelInput.defaultProps = {
  errors: [],
  hidden: false,
  required: false,
  showRequiredError: false,
}

export default LabelInput
