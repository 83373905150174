import posthog from 'posthog-js'

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
})

export const capture = (eventName) => {
  //   if (process.env.NODE_ENV === 'production')
  posthog.capture(eventName)
}
