import PropTypes from 'prop-types'
import AddOrRemoveList from '../AddOrRemoveList'
import * as S from './styles'

const EditProjectList = ({
  projects,
  isMember,
  projectsToAdd,
  projectsToRemove,
  onChange,
}) => (
  <AddOrRemoveList
    options={projects}
    isPart={isMember}
    toAdd={projectsToAdd}
    toRemove={projectsToRemove}
    onChange={onChange}
    renderContent={(option, color) => (
      <S.ProjectItemName
        style={{
          color,
        }}
      >
        {option.name}
      </S.ProjectItemName>
    )}
  />
)

EditProjectList.propTypes = {
  projects: PropTypes.any.isRequired,
  isMember: PropTypes.bool.isRequired,
  projectsToAdd: PropTypes.array.isRequired,
  projectsToRemove: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

EditProjectList.defaultProps = {}

export default EditProjectList
