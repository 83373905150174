import styled from 'styled-components'
import HoverShadow from '../HoverShadow'

export const Tab = styled(HoverShadow)`
  height: 42px;
  border-radius: 6px;
  background: transparent;
  border: 1px solid var(--white-40);
  color: var(--white-40);
  padding: 5px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  cursor: pointer;

  ${(props) =>
    props.$selected &&
    `
    background: var(--white-90);
  `}
`
