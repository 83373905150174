import styled from 'styled-components'
import FloatingButton from '../FloatingButton'

export const GrayButton = styled(FloatingButton)`
  background: var(--white-90);
  border-color: var(--white-90);
  font-family: var(--semibold-font);
  color: var(--white-20);
  font-size: 14px;

  :hover {
    border-color: var(--white-90);
  }
`
