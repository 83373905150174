import { motion } from 'framer-motion'
import styled from 'styled-components'

export const BackButton = styled(motion.button)`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  borde-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  font-family: var(--semibold-font);
  color: var(--black);
`
