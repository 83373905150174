import PropTypes from 'prop-types'
import { toUpper } from 'ramda'
import { useState } from 'react'
import { getInitials } from '../../utils'
import * as S from './styles'

const UserBadge = ({ name, pictureUrl, ...rest }) => {
  const nameInitials = name ? getInitials(name) : ''

  const [isImageLoadError, setIsImageLoadError] = useState(false)

  const handleImageError = () => {
    setIsImageLoadError(true)
  }

  return pictureUrl && !isImageLoadError ? (
    <S.OwnerPicture
      {...rest}
      src={pictureUrl}
      title={name}
      onError={handleImageError}
    />
  ) : (
    <S.Owner {...rest} title={name}>
      {toUpper(nameInitials)}
    </S.Owner>
  )
}

UserBadge.propTypes = {
  name: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
}

UserBadge.defaultProps = {
  pictureUrl: null,
}

export default UserBadge
