import cohere from 'cohere-js'
import { AnimatePresence } from 'framer-motion'
import { head, isNil, prop } from 'ramda'
import { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  setAttachmentsToUpload,
  setFilter,
  setHasTaskToEdit,
  setNewTaskInit,
  setPreview,
  setSelectedCompanyId,
  setShowInvites,
  setShowUpsertTaskModal,
} from '../../actions'
import { HOME_PATH, NOT_DONE_STATE_TYPE_LIST } from '../../constants'
import { useLogout } from '../../hooks'
import useAppAuth from '../../hooks/useAppAuth'
import { appGetProjectById } from '../../reducers/app/utils'
import { selectApp } from '../../selectors'
import { findId } from '../../utils/ramdaExtensions'
import DeleteProjectModal from '../DeleteProjectModal'
import EditCompanyMemberModal from '../EditCompanyMemberModal'
import EditProjectModal from '../EditProjectModal'
import EditProjectPeopleModal from '../EditProjectPeopleModal'
import Header from '../Header'
import Loading from '../Loading'
import Poller from '../Poller'
import Root from '../Root'
import Sidebar from '../Sidebar'
import TaskArea from '../TaskArea'
import { handleAttachmentDrop } from '../TaskForm'
import UpsertTask from '../UpsertTask'
import { acceptCompanyInvite } from '../UserInvitesModal'
import * as S from './styles'

const getDefaultFilter = (selectedCompany, stateList = []) => {
  const initialFilter = {
    state_id: stateList
      .filter(({ type }) => NOT_DONE_STATE_TYPE_LIST.includes(type))
      .map(prop('id')),
  }

  return initialFilter
}

const getDefaultSelectedCompany = (user) => {
  const initialCompany = head(user?.companies ?? [])

  return initialCompany
}

const App = () => {
  const app = useSelector(selectApp)

  const {
    isGuest,
    root,
    user,
    error,
    preview,
    isLoading,
    filter,
    selectedTask,
    invalidToken,
    showCreateProjectModal,
    projectToDelete,
    projectIdToEdit,
    projectIdToPeople,
    selectedCompanyId,
    selectedCompany,
    showUpsertTaskModal,
    showMobileSidebar,
    memberToEdit,
  } = app

  const { handleLogout } = useLogout()

  useEffect(() => {
    if (root) {
      cohere.identify(root.user.id, {
        environment: process.env.NODE_ENV,
        displayName: root.user.profile?.full_name,
        email: root.user.email,
      })
    }
  }, [root])

  useEffect(() => {
    if (!showUpsertTaskModal) {
      setNewTaskInit({})
    }
  }, [showUpsertTaskModal])

  useEffect(() => {
    if (isNil(selectedCompanyId)) {
      const defaultCompany = getDefaultSelectedCompany(user)

      setSelectedCompanyId(defaultCompany?.id)
    }
  }, [user, selectedCompanyId])

  useEffect(() => {
    if (user && selectedCompanyId) {
      const company = findId(selectedCompanyId, user?.companies ?? [])

      if (!company) {
        const defaultCompany = getDefaultSelectedCompany(user)

        setSelectedCompanyId(defaultCompany?.id)
      }
    }
  }, [user, isGuest, selectedCompanyId])

  useEffect(() => {
    if (invalidToken) {
      handleLogout()
    }
  }, [handleLogout, invalidToken])

  useEffect(() => {
    if (!filter) {
      const nextDefaultFilter = getDefaultFilter(selectedCompany)

      setFilter(nextDefaultFilter)
    }
  }, [filter, selectedCompany])

  const navigate = useNavigate()

  const { hash } = window.location

  useAppAuth()

  useEffect(() => {
    if (!isGuest && ((root && (!user || !user.profile)) || (!root && error))) {
      navigate('/onboarding')
    }
  }, [root, user, navigate, error, isGuest])

  useEffect(() => {
    if (root && hash) {
      if (hash === '#invites') {
        setShowInvites(true)
      }
    }
  }, [root, hash])

  useEffect(() => {
    if (root && hash) {
      const searchParams = new URLSearchParams(`?${hash.slice(1)}`)

      const inviteToken = searchParams.get('invite')

      if (inviteToken) {
        const invite = user.company_invites?.find(
          ({ token }) => token === inviteToken
        )

        if (invite) {
          ;(async () => {
            await acceptCompanyInvite(user, invite)
          })()
        } else {
          toast.error('Invalid URL company invite token.')
        }

        navigate(HOME_PATH)
      }
    }
  }, [root, hash, user, navigate])

  const projectToEdit = appGetProjectById(app, projectIdToEdit)
  const projectToPeople =
    projectIdToPeople && appGetProjectById(app, projectIdToPeople)

  function renderContent() {
    if (isLoading) {
      return (
        <S.Center>
          <Loading message="Loading user" />
        </S.Center>
      )
    }

    const isUpsertTaskModalVisible = showUpsertTaskModal || !isNil(selectedTask)

    return (
      <S.Wrapper
        onDragOver={(event) => {
          event.preventDefault()
        }}
        onDrop={(event) => {
          if (event.dataTransfer?.files.length === 0) {
            return
          }

          if (!isUpsertTaskModalVisible) {
            handleAttachmentDrop(event, (dropFiles) => {
              setAttachmentsToUpload(dropFiles)
              setPreview(false)
              setHasTaskToEdit(false)
              setShowUpsertTaskModal(true)
            })
          } else if (preview) {
            setPreview(false)
          }
        }}
      >
        <S.WrappedContainer>
          <TaskArea />
          <AnimatePresence>
            {isUpsertTaskModalVisible && <UpsertTask key="upsertTask" />}
          </AnimatePresence>
          {showCreateProjectModal && (
            <EditProjectModal
              company={selectedCompany}
              project={projectToEdit}
            />
          )}
          {projectToPeople && (
            <EditProjectPeopleModal
              company={selectedCompany}
              project={projectToPeople}
            />
          )}
          {projectToDelete && (
            <DeleteProjectModal
              company={selectedCompany}
              project={projectToDelete}
            />
          )}
          {memberToEdit && (
            <EditCompanyMemberModal
              member={memberToEdit}
              company={selectedCompany}
            />
          )}
          <Poller t={10} />
        </S.WrappedContainer>
      </S.Wrapper>
    )
  }

  return (
    <Root>
      <DndProvider backend={HTML5Backend}>
        <S.Container>
          {showMobileSidebar && (
            <S.SidebarContainer>
              <Sidebar />
            </S.SidebarContainer>
          )}
          <Header />
          <S.ContentContainer>
            {renderContent()}
            <Outlet />
          </S.ContentContainer>
        </S.Container>
      </DndProvider>
    </Root>
  )
}

export default App
