import PropTypes from 'prop-types'
import { useState } from 'react'
import Column from '../Column'
import * as S from './styles'

const Collapsable = ({ children, title, maxHeight, ...props }) => {
  const [show, setShow] = useState(false)

  return (
    <Column {...props}>
      <S.CustomRow
        onClick={() => {
          setShow(!show)
        }}
      >
        <S.CustomLabel>{title}</S.CustomLabel>{' '}
        <S.CustomChevronUp $show={show} />
      </S.CustomRow>
      <S.CustomColumn $show={show} $maxHeight={maxHeight}>
        {children}
      </S.CustomColumn>
    </Column>
  )
}

Collapsable.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  maxHeight: PropTypes.number,
}

Collapsable.defaultProps = {
  title: '',
  children: null,
  maxHeight: 1000,
}

export default Collapsable
