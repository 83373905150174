import PropTypes from 'prop-types'
import * as S from './styles'

const ErrorLabel = ({ message, ...rest }) => (
  <S.ErrorLabel {...rest}>{message}</S.ErrorLabel>
)

ErrorLabel.propTypes = {
  ...S.ErrorLabel.propTypes,
  message: PropTypes.string.isRequired,
}

ErrorLabel.defaultProps = {}

export default ErrorLabel
