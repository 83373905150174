import { motion } from 'framer-motion'
import TextareaAutosize from 'react-autosize-textarea'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { LabelInput, Row } from '..'
import AttachmentSpace from '../AttachmentSpace'
import Column from '../Column'

const SIDEBAR_WIDTH = 30

export const Form = styled.form``

export const Flex = styled.div`
  display: flex;
  gap: 15px;

  ${(props) =>
    props.center &&
    `
    align-items: center;
  `}
`

export const Content = styled(motion.div)`
  width: calc(100% - ${SIDEBAR_WIDTH}%);
  padding: ${(props) => (props.$nopadding ? '0' : '15px')};
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$nopadding &&
    `
    width: 100%;
    word-break: break-word;
    padding-left: 0px;
    padding-right: 0px;
    transform: translateX(0px);
  `}

  ${(props) =>
    props.body &&
    `
    overflow: auto;
    max-height: 70vh;
  `}

  a {
    text-decoration: none;
    display: inline;
    width: fit-content
    color: var(--aqua-39);
    transition: 0.2s all ease;

    :hover {
      color: var(--aqua-49);
    }

    :visited {
      color: var(--aqua-29);
    }
  }

  ${(props) =>
    props.$sidebar &&
    `
    width: ${SIDEBAR_WIDTH}%;
    overflow: auto;
    max-height: 60vh;
  `};

  ${(props) =>
    props.$scrollbody &&
    `
    overflow: auto;
    max-height: 45vh;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    left: -15px;

    @media screen and (max-width: 1440px) {
      max-height: 35vh;
    }

    @media screen and (max-width: 1366px) {
      max-height: 30vh;
    }
  `}
`

export const StatusContainer = styled.div`
  position: relative;
  z-index: 0;

  ::after {
    display: none;
    content: '';
    width: calc(100% + 42px);
    height: calc(100% + 12px);
    left: -30px;
    top: -8px;
    transition: 0.4s all ease;
    background: ${(props) => props.color};
    position: absolute;
    border-radius: var(--border-radius);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    opacity: 0.15;
    z-index: -1;
  }
`

export const Tutorial = styled(motion.div)`
  margin-left: auto;
  background: var(--aqua-59);
  gap: 8px;
  padding: 6px 8px 4px;
  font-size: 13px;
  color: var(--white);
  font-family: var(--semibold-font);
  display: flex;
  position: absolute;
  bottom: -30px;
  right: 0;
`

export const Textarea = styled(motion(TextareaAutosize))`
  border: none;
  border-bottom: var(--border);
  border-radius: 0px;
  color: var(--white-20);
  resize: none;
  width: 100%;
  padding: 10px 0 0;
  font-size: 16px;
  position: relative;
  min-height: 20px;

  ::placeholder {
    opacity: 0.5;
    text-transform: capitalize;
    font-family: var(--light-font);
  }

  ${(props) =>
    props.name === 'title' &&
    `
    font-size: 24px;
    font-family: var(--semibold-font);
  `}

  ${(props) =>
    props.disabled &&
    `
    background: none;
    border-bottom: none;
  `}
`

export const Text = styled.span`
  font-size: 14px;
  font-family: var(--semibold-font);

  ::before {
    content: '>';
    color: var(--aqua-59);
    margin-right: 8px;
  }
`

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const NotesRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`

export const Preline = styled.p`
  white-space: pre-line;
  min-height: 27px;
`

export const TaskAttachmentSpace = styled(AttachmentSpace)`
  transform: translateX(-5px);
  width: calc(100% + 10px);
`

export const DueDate = styled(LabelInput)`
  font-family: var(--semibold-font);
  font-size: 16px;
  min-height: 38px;

  background: red;
`

export const FieldContainer = styled(Column)`
  gap: 12px;
`

export const FirstColumn = styled(Column)`
  width: calc(100% - 194px);
  gap: 24px;

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const SecondColumn = styled(Column)`
  width: 170px;
  gap: 24px;

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const StatusRow = styled(Row)`
  width: 100%,
  justify-content: space-between,
  flex-wrap: nowrap,
  align-items: flex-end,
  gap: 10px,

  ${
    isMobile &&
    `
    flex-wrap: wrap;
  `
  }
`
