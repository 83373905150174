import PropTypes from 'prop-types'
import PromptModal from '../PromptModal'

const DeleteInviteModal = ({ email, ...modalProps }) => (
  <PromptModal
    title="Remove Invite"
    submitLabel="REMOVE"
    message={`Are you sure you want delete invite for "${email}"?`}
    {...modalProps}
  />
)

DeleteInviteModal.propTypes = {
  ...PromptModal.propTypes,
  email: PropTypes.string.isRequired,
}

DeleteInviteModal.defaultProps = {}

export default DeleteInviteModal
