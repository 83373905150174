import styled from 'styled-components'
import { Icon } from '..'
import Paperclip from '../../assets/components/icon_paperclip'
import X from '../../assets/components/icon_x'
import HoverShadow from '../HoverShadow'

export const File = styled(HoverShadow)`
  border-radius: 5px;
  border: 1px solid currentcolor;
  color: currentcolor;
  padding: 5px 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  color: var(--white-40);

  ${(props) =>
    props.selected &&
    `
    background: var(--aqua-59);
    color: var(--white);
    border-color: var(--aqua-59);
  `}

  ${(props) =>
    props.$touchable &&
    `
    cursor: pointer;
  `}
`

export const FileText = styled.div`
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 7px;
  font-family: inherit;
`

export const FileIcon = styled(Icon)``

export const FilePaperclipIcon = styled(Paperclip)`
  width: 17px;
  height: 17px;
`

export const FileXIcon = styled(X)`
  cursor: pointer;
  width: 17px;
  height: 17px;
`
