import propTypes from 'prop-types'
import { Dropdown } from '..'
import { findValueEq } from '../../utils/ramdaExtensions'
import * as S from './styles'

const LineInput = ({
  name,
  type,
  value,
  options,
  onChange,
  inputRef,
  ...inputProps
}) => {
  if (type === 'select') {
    const dropdownValue = value !== null ? findValueEq(value)(options) : null

    return (
      <Dropdown
        {...inputProps}
        borderless
        options={options}
        value={dropdownValue}
        onChange={(option) => onChange && onChange(option.value)}
        inputRef={inputRef}
      />
    )
  }

  if (type === 'autoresize') {
    return (
      <S.Textarea
        value={value}
        {...inputProps}
        onChange={(event) => onChange && onChange(event.target.value)}
        ref={inputRef}
      />
    )
  }

  if (type === 'radiogroup') {
    return (
      <S.RadioGroup {...inputProps} ref={inputRef}>
        {options.map((option) => (
          <S.Radio key={option.value}>
            <S.LineInput
              type="radio"
              name={name}
              checked={option.value === value}
              onChange={() => onChange && onChange(option.value)}
            />
            {option.label}
          </S.Radio>
        ))}
      </S.RadioGroup>
    )
  }

  return (
    <S.LineInput
      {...inputProps}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      inputRef={inputRef}
    />
  )
}

LineInput.propTypes = {
  ...S.LineInput.propTypes,
  inputRef: propTypes.func,
}

LineInput.defaultProps = {
  inputRef: null,
}

export default LineInput
