import { combineReducers } from '@reduxjs/toolkit'
import { routerReducer } from 'react-router-redux'
import { persistReducer } from 'redux-persist'
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1'
import storage from 'redux-persist/lib/storage'
import { toolkitApi } from '../services'
import appReducer from './app'
import pollerReducer from './poller'

const appPersistConfig = {
  key: 'app',
  storage,
  whitelist: [
    'token',
    'lastSeenDate',
    'selectedCompanyId',
    'companyOpenSidebarMenu',
    'companyFilters',
    'companyShowDetails',
    'companySortBy',
    'companySelectedProjectIdList',
    'companySelectedFilteredTags',
  ],
  stateReconciler: autoMergeLevel1,
}

const pollerPersistConfig = {
  key: 'poller',
  storage,
  whitelist: ['lastUpdateDate'],
  stateReconciler: autoMergeLevel1,
}

const reducer = combineReducers({
  [toolkitApi.reducerPath]: toolkitApi.reducer,
  routing: routerReducer,
  app: persistReducer(appPersistConfig, appReducer),
  poller: persistReducer(pollerPersistConfig, pollerReducer),
})

export default reducer
