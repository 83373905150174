import PropTypes from 'prop-types'

export default function People({ ...svgProps }) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...svgProps}
    >
      <path
        d="M1.50781 17.7109C1.50781 14.7109 5.50781 14.7109 7.50781 12.7109C8.50781 11.7109 5.50781 11.7109 5.50781 6.71094C5.50781 3.37794 6.84081 1.71094 9.50781 1.71094C12.1748 1.71094 13.5078 3.37794 13.5078 6.71094C13.5078 11.7109 10.5078 11.7109 11.5078 12.7109C13.5078 14.7109 17.5078 14.7109 17.5078 17.7109"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

People.propTypes = {
  stroke: PropTypes.string,
}

People.defaultProps = {
  stroke: '#808080',
}
