import PropTypes from 'prop-types'
import { includes } from 'ramda'
import { AddButton, Tag } from '..'
import { stopPropagationAnd } from '../../utils/event/stopPropagationAnd'
import { hasId } from '../../utils/ramdaExtensions'
import * as S from './styles'

const Tags = ({
  tags,
  selected,
  touchable,
  onTagClick,
  onTagXClick,
  onAddTagClick,
  disabled,
  isEditMode,
}) => {
  function handleTagClick(tag) {
    onTagClick(tag)
  }

  function handleAddTagClick() {
    onAddTagClick()
  }

  return (
    <S.Tags>
      {tags.map((tag) => (
        <Tag
          key={tag.title}
          tag={tag}
          $isNew={!hasId(tag)}
          $touchable={touchable}
          onClick={() => {
            if (!disabled) {
              handleTagClick(tag)
            }
          }}
          onXClick={stopPropagationAnd(() => {
            onTagXClick(tag)
          })}
          $isEditMode={isEditMode}
          selected={includes(tag.id, selected)}
          disabled={disabled}
        />
      ))}
      {isEditMode && (
        <AddButton onClick={handleAddTagClick}>+ Add a Tag</AddButton>
      )}
    </S.Tags>
  )
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  touchable: PropTypes.bool,
  selected: PropTypes.array,
  onTagClick: PropTypes.func,
  onTagXClick: PropTypes.func,
  disabled: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onAddTagClick: PropTypes.func,
}

Tags.defaultProps = {
  disabled: false,
  isEditMode: false,
  selected: [],
  touchable: false,
  onTagXClick: () => {},
  onTagClick: () => {},
  onAddTagClick: () => {},
}

export default Tags
