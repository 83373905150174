import { toolkitApi } from '.'

const rootApi = toolkitApi.injectEndpoints({
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getRoot: builder.query({
      query: ({ isGuest }) => ({
        url: isGuest ? '/guest' : '/',
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
  }),
})

export const { useGetRootQuery } = rootApi
