import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import parse from 'html-react-parser'
import Linkify from 'linkify-react'
import PropTypes from 'prop-types'
import { isEmpty, map, pipe, prop, __ } from 'ramda'
import { Row } from '..'
import { formatMMDDYYYY } from '../../utils/date'
import { filterNotNil, findId } from '../../utils/ramdaExtensions'
import { NO_TASK_OWNER_LABEL } from '../UpsertTask'
import * as S from './styles'

dayjs.extend(relativeTime)

const LINKIFY_OPTIONS = {
  target: '_blank',
}

export function getCompanyPersonName(person) {
  return (
    person?.profile?.full_name ??
    person?.company_outsider?.name ??
    NO_TASK_OWNER_LABEL
  )
}

export function addTypeToArray({ array = [], type }) {
  return array.map((props) => ({ ...props, type }))
}

const TaskHistoryEntry = ({
  userId,
  date,
  group,
  stateList,
  tagList,
  customFieldList,
  getCompanyPersonById,
}) => {
  const {
    historyCustomField,
    historyOwner,
    historyRequestor,
    historyDeadline,
    historyPriority,
    historyState,
    historyTag,
    note,
    attachment,
  } = group

  const updater = getCompanyPersonById(userId)
  const updaterName = getCompanyPersonName(updater)

  const renderOwner = (item) => {
    const to = getCompanyPersonById(item.owner_id)

    const toName = getCompanyPersonName(to)

    return (
      <S.HistoryOwner>
        <S.OwnerIcon />
        <S.FieldName>Owner:</S.FieldName>
        <S.FieldValue>{toName}</S.FieldValue>
      </S.HistoryOwner>
    )
  }

  const renderRequestor = (item) => {
    const to = getCompanyPersonById(item.requestor_id)

    const toName = getCompanyPersonName(to)

    return (
      <S.HistoryOwner>
        <S.RequestorIcon />
        <S.FieldName>Requestor:</S.FieldName>
        <S.FieldValue>{toName}</S.FieldValue>
      </S.HistoryOwner>
    )
  }

  const renderCustomFields = (items) => (
    <S.HistoryCustomFields>
      {items.map((item, index) => {
        const { field_id, value } = item

        const field = findId(field_id)(customFieldList)

        if (!field) {
          return null
        }

        const tryParse = () => {
          try {
            return JSON.parse(value)
          } catch (e) {
            return value
          }
        }

        const showValue = tryParse()

        return (
          <S.HistoryCustomField key={index}>
            <S.CustomFieldIcon />
            <S.FieldName>{field.name}:</S.FieldName>
            <S.FieldValue>{showValue}</S.FieldValue>
          </S.HistoryCustomField>
        )
      })}
    </S.HistoryCustomFields>
  )

  const renderDeadline = (item) => {
    const { deadline } = item

    return (
      <S.HistoryDeadline>
        <S.ReminderIcon />
        <S.FieldName>{`Due date:`}</S.FieldName>
        <S.FieldValue>{`${formatMMDDYYYY(deadline)}`}</S.FieldValue>
      </S.HistoryDeadline>
    )
  }

  const renderPriority = (item) => {
    const { priority } = item

    return (
      <S.HistoryPriority>
        <S.PriorityIcon />
        <S.FieldName>{`Priority:`}</S.FieldName>
        <S.HistoryPriorityItem priority={priority}></S.HistoryPriorityItem>
      </S.HistoryPriority>
    )
  }

  const renderState = (item) => {
    const { state_id } = item

    const state = findId(state_id)(stateList)

    if (!state) {
      return null
    }

    return (
      <S.HistoryState>
        <S.StatusIcon />
        <S.FieldName>Status:</S.FieldName>
        <S.FieldValue>{state.title}</S.FieldValue>
      </S.HistoryState>
    )
  }

  const mapTags = pipe(
    map(pipe(prop('tag_id'), findId(__, tagList))),
    filterNotNil
  )

  const renderTag = (item) => {
    const { added = [], removed = [] } = item

    const addedTags = mapTags(added)
    const removedTags = mapTags(removed)

    const renderTags = (tags, Class) =>
      tags.length > 0 && (
        <>
          <Row>
            {tags.map(
              (tag) =>
                tag && (
                  <Class disabled={true} key={tag.id} tag={tag}>
                    {tag.title}
                  </Class>
                )
            )}
          </Row>
        </>
      )

    return (
      <S.HistoryTag>
        <S.TagsIcon />
        <div>{`Tags:`}</div>
        {renderTags(addedTags, S.AddedTag)}
        {renderTags(removedTags, S.RemovedTag)}
      </S.HistoryTag>
    )
  }

  const renderNote = (item) => {
    const { text } = item

    return (
      <S.Note>
        <S.NoteIcon />
        <S.NoteBody>
          <Linkify options={LINKIFY_OPTIONS}>{parse(text)}</Linkify>
        </S.NoteBody>
      </S.Note>
    )
  }

  const renderAttachment = (item) => {
    const { files } = item

    return (
      <S.HistoryFiles>
        <S.PaperclipIcon />
        <S.FieldName>Files:</S.FieldName>
        {files.map((file) => (
          <S.HistoryAttachmentFile
            key={file.filename}
            file={file}
            disabled={true}
            $isNew={false}
            showIcon={false}
            $touchable={false}
            isRemovable={false}
            onClick={() => {}}
            onXClick={() => {}}
          />
        ))}
      </S.HistoryFiles>
    )
  }

  const isEmptyAttachments = !attachment || isEmpty(attachment.files)
  const isEmptyTags =
    !historyTag ||
    (isEmpty(mapTags(historyTag.added ?? [])) &&
      isEmpty(mapTags(historyTag.removed ?? [])))

  const isEmptyHistory =
    !historyRequestor &&
    !historyOwner &&
    !historyState &&
    !historyPriority &&
    !historyDeadline &&
    !historyCustomField &&
    !note &&
    isEmptyAttachments &&
    isEmptyTags

  if (isEmptyHistory) {
    return null
  }

  return (
    <S.HistoryContainer>
      <S.UpdaterRow>
        <Row style={{ alignItems: 'center', gap: '24px' }}>
          <S.StyledUserBadge name={updaterName} />
          <strong>{updaterName}</strong>
        </Row>
        <S.Date>{dayjs(date).fromNow()}</S.Date>
      </S.UpdaterRow>
      <S.UpdatesColumn>
        {historyRequestor && renderRequestor(historyRequestor)}
        {historyOwner && renderOwner(historyOwner)}
        {historyState && renderState(historyState)}
        {historyTag && renderTag(historyTag)}
        {historyPriority && renderPriority(historyPriority)}
        {historyDeadline && renderDeadline(historyDeadline)}
        {historyCustomField && renderCustomFields(historyCustomField)}
        {note && renderNote(note)}
        {attachment && renderAttachment(attachment)}
      </S.UpdatesColumn>
    </S.HistoryContainer>
  )
}

TaskHistoryEntry.propTypes = {
  userId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  group: PropTypes.shape({
    historyCustomField: PropTypes.array,
    historyOwner: PropTypes.object,
    historyState: PropTypes.object,
    historyRequestor: PropTypes.object,
    historyDeadline: PropTypes.object,
    historyPriority: PropTypes.object,
    historyTag: PropTypes.object,
    note: PropTypes.object,
    attachment: PropTypes.object,
  }).isRequired,
  stateList: PropTypes.array.isRequired,
  tagList: PropTypes.array.isRequired,
  customFieldList: PropTypes.array.isRequired,
  getCompanyPersonById: PropTypes.func.isRequired,
}

export default TaskHistoryEntry
