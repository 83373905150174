import { useSelector } from 'react-redux'
import { NotificationBell, SearchBox } from '..'
import { selectApp } from '../../selectors'
import CompanyList from '../CompanyList'
import NewTask from '../NewTask'
import SelectedFilters from '../SelectedFilters'
import UserMenu from '../UserMenu'
import * as S from './styles'

const Header = () => {
  const { isUserCollaborator } = useSelector(selectApp)

  return (
    <S.Container>
      <S.Row>
        <CompanyList />
        <SearchBox />
        <NotificationBell />
        <UserMenu />
      </S.Row>
      <S.Row>
        <SelectedFilters />
        {isUserCollaborator && <NewTask />}
      </S.Row>
    </S.Container>
  )
}

export default Header
