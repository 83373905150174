import {
  configureStore as createStore,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import rootReducer from './reducers'
import { toolkitApi } from './services'

const rtkQueryErrorLogger = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    toast.error(action?.payload?.data?.error?.message)
  }

  return next(action)
}

export default function configureStore() {
  const store = createStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(toolkitApi.middleware, rtkQueryErrorLogger),
  })

  return store
}
