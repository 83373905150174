import styled from 'styled-components'
import { getPriorityColor } from '../TaskCard/helpers'

export const Priority = styled.div`
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 30px;
  font-family: var(--semibold-font);
  background-color: var(--white-95);

  ::after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    background: var(--${(props) => getPriorityColor(props.priority)});
  }
`
