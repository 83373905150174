import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { isNil, map, reverse } from 'ramda'
import {
  MOTION_CASCADE_VARIANTS,
  MOTION_CONTAINER_VARIANTS,
} from '../../constants'
import { includesId, isNotEmpty } from '../../utils/ramdaExtensions'
import MiniCard from '../MiniCard'
import * as S from './styles'

const MiniCardList = ({
  list,
  selectedList,
  setSelected,
  isReverse,
  menuOptions,
  icon,
  hasOwnerId,
  disabled,
  getTaskCount,
  showClearAll,
  onClearAll,
  separateBy,
  renderContent,
  action,
  actionName,
}) => {
  function handleHeaderAction() {
    return action()
  }

  function isPeopleList(item) {
    return !isNil(item.full_name)
  }

  const handleClick = ({ item, selected }) => {
    setSelected(item, !selected)
  }

  const parsedList = isReverse ? reverse(list) : list

  const isAnySelected = isNotEmpty(selectedList)

  const groups = isNil(separateBy)
    ? [{ title: '', list: parsedList }]
    : separateBy(parsedList)

  return (
    <S.Container
      initial="hidden"
      animate="visible"
      variants={MOTION_CONTAINER_VARIANTS}
    >
      <>
        <S.Content>
          {groups.map(({ title, list: groupList }) => (
            <>
              {title && (
                <motion.div variants={MOTION_CASCADE_VARIANTS}>
                  <S.Section>{title}</S.Section>
                </motion.div>
              )}
              {groupList.map((item, index) => {
                const selected = includesId(item.id, selectedList)

                const miniCardProps = {
                  icon,
                  selected,
                  menuOptions: map(({ onClick, ...rest }) => ({
                    onClick: () => {
                      if (onClick) {
                        onClick(item)
                      }
                    },
                    ...rest,
                  }))(
                    typeof menuOptions === 'function'
                      ? menuOptions(item)
                      : menuOptions
                  ),
                  title: item.name || item.full_name || item.title,
                  onClick: disabled
                    ? null
                    : () => handleClick({ item, selected }),
                  owner_id: hasOwnerId ? item.id : null,
                  isPeopleList: isPeopleList(item),
                  color: item.default_color,
                  taskCount: getTaskCount(item),
                }

                return (
                  <motion.div key={index} variants={MOTION_CASCADE_VARIANTS}>
                    <MiniCard {...miniCardProps} />
                  </motion.div>
                )
              })}
              {renderContent && renderContent()}
            </>
          ))}
        </S.Content>
      </>
      <S.Action>
        {showClearAll && (
          <S.ClearAllButton
            onClick={() => {
              onClearAll()
            }}
          >
            {isAnySelected ? 'Clear All' : 'Select All'}
          </S.ClearAllButton>
        )}
        {!isNil(action) && (
          <S.Button onClick={handleHeaderAction}>{actionName}</S.Button>
        )}
      </S.Action>
    </S.Container>
  )
}

MiniCardList.propTypes = {
  action: PropTypes.func,
  actionName: PropTypes.string,
  list: PropTypes.array,
  selectedList: PropTypes.array,
  setSelected: PropTypes.func,
  isReverse: PropTypes.bool,
  menuOptions: PropTypes.array,
  icon: PropTypes.string,
  hasOwnerId: PropTypes.bool,
  disabled: PropTypes.bool,
  getTaskCount: PropTypes.func,
  showClearAll: PropTypes.bool,
  onClearAll: PropTypes.func,
  separateBy: PropTypes.func,
  renderContent: PropTypes.func,
}

MiniCardList.defaultProps = {
  list: [],
  selectedList: [],
  menuOptions: [],
  setSelected: null,
  isReverse: false,
  icon: null,
  hasOwnerId: false,
  disabled: false,
  showClearAll: false,
  onClearAll: () => {},
  getTaskCount: () => {},
  separateBy: undefined,
  renderContent: undefined,
  action: null,
  actionName: null,
}

export default MiniCardList
