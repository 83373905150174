import PropTypes from 'prop-types'
import { useRef } from 'react'
import Select, { createFilter } from 'react-select'
import * as S from './styles'

const NoOptionsMessage = (props) => (
  <S.NoOptionsMessage {...props}>No results found</S.NoOptionsMessage>
)

function getIndex({ option, options }) {
  return options.findIndex((t) => t === option.data)
}

const SingleValue = (props) => <S.SingleValue {...props} />

const Dropdown = ({
  borderless,
  disabled,
  valueStyle,
  components,
  inputRef,
  style,
  styles,
  ...props
}) => {
  const containerRef = useRef(null)

  return (
    <div ref={containerRef}>
      <Select
        id="scrollContainer"
        openMenuOnFocus={true}
        menuPlacement="auto"
        filterOption={createFilter({ ignoreAccents: false })}
        isDisabled={disabled}
        closeMenuOnScroll={(e) => e.target.contains(containerRef.current)}
        menuPortalTarget={document.getElementById('modal-root')}
        styles={{
          container: (base) => ({ ...base, ...style }),
          menuPortal: (base) => ({ ...base, zIndex: 3 }),
          ...(styles ?? {}),
        }}
        ref={inputRef}
        components={{
          NoOptionsMessage,
          SingleValue,
          IndicatorSeparator: null,
          Menu: (menuProps) => (
            <S.Menu borderless={borderless} {...menuProps} />
          ),
          Option: (optionProps) => (
            <S.Option
              index={getIndex({ option: optionProps, options: props.options })}
              borderless={borderless}
              {...optionProps}
            />
          ),
          IndicatorsContainer: (indicatorsContainerProps) => (
            <S.IndicatorsContainer
              borderless={borderless}
              {...indicatorsContainerProps}
              $hidden={disabled}
            />
          ),
          ValueContainer: (valueContainerProps) => (
            <S.ValueContainer
              style={{ ...valueContainerProps.style, ...valueStyle }}
              borderless={borderless}
              {...valueContainerProps}
            />
          ),
          Control: (controlProps) => (
            <S.Control borderless={borderless} {...controlProps} />
          ),
          MenuList: (menuListProps) => (
            <S.MenuList borderless={borderless} {...menuListProps} />
          ),
          ...components,
        }}
        {...props}
      />
    </div>
  )
}

Dropdown.propTypes = {
  ...Select.propTypes,
  components: PropTypes.object,
  borderless: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
}

Dropdown.defaultProps = {
  components: {},
  borderless: false,
  disabled: false,
  options: [],
}

export default Dropdown
