import PropTypes from 'prop-types'
import * as S from './styles'

const Priority = ({ priority, ...rest }) => (
  <S.Priority priority={priority} {...rest}>
    {priority || 'N/A'}
  </S.Priority>
)

Priority.propTypes = {
  priority: PropTypes.any.isRequired,
}

Priority.defaultProps = {}

export default Priority
