import { serverDelete, serverPost, serverPut } from './server'

export async function inviteMember(companyId, member) {
  return await serverPost(`/company/${companyId}/invite`, {
    body: member,
  })
}

export async function deleteCompanyInviteOnServer(companyId, inviteId) {
  return await serverDelete(`/company/${companyId}/invite/${inviteId}`)
}

export async function updateCompanyMemberOnServer(companyId, memberId, member) {
  return await serverPut(`/company/${companyId}/member/${memberId}`, {
    body: member,
  })
}

export async function deleteCompanyMemberOnServer(companyId, memberId) {
  return await serverDelete(`/company/${companyId}/member/${memberId}`)
}
